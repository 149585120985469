@use "../global" as *;

.secondary-hero {
    display: grid;
    place-content: center;
    padding-top: clamp(6rem, 6.5vw, 7.5rem);
    padding-bottom: clamp(6rem, 6.5vw, 7.5rem);
    min-height: 100vh;
    position: relative;
    isolation: isolate;
    background-image: url(../img/secondary-hero-bg.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        z-index: -1;
        background-color: hsl(var(--neutral-1000)/.4);
    }

    &__btn-group {
        .list-group-item {
            &.active {
                background-color: hsl(var(--primary-300)) !important;
            }
        }
    }
}