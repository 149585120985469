@use "../global" as *;

.property-filter {
    display: flex;
    flex-wrap: wrap;
    background-color: hsl(var(--neutral-0));

    &__item {
        padding: 1.75rem 1.5rem;
        width: 100%;

        @include screen(md) {
            width: 33%;
        }

        @include screen(xl) {
            width: calc(33% - 27px);
        }

        &+.property-filter__item {
            border-block-start: 1px solid hsl(var(--neutral-50));

            @include screen(md) {
                border-block-start: 0;
                border-inline-start: 1px solid hsl(var(--neutral-50));
            }
        }

        .form-select {
            border: none;
            color: hsl(var(--neutral-600));

            &:focus {
                box-shadow: unset;
            }
        }

        .property-search__price {
            width: 100%;
            background: transparent;
            border: none;
        }

        .property-search__range-price {
            background-color: hsl(var(--primary-50));
            inset-block-start: -0.5rem;
            inset-inline-end: 1rem;
        }
    }

    &__btn {
        display: inline-block;
        width: 100%;
        border: 0;
        padding: 1.5rem;
        background-color: hsl(var(--primary-300));
        color: hsl(var(--neutral-0));
        transition: all .3s ease;

        @include screen(xl) {
            width: auto;
        }

        &:hover {
            background-color: hsl(var(--primary-400));
        }

        span {
            transform: rotate(90deg);
        }
    }
}