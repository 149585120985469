@use "../global" as *;

.flight-card {
    &__info {
        @include screen(md) {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem 1.5rem;
            background-color: hsl(var(--primary-300)/.04);
            border-radius: .5rem;
        }

        p+p {
            @include screen(md) {
                margin-left: 1rem;
                padding-left: 1rem;
                position: relative;
                isolation: isolate;

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: .25rem;
                    height: .25rem;
                    transform: translateY(-50%);
                    border-radius: 50%;
                    background-color: hsl(var(--primary-300));
                }
            }
        }
    }

    &__footer {
        border-radius: 0 0 1rem 1rem;

        @include screen(md) {
            border-radius: 0 1rem 1rem 0;
        }
    }
}