@use "../global" as *;

.scroll-nav {
    display: none;

    @include screen(exxl) {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        inset-inline-start: 50%;
        inset-block-end: #{rem(32)};
        transform: translateX(-50%);
    }
}