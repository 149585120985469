@use "sass:map";
@use "variables" as *;

@mixin screen($key) {
  $size: map.get($screen-sizes, $key);
  @media (min-width: $size) {
    @content;
  }
}
//Mobile Landscape
@mixin landscape {
  @media all and (orientation: landscape) {
    @content;
  }
}

//Mobile Potrait
@mixin potrait {
  @media all and (orientation: potrait) {
    @content;
  }
}
