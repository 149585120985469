@use "../global" as *;

#map {
    height: 600px;
    min-height: 100%;

    @include screen(md) {
        height: 1595px;
    }

    @include screen(lg) {
        height: 1545px;
    }

    @include screen(xl) {
        height: 1220px;
    }
}