@use "../global" as *;

.cta-section {
    position: relative;
    isolation: isolate;
    padding-inline: 1rem;

    @include screen(sm) {
        padding-inline: 1.5rem;
    }

    @include screen(md) {
        padding-inline: 2.5rem;
    }

    @include screen(xl) {
        padding-inline-start: #{rem(80)};
        padding-inline-end: 0;
    }

    @include screen(xxl) {
        padding-inline-start: #{rem(120)};
        padding-inline-end: 0;
    }

    &::before {
        @include screen(lg) {
            content: '';
            position: absolute;
            inset-block-start: 1.5rem;
            inset-block-end: 0;
            inset-inline-start: 60%;
            inset-inline-end: 0;
            background-image: url(../img/cta-img.png);
            background-position: bottom;
            background-size: contain;
            background-repeat: no-repeat;
            z-index: -1;
        }
    }

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        z-index: -2;
        background-image: url(../img/cta-bg.png), linear-gradient(180deg, hsl(var(--primary-300)/.3) 0, hsl(var(--primary-300)/.15) 55.86%, hsl(var(--primary-300)/.3) 100%);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @include screen(xl) {
            inset-inline-end: -100%;
        }
    }
}