.team-slider {
    &__pagination {
        bottom: -40px !important;

        .swiper-pagination-bullet {
            width: .75rem;
            height: .75rem;
            opacity: 1;
            background-color: hsl(var(--neutral-40));

            &-active {
                width: 1.75rem;
                height: .75rem;
                border-radius: 1rem;
                background-color: hsl(var(--primary-300));
            }
        }
    }
}