@use "../global" as *;

.link {
    text-decoration: none;
    transition: all .3s ease;
}

.ff-1 {
    font-family: var(--ff-1);
}

.lh-heading {
    line-height: 1.2;
}

.transition {
    transition: all 0.3s ease;
}

.place-content-center {
    place-content: center;
}

.place-items-center {
    place-items: center;
}

.position-center {
    inset-inline-start: 50%;
    inset-block-start: 50%;
    transform: translate(-50%, -50%);
}

.translate-quarter {
    transform: translate(-25%, -25%);
}

.translate-center {
    transform: translate(-50%, -50%);
}

.category-section-element {
    inset-block-start: 50px;
    inset-inline-start: 40px;
}

.featured-section-el-1 {
    inset-block-start: 50px;
    inset-inline-end: 40px;
}

.featured-section-el-2 {
    inset-block-start: 50%;
    inset-inline-start: 60px;
    transform: translateY(-50%);
}

.hr-dashed {
    height: 1px;
    background-image: linear-gradient(to right, hsl(var(--neutral-50)) 33%, transparent 0%);
    background-position: bottom;
    background-size: .75rem 1px;
    background-repeat: repeat-x;
}

.hr-line {
    height: 1px;
    background-image: linear-gradient(to right, hsl(var(--neutral-40)), hsl(var(--neutral-40)));
    background-position: bottom;
    background-size: .75rem 1px;
    background-repeat: repeat-x;
}

.drop-shadow {
    filter: drop-shadow(0 .25rem .5rem hsl(var(--black)/.07));
}

.outline-shadow {
    outline: 0 0 1rem hsl(var(--black)/.07);
}

.box-shadow {
    box-shadow: 0 .25rem .75rem hsl(var(--black)/.07);
}

.shadow-1 {
    box-shadow: 0 6px 30px hsl(var(--black)/.08);
}

.shadow-2 {
    box-shadow: 0 6px 30px hsl(var(--black)/.06);
}

.shadow-3 {
    box-shadow: 0 6px 30px hsl(var(--black)/.04);
}

.\:box-shadow {
    transition: all .3s ease;

    &:hover {
        box-shadow: 0 .25rem .75rem hsl(var(--black)/.07);
    }
}

.border-primary {
    --bs-border-color: hsl(var(--primary-300));

    &-50 {
        --bs-border-color: hsl(var(--primary-50));
    }

    &-100 {
        --bs-border-color: hsl(var(--primary-100));
    }

    &-300 {
        --bs-border-color: hsl(var(--primary-300));
    }

    &-400 {
        --bs-border-color: hsl(var(--primary-400));
    }

    &-500 {
        --bs-border-color: hsl(var(--primary-500));
    }
}

.border-neutral {
    --bs-border-color: hsl(var(--neutral-0));

    &-30 {
        --bs-border-color: hsl(var(--neutral-30));
    }

    &-40 {
        --bs-border-color: hsl(var(--neutral-40));
    }

    &-50 {
        --bs-border-color: hsl(var(--neutral-50));
    }

    &-100 {
        --bs-border-color: hsl(var(--neutral-100));
    }

    &-200 {
        --bs-border-color: hsl(var(--neutral-200));
    }
}

.border-dashed {
    --bs-border-width: 2px;
    --bs-border-style: dashed;
    --bs-border-color: hsl(var(--neutral-40));
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.\:border-0 {
    transition: all .3s ease;

    &:hover {
        border: 1px solid transparent !important;
    }
}

.\:\:placeholder-neutral-30 {
    &::placeholder {
        color: hsl(var(--neutral-30));
    }
}

.\:\:placeholder-neutral-300 {
    &::placeholder {
        color: hsl(var(--neutral-300));
    }
}

.\:\:placeholder-neutral-600 {
    &::placeholder {
        color: hsl(var(--neutral-600));
    }
}

.\:focus-outline-0 {
    &:focus {
        outline: none;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.form-control {
    border-radius: .25rem;

    &:focus {
        box-shadow: none;
        border-color: var(--bs-border-color);
    }
}

.form-select {
    border-radius: .25rem;

    &:focus {
        box-shadow: none;
        border-color: var(--bs-border-color);
    }
}

.custom-form-select {
    padding: 0.375rem 1.5rem 0.375rem 0.75rem;
    background-position: right 0 center;
}

.active-clr {
    color: hsl(var(--primary-300)) !important;
}

.active-bg {
    background-color: hsl(var(--primary-300)) !important;
}

.scale-05 {
    @include screen(xxl) {
        transform: scale(1.05);
    }
}

.max-width {
    width: max-content;
}

.white-space-normal {
    white-space: normal;
}

.notifications-content {
    max-width: 20ch;

    @include screen(xs) {
        max-width: 24ch;
    }
}

.overflow-x-auto {
    scrollbar-color: hsl(var(--neutral-30)/.5) hsl(var(--neutral-30)/.5);
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: .25rem;
        height: .25rem;
    }

    &::-webkit-scrollbar-track {
        background: hsl(var(--neutral-30)/.5);
    }

    &::-webkit-scrollbar-thumb {
        background: hsl(var(--neutral-30)/.5);
    }
}

.overflow-y-auto {
    scrollbar-color: transparent transparent;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: transparent;
    }
}

.inset-0 {
    inset: 0;
}

.pointer-none {
    pointer-events: none;
}

.w-lg-auto {
    @include screen(lg) {
        width: auto !important;
    }
}

.table-primary-50 {
    --bs-table-bg: hsl(var(--primary-300)/.05);
    --bs-table-color: hsl(var(--neutral-500));
}