@use "../global" as *;

.discount-card {
    &__img {
        position: absolute;
        inset: 0;
        z-index: -1;
        object-fit: cover;
    }

    &__content {
        @include screen(lg) {
            width: 60%;
        }

        @include screen(xl) {
            width: 50%;
        }
    }

}