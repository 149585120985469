@use "../global" as *;

.signup-section {
    @include screen(xl) {
        position: relative;
        isolation: isolate;

        &::after {
            content: '';
            position: absolute;
            inset-block: clamp(2rem, 3.25vw + 1rem, 3.75rem);
            inset-inline-end: 0;
            inset-inline-start: 55%;
            background-image: url(../img/login-img.png);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}