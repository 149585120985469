.faq-banner {
    position: relative;
    isolation: isolate;
    background-image: url(../img/faq-banner-img.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        z-index: -1;
        background: linear-gradient(180deg, rgba(38, 41, 166, 0.4) 0%, rgba(38, 41, 166, 0.8) 45.16%, #2629A6 100%);
        transform: matrix(1, 0, 0, -1, 0, 0);
    }
}