.auth-slider {
    &__btn {
        display: flex;
        align-items: center;
        gap: .75rem;
    }

    &__prev {
        --swiper-navigation-size: 40px;
        position: static;
        margin: 0;
        width: var(--swiper-navigation-size);
        height: var(--swiper-navigation-size);
        border: 1px solid hsl(var(--primary-300)/.5);
        border-radius: 50%;
        transition: all .3s ease;

        &:hover {
            background: hsl(var(--primary-300));

            &::after {
                color: hsl(var(--neutral-0));
            }
        }

        &::after {
            font-size: 1.5rem;
            color: hsl(var(--primary-300)/.5);
        }
    }

    &__next {
        --swiper-navigation-size: 40px;
        position: static;
        margin: 0;
        width: var(--swiper-navigation-size);
        height: var(--swiper-navigation-size);
        border: 1px solid hsl(var(--primary-300)/.5);
        border-radius: 50%;
        transition: all .3s ease;

        &:hover {
            background: hsl(var(--primary-300));
            color: hsl(var(--neutral-0));

            &::after {
                color: hsl(var(--neutral-0));
            }
        }

        &::after {
            font-size: 1.5rem;
            color: hsl(var(--primary-300)/.5);
        }
    }
}