@use "../global" as *;

.testimonial-section {
    position: relative;
    isolation: isolate;

    &__el {
        display: none;

        @include screen(exl) {
            display: block;
            position: absolute;
        }

        &-1 {
            @include screen(exl) {
                inset-block-start: rem(50);
                inset-inline-start: rem(50);
            }
        }

        &-2 {
            @include screen(exl) {
                inset-block-start: 0;
                inset-inline-end: 0;
            }
        }

        &-3 {
            @include screen(exl) {
                inset-block-start: 50%;
                inset-inline-end: rem(56);
                transform: translateY(-50%);
            }
        }
    }
}