@use "../global" as *;
.why-choose-el {
    display: none;

    @include screen(exxl) {
        display: block;
        position: absolute;
        z-index: -1;

        &--1 {
            inset-block-start: 50%;
            inset-inline-start: 0;
            transform: translate(-100%, -50%);
        }

        &--2 {
            inset-block-end: 0;
            inset-inline-start: 0;
            transform: translate(-50%, 50%);
        }
    }
}