@use "../global" as *;

.booking-tab {
    flex-direction: row;
    flex-wrap: wrap;
    padding-inline: 1rem;
    gap: 1rem;

    @include screen(xl) {
        justify-content: space-between;
    }

    &__btn {
        display: inline-flex;
        align-items: center;
        gap: .25rem;
        padding: 1rem;
        color: hsl(var(--neutral-500));
        font-weight: 500;

        &.active {
            position: relative;
            isolation: isolate;
            color: hsl(var(--primary-300));

            &::after {
                content: '';
                position: absolute;
                inset-inline: 0;
                inset-block-end: 0;
                height: 1px;
                background-color: hsl(var(--primary-300));
                z-index: -1;
            }
        }
    }
}