.btn {
    &-outline-primary {
        --bs-btn-color: hsl(var(--primary-300));
        --bs-btn-border-color: hsl(var(--primary-300));
        --bs-btn-hover-color: hsl(var(--neutral-0));
        --bs-btn-hover-bg: hsl(var(--primary-300));
        --bs-btn-hover-border-color: hsl(var(--primary-300));
        --bs-btn-active-color: hsl(var(--neutral-0));
        --bs-btn-active-bg: hsl(var(--primary-300));
        --bs-btn-active-border-color: hsl(var(--primary-300));
        --bs-btn-disabled-color: hsl(var(--primary-300));
        --bs-btn-disabled-border-color: hsl(var(--primary-300));
    }

    &-outline-secondary {
        --bs-btn-color: hsl(var(--secondary-300));
        --bs-btn-border-color: hsl(var(--secondary-300));
        --bs-btn-hover-color: hsl(var(--neutral-900));
        --bs-btn-hover-bg: hsl(var(--secondary-300));
        --bs-btn-hover-border-color: hsl(var(--secondary-300));
        --bs-btn-active-color: hsl(var(--neutral-900));
        --bs-btn-active-bg: hsl(var(--secondary-300));
        --bs-btn-active-border-color: hsl(var(--secondary-300));
        --bs-btn-disabled-color: hsl(var(--secondary-300));
        --bs-btn-disabled-border-color: hsl(var(--secondary-300));
    }

    &-outline-neutral {
        --bs-btn-color: hsl(var(--neutral-200));
        --bs-btn-border-color: hsl(var(--neutral-200));
        --bs-btn-hover-color: hsl(var(--neutral-0));
        --bs-btn-hover-bg: hsl(var(--neutral-200));
        --bs-btn-hover-border-color: hsl(var(--neutral-200));
        --bs-btn-active-color: hsl(var(--neutral-0));
        --bs-btn-active-bg: hsl(var(--neutral-200));
        --bs-btn-active-border-color: hsl(var(--neutral-200));
        --bs-btn-disabled-color: hsl(var(--neutral-200));
        --bs-btn-disabled-border-color: hsl(var(--neutral-200));
    }
}