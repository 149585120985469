@use "../global" as *;

.custom-radio {
    width: 1rem;
    height: 1rem;
    cursor: pointer;

    @each $color,
    $shades in $colors {

        @each $shade,
        $value in $shades {
            &--#{$color}-#{$shade} {
                accent-color: hsl(var(--#{$color}-#{$shade}));
            }
        }
    }
}