.service-card {
    &:hover {

        h3,
        h4,
        p {
            color: hsl(var(--neutral-0));
        }

        .btn-outline-primary {
            --bs-btn-color: hsl(var(--neutral-900));
            --bs-btn-bg: hsl(var(--tertiary-300));
            --bs-btn-border-color: hsl(var(--tertiary-300));
            --bs-btn-hover-color: hsl(var(--neutral-900));
            --bs-btn-hover-bg: hsl(var(--tertiary-300));
            --bs-btn-hover-border-color: hsl(var(--tertiary-300));
            --bs-btn-active-color: hsl(var(--neutral-900));
            --bs-btn-active-bg: hsl(var(--tertiary-300));
            --bs-btn-active-border-color: hsl(var(--tertiary-300));
            --bs-btn-disabled-color: hsl(var(--tertiary-300));
            --bs-btn-disabled-border-color: hsl(var(--tertiary-300));
        }

        .service-card__btn {
            border-color: hsl(var(--tertiary-300));
            background-color: hsl(var(--tertiary-300));
            color: hsl(var(--primary-400));
        }
    }
}