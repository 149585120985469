@use "../global" as *;

.itinerary-list {
    &>li {
        position: relative;
        isolation: isolate;

        &::before {
            @include screen(md) {
                content: '';
                position: absolute;
                top: 114px;
                bottom: -14px;
                left: 52px;
                width: 1px;
                border-left: 1px dashed hsl(var(--primary-300));
                z-index: -1;
            }
        }

        &:last-child {
            &::before {
                bottom: 0;
            }
        }
    }
}