@use "../global" as *;

@each $size,
$value in $spacers {

    // Width
    .w-#{$size} {
        width: $value;
    }

    // Height
    .h-#{$size} {
        height: $value;
    }

}

.w-200 {
    width: 200px;
}

.w-45p {
    width: 45%;
}

.w-65p {
    width: 65%;
}

.w-90p {
    width: 90%;
}

.w-24ch {
    width: 24ch;
}

.w-sm-50 {
    @include screen(sm) {
        width: 50%;
    }
}

.h-200 {
    height: 200px;
}

.h-400 {
    height: 400px;
}