@use "../global" as *;

.dotted-divider {
    li+li {
        padding-inline-start: .75rem;
        margin-inline-start: .75rem;
        position: relative;
        isolation: isolate;

        ::before {
            content: '';
            width: 1px;
            position: absolute;
            inset-inline-start: 0;
            inset-block: 0;
            background-image: linear-gradient(hsl(var(--neutral-200)) 33%, transparent 0%);
            background-position: right;
            background-size: 1px .5rem;
            background-repeat: repeat-y;
        }

        @include screen(xs) {
            padding-inline-start: .75rem;
            margin-inline-start: 1rem;
        }

        @include screen(xs-plus) {
            padding-inline-start: 1.5rem;
            margin-inline-start: 1.5rem;
        }

        @include screen(md) {
            padding-inline-start: 1rem;
            margin-inline-start: 1rem;
        }

        @include screen(lg) {
            padding-inline-start: 1.75rem;
            margin-inline-start: 1.75rem;
        }

        @include screen(xl) {
            padding-inline-start: 1rem;
            margin-inline-start: 1rem;
        }

        @include screen(xxl) {
            padding-inline-start: 1.65rem;
            margin-inline-start: 1.65rem;
        }
    }
}