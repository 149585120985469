.logo {
  display: inline-block;
  max-width: 10.75rem;
  height: 3rem;
  object-fit: contain;
}

:root {
  --d1: clamp(2.4rem, 2.526vw + 1rem, 5rem);
  --d2: clamp(2rem, 2.105vw + 1rem, 4.5rem);
  --d3: clamp(1.6rem, 1.684vw + 1rem, 4rem);
  --d4: clamp(1.2rem, 1.263vw + 1rem, 3.5rem);
  --h1: clamp(2.4rem, 2.526vw + 1rem, 3rem);
  --h2: clamp(2rem, 2.105vw + 1rem, 2.5rem);
  --h3: clamp(1.6rem, 1.684vw + 1rem, 2rem);
  --h4: clamp(1.2rem, 1.263vw + 1rem, 1.5rem);
  --h5: clamp(1.1rem, 1.052vw + 1rem, 1.25rem);
  --h6: 1rem;
  --fb: 214 89% 52%;
  --tw: 203 89% 53%;
  --yt: 0 100% 50%;
  --ins: 340 75% 54%;
  --in: 201 100% 35%;
  --pin: 351 100% 45%;
  --wapp: 142 70% 49%;
  --dbl: 337 79% 61%;
  --be: 219 100% 55%;
  --vim: 195 83% 51%;
  --wc: 138 91% 38%;
  --rdt: 16 100% 50%;
  --skp: 196 100% 47%;
  --snap: 59 100% 50%;
  --twitch: 264 100% 64%;
  --qra: 13 100% 33%;
  --primary-50: 240 82% 96%;
  --primary-75: 239 84% 83%;
  --primary-100: 238 84% 75%;
  --primary-200: 239 84% 64%;
  --primary-300: 239 84% 57%;
  --primary-400: 239 63% 40%;
  --primary-500: 239 63% 35%;
  --secondary-50: 146 67% 95%;
  --secondary-75: 145 64% 80%;
  --secondary-100: 145 63% 72%;
  --secondary-200: 146 64% 60%;
  --secondary-300: 146 63% 52%;
  --secondary-400: 146 58% 36%;
  --secondary-500: 146 58% 32%;
  --tertiary-50: 40 100% 96%;
  --tertiary-75: 39 100% 85%;
  --tertiary-100: 39 100% 79%;
  --tertiary-200: 39 100% 70%;
  --tertiary-300: 39 100% 64%;
  --tertiary-400: 39 57% 45%;
  --tertiary-500: 39 57% 39%;
  --neutral-0: 0 0% 100%;
  --neutral-10: 180 11% 98%;
  --neutral-20: 210 11% 96%;
  --neutral-30: 216 14% 93%;
  --neutral-40: 214 12% 89%;
  --neutral-50: 219 13% 79%;
  --neutral-60: 219 13% 74%;
  --neutral-70: 217 13% 69%;
  --neutral-80: 218 13% 64%;
  --neutral-90: 218 13% 59%;
  --neutral-100: 217 13% 54%;
  --neutral-200: 218 14% 49%;
  --neutral-300: 217 17% 44%;
  --neutral-400: 218 20% 39%;
  --neutral-500: 218 25% 34%;
  --neutral-600: 217 31% 30%;
  --neutral-700: 218 41% 24%;
  --neutral-800: 218 56% 19%;
  --neutral-900: 218 76% 15%;
  --neutral-1000: 239 84% 10%;
  --black: 0 0% 0%;
  --white: 0 0% 100%;
}

html {
  font-size: 100%;
}

body {
  position: relative;
  background: var(--white);
  font-family: var(--ff-1);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: hsl(var(--neutral-700));
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
}

@media (min-width: 1600px) {
  .container-max {
    max-width: 1540px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1920px) {
  .container-max {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
  }
}

form {
  margin: 0;
}

p {
  margin: 0 0 0.5rem;
}

.d1,
.d2,
.d3,
.d4 {
  margin: 0 0 0.5rem;
  font-family: var(--ff-1);
  font-weight: 600;
  line-height: 1.2;
  color: hsl(var(--neutral-700));
}

.d1 {
  font-size: var(--d1);
}

.d2 {
  font-size: var(--d2);
}

.d3 {
  font-size: var(--d3);
}

.d4 {
  font-size: var(--d4);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.5rem;
  font-family: var(--ff-1);
  font-weight: 600;
  line-height: 1.2;
  color: hsl(var(--neutral-700));
}

h1,
.h1 {
  font-size: var(--h1);
}

h2,
.h2 {
  font-size: var(--h2);
}

h3,
.h3 {
  font-size: var(--h3);
}

h4,
.h4 {
  font-size: var(--h4);
}

h5,
.h5 {
  font-size: var(--h5);
}

h6,
.h6 {
  font-size: var(--h6);
  letter-spacing: 0.05em;
  line-height: 1.5;
}

.fs-10 {
  font-size: 0.625rem;
}

.fs-12 {
  font-size: 0.75rem;
}

small,
.fs-14 {
  font-size: 0.875rem;
}

.fs-16 {
  font-size: 1rem;
}

.fs-18 {
  font-size: 1.125rem;
}

.fs-20 {
  font-size: 1.25rem;
}

.fs-24 {
  font-size: 1.5rem;
}

.fs-32 {
  font-size: 2rem;
}

.fs-36 {
  font-size: 2.25rem;
}

.fs-40 {
  font-size: 2.5rem;
}

.fs-44 {
  font-size: 2.75rem;
}

.fs-48 {
  font-size: 3rem;
}

.fs-52 {
  font-size: 3.25rem;
}

.fs-56 {
  font-size: 3.5rem;
}

.fs-60 {
  font-size: 3.75rem;
}

.max-text-16 {
  max-width: 16ch;
}

.max-text-18 {
  max-width: 18ch;
}

.max-text-20 {
  max-width: 20ch;
}

.max-text-24 {
  max-width: 24ch;
}

.max-text-30 {
  max-width: 30ch;
}

.max-text-35 {
  max-width: 35ch;
}

.max-text-40 {
  max-width: 40ch;
}

.max-text-50 {
  max-width: 50ch;
}

.max-text-60 {
  max-width: 60ch;
}

.max-text-70 {
  max-width: 70ch;
}

.list {
  --direction: column;
  display: flex;
  flex-direction: var(--direction);
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-row {
  --direction: row;
}
@media (min-width: 320px) {
  .list-xxs-row {
    --direction: row;
  }
}
@media (min-width: 375px) {
  .list-xs-row {
    --direction: row;
  }
}
@media (min-width: 410px) {
  .list-xs-plus-row {
    --direction: row;
  }
}
@media (min-width: 576px) {
  .list-sm-row {
    --direction: row;
  }
}
@media (min-width: 768px) {
  .list-md-row {
    --direction: row;
  }
}
@media (min-width: 992px) {
  .list-lg-row {
    --direction: row;
  }
}
@media (min-width: 1200px) {
  .list-xl-row {
    --direction: row;
  }
}
@media (min-width: 1400px) {
  .list-xxl-row {
    --direction: row;
  }
}
@media (min-width: 1600px) {
  .list-exl-row {
    --direction: row;
  }
}
@media (min-width: 1920px) {
  .list-exxl-row {
    --direction: row;
  }
}
.list-column {
  --direction: column;
}
@media (min-width: 320px) {
  .list-xxs-column {
    --direction: column;
  }
}
@media (min-width: 375px) {
  .list-xs-column {
    --direction: column;
  }
}
@media (min-width: 410px) {
  .list-xs-plus-column {
    --direction: column;
  }
}
@media (min-width: 576px) {
  .list-sm-column {
    --direction: column;
  }
}
@media (min-width: 768px) {
  .list-md-column {
    --direction: column;
  }
}
@media (min-width: 992px) {
  .list-lg-column {
    --direction: column;
  }
}
@media (min-width: 1200px) {
  .list-xl-column {
    --direction: column;
  }
}
@media (min-width: 1400px) {
  .list-xxl-column {
    --direction: column;
  }
}
@media (min-width: 1600px) {
  .list-exl-column {
    --direction: column;
  }
}
@media (min-width: 1920px) {
  .list-exxl-column {
    --direction: column;
  }
}
.list-disc {
  list-style: disc;
  padding-inline-start: 1rem;
}
.list-divider > li + li {
  padding-inline-start: 1rem;
  margin-inline-start: 1rem;
  position: relative;
  isolation: isolate;
}
.list-divider > li + li::before {
  content: "";
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 70%;
  background-color: hsl(var(--neutral-50));
}
.list-divider-half > li + li {
  padding-inline-start: 1rem;
  margin-inline-start: 1rem;
  position: relative;
  isolation: isolate;
}
.list-divider-half > li + li::before {
  content: "";
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 45%;
  background-color: hsl(var(--neutral-50));
}
.list-divider-half-xs > li + li {
  padding-inline-start: 0.5rem;
  margin-inline-start: 0.5rem;
  position: relative;
  isolation: isolate;
}
.list-divider-half-xs > li + li::before {
  content: "";
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 50%;
  background-color: hsl(var(--neutral-500));
}
@media (min-width: 768px) {
  .list-divider-dot > li + li {
    padding-inline-start: 1.5rem;
    margin-inline-start: 1.5rem;
    position: relative;
    isolation: isolate;
  }
  .list-divider-dot > li + li::before {
    content: "";
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 0;
    transform: translateY(-50%);
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 50%;
    background-color: hsl(var(--primary-300));
  }
}
@media (min-width: 992px) {
  .list-divider-dot > li + li {
    padding-inline-start: 3.25rem;
    margin-inline-start: 3.25rem;
  }
}
@media (min-width: 1200px) {
  .list-divider-dot > li + li {
    padding-inline-start: 1.5rem;
    margin-inline-start: 1.5rem;
  }
}
@media (min-width: 1400px) {
  .list-divider-dot > li + li {
    padding-inline-start: 2.75rem;
    margin-inline-start: 2.75rem;
  }
}
@media (min-width: 768px) {
  .list-divider-dot-sm > li + li {
    padding-inline-start: 1.5rem;
    margin-inline-start: 1.5rem;
    position: relative;
    isolation: isolate;
  }
  .list-divider-dot-sm > li + li::before {
    content: "";
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 0;
    transform: translateY(-50%);
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 50%;
    background-color: hsl(var(--primary-300));
  }
}
@media (min-width: 992px) {
  .list-divider-dot-sm > li + li {
    padding-inline-start: 3.25rem;
    margin-inline-start: 3.25rem;
  }
}
@media (min-width: 1200px) {
  .list-divider-dot-sm > li + li {
    padding-inline-start: 1.5rem;
    margin-inline-start: 1.5rem;
  }
}
@media (min-width: 768px) {
  .list-divider-dot-xsm > li + li {
    padding-inline-start: 1.25rem;
    margin-inline-start: 1.25rem;
    position: relative;
    isolation: isolate;
  }
  .list-divider-dot-xsm > li + li::before {
    content: "";
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 0;
    transform: translateY(-50%);
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 50%;
    background-color: hsl(var(--primary-300));
  }
}
.list-arrow > li + li {
  padding-inline-start: 1rem;
  margin-inline-start: 1rem;
  position: relative;
  isolation: isolate;
}
.list-arrow > li + li::before {
  content: "\e5cc";
  font-family: "Material Symbols Outlined";
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1;
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 0;
  transform: translate(-50%, -50%);
  color: hsl(var(--neutral-0));
}

.menu {
  position: relative;
  isolation: isolate;
}
.menu-nav {
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  position: absolute;
  inset-inline: 0;
  inset-block-start: calc(100% - 0.5rem);
  background-color: hsl(var(--neutral-0));
  box-shadow: 0 0.5rem 1rem hsl(var(--black)/0.1);
  transition: all 0.3s ease;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  scrollbar-color: hsl(var(--neutral-30)/0.5) hsl(var(--neutral-30)/0.5);
  scrollbar-width: thin;
}
.menu-nav::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}
.menu-nav::-webkit-scrollbar-track {
  background: hsl(var(--neutral-30)/0.5);
}
.menu-nav::-webkit-scrollbar-thumb {
  background: hsl(var(--neutral-30)/0.5);
}
@media (min-width: 992px) {
  .menu-nav {
    max-height: none;
    overflow-y: unset;
    background-color: transparent;
    position: relative;
    z-index: 1;
    visibility: visible;
    opacity: 1;
    pointer-events: unset;
    box-shadow: none;
  }
}
.menu-nav .has-sub {
  justify-content: space-between;
  gap: 0.25rem;
}
.menu-nav .has-sub::after {
  content: "\e5cf";
  font-family: "Material Symbols Outlined";
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1;
}
.menu-nav .has-sub.clear-content::after {
  display: none;
}
.menu-open .menu-nav {
  z-index: 1;
  visibility: visible;
  opacity: 1;
  pointer-events: unset;
  inset-block-start: 100%;
}
@media (min-width: 992px) {
  .menu-list {
    position: relative;
    isolation: isolate;
  }
}
.menu-list + .menu-list .menu-link {
  border-top: 1px solid hsl(var(--neutral-50)/0.5);
}
@media (min-width: 992px) {
  .menu-list + .menu-list .menu-link {
    border-top: none;
  }
}
.menu-list:hover .menu-link {
  color: hsl(var(--primary-300));
}
@media (min-width: 992px) {
  .menu-list:hover .menu-link {
    color: hsl(var(--primary-300));
  }
  .menu-list:hover .menu-sub {
    inset-block-start: 100%;
    pointer-events: unset;
    visibility: visible;
    opacity: 1;
  }
}
.menu-list.current-page > .menu-link {
  color: hsl(var(--primary-300));
}
.menu-link {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  position: relative;
  isolation: isolate;
  color: hsl(var(--neutral-500));
  font-weight: 500;
}
@media (min-width: 992px) {
  .menu-link {
    padding: 1.5rem 0.75rem;
  }
}
.menu-link.active {
  color: hsl(var(--primary-300));
}
.menu-sub {
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}
@media (min-width: 992px) {
  .menu-sub {
    min-width: 200px;
    max-height: 245px;
    position: absolute;
    inset-block-start: calc(100% + 0.5rem);
    inset-inline-start: 0;
    background-color: hsl(var(--white));
    box-shadow: 0 0.5rem 1rem hsl(var(--black)/0.1);
    height: auto !important;
    pointer-events: none;
    overflow-x: unset;
    overflow-y: auto;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    scrollbar-color: hsl(var(--primary-75)/0.5) hsl(var(--neutral-50));
    scrollbar-width: thin;
  }
  .menu-sub::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
  }
  .menu-sub::-webkit-scrollbar-track {
    background: hsl(var(--neutral-30)/0.5);
  }
  .menu-sub::-webkit-scrollbar-thumb {
    background: hsl(var(--primary-75)/0.5);
  }
}
.menu-sub:not(.active) {
  display: none;
}
@media (min-width: 992px) {
  .menu-sub:not(.active) {
    display: block;
  }
}
.menu-sub-link {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem 0.75rem 2rem;
  position: relative;
  isolation: isolate;
  border-top: 1px solid hsl(var(--neutral-50)/0.5);
  color: hsl(var(--neutral-500));
  transition: all 0.3s ease-in-out;
}
@media (min-width: 992px) {
  .menu-sub-link {
    padding: 0.75rem 1rem;
  }
  .menu-sub-link:hover {
    color: hsl(var(--primary-300));
  }
}
.menu-nav--secondary {
  background-color: hsl(var(--neutral-900));
}
@media (min-width: 992px) {
  .menu-nav--secondary {
    background-color: transparent;
  }
}
.menu-nav--secondary .menu-list.current-page > .menu-link {
  color: hsl(var(--secondary-300));
}
.menu-nav--secondary .menu-link {
  color: hsl(var(--neutral-30));
}
@media (min-width: 992px) {
  .menu-nav--secondary .menu-link {
    color: hsl(var(--neutral-0));
  }
  .menu-nav--secondary .menu-link.active {
    color: hsl(var(--secondary-300));
  }
  .menu-nav--secondary .menu-link:hover {
    color: hsl(var(--secondary-300));
  }
}
.menu-nav--secondary .menu-sub-link {
  color: hsl(var(--neutral-30));
}
@media (min-width: 992px) {
  .menu-nav--secondary .menu-sub-link {
    color: hsl(var(--neutral-900));
  }
  .menu-nav--secondary .menu-sub-link:hover {
    color: hsl(var(--secondary-300));
  }
}

.property-search {
  box-shadow: 0 6px 30px hsl(var(--black)/0.08);
}
.property-search__select {
  border: 1px solid hsl(var(--neutral-40));
  background-color: hsl(var(--primary-300)/0.03);
}
.property-search__select .form-select {
  min-width: 130px;
  padding-block: 0.75rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.property-search__select .form-select:focus {
  box-shadow: none;
}
.property-search__select .form-select option {
  padding-block: 0.5rem;
}
.property-search__price {
  position: relative;
  isolation: isolate;
  background-color: hsl(var(--primary-300)/0.03);
  border: 1px solid hsl(var(--neutral-40));
}
.property-search__range {
  max-width: 100px;
}
.property-search__range-input {
  -webkit-appearance: none;
  width: 100%;
  height: 0.25rem;
  background: hsl(var(--neutral-50));
  border-radius: 1rem;
  background-image: linear-gradient(hsl(var(--primary-300)), hsl(var(--primary-300)));
  background-size: 45% 100%;
  background-repeat: no-repeat;
}
.property-search__range-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid hsl(var(--primary-300)/0.75);
  height: 0.75rem;
  width: 0.5rem;
  border-radius: 0.25rem;
  background-color: hsl(var(--white));
  cursor: ew-resize;
}
.property-search__range-input::-moz-range-thumb {
  -webkit-appearance: none;
  border: 1px solid hsl(var(--primary-300)/0.75);
  height: 0.75rem;
  width: 0.5rem;
  border-radius: 0.25rem;
  background-color: hsl(var(--white));
  cursor: ew-resize;
}
.property-search__range-input::-ms-thumb {
  -webkit-appearance: none;
  border: 1px solid hsl(var(--primary-300)/0.75);
  height: 0.75rem;
  width: 0.5rem;
  border-radius: 0.25rem;
  background-color: hsl(var(--white));
  cursor: ew-resize;
}
.property-search__range-input::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
.property-search__range-input::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
.property-search__range-input::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
.property-search__range-price {
  display: flex;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  background-color: hsl(var(--neutral-0));
  border: 1px solid hsl(var(--neutral-40)/0.25);
  border-radius: 1rem;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 1rem;
  transform: translateY(-50%);
  box-shadow: 0 0.25rem 0.5rem hsl(var(--black)/0.08);
}
.property-search__btn {
  padding: 0.75rem 1.5rem;
  background-color: hsl(var(--primary-300));
  border: none;
  color: hsl(var(--neutral-0));
  line-height: 1;
}
.property-search__btn:hover {
  background-color: hsl(var(--primary-300));
  border: none;
  color: hsl(var(--neutral-0));
}
.property-search__btn:focus {
  background-color: hsl(var(--primary-300));
  border: none;
  color: hsl(var(--neutral-0));
}
.property-search__btn:active {
  background-color: hsl(var(--primary-300)) !important;
  border: none;
  color: hsl(var(--neutral-0)) !important;
}
.property-search__col {
  width: 100%;
}
@media (min-width: 768px) {
  .property-search__col {
    width: calc(50% - 0.5rem);
  }
}
@media (min-width: 992px) {
  .property-search__col {
    width: calc(25% - 0.75rem);
  }
}
@media (min-width: 1200px) {
  .property-search__col {
    width: auto;
  }
}
@media (min-width: 768px) {
  .property-search__col:last-child {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .property-search__col:last-child {
    width: auto;
  }
}

.scroll-nav {
  display: none;
}
@media (min-width: 1920px) {
  .scroll-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    inset-inline-start: 50%;
    inset-block-end: 2rem;
    transform: translateX(-50%);
  }
}

.section-space {
  padding-top: clamp(3.75rem, 6.5vw + 1rem, 7.5rem);
  padding-bottom: clamp(3.75rem, 6.5vw + 1rem, 7.5rem);
}
.section-space--sm {
  padding-top: clamp(2rem, 3.25vw + 1rem, 3.75rem);
  padding-bottom: clamp(2rem, 3.25vw + 1rem, 3.75rem);
}
.section-space--sm-top {
  padding-top: clamp(2rem, 3.25vw + 1rem, 3.75rem);
}
.section-space--sm-bottom {
  padding-bottom: clamp(2rem, 3.25vw + 1rem, 3.75rem);
}
.section-space-top {
  padding-top: clamp(3.75rem, 6.5vw + 1rem, 7.5rem);
}
.section-space-bottom {
  padding-bottom: clamp(3.75rem, 6.5vw + 1rem, 7.5rem);
}

@media (min-width: 1920px) {
  .section-exxl-nspace {
    margin-top: -9.375rem;
  }
}

.category-card:hover span,
.category-card:hover h5,
.category-card:hover h4,
.category-card:hover h3,
.category-card:hover h2,
.category-card:hover a {
  color: hsl(var(--neutral-0));
}
.category-card:hover .category-card__footer {
  background-color: hsl(var(--primary-500));
}
.category-card__footer {
  background-color: hsl(var(--primary-300)/0.03);
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.circle-btn {
  display: grid;
  place-content: center;
  position: relative;
  isolation: isolate;
  aspect-ratio: 1;
  overflow: hidden;
}
.circle-btn:hover span {
  color: hsl(var(--neutral-0));
}
.circle-btn:hover::before {
  width: 180%;
  height: 180%;
}
.circle-btn:hover .circle-btn__arrow {
  background-color: hsl(var(--tertiary-300));
  color: hsl(var(--neutral-0));
}
.circle-btn::before {
  content: "";
  position: absolute;
  top: var(--y);
  left: var(--x);
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: hsl(var(--secondary-400));
  transition: all 0.5s ease;
  z-index: -1;
}

.featured-tab.active {
  color: hsl(var(--neutral-0));
  background-color: hsl(var(--primary-300));
}

.btn-outline-primary {
  --bs-btn-color: hsl(var(--primary-300));
  --bs-btn-border-color: hsl(var(--primary-300));
  --bs-btn-hover-color: hsl(var(--neutral-0));
  --bs-btn-hover-bg: hsl(var(--primary-300));
  --bs-btn-hover-border-color: hsl(var(--primary-300));
  --bs-btn-active-color: hsl(var(--neutral-0));
  --bs-btn-active-bg: hsl(var(--primary-300));
  --bs-btn-active-border-color: hsl(var(--primary-300));
  --bs-btn-disabled-color: hsl(var(--primary-300));
  --bs-btn-disabled-border-color: hsl(var(--primary-300));
}
.btn-outline-secondary {
  --bs-btn-color: hsl(var(--secondary-300));
  --bs-btn-border-color: hsl(var(--secondary-300));
  --bs-btn-hover-color: hsl(var(--neutral-900));
  --bs-btn-hover-bg: hsl(var(--secondary-300));
  --bs-btn-hover-border-color: hsl(var(--secondary-300));
  --bs-btn-active-color: hsl(var(--neutral-900));
  --bs-btn-active-bg: hsl(var(--secondary-300));
  --bs-btn-active-border-color: hsl(var(--secondary-300));
  --bs-btn-disabled-color: hsl(var(--secondary-300));
  --bs-btn-disabled-border-color: hsl(var(--secondary-300));
}
.btn-outline-neutral {
  --bs-btn-color: hsl(var(--neutral-200));
  --bs-btn-border-color: hsl(var(--neutral-200));
  --bs-btn-hover-color: hsl(var(--neutral-0));
  --bs-btn-hover-bg: hsl(var(--neutral-200));
  --bs-btn-hover-border-color: hsl(var(--neutral-200));
  --bs-btn-active-color: hsl(var(--neutral-0));
  --bs-btn-active-bg: hsl(var(--neutral-200));
  --bs-btn-active-border-color: hsl(var(--neutral-200));
  --bs-btn-disabled-color: hsl(var(--neutral-200));
  --bs-btn-disabled-border-color: hsl(var(--neutral-200));
}

.property-card {
  background-color: hsl(var(--neutral-0));
  border-radius: 1rem;
  transition: all 0.3s ease;
}
.property-card:hover {
  box-shadow: 0 0.5rem 1.5rem hsl(var(--black)/0.1);
}
.property-card__head {
  padding: 0.5rem;
  position: relative;
  isolation: isolate;
}
.property-card__img {
  border-radius: 1rem;
}
.property-card__img img {
  border-radius: inherit;
}
.property-card__tag {
  position: absolute;
  inset-block-start: 1.75rem;
  inset-inline-start: 1.75rem;
  z-index: 1;
}
.property-card__fav {
  position: absolute;
  inset-block-start: 1.75rem;
  inset-inline-end: 1.75rem;
  z-index: 1;
}
.property-card__heart.solid {
  color: hsl(var(--tertiary-300));
}
.property-card__popular-tag {
  display: inline-block;
  position: absolute;
  inset-block-end: 0.5rem;
  inset-inline-start: -0.5rem;
  border-radius: 0.25rem 0.25rem 0.25rem 0;
  transform: translateY(50%);
  z-index: 1;
}
.property-card__popular-tag span {
  position: relative;
  isolation: isolate;
  border-radius: inherit;
}
.property-card__popular-tag span::after {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  inset-block-start: calc(100% - 1px);
  inset-inline-start: 0;
  background-color: hsl(var(--primary-400));
  border-radius: 0 0 0 0.25rem;
}
.property-card__body {
  padding: 1.5rem 0.5rem;
}
@media (min-width: 375px) {
  .property-card__body {
    padding: 2rem 1rem;
  }
}
@media (min-width: 992px) {
  .property-card__body {
    padding: 2rem;
  }
}
@media (min-width: 1200px) {
  .property-card__body {
    padding: 1.5rem;
  }
}
.property-card-next {
  --swiper-navigation-size: 38px;
  width: var(--swiper-navigation-size);
  background-color: hsl(var(--primary-50));
  border: 1px solid hsl(var(--primary-300));
  border-radius: 50%;
  color: hsl(var(--primary-300));
  opacity: 0.5;
  transition: all 0.3s ease;
}
.property-card-next::after {
  --swiper-navigation-size: 20px;
}
.property-card-next:hover {
  background-color: hsl(var(--primary-300));
  color: hsl(var(--neutral-0));
  opacity: 1;
}
.property-card-prev {
  --swiper-navigation-size: 38px;
  width: var(--swiper-navigation-size);
  background-color: hsl(var(--primary-50));
  border: 1px solid hsl(var(--primary-300));
  border-radius: 50%;
  color: hsl(var(--primary-300));
  opacity: 0.5;
  transition: all 0.3s ease;
}
.property-card-prev::after {
  --swiper-navigation-size: 20px;
}
.property-card-prev:hover {
  background-color: hsl(var(--primary-300));
  color: hsl(var(--neutral-0));
  opacity: 1;
}
.property-card-pagination .swiper-pagination-bullet {
  background: hsl(var(--neutral-40));
  opacity: 1;
}
.property-card-pagination .swiper-pagination-bullet-active {
  background: hsl(var(--tertiary-300));
}
@media (min-width: 768px) {
  .property-card--row {
    display: flex;
  }
}
@media (min-width: 768px) {
  .property-card--row .property-card__img {
    height: 100%;
  }
  .property-card--row .property-card__img img {
    height: 100%;
    object-fit: cover;
  }
}
@media (min-width: 768px) {
  .property-card--row .property-card__head {
    flex-shrink: 0;
    width: 45%;
  }
}
@media (min-width: 1200px) {
  .property-card--row .dotted-divider li + li {
    padding-inline-start: 0.75rem;
    margin-inline-start: 0.75rem;
  }
}
@media (min-width: 1400px) {
  .property-card--row .dotted-divider li + li {
    padding-inline-start: 1.25rem;
    margin-inline-start: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .property-card--row .property-card__body {
    padding-inline: 1rem;
  }
}
@media (min-width: 768px) {
  .property-card--row .property-card-slider {
    height: 100%;
  }
}
@media (min-width: 768px) {
  .property-card--row .property-card__content {
    flex-grow: 1;
  }
}
@media (min-width: 768px) {
  .property-card--row-sm .property-card__head {
    flex-shrink: 0;
    width: 45%;
  }
}
@media (min-width: 1200px) {
  .property-card--row-sm .property-card__head {
    width: 30%;
  }
}

.dotted-divider li + li {
  padding-inline-start: 0.75rem;
  margin-inline-start: 0.75rem;
  position: relative;
  isolation: isolate;
}
.dotted-divider li + li ::before {
  content: "";
  width: 1px;
  position: absolute;
  inset-inline-start: 0;
  inset-block: 0;
  background-image: linear-gradient(hsl(var(--neutral-200)) 33%, transparent 0%);
  background-position: right;
  background-size: 1px 0.5rem;
  background-repeat: repeat-y;
}
@media (min-width: 375px) {
  .dotted-divider li + li {
    padding-inline-start: 0.75rem;
    margin-inline-start: 1rem;
  }
}
@media (min-width: 410px) {
  .dotted-divider li + li {
    padding-inline-start: 1.5rem;
    margin-inline-start: 1.5rem;
  }
}
@media (min-width: 768px) {
  .dotted-divider li + li {
    padding-inline-start: 1rem;
    margin-inline-start: 1rem;
  }
}
@media (min-width: 992px) {
  .dotted-divider li + li {
    padding-inline-start: 1.75rem;
    margin-inline-start: 1.75rem;
  }
}
@media (min-width: 1200px) {
  .dotted-divider li + li {
    padding-inline-start: 1rem;
    margin-inline-start: 1rem;
  }
}
@media (min-width: 1400px) {
  .dotted-divider li + li {
    padding-inline-start: 1.65rem;
    margin-inline-start: 1.65rem;
  }
}

.listing-card {
  --listing-overlay-clr: var(--neutral-900);
  position: relative;
  isolation: isolate;
}
.listing-card:hover {
  --listing-overlay-clr: var(--primary-500);
}
.listing-card:hover .btn-outline-secondary {
  background: hsl(var(--secondary-300));
  color: hsl(var(--neutral-900));
}
.listing-card__img {
  position: relative;
  isolation: isolate;
  border-radius: 1rem;
}
.listing-card__img::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(180deg, hsl(var(--listing-overlay-clr)/0) 0%, hsl(var(--listing-overlay-clr)/0.3) 25%, hsl(var(--listing-overlay-clr)/0.7) 60%, hsl(var(--listing-overlay-clr)) 100%);
  border-radius: inherit;
}
.listing-card__img img {
  min-height: 400px;
  object-fit: cover;
  border-radius: inherit;
}
@media (min-width: 1200px) {
  .listing-card__img img {
    min-height: unset;
  }
}
.listing-card__content {
  padding: 1rem;
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 576px) {
  .listing-card__content {
    padding: 1.5rem;
  }
}

.service-card:hover h3,
.service-card:hover h4,
.service-card:hover p {
  color: hsl(var(--neutral-0));
}
.service-card:hover .btn-outline-primary {
  --bs-btn-color: hsl(var(--neutral-900));
  --bs-btn-bg: hsl(var(--tertiary-300));
  --bs-btn-border-color: hsl(var(--tertiary-300));
  --bs-btn-hover-color: hsl(var(--neutral-900));
  --bs-btn-hover-bg: hsl(var(--tertiary-300));
  --bs-btn-hover-border-color: hsl(var(--tertiary-300));
  --bs-btn-active-color: hsl(var(--neutral-900));
  --bs-btn-active-bg: hsl(var(--tertiary-300));
  --bs-btn-active-border-color: hsl(var(--tertiary-300));
  --bs-btn-disabled-color: hsl(var(--tertiary-300));
  --bs-btn-disabled-border-color: hsl(var(--tertiary-300));
}
.service-card:hover .service-card__btn {
  border-color: hsl(var(--tertiary-300));
  background-color: hsl(var(--tertiary-300));
  color: hsl(var(--primary-400));
}

.team:hover .team__member-img {
  filter: grayscale(100%);
}
.team:hover .team__contact-list {
  visibility: visible;
  opacity: 1;
  pointer-events: unset;
}
.team:hover .team__contact-list li:nth-child(2) {
  inset-inline-start: 25%;
  transform: translateX(-75%);
}
.team:hover .team__contact-list li:nth-child(3) {
  inset-inline-start: auto;
  inset-inline-end: 25%;
  transform: translateX(75%);
}
.team__member {
  position: relative;
  isolation: isolate;
}
.team__member-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  transition: all 0.3s ease;
}
.team__contact {
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 50%;
  transform: translate(-50%, 50%);
  z-index: 1;
}
.team__contact-list {
  position: absolute;
  inset: 0;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
}
.team__contact-list li {
  transition: all 0.3s ease;
}
.team__contact-list li:nth-child(1) {
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 50%;
  transform: translate(-50%, 50%);
}
.team__contact-list li:nth-child(2) {
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 50%;
  transform: translate(-50%, 50%);
}
.team__contact-list li:nth-child(3) {
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 50%;
  transform: translate(-50%, 50%);
}

.auth-slider__btn {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}
.auth-slider__prev {
  --swiper-navigation-size: 40px;
  position: static;
  margin: 0;
  width: var(--swiper-navigation-size);
  height: var(--swiper-navigation-size);
  border: 1px solid hsl(var(--primary-300)/0.5);
  border-radius: 50%;
  transition: all 0.3s ease;
}
.auth-slider__prev:hover {
  background: hsl(var(--primary-300));
}
.auth-slider__prev:hover::after {
  color: hsl(var(--neutral-0));
}
.auth-slider__prev::after {
  font-size: 1.5rem;
  color: hsl(var(--primary-300)/0.5);
}
.auth-slider__next {
  --swiper-navigation-size: 40px;
  position: static;
  margin: 0;
  width: var(--swiper-navigation-size);
  height: var(--swiper-navigation-size);
  border: 1px solid hsl(var(--primary-300)/0.5);
  border-radius: 50%;
  transition: all 0.3s ease;
}
.auth-slider__next:hover {
  background: hsl(var(--primary-300));
  color: hsl(var(--neutral-0));
}
.auth-slider__next:hover::after {
  color: hsl(var(--neutral-0));
}
.auth-slider__next::after {
  font-size: 1.5rem;
  color: hsl(var(--primary-300)/0.5);
}

.testimonial-slider {
  position: relative;
  isolation: isolate;
}
.testimonial-slider__btn {
  --swiper-navigation-size: 40px;
  margin: 0;
  width: var(--swiper-navigation-size);
  height: var(--swiper-navigation-size);
  border: 1px solid hsl(var(--primary-300)/0.5);
  border-radius: 50%;
  transition: all 0.3s ease;
}
.testimonial-slider__btn:hover {
  background: hsl(var(--primary-300));
}
.testimonial-slider__btn:hover::after {
  color: hsl(var(--neutral-0));
}
.testimonial-slider__btn::after {
  font-size: 1.5rem;
  color: hsl(var(--primary-300)/0.5);
}

.accordion {
  --bs-accordion-border-width: 0;
  --bs-accordion-inner-border-radius: 2rem;
  --bs-accordion-btn-icon-width: 2rem;
  --bs-accordion-body-padding-y: 1.5rem;
  --bs-accordion-body-padding-x: 2.5rem;
}
.accordion .accordion-item {
  border-radius: var(--bs-accordion-inner-border-radius);
}
.accordion .accordion-item:first-of-type {
  border-radius: var(--bs-accordion-inner-border-radius);
}
.accordion .accordion-item:first-of-type .accordion-button {
  border-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-button {
  padding: 1.5rem 2.5rem;
  border-radius: var(--bs-accordion-inner-border-radius);
  font-size: 1.25rem;
  font-weight: 500;
}
.accordion-button::after {
  content: "\e145";
  display: grid;
  place-content: center;
  background: hsl(var(--secondary-500));
  border-radius: 50%;
  font-family: "Material Symbols Outlined";
  font-weight: 300;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-grid;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  color: hsl(var(--neutral-0));
}
.accordion-button:not(.collapsed) {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.accordion-button:not(.collapsed)::after {
  content: "\e15b";
  display: grid;
  place-content: center;
  background: hsl(var(--secondary-500));
  border-radius: 50%;
  font-family: "Material Symbols Outlined";
  font-weight: 300;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-grid;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  color: hsl(var(--neutral-0));
  transform: unset;
}
.accordion-body {
  position: relative;
  isolation: isolate;
  background-color: var(--accordion-bg);
  border-radius: 0 0 2rem 2rem;
}
.accordion-body::after {
  content: "";
  position: absolute;
  inset-block: 0;
  inset-inline: 2.5rem;
  height: 1px;
  background-image: linear-gradient(to right, hsl(var(--neutral-200)) 33%, transparent 0);
  background-position: bottom;
  background-size: 0.75rem 1px;
  background-repeat: repeat-x;
}
.accordion--separated {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.accordion--secondary {
  --bs-accordion-active-color: hsl(var(--neutral-700));
  --bs-accordion-active-bg: hsl(var(--secondary-50));
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0 transparent;
  --bs-accordion-btn-bg: hsl(var(--secondary-50));
  --bs-accordion-btn-color: hsl(var(--neutral-700));
  --accordion-bg: hsl(var(--secondary-50));
}

.property-filter {
  display: flex;
  flex-wrap: wrap;
  background-color: hsl(var(--neutral-0));
}
.property-filter__item {
  padding: 1.75rem 1.5rem;
  width: 100%;
}
@media (min-width: 768px) {
  .property-filter__item {
    width: 33%;
  }
}
@media (min-width: 1200px) {
  .property-filter__item {
    width: calc(33% - 27px);
  }
}
.property-filter__item + .property-filter__item {
  border-block-start: 1px solid hsl(var(--neutral-50));
}
@media (min-width: 768px) {
  .property-filter__item + .property-filter__item {
    border-block-start: 0;
    border-inline-start: 1px solid hsl(var(--neutral-50));
  }
}
.property-filter__item .form-select {
  border: none;
  color: hsl(var(--neutral-600));
}
.property-filter__item .form-select:focus {
  box-shadow: unset;
}
.property-filter__item .property-search__price {
  width: 100%;
  background: transparent;
  border: none;
}
.property-filter__item .property-search__range-price {
  background-color: hsl(var(--primary-50));
  inset-block-start: -0.5rem;
  inset-inline-end: 1rem;
}
.property-filter__btn {
  display: inline-block;
  width: 100%;
  border: 0;
  padding: 1.5rem;
  background-color: hsl(var(--primary-300));
  color: hsl(var(--neutral-0));
  transition: all 0.3s ease;
}
@media (min-width: 1200px) {
  .property-filter__btn {
    width: auto;
  }
}
.property-filter__btn:hover {
  background-color: hsl(var(--primary-400));
}
.property-filter__btn span {
  transform: rotate(90deg);
}

.location-slider__card {
  --card-overlay: var(--neutral-900);
  position: relative;
  isolation: isolate;
  border-radius: 1rem;
  overflow: hidden;
}
.location-slider__card:hover {
  --card-overlay: var(--primary-500);
}
.location-slider__card:hover .location-slider__play {
  visibility: visible;
  opacity: 1;
  pointer-events: unset;
}
.location-slider__img {
  position: relative;
  isolation: isolate;
}
.location-slider__img::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(180deg, hsl(var(--card-overlay)/0) 0%, hsl(var(--card-overlay)/0.25) 46.59%, hsl(var(--card-overlay)/0.9) 76.17%, hsl(var(--card-overlay)) 100%);
}
.location-slider__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2rem 1.5rem;
  position: absolute;
  inset: 0;
}
.location-slider__play {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
.location-slider-container {
  position: relative;
}
.location-slider__pagination {
  bottom: -40px !important;
}
.location-slider__pagination .swiper-pagination-bullet {
  width: 0.75rem;
  height: 0.75rem;
  opacity: 1;
  background-color: hsl(var(--neutral-40));
}
.location-slider__pagination .swiper-pagination-bullet-active {
  width: 1.75rem;
  height: 0.75rem;
  border-radius: 1rem;
  background-color: hsl(var(--primary-300));
}

.testimonial-slider-two__btn {
  --swiper-navigation-size: 40px;
  margin: 0;
  position: static;
  width: var(--swiper-navigation-size);
  height: var(--swiper-navigation-size);
  border: 1px solid hsl(var(--primary-300)/0.5);
  border-radius: 50%;
  transition: all 0.3s ease;
}
.testimonial-slider-two__btn:hover {
  background: hsl(var(--primary-300));
}
.testimonial-slider-two__btn:hover::after {
  color: hsl(var(--neutral-0));
}
.testimonial-slider-two__btn::after {
  font-size: 1.25rem;
  color: hsl(var(--primary-300)/0.5);
}

.team-card:hover {
  color: hsl(var(--neutral-0));
}
.team-card:hover h4 {
  color: hsl(var(--neutral-0));
}
.team-card:hover::after {
  background-color: hsl(var(--primary-300));
}
.team-card::after {
  content: "";
  position: absolute;
  inset-inline: 0;
  inset-block-start: 5.3125rem;
  inset-block-end: 0;
  border-radius: inherit;
  background-color: hsl(var(--neutral-20));
  transition: all 0.3s ease;
  z-index: -1;
}
@media (min-width: 1200px) {
  .team-card::after {
    background-color: hsl(var(--neutral-0));
  }
}

.team-slider__pagination {
  bottom: -40px !important;
}
.team-slider__pagination .swiper-pagination-bullet {
  width: 0.75rem;
  height: 0.75rem;
  opacity: 1;
  background-color: hsl(var(--neutral-40));
}
.team-slider__pagination .swiper-pagination-bullet-active {
  width: 1.75rem;
  height: 0.75rem;
  border-radius: 1rem;
  background-color: hsl(var(--primary-300));
}

.about-tab {
  flex-direction: row;
  flex-wrap: wrap;
}
.about-tab .list-group-item {
  padding: 0.5rem;
  border-radius: 0;
  border: 0;
  background-color: transparent;
  font-weight: 500;
  color: hsl(var(--neutral-600));
}
@media (min-width: 375px) {
  .about-tab .list-group-item {
    padding: 0.5rem 1rem;
  }
}
@media (min-width: 768px) {
  .about-tab .list-group-item {
    padding: 0.5rem 2rem;
  }
}
.about-tab .list-group-item:first-child {
  padding-inline-start: 0;
}
.about-tab .list-group-item:first-child.active::after {
  inset-inline-start: 0;
  transform: translateX(0);
  width: calc(100% - 0.5rem);
}
@media (min-width: 375px) {
  .about-tab .list-group-item:first-child.active::after {
    width: calc(100% - 1rem);
  }
}
@media (min-width: 768px) {
  .about-tab .list-group-item:first-child.active::after {
    width: calc(100% - 2rem);
  }
}
.about-tab .list-group-item.active {
  position: relative;
  isolation: isolate;
  color: hsl(var(--primary-300));
}
.about-tab .list-group-item.active::after {
  content: "";
  position: absolute;
  inset-inline-start: 50%;
  inset-block-end: 0;
  transform: translateX(-50%);
  width: calc(100% - 1rem);
  height: 1px;
  background-color: hsl(var(--primary-300));
}
@media (min-width: 375px) {
  .about-tab .list-group-item.active::after {
    width: calc(100% - 2rem);
  }
}
@media (min-width: 768px) {
  .about-tab .list-group-item.active::after {
    width: calc(100% - 3rem);
  }
}
@media (min-width: 768px) {
  .about-tab .list-group-item + .list-group-item::before {
    content: "";
    position: absolute;
    inset-inline-start: 0;
    inset-block: 0.5rem;
    width: 1px;
    background-color: hsl(var(--neutral-200));
  }
}

.about-list {
  row-gap: 1rem;
}
@media (min-width: 576px) {
  .about-list {
    column-gap: 2rem;
  }
}
@media (min-width: 768px) {
  .about-list {
    column-gap: 3rem;
  }
}
@media (min-width: 992px) {
  .about-list {
    column-gap: 2.5rem;
  }
}
@media (min-width: 1400px) {
  .about-list {
    column-gap: 3rem;
  }
}

.about-img-el {
  display: none;
}
@media (min-width: 1920px) {
  .about-img-el {
    display: block;
    position: absolute;
    z-index: -1;
  }
  .about-img-el--1 {
    inset-inline-end: 3rem;
    inset-block-start: 3rem;
  }
  .about-img-el--2 {
    inset-inline-start: 3rem;
    inset-block-end: 3rem;
  }
}

.custom-accordion {
  --accordion-gap: 1rem;
  --accordion-border-clr: hsl(var(--neutral-40));
  --accordion-header-bg: hsl(var(--white));
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--accordion-gap);
}
.custom-accordion__header {
  cursor: pointer;
  padding: 0.75rem 1.5rem;
  background-color: var(--accordion-header-bg);
}
.custom-accordion__body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.custom-accordion__content {
  padding: 0.75rem 1.5rem;
  background-color: var(--accordion-header-bg);
  border: 1px solid var(--accordion-border-clr);
  border-top: 0;
}

.property-accordion .custom-accordion__header {
  padding-top: 0;
  padding-bottom: 1.5rem;
  padding-inline: 0;
  position: relative;
  isolation: isolate;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--accordion-border-clr);
}
.property-accordion .custom-accordion__header::after {
  content: "\e5cf";
  position: relative;
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-grid;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
.property-accordion .accordion-item:first-of-type .accordion-button {
  padding: 0;
  border-radius: 0;
  background-color: transparent;
}
.property-accordion .accordion-button {
  font-size: clamp(1.6rem, 1.684vw + 1rem, 2rem);
  font-weight: 600;
  color: hsl(var(--neutral-700));
}
.property-accordion .accordion-button:focus {
  box-shadow: none;
}
.property-accordion .accordion-button:not(.collapsed)::after {
  content: "\e5cf";
  background-color: transparent;
  color: hsl(var(--neutral-700));
  font-size: 2rem;
}
.property-accordion .accordion-button::after {
  content: "\e5ce";
  background-color: transparent;
  color: hsl(var(--neutral-700));
  font-size: 2rem;
}
.property-accordion .accordion-body {
  padding: 0;
  margin-top: 1.5rem;
  border-top: 1px solid hsl(var(--neutral-40));
}
.property-accordion .accordion-body::after {
  display: none;
}

.custom-radio {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}
.custom-radio--primary-50 {
  accent-color: hsl(var(--primary-50));
}
.custom-radio--primary-75 {
  accent-color: hsl(var(--primary-75));
}
.custom-radio--primary-100 {
  accent-color: hsl(var(--primary-100));
}
.custom-radio--primary-200 {
  accent-color: hsl(var(--primary-200));
}
.custom-radio--primary-300 {
  accent-color: hsl(var(--primary-300));
}
.custom-radio--primary-400 {
  accent-color: hsl(var(--primary-400));
}
.custom-radio--primary-500 {
  accent-color: hsl(var(--primary-500));
}
.custom-radio--secondary-50 {
  accent-color: hsl(var(--secondary-50));
}
.custom-radio--secondary-75 {
  accent-color: hsl(var(--secondary-75));
}
.custom-radio--secondary-100 {
  accent-color: hsl(var(--secondary-100));
}
.custom-radio--secondary-200 {
  accent-color: hsl(var(--secondary-200));
}
.custom-radio--secondary-300 {
  accent-color: hsl(var(--secondary-300));
}
.custom-radio--secondary-400 {
  accent-color: hsl(var(--secondary-400));
}
.custom-radio--secondary-500 {
  accent-color: hsl(var(--secondary-500));
}
.custom-radio--tertiary-50 {
  accent-color: hsl(var(--tertiary-50));
}
.custom-radio--tertiary-75 {
  accent-color: hsl(var(--tertiary-75));
}
.custom-radio--tertiary-100 {
  accent-color: hsl(var(--tertiary-100));
}
.custom-radio--tertiary-200 {
  accent-color: hsl(var(--tertiary-200));
}
.custom-radio--tertiary-300 {
  accent-color: hsl(var(--tertiary-300));
}
.custom-radio--tertiary-400 {
  accent-color: hsl(var(--tertiary-400));
}
.custom-radio--tertiary-500 {
  accent-color: hsl(var(--tertiary-500));
}
.custom-radio--neutral-0 {
  accent-color: hsl(var(--neutral-0));
}
.custom-radio--neutral-10 {
  accent-color: hsl(var(--neutral-10));
}
.custom-radio--neutral-20 {
  accent-color: hsl(var(--neutral-20));
}
.custom-radio--neutral-30 {
  accent-color: hsl(var(--neutral-30));
}
.custom-radio--neutral-40 {
  accent-color: hsl(var(--neutral-40));
}
.custom-radio--neutral-50 {
  accent-color: hsl(var(--neutral-50));
}
.custom-radio--neutral-60 {
  accent-color: hsl(var(--neutral-60));
}
.custom-radio--neutral-70 {
  accent-color: hsl(var(--neutral-70));
}
.custom-radio--neutral-80 {
  accent-color: hsl(var(--neutral-80));
}
.custom-radio--neutral-90 {
  accent-color: hsl(var(--neutral-90));
}
.custom-radio--neutral-100 {
  accent-color: hsl(var(--neutral-100));
}
.custom-radio--neutral-200 {
  accent-color: hsl(var(--neutral-200));
}
.custom-radio--neutral-300 {
  accent-color: hsl(var(--neutral-300));
}
.custom-radio--neutral-400 {
  accent-color: hsl(var(--neutral-400));
}
.custom-radio--neutral-500 {
  accent-color: hsl(var(--neutral-500));
}
.custom-radio--neutral-600 {
  accent-color: hsl(var(--neutral-600));
}
.custom-radio--neutral-700 {
  accent-color: hsl(var(--neutral-700));
}
.custom-radio--neutral-800 {
  accent-color: hsl(var(--neutral-800));
}
.custom-radio--neutral-900 {
  accent-color: hsl(var(--neutral-900));
}
.custom-radio--neutral-1000 {
  accent-color: hsl(var(--neutral-1000));
}

.why-choose-overlay {
  inset-inline-start: 50%;
  inset-block-start: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 1920px) {
  .why-choose-overlay {
    inset-inline-start: 0;
    inset-block-start: 2rem;
    transform: translateX(-50%);
  }
}

.why-choose-el {
  display: none;
}
@media (min-width: 1920px) {
  .why-choose-el {
    display: block;
    position: absolute;
    z-index: -1;
  }
  .why-choose-el--1 {
    inset-block-start: 50%;
    inset-inline-start: 0;
    transform: translate(-100%, -50%);
  }
  .why-choose-el--2 {
    inset-block-end: 0;
    inset-inline-start: 0;
    transform: translate(-50%, 50%);
  }
}

.range-slider {
  position: relative;
  width: 100%;
  height: 10px;
  border-radius: 15px;
  background: hsl(var(--neutral-40));
}
.range-slider__progress {
  position: absolute;
  left: 25%;
  right: 0%;
  height: 100%;
  border-radius: 15px;
  background: hsl(var(--primary-300));
}
.range-slider__input {
  position: absolute;
  width: 100%;
  height: 10px;
  -webkit-appearance: none;
  pointer-events: none;
  background: none;
  outline: none;
}
.range-slider__input::-webkit-slider-thumb {
  pointer-events: auto;
  -webkit-appearance: none;
  width: 0.5rem;
  height: 1.25rem;
  background: hsl(var(--neutral-0));
  border: 1px solid hsl(var(--neutral-50));
  border-radius: 1rem;
  opacity: 1;
  cursor: e-resize;
}
.range-slider__value {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.range-slider__value.max-value {
  padding-inline-start: 0.5rem;
}
.range-slider__value input {
  border: 0;
  background: transparent;
  width: 1.75rem;
}

.custom-checkbox {
  position: relative;
  border: 1px solid hsl(var(--neutral-50));
  border-radius: 2px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  width: 1.25rem;
  height: 1.25rem;
  -webkit-appearance: none;
  opacity: 0.75;
}
.custom-checkbox:hover {
  opacity: 1;
}
.custom-checkbox:checked {
  opacity: 1;
}
.custom-checkbox:checked::before {
  content: "";
  position: absolute;
  right: 50%;
  top: 50%;
  width: 5px;
  height: 10px;
  border: solid hsl(var(--primary-300));
  border-width: 0 1px 1px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}
.custom-checkbox--rounded {
  border-radius: 50%;
}
.custom-checkbox--rounded:checked {
  background-color: hsl(var(--neutral-0));
}

#map {
  height: 600px;
  min-height: 100%;
}
@media (min-width: 768px) {
  #map {
    height: 1595px;
  }
}
@media (min-width: 992px) {
  #map {
    height: 1545px;
  }
}
@media (min-width: 1200px) {
  #map {
    height: 1220px;
  }
}

.property-gallery-slider:hover .property-gallery-slider__btn {
  opacity: 1;
  pointer-events: unset;
}
.property-gallery-slider__btn {
  --swiper-navigation-size: 40px;
  margin: 0;
  width: var(--swiper-navigation-size);
  height: var(--swiper-navigation-size);
  border: 1px solid hsl(var(--primary-300)/0.5);
  border-radius: 50%;
  opacity: 1;
  background-color: hsl(var(--neutral-0));
  color: hsl(var(--primary-300));
  transition: all 0.3s ease;
  opacity: 0;
  pointer-events: none;
}
.property-gallery-slider__btn:hover {
  background: hsl(var(--primary-300));
}
.property-gallery-slider__btn:hover::after {
  color: hsl(var(--neutral-0));
}
.property-gallery-slider__btn::after {
  font-size: 1.25rem;
  color: hsl(var(--primary-300)/0.5);
}

.quantity {
  display: flex;
  align-items: center;
}
.quantity__value {
  width: 3rem;
  border: none;
  background-color: transparent;
  text-align: center;
  font-weight: 600;
  color: hsl(var(--neutral-500));
}
.quantity__value:focus {
  outline: none;
}
.quantity__button {
  display: inline-block;
  padding: 0;
  border: none;
  background-color: transparent;
  line-height: 1;
}

.recent-view-slider__btn {
  --swiper-navigation-size: 40px;
  margin: 0;
  position: static;
  width: var(--swiper-navigation-size);
  height: var(--swiper-navigation-size);
  border: 1px solid hsl(var(--primary-300)/0.5);
  border-radius: 50%;
  transition: all 0.3s ease;
}
.recent-view-slider__btn:hover {
  background: hsl(var(--primary-300));
}
.recent-view-slider__btn:hover::after {
  color: hsl(var(--neutral-0));
}
.recent-view-slider__btn::after {
  font-size: 1.25rem;
  color: hsl(var(--primary-300)/0.5);
}

.custom-switch {
  display: grid;
}
.custom-switch__input {
  position: relative;
  width: 4rem;
  height: 2rem;
  -webkit-appearance: none;
  background: hsl(var(--primary-50));
  outline: none;
  border: 1px solid hsl(var(--neutral-50)/0.1);
  border-radius: 24px;
  transition: 0.7s;
}
.custom-switch__input:checked[type=checkbox] {
  background: hsl(var(--primary-300));
}
.custom-switch__input:checked[type=checkbox]::before {
  left: 2rem;
  background: hsl(var(--neutral-0));
}
.custom-switch__input::before {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 24px;
  top: 50%;
  left: 0.25rem;
  background: hsl(var(--primary-300));
  transform: translateY(-50%);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

@media (min-width: 1920px) {
  .brand-slider {
    margin-right: -400px;
  }
}

.service-slider {
  position: relative;
}
.service-slider__pagination {
  bottom: -40px !important;
}
.service-slider__pagination .swiper-pagination-bullet {
  width: 0.75rem;
  height: 0.75rem;
  opacity: 1;
  background-color: hsl(var(--neutral-40));
}
.service-slider__pagination .swiper-pagination-bullet-active {
  width: 1.75rem;
  height: 0.75rem;
  border-radius: 1rem;
  background-color: hsl(var(--primary-300));
}

.faq-nav {
  --bs-list-group-item-padding-x: 1.25rem;
  --bs-list-group-item-padding-y: .75rem;
  --bs-list-group-active-bg: hsl(var(--primary-300));
  --bs-list-group-active-border-color: hsl(var(--primary-300));
}
.faq-nav__link {
  border: none;
  background-color: transparent;
  border-radius: 2rem !important;
  font-size: 18px;
  font-weight: 500;
}

.faq-banner {
  position: relative;
  isolation: isolate;
  background-image: url(../img/faq-banner-img.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.faq-banner::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(180deg, rgba(38, 41, 166, 0.4) 0%, rgba(38, 41, 166, 0.8) 45.16%, #2629A6 100%);
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.blog-details-slider__btn-group {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1.25rem 1.5rem;
  position: absolute;
  inset-inline-end: 0;
  inset-block-end: 0;
  background-color: hsl(var(--primary-300));
  border-radius: 1rem 0 1rem 0;
  z-index: 1;
}
.blog-details-slider__btn {
  position: static;
  width: 40px;
  height: 40px;
  margin: 0;
  border: 1px solid hsl(var(--primary-50));
  color: hsl(var(--primary-50));
  border-radius: 50%;
  transition: all 0.3s ease;
}
.blog-details-slider__btn:hover {
  background-color: hsl(var(--primary-50));
  color: hsl(var(--primary-300));
}
.blog-details-slider__btn::after {
  font-size: 20px;
}

.contact-map {
  height: 100%;
}
.contact-map__frame {
  width: 100%;
  height: 100%;
}

.discount-card__img {
  position: absolute;
  inset: 0;
  z-index: -1;
  object-fit: cover;
}
@media (min-width: 992px) {
  .discount-card__content {
    width: 60%;
  }
}
@media (min-width: 1200px) {
  .discount-card__content {
    width: 50%;
  }
}

.user-group {
  --direction: row;
}
.user-group > li + li {
  margin-inline-start: -20px;
}

.choice-slider {
  margin-block: -1rem;
}
.choice-slider__btn {
  --swiper-navigation-size: 20px;
  display: grid;
  place-content: center;
  width: 40px;
  height: 40px;
  position: unset;
  margin: 0;
  border: 1px solid hsl(var(--primary-300));
  border-radius: 50%;
  color: hsl(var(--primary-300));
  transition: all 0.3s ease;
}
.choice-slider__btn:hover {
  background-color: hsl(var(--primary-300));
  color: hsl(var(--neutral-0));
}

.itinerary-card__number {
  position: relative;
}
@media (min-width: 375px) {
  .itinerary-card__number::after {
    content: "";
    position: absolute;
    inset: -0.75rem;
    border: 1px dashed hsl(var(--primary-300));
    border-radius: 50%;
  }
}

.itinerary-list > li {
  position: relative;
  isolation: isolate;
}
@media (min-width: 768px) {
  .itinerary-list > li::before {
    content: "";
    position: absolute;
    top: 114px;
    bottom: -14px;
    left: 52px;
    width: 1px;
    border-left: 1px dashed hsl(var(--primary-300));
    z-index: -1;
  }
}
.itinerary-list > li:last-child::before {
  bottom: 0;
}

@media (min-width: 768px) {
  .flight-card__info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.5rem;
    background-color: hsl(var(--primary-300)/0.04);
    border-radius: 0.5rem;
  }
}
@media (min-width: 768px) {
  .flight-card__info p + p {
    margin-left: 1rem;
    padding-left: 1rem;
    position: relative;
    isolation: isolate;
  }
  .flight-card__info p + p::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 0.25rem;
    height: 0.25rem;
    transform: translateY(-50%);
    border-radius: 50%;
    background-color: hsl(var(--primary-300));
  }
}
.flight-card__footer {
  border-radius: 0 0 1rem 1rem;
}
@media (min-width: 768px) {
  .flight-card__footer {
    border-radius: 0 1rem 1rem 0;
  }
}

@keyframes imageBeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}
.preloader {
  position: relative;
  display: grid;
  place-items: center;
  height: 100vh;
  background-color: hsl(var(--neutral-900));
  position: fixed;
  inset: 0;
  z-index: 9999;
}
.preloader__img {
  width: 80px;
  height: 80px;
  animation: imageBeat 2s infinite ease;
}
.preloader__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.user-accordion {
  --bs-accordion-inner-border-radius: 1rem;
  --bs-accordion-active-bg: hsl(var(--white));
  --bs-accordion-active-color: hsl(var(--neutral-700));
}
.user-accordion .accordion-body::after {
  background-image: linear-gradient(to right, hsl(var(--neutral-40)), hsl(var(--neutral-40)));
}
.user-accordion .accordion-button:not(.collapsed)::after {
  content: "\e5cf";
  background-color: transparent;
  color: hsl(var(--neutral-700));
}
.user-accordion .accordion-button::after {
  content: "\e5ce";
  background-color: transparent;
  color: hsl(var(--neutral-700));
}
.user-accordion .accordion-button:focus {
  border-color: transparent;
  box-shadow: unset;
}

.avatar-upload {
  position: relative;
  max-width: 200px;
}
.avatar-upload__edit {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  z-index: 1;
}
.avatar-upload__input {
  display: none;
}
.avatar-upload__label {
  display: grid;
  place-content: center;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: hsl(var(--white));
  border: 1px solid hsl(var(--primary-100)/0.75);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload__label:hover {
  background: hsl(var(--primary-300));
}
.avatar-upload__label:hover::after {
  color: hsl(var(--neutral-0));
}
.avatar-upload__label:after {
  content: "\e3c9";
  font-family: "Material Symbols Outlined";
  color: hsl(var(--primary-200));
  text-align: center;
  margin: auto;
  transition: all 0.2s ease-in-out;
}
.avatar-upload__preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid hsl(var(--primary-50)/0.5);
  box-shadow: 0px 2px 4px 0px hsl(var(--black)/0.1);
}
.avatar-upload__img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.switch[type=checkbox] {
  position: relative;
  width: 3.75rem;
  height: 2rem;
  -webkit-appearance: none;
  background: hsl(var(--primary-50));
  outline: none;
  border-radius: 20px;
  transition: 0.7s;
}
.switch[type=checkbox]::before {
  content: "";
  position: absolute;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 1.5rem;
  top: 2px;
  left: 2px;
  background: hsl(var(--neutral-0));
  box-shadow: 0 0 0.25rem hsl(var(--black)/0.1);
  transition: 0.5s;
}
.switch:checked[type=checkbox] {
  background: hsl(var(--primary-300));
}
.switch:checked[type=checkbox]::before {
  left: 1.875rem;
}

.booking-tab {
  flex-direction: row;
  flex-wrap: wrap;
  padding-inline: 1rem;
  gap: 1rem;
}
@media (min-width: 1200px) {
  .booking-tab {
    justify-content: space-between;
  }
}
.booking-tab__btn {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  padding: 1rem;
  color: hsl(var(--neutral-500));
  font-weight: 500;
}
.booking-tab__btn.active {
  position: relative;
  isolation: isolate;
  color: hsl(var(--primary-300));
}
.booking-tab__btn.active::after {
  content: "";
  position: absolute;
  inset-inline: 0;
  inset-block-end: 0;
  height: 1px;
  background-color: hsl(var(--primary-300));
  z-index: -1;
}

@media (min-width: 768px) {
  .chat-box {
    display: flex;
  }
}
.chat-box__nav {
  border-bottom: 1px solid hsl(var(--neutral-40));
}
@media (min-width: 768px) {
  .chat-box__nav {
    width: 18.75rem;
    flex-shrink: 0;
    border-right: 1px solid hsl(var(--neutral-40));
    border-bottom: 0;
  }
}
@media (min-width: 992px) {
  .chat-box__nav {
    width: 17.5rem;
  }
}
@media (min-width: 1200px) {
  .chat-box__nav {
    width: 18.75rem;
  }
}
.chat-box__menu {
  max-height: 35.5rem;
}
@media (min-width: 768px) {
  .chat-box__menu {
    max-height: 39.6875rem;
  }
}
@media (min-width: 1200px) {
  .chat-box__menu {
    max-height: 36.25rem;
  }
}
.chat-box__content {
  flex-grow: 1;
}
.chat-box__content-body {
  max-height: 35.5rem;
}
@media (min-width: 768px) {
  .chat-box__content-body {
    max-height: 37.5rem;
  }
}
.chat-box__message {
  display: inline-flex;
  padding: 0.75rem 1.25rem;
  position: relative;
  isolation: isolate;
  border-radius: 0.5rem;
  background-color: hsl(var(--neutral-0));
}
@media (min-width: 768px) {
  .chat-box__message {
    max-width: 75%;
  }
}
@media (min-width: 992px) {
  .chat-box__message {
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .chat-box__message {
    max-width: 45%;
  }
}
.chat-box__message::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}
.chat-box__message.arrow-up::after {
  inset-block-end: 100%;
  inset-inline-start: 0.75rem;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid hsl(var(--neutral-0));
}
.chat-box__message.arrow-bottom::after {
  inset-block-start: 100%;
  inset-inline-end: 0.75rem;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid hsl(var(--neutral-0));
}

.header {
  z-index: 999;
  transition: all 0.3s ease;
  background-color: hsl(var(--primary-300)/0.03);
}
.header-top {
  background-color: hsl(var(--primary-300)/0.03);
}
@media (min-width: 992px) {
  .header--light .menu-link {
    color: hsl(var(--neutral-10));
  }
}
@media (min-width: 992px) {
  .header--light .menu-link.has-sub {
    color: hsl(var(--neutral-10));
  }
}
.header--light [data-bs-toggle] {
  background-color: hsl(var(--primary-50));
}
.header--fixed {
  position: fixed;
  width: 100%;
}
.header--sticky {
  position: sticky;
  top: 0;
}
.header-crossed .header--fixed {
  top: 0;
  border-color: transparent !important;
}
.header-crossed .header--fixed::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background-color: hsl(var(--neutral-900));
}
.header-crossed .header--sticky {
  background-color: hsl(var(--white));
  box-shadow: 0 0.25rem 1rem hsl(var(--black)/0.1);
  border-color: transparent !important;
}

.primary-hero {
  padding-top: clamp(3.75rem, 6.5vw, 7.5rem);
  padding-bottom: clamp(3.75rem, 6.5vw, 7.5rem);
  background-color: hsl(var(--primary-300)/0.03);
}
@media (min-width: 1200px) {
  .primary-hero {
    display: grid;
    place-content: center;
    min-height: calc(100vh - 10.75rem);
    position: relative;
    isolation: isolate;
  }
}
.primary-hero__el {
  display: none;
}
@media (min-width: 1200px) {
  .primary-hero__el {
    display: block;
    position: absolute;
    z-index: -1;
  }
  .primary-hero__el-1 {
    inset-block-start: 0;
    inset-inline-start: 0;
    opacity: 0.25;
  }
}
@media (min-width: 1200px) and (min-width: 1920px) {
  .primary-hero__el-1 {
    opacity: 1;
  }
}
@media (min-width: 1200px) {
  .primary-hero__el-2 {
    inset-block-start: 0;
    inset-inline-end: 0;
    opacity: 0.25;
  }
}
@media (min-width: 1200px) and (min-width: 1920px) {
  .primary-hero__el-2 {
    opacity: 1;
  }
}
@media (min-width: 1200px) {
  .primary-hero__el-3 {
    inset-block-start: 4.375rem;
    inset-inline-start: 11.875rem;
  }
}
@media (min-width: 1200px) and (min-width: 1920px) {
  .primary-hero__el-3 {
    inset-block-start: 6.25rem;
    inset-inline-start: 21.25rem;
  }
}
@media (min-width: 1200px) {
  .primary-hero__el-4 {
    inset-block-start: 2.5rem;
    inset-inline-end: 19.6875rem;
  }
}
@media (min-width: 1200px) and (min-width: 1920px) {
  .primary-hero__el-4 {
    inset-block-start: 5.5rem;
    inset-inline-end: 33.125rem;
  }
}
@media (min-width: 1200px) {
  .primary-hero__el-5 {
    inset-block-start: 25.625rem;
    inset-inline-start: 35.9375rem;
    opacity: 0;
  }
}
@media (min-width: 1200px) and (min-width: 1920px) {
  .primary-hero__el-5 {
    opacity: 1;
  }
}
@media (min-width: 1200px) {
  .primary-hero__el-6 {
    inset-block-start: 23.125rem;
    inset-inline-end: 32.1875rem;
    opacity: 0;
  }
}
@media (min-width: 1200px) and (min-width: 1920px) {
  .primary-hero__el-6 {
    opacity: 1;
  }
}
@media (min-width: 1200px) {
  .primary-hero__el-7 {
    inset-block-end: 1rem;
    inset-inline-start: 20rem;
  }
}
@media (min-width: 1200px) and (min-width: 1920px) {
  .primary-hero__el-7 {
    inset-block-end: 1rem;
    inset-inline-start: 33.75rem;
  }
}
@media (min-width: 1200px) {
  .primary-hero__el-8 {
    inset-block-end: 1rem;
    inset-inline-end: 5rem;
    transform: scale(0.8);
  }
}
@media (min-width: 1200px) and (min-width: 1920px) {
  .primary-hero__el-8 {
    inset-block-end: 0.5rem;
    inset-inline-end: 8.125rem;
  }
}
.primary-hero__img-1 {
  inset-block-start: 0;
  inset-inline-end: 0;
  width: 18.75rem;
}
@media (min-width: 1920px) {
  .primary-hero__img-1 {
    width: auto;
  }
}
.primary-hero__img-2 {
  inset-block-end: 0;
  inset-inline-start: 0;
  width: 18.75rem;
}
@media (min-width: 1920px) {
  .primary-hero__img-2 {
    width: auto;
  }
}

.hero-container {
  position: relative;
  isolation: isolate;
  background-color: hsl(var(--primary-300)/0.03);
}

.featured-property-section {
  position: relative;
  isolation: isolate;
  background-color: hsl(var(--primary-300)/0.05);
}

.calculate-section {
  position: relative;
  isolation: isolate;
}
@media (min-width: 992px) {
  .calculate-section::after {
    content: "";
    position: absolute;
    inset-block: clamp(3.75rem, 6.5vw + 1rem, 7.5rem);
    inset-inline-end: 0;
    inset-inline-start: 55%;
    background-image: url(../img/calculate-section-bg.png);
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;
  }
}
@media (min-width: 992px) {
  .calculate-section__content {
    padding-block: clamp(3.75rem, 6.5vw + 1rem, 7.5rem);
  }
}

.benefit-section {
  position: relative;
  isolation: isolate;
}
@media (min-width: 1920px) {
  .benefit-section::before {
    content: url(../img/category-section-el.png);
    position: absolute;
    inset-block-end: 3.75rem;
    inset-inline-end: 2rem;
  }
}
@media (min-width: 992px) {
  .benefit-section::after {
    content: "";
    position: absolute;
    inset-block: 0;
    inset-inline-start: 0;
    inset-inline-end: 55%;
    background-image: url(../img/benefit-section-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
  }
}
@media (min-width: 1200px) {
  .benefit-section::after {
    inset-inline-end: 50%;
  }
}
@media (min-width: 992px) {
  .benefit-section__content {
    padding: 1.5rem;
    background-color: hsl(var(--neutral-0));
    border-radius: 1rem;
  }
}
@media (min-width: 1400px) {
  .benefit-section__content {
    padding: 3.75rem 0 3.75rem 6.875rem;
  }
}

.cta-section {
  position: relative;
  isolation: isolate;
  padding-inline: 1rem;
}
@media (min-width: 576px) {
  .cta-section {
    padding-inline: 1.5rem;
  }
}
@media (min-width: 768px) {
  .cta-section {
    padding-inline: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .cta-section {
    padding-inline-start: 5rem;
    padding-inline-end: 0;
  }
}
@media (min-width: 1400px) {
  .cta-section {
    padding-inline-start: 7.5rem;
    padding-inline-end: 0;
  }
}
@media (min-width: 992px) {
  .cta-section::before {
    content: "";
    position: absolute;
    inset-block-start: 1.5rem;
    inset-block-end: 0;
    inset-inline-start: 60%;
    inset-inline-end: 0;
    background-image: url(../img/cta-img.png);
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
  }
}
.cta-section::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -2;
  background-image: url(../img/cta-bg.png), linear-gradient(180deg, hsl(var(--primary-300)/0.3) 0, hsl(var(--primary-300)/0.15) 55.86%, hsl(var(--primary-300)/0.3) 100%);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (min-width: 1200px) {
  .cta-section::after {
    inset-inline-end: -100%;
  }
}

.auth-container {
  position: relative;
  isolation: isolate;
}
@media (min-width: 1200px) {
  .auth-container::after {
    content: "";
    position: absolute;
    inset-inline: 0;
    inset-block-start: 40%;
    inset-block-end: 10%;
    background-color: hsl(var(--primary-300)/0.04);
    z-index: -1;
  }
}
@media (min-width: 1200px) {
  .auth-container--max::after {
    inset-block-start: 0;
  }
}
.auth-container__el {
  display: none;
}
@media (min-width: 1920px) {
  .auth-container__el {
    display: block;
    position: absolute;
    z-index: -1;
    opacity: 0.7;
  }
}
.auth-container__el-1 {
  inset-inline-start: 0;
  inset-block-start: 50%;
}
.auth-container__el-2 {
  inset-inline-end: 0;
  inset-block-start: 50%;
}
.auth-container__el-3 {
  inset-inline-start: 5.875rem;
  inset-block-start: 5.375rem;
}
.auth-container__el-4 {
  inset-inline-end: 5.3125rem;
  inset-block-start: 4.5rem;
}
.auth-container__el-5 {
  inset-inline-start: 5rem;
  inset-block-end: 21.875rem;
}
.auth-container__el-6 {
  inset-inline-start: 0;
  inset-block-end: 10%;
}

.testimonial-section {
  position: relative;
  isolation: isolate;
}
.testimonial-section__el {
  display: none;
}
@media (min-width: 1600px) {
  .testimonial-section__el {
    display: block;
    position: absolute;
  }
}
@media (min-width: 1600px) {
  .testimonial-section__el-1 {
    inset-block-start: 3.125rem;
    inset-inline-start: 3.125rem;
  }
}
@media (min-width: 1600px) {
  .testimonial-section__el-2 {
    inset-block-start: 0;
    inset-inline-end: 0;
  }
}
@media (min-width: 1600px) {
  .testimonial-section__el-3 {
    inset-block-start: 50%;
    inset-inline-end: 3.5rem;
    transform: translateY(-50%);
  }
}

@media (min-width: 992px) {
  .cta-section-ii {
    position: relative;
    isolation: isolate;
  }
}
@media (min-width: 992px) {
  .cta-section-ii::after {
    content: "";
    position: absolute;
    inset-block: 0;
    inset-inline-start: 55%;
    inset-inline-end: 0;
    background-image: url(../img/cta-bg-2.png);
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
  }
}
@media (min-width: 1200px) {
  .cta-section-ii::after {
    inset-inline-start: 50%;
  }
}

.faq-section {
  position: relative;
  isolation: isolate;
}
.faq-section__el {
  display: none;
}
@media (min-width: 1600px) {
  .faq-section__el {
    display: block;
    position: absolute;
    z-index: -1;
  }
}
.faq-section__el-1 {
  inset-block-start: 3.4375rem;
  inset-inline-end: 4.375rem;
}
.faq-section__el-2 {
  inset-block-end: 0;
  inset-inline-start: 0;
}

.secondary-hero {
  display: grid;
  place-content: center;
  padding-top: clamp(6rem, 6.5vw, 7.5rem);
  padding-bottom: clamp(6rem, 6.5vw, 7.5rem);
  min-height: 100vh;
  position: relative;
  isolation: isolate;
  background-image: url(../img/secondary-hero-bg.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.secondary-hero::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background-color: hsl(var(--neutral-1000)/0.4);
}
.secondary-hero__btn-group .list-group-item.active {
  background-color: hsl(var(--primary-300)) !important;
}

.discover-location-section {
  position: relative;
  isolation: isolate;
}
@media (min-width: 1920px) {
  .discover-location-section::before {
    content: url(../img/location-el.png);
    position: absolute;
    inset-block-start: 4.0625rem;
    inset-inline-end: 7.5rem;
  }
}
.discover-location-section::after {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 50%;
  inset-inline: 0;
  background-color: hsl(var(--primary-300)/0.05);
  z-index: -1;
}

.app-section {
  padding-inline: 1rem;
  border-radius: 1rem;
}
@media (min-width: 768px) {
  .app-section {
    padding-inline-start: 3.75rem;
  }
}
@media (min-width: 992px) {
  .app-section {
    padding-inline-start: 7.5rem;
  }
}
@media (min-width: 1200px) {
  .app-section {
    position: relative;
    isolation: isolate;
  }
  .app-section::after {
    content: "";
    position: absolute;
    inset-block: 0;
    inset-inline-start: 67%;
    inset-inline-end: -120px;
    background-image: url(../img/app-img.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    transform: scale(1.2);
  }
}

.property-showcase::after {
  content: "";
  position: absolute;
  inset: 0.25rem;
  background-color: hsl(var(--primary-300)/0.2);
  border-radius: inherit;
  z-index: 0;
}

@media (min-width: 1920px) {
  .explore-property-section {
    position: relative;
    isolation: isolate;
  }
}
.explore-property-section__el {
  display: none;
}
@media (min-width: 1920px) {
  .explore-property-section__el {
    display: block;
    position: absolute;
    z-index: 1;
  }
}
@media (min-width: 1920px) {
  .explore-property-section__el-1 {
    inset-block-start: 12.5rem;
    inset-inline-start: 22.5rem;
  }
}
@media (min-width: 1920px) {
  .explore-property-section__el-2 {
    inset-block-start: 6.25rem;
    inset-inline-end: 5.625rem;
  }
}
@media (min-width: 1920px) {
  .explore-property-section__el-3 {
    inset-block-end: 6.25rem;
    inset-inline-end: 6.25rem;
  }
}
.explore-property-section__content {
  position: relative;
  isolation: isolate;
}
.explore-property-section__content::after {
  content: "";
  position: absolute;
  inset-block-start: 50%;
  inset-block-end: 0;
  inset-inline: 0;
  background-color: hsl(var(--neutral-1000));
  z-index: -1;
}

@media (min-width: 1200px) {
  .signup-section {
    position: relative;
    isolation: isolate;
  }
  .signup-section::after {
    content: "";
    position: absolute;
    inset-block: clamp(2rem, 3.25vw + 1rem, 3.75rem);
    inset-inline-end: 0;
    inset-inline-start: 55%;
    background-image: url(../img/login-img.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.help-section {
  position: relative;
  isolation: isolate;
}
.help-section::after {
  content: "";
  position: absolute;
  inset-inline: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  background-color: hsl(var(--primary-300)/0.05);
  z-index: -1;
}
@media (min-width: 1200px) {
  .help-section::after {
    inset-block-start: 65%;
  }
}

.rental-hero {
  padding-top: clamp(4rem, 7vw, 10rem);
  padding-bottom: clamp(4rem, 7vw, 10rem);
  background-image: url(../img/hero-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.hotel-hero {
  padding-top: clamp(4rem, 7vw, 10rem);
  padding-bottom: clamp(4rem, 7vw, 10rem);
  position: relative;
  isolation: isolate;
}
@media (min-width: 1200px) {
  .hotel-hero::after {
    content: "";
    position: absolute;
    inset-block-start: 3.75rem;
    inset-block-end: 3.75rem;
    inset-inline-end: 3.75rem;
    inset-inline-start: 50%;
    background-image: url(../img/hotel-hero-bg.png);
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    z-index: -1;
  }
}
@media (min-width: 1400px) {
  .hotel-hero::after {
    inset-block-start: 5rem;
    inset-block-end: 5rem;
  }
}
.hotel-hero__el {
  display: none !important;
}
@media (min-width: 1920px) {
  .hotel-hero__el {
    display: inline-block !important;
    position: absolute;
    z-index: -1;
  }
  .hotel-hero__el-1 {
    inset-inline-end: 2.875rem;
    inset-block-end: 2.125rem;
  }
  .hotel-hero__el-2 {
    inset-inline-start: 3rem;
    inset-block-end: 2.5rem;
  }
  .hotel-hero__el-3 {
    inset-inline-start: 50%;
    inset-block-start: 3.125rem;
    transform: translateX(-50%);
  }
  .hotel-hero__el-4 {
    inset-inline-start: 4.875rem;
    inset-block-start: 2.75rem;
  }
  .hotel-hero__el-5 {
    inset-inline-start: 40%;
    inset-block-start: 17.5rem;
    transform: translateX(-50%);
  }
  .hotel-hero__el-6 {
    inset-inline-end: 20%;
    inset-block-start: 6.25rem;
    transform: translateX(50%);
    z-index: 9999;
  }
}

.discount-section {
  position: relative;
  isolation: isolate;
}
.discount-section::after {
  content: "";
  position: absolute;
  inset-inline: 0;
  inset-block-start: 50%;
  inset-block-end: 0;
  background-color: hsl(var(--primary-300)/0.05);
}

.discount-section-start {
  position: relative;
  isolation: isolate;
}
.discount-section-start::after {
  content: "";
  position: absolute;
  inset-inline: 0;
  inset-block-start: 0;
  inset-block-end: 50%;
  background-color: hsl(var(--primary-300)/0.05);
}

.tour-hero {
  padding-top: clamp(4rem, 7vw, 10rem);
  padding-bottom: clamp(4rem, 7vw, 10rem);
  position: relative;
  isolation: isolate;
}
@media (min-width: 1200px) {
  .tour-hero::before {
    content: "";
    position: absolute;
    inset-block-start: 5rem;
    inset-block-end: 3.125rem;
    inset-inline-end: 5rem;
    inset-inline-start: 50%;
    background-image: url(../img/tour-hero-el.png);
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    z-index: -2;
  }
}
@media (min-width: 1200px) {
  .tour-hero::after {
    content: "";
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-end: 3.75rem;
    inset-inline-start: 50%;
    background-image: url(../img/tour-hero-bg.png);
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    z-index: -1;
  }
}
.tour-hero__el {
  display: none !important;
}
@media (min-width: 1920px) {
  .tour-hero__el {
    display: inline-block !important;
    position: absolute;
    z-index: -1;
  }
  .tour-hero__el-1 {
    inset-inline-end: 44.5%;
    inset-block-start: 6.75rem;
    transform: translateX(50%);
    z-index: 9999;
  }
}

.flight-hero {
  padding-top: clamp(4rem, 7vw, 10rem);
  padding-bottom: clamp(4rem, 7vw, 10rem);
  position: relative;
  isolation: isolate;
}
@media (min-width: 1200px) {
  .flight-hero::after {
    content: "";
    position: absolute;
    inset-block: 0;
    inset-inline-end: 3.75rem;
    inset-inline-start: 50%;
    background-image: url(../img/flight-hero-bg.png);
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    z-index: -1;
  }
}
.flight-hero__el {
  display: none !important;
}
@media (min-width: 1920px) {
  .flight-hero__el {
    display: inline-block !important;
    position: absolute;
    z-index: -1;
  }
  .flight-hero__el-1 {
    inset-block-start: 3.75rem;
    inset-inline-start: 3.75rem;
  }
  .flight-hero__el-2 {
    inset-inline-start: 50%;
    inset-block-start: 50%;
    transform: translate(-50%, -50%);
  }
  .flight-hero__el-3 {
    inset-inline-end: 27%;
    inset-block-start: 6.25rem;
    transform: translateX(50%);
    z-index: 9999;
  }
}

.link {
  text-decoration: none;
  transition: all 0.3s ease;
}

.ff-1 {
  font-family: var(--ff-1);
}

.lh-heading {
  line-height: 1.2;
}

.transition {
  transition: all 0.3s ease;
}

.place-content-center {
  place-content: center;
}

.place-items-center {
  place-items: center;
}

.position-center {
  inset-inline-start: 50%;
  inset-block-start: 50%;
  transform: translate(-50%, -50%);
}

.translate-quarter {
  transform: translate(-25%, -25%);
}

.translate-center {
  transform: translate(-50%, -50%);
}

.category-section-element {
  inset-block-start: 50px;
  inset-inline-start: 40px;
}

.featured-section-el-1 {
  inset-block-start: 50px;
  inset-inline-end: 40px;
}

.featured-section-el-2 {
  inset-block-start: 50%;
  inset-inline-start: 60px;
  transform: translateY(-50%);
}

.hr-dashed {
  height: 1px;
  background-image: linear-gradient(to right, hsl(var(--neutral-50)) 33%, transparent 0%);
  background-position: bottom;
  background-size: 0.75rem 1px;
  background-repeat: repeat-x;
}

.hr-line {
  height: 1px;
  background-image: linear-gradient(to right, hsl(var(--neutral-40)), hsl(var(--neutral-40)));
  background-position: bottom;
  background-size: 0.75rem 1px;
  background-repeat: repeat-x;
}

.drop-shadow {
  filter: drop-shadow(0 0.25rem 0.5rem hsl(var(--black)/0.07));
}

.outline-shadow {
  outline: 0 0 1rem hsl(var(--black)/0.07);
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem hsl(var(--black)/0.07);
}

.shadow-1 {
  box-shadow: 0 6px 30px hsl(var(--black)/0.08);
}

.shadow-2 {
  box-shadow: 0 6px 30px hsl(var(--black)/0.06);
}

.shadow-3 {
  box-shadow: 0 6px 30px hsl(var(--black)/0.04);
}

.\:box-shadow {
  transition: all 0.3s ease;
}
.\:box-shadow:hover {
  box-shadow: 0 0.25rem 0.75rem hsl(var(--black)/0.07);
}

.border-primary {
  --bs-border-color: hsl(var(--primary-300));
}
.border-primary-50 {
  --bs-border-color: hsl(var(--primary-50));
}
.border-primary-100 {
  --bs-border-color: hsl(var(--primary-100));
}
.border-primary-300 {
  --bs-border-color: hsl(var(--primary-300));
}
.border-primary-400 {
  --bs-border-color: hsl(var(--primary-400));
}
.border-primary-500 {
  --bs-border-color: hsl(var(--primary-500));
}

.border-neutral {
  --bs-border-color: hsl(var(--neutral-0));
}
.border-neutral-30 {
  --bs-border-color: hsl(var(--neutral-30));
}
.border-neutral-40 {
  --bs-border-color: hsl(var(--neutral-40));
}
.border-neutral-50 {
  --bs-border-color: hsl(var(--neutral-50));
}
.border-neutral-100 {
  --bs-border-color: hsl(var(--neutral-100));
}
.border-neutral-200 {
  --bs-border-color: hsl(var(--neutral-200));
}

.border-dashed {
  --bs-border-width: 2px;
  --bs-border-style: dashed;
  --bs-border-color: hsl(var(--neutral-40));
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.\:border-0 {
  transition: all 0.3s ease;
}
.\:border-0:hover {
  border: 1px solid transparent !important;
}

.\:\:placeholder-neutral-30::placeholder {
  color: hsl(var(--neutral-30));
}

.\:\:placeholder-neutral-300::placeholder {
  color: hsl(var(--neutral-300));
}

.\:\:placeholder-neutral-600::placeholder {
  color: hsl(var(--neutral-600));
}

.\:focus-outline-0:focus {
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.form-control {
  border-radius: 0.25rem;
}
.form-control:focus {
  box-shadow: none;
  border-color: var(--bs-border-color);
}

.form-select {
  border-radius: 0.25rem;
}
.form-select:focus {
  box-shadow: none;
  border-color: var(--bs-border-color);
}

.custom-form-select {
  padding: 0.375rem 1.5rem 0.375rem 0.75rem;
  background-position: right 0 center;
}

.active-clr {
  color: hsl(var(--primary-300)) !important;
}

.active-bg {
  background-color: hsl(var(--primary-300)) !important;
}

@media (min-width: 1400px) {
  .scale-05 {
    transform: scale(1.05);
  }
}

.max-width {
  width: max-content;
}

.white-space-normal {
  white-space: normal;
}

.notifications-content {
  max-width: 20ch;
}
@media (min-width: 375px) {
  .notifications-content {
    max-width: 24ch;
  }
}

.overflow-x-auto {
  scrollbar-color: hsl(var(--neutral-30)/0.5) hsl(var(--neutral-30)/0.5);
  scrollbar-width: thin;
}
.overflow-x-auto::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}
.overflow-x-auto::-webkit-scrollbar-track {
  background: hsl(var(--neutral-30)/0.5);
}
.overflow-x-auto::-webkit-scrollbar-thumb {
  background: hsl(var(--neutral-30)/0.5);
}

.overflow-y-auto {
  scrollbar-color: transparent transparent;
  scrollbar-width: none;
}
.overflow-y-auto::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}
.overflow-y-auto::-webkit-scrollbar-thumb {
  background: transparent;
}

.inset-0 {
  inset: 0;
}

.pointer-none {
  pointer-events: none;
}

@media (min-width: 992px) {
  .w-lg-auto {
    width: auto !important;
  }
}

.table-primary-50 {
  --bs-table-bg: hsl(var(--primary-300)/.05);
  --bs-table-color: hsl(var(--neutral-500));
}

@keyframes pulse {
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.bg-fb {
  background: hsl(var(--fb));
}

.\:bg-fb {
  transition: all 0.3s ease;
}
.\:bg-fb:hover {
  background: hsl(var(--fb));
}

.bg-tw {
  background: hsl(var(--tw));
}

.\:bg-tw {
  transition: all 0.3s ease;
}
.\:bg-tw:hover {
  background: hsl(var(--tw));
}

.bg-yt {
  background: hsl(var(--yt));
}

.\:bg-yt {
  transition: all 0.3s ease;
}
.\:bg-yt:hover {
  background: hsl(var(--yt));
}

.bg-ins {
  background: hsl(var(--ins));
}

.\:bg-ins {
  transition: all 0.3s ease;
}
.\:bg-ins:hover {
  background: hsl(var(--ins));
}

.bg-in {
  background: hsl(var(--in));
}

.\:bg-in {
  transition: all 0.3s ease;
}
.\:bg-in:hover {
  background: hsl(var(--in));
}

.bg-pin {
  background: hsl(var(--pin));
}

.\:bg-pin {
  transition: all 0.3s ease;
}
.\:bg-pin:hover {
  background: hsl(var(--pin));
}

.bg-wapp {
  background: hsl(var(--wapp));
}

.\:bg-wapp {
  transition: all 0.3s ease;
}
.\:bg-wapp:hover {
  background: hsl(var(--wapp));
}

.bg-dbl {
  background: hsl(var(--dbl));
}

.\:bg-dbl {
  transition: all 0.3s ease;
}
.\:bg-dbl:hover {
  background: hsl(var(--dbl));
}

.bg-be {
  background: hsl(var(--be));
}

.\:bg-be {
  transition: all 0.3s ease;
}
.\:bg-be:hover {
  background: hsl(var(--be));
}

.bg-vim {
  background: hsl(var(--vim));
}

.\:bg-vim {
  transition: all 0.3s ease;
}
.\:bg-vim:hover {
  background: hsl(var(--vim));
}

.bg-wc {
  background: hsl(var(--wc));
}

.\:bg-wc {
  transition: all 0.3s ease;
}
.\:bg-wc:hover {
  background: hsl(var(--wc));
}

.bg-rdt {
  background: hsl(var(--rdt));
}

.\:bg-rdt {
  transition: all 0.3s ease;
}
.\:bg-rdt:hover {
  background: hsl(var(--rdt));
}

.bg-skp {
  background: hsl(var(--skp));
}

.\:bg-skp {
  transition: all 0.3s ease;
}
.\:bg-skp:hover {
  background: hsl(var(--skp));
}

.bg-snap {
  background: hsl(var(--snap));
}

.\:bg-snap {
  transition: all 0.3s ease;
}
.\:bg-snap:hover {
  background: hsl(var(--snap));
}

.bg-twitch {
  background: hsl(var(--twitch));
}

.\:bg-twitch {
  transition: all 0.3s ease;
}
.\:bg-twitch:hover {
  background: hsl(var(--twitch));
}

.bg-qra {
  background: hsl(var(--qra));
}

.\:bg-qra {
  transition: all 0.3s ease;
}
.\:bg-qra:hover {
  background: hsl(var(--qra));
}

.bg-primary-50 {
  background-color: hsl(var(--primary-50));
}

.\:bg-primary-50:hover {
  background-color: hsl(var(--primary-50));
}

.bg-primary-75 {
  background-color: hsl(var(--primary-75));
}

.\:bg-primary-75:hover {
  background-color: hsl(var(--primary-75));
}

.bg-primary-100 {
  background-color: hsl(var(--primary-100));
}

.\:bg-primary-100:hover {
  background-color: hsl(var(--primary-100));
}

.bg-primary-200 {
  background-color: hsl(var(--primary-200));
}

.\:bg-primary-200:hover {
  background-color: hsl(var(--primary-200));
}

.bg-primary-300 {
  background-color: hsl(var(--primary-300));
}

.\:bg-primary-300:hover {
  background-color: hsl(var(--primary-300));
}

.bg-primary-400 {
  background-color: hsl(var(--primary-400));
}

.\:bg-primary-400:hover {
  background-color: hsl(var(--primary-400));
}

.bg-primary-500 {
  background-color: hsl(var(--primary-500));
}

.\:bg-primary-500:hover {
  background-color: hsl(var(--primary-500));
}

.bg-secondary-50 {
  background-color: hsl(var(--secondary-50));
}

.\:bg-secondary-50:hover {
  background-color: hsl(var(--secondary-50));
}

.bg-secondary-75 {
  background-color: hsl(var(--secondary-75));
}

.\:bg-secondary-75:hover {
  background-color: hsl(var(--secondary-75));
}

.bg-secondary-100 {
  background-color: hsl(var(--secondary-100));
}

.\:bg-secondary-100:hover {
  background-color: hsl(var(--secondary-100));
}

.bg-secondary-200 {
  background-color: hsl(var(--secondary-200));
}

.\:bg-secondary-200:hover {
  background-color: hsl(var(--secondary-200));
}

.bg-secondary-300 {
  background-color: hsl(var(--secondary-300));
}

.\:bg-secondary-300:hover {
  background-color: hsl(var(--secondary-300));
}

.bg-secondary-400 {
  background-color: hsl(var(--secondary-400));
}

.\:bg-secondary-400:hover {
  background-color: hsl(var(--secondary-400));
}

.bg-secondary-500 {
  background-color: hsl(var(--secondary-500));
}

.\:bg-secondary-500:hover {
  background-color: hsl(var(--secondary-500));
}

.bg-tertiary-50 {
  background-color: hsl(var(--tertiary-50));
}

.\:bg-tertiary-50:hover {
  background-color: hsl(var(--tertiary-50));
}

.bg-tertiary-75 {
  background-color: hsl(var(--tertiary-75));
}

.\:bg-tertiary-75:hover {
  background-color: hsl(var(--tertiary-75));
}

.bg-tertiary-100 {
  background-color: hsl(var(--tertiary-100));
}

.\:bg-tertiary-100:hover {
  background-color: hsl(var(--tertiary-100));
}

.bg-tertiary-200 {
  background-color: hsl(var(--tertiary-200));
}

.\:bg-tertiary-200:hover {
  background-color: hsl(var(--tertiary-200));
}

.bg-tertiary-300 {
  background-color: hsl(var(--tertiary-300));
}

.\:bg-tertiary-300:hover {
  background-color: hsl(var(--tertiary-300));
}

.bg-tertiary-400 {
  background-color: hsl(var(--tertiary-400));
}

.\:bg-tertiary-400:hover {
  background-color: hsl(var(--tertiary-400));
}

.bg-tertiary-500 {
  background-color: hsl(var(--tertiary-500));
}

.\:bg-tertiary-500:hover {
  background-color: hsl(var(--tertiary-500));
}

.bg-neutral-0 {
  background-color: hsl(var(--neutral-0));
}

.\:bg-neutral-0:hover {
  background-color: hsl(var(--neutral-0));
}

.bg-neutral-10 {
  background-color: hsl(var(--neutral-10));
}

.\:bg-neutral-10:hover {
  background-color: hsl(var(--neutral-10));
}

.bg-neutral-20 {
  background-color: hsl(var(--neutral-20));
}

.\:bg-neutral-20:hover {
  background-color: hsl(var(--neutral-20));
}

.bg-neutral-30 {
  background-color: hsl(var(--neutral-30));
}

.\:bg-neutral-30:hover {
  background-color: hsl(var(--neutral-30));
}

.bg-neutral-40 {
  background-color: hsl(var(--neutral-40));
}

.\:bg-neutral-40:hover {
  background-color: hsl(var(--neutral-40));
}

.bg-neutral-50 {
  background-color: hsl(var(--neutral-50));
}

.\:bg-neutral-50:hover {
  background-color: hsl(var(--neutral-50));
}

.bg-neutral-60 {
  background-color: hsl(var(--neutral-60));
}

.\:bg-neutral-60:hover {
  background-color: hsl(var(--neutral-60));
}

.bg-neutral-70 {
  background-color: hsl(var(--neutral-70));
}

.\:bg-neutral-70:hover {
  background-color: hsl(var(--neutral-70));
}

.bg-neutral-80 {
  background-color: hsl(var(--neutral-80));
}

.\:bg-neutral-80:hover {
  background-color: hsl(var(--neutral-80));
}

.bg-neutral-90 {
  background-color: hsl(var(--neutral-90));
}

.\:bg-neutral-90:hover {
  background-color: hsl(var(--neutral-90));
}

.bg-neutral-100 {
  background-color: hsl(var(--neutral-100));
}

.\:bg-neutral-100:hover {
  background-color: hsl(var(--neutral-100));
}

.bg-neutral-200 {
  background-color: hsl(var(--neutral-200));
}

.\:bg-neutral-200:hover {
  background-color: hsl(var(--neutral-200));
}

.bg-neutral-300 {
  background-color: hsl(var(--neutral-300));
}

.\:bg-neutral-300:hover {
  background-color: hsl(var(--neutral-300));
}

.bg-neutral-400 {
  background-color: hsl(var(--neutral-400));
}

.\:bg-neutral-400:hover {
  background-color: hsl(var(--neutral-400));
}

.bg-neutral-500 {
  background-color: hsl(var(--neutral-500));
}

.\:bg-neutral-500:hover {
  background-color: hsl(var(--neutral-500));
}

.bg-neutral-600 {
  background-color: hsl(var(--neutral-600));
}

.\:bg-neutral-600:hover {
  background-color: hsl(var(--neutral-600));
}

.bg-neutral-700 {
  background-color: hsl(var(--neutral-700));
}

.\:bg-neutral-700:hover {
  background-color: hsl(var(--neutral-700));
}

.bg-neutral-800 {
  background-color: hsl(var(--neutral-800));
}

.\:bg-neutral-800:hover {
  background-color: hsl(var(--neutral-800));
}

.bg-neutral-900 {
  background-color: hsl(var(--neutral-900));
}

.\:bg-neutral-900:hover {
  background-color: hsl(var(--neutral-900));
}

.bg-neutral-1000 {
  background-color: hsl(var(--neutral-1000));
}

.\:bg-neutral-1000:hover {
  background-color: hsl(var(--neutral-1000));
}

.bg-primary-3p {
  background-color: hsl(var(--primary-300)/0.03);
}

.bg-primary-5p {
  background-color: hsl(var(--primary-300)/0.05);
}

.bg-primary-10p {
  background-color: hsl(var(--primary-300)/0.1);
}

.bg-primary-15p {
  background-color: hsl(var(--primary-300)/0.15);
}

.bg-primary-20p {
  background-color: hsl(var(--primary-300)/0.2);
}

.bg-primary-30p {
  background-color: hsl(var(--primary-300)/0.3);
}

.bg-primary-70p {
  background-color: hsl(var(--primary-300)/0.7);
}

.bg-secondary-3p {
  background-color: hsl(var(--secondary-300)/0.03);
}

.bg-secondary-5p {
  background-color: hsl(var(--secondary-300)/0.05);
}

.bg-secondary-10p {
  background-color: hsl(var(--secondary-300)/0.1);
}

.bg-secondary-15p {
  background-color: hsl(var(--secondary-300)/0.15);
}

.bg-secondary-20p {
  background-color: hsl(var(--secondary-300)/0.2);
}

.bg-secondary-30p {
  background-color: hsl(var(--secondary-300)/0.3);
}

.bg-secondary-70p {
  background-color: hsl(var(--secondary-300)/0.7);
}

.bg-tertiary-3p {
  background-color: hsl(var(--tertiary-300)/0.03);
}

.bg-tertiary-5p {
  background-color: hsl(var(--tertiary-300)/0.05);
}

.bg-tertiary-10p {
  background-color: hsl(var(--tertiary-300)/0.1);
}

.bg-tertiary-15p {
  background-color: hsl(var(--tertiary-300)/0.15);
}

.bg-tertiary-20p {
  background-color: hsl(var(--tertiary-300)/0.2);
}

.bg-tertiary-30p {
  background-color: hsl(var(--tertiary-300)/0.3);
}

.bg-tertiary-70p {
  background-color: hsl(var(--tertiary-300)/0.7);
}

.clr-fb {
  background: hsl(var(--fb));
}

.\:clr-fb {
  transition: all 0.3s ease;
}
.\:clr-fb:hover {
  background: hsl(var(--fb));
}

.clr-tw {
  background: hsl(var(--tw));
}

.\:clr-tw {
  transition: all 0.3s ease;
}
.\:clr-tw:hover {
  background: hsl(var(--tw));
}

.clr-yt {
  background: hsl(var(--yt));
}

.\:clr-yt {
  transition: all 0.3s ease;
}
.\:clr-yt:hover {
  background: hsl(var(--yt));
}

.clr-ins {
  background: hsl(var(--ins));
}

.\:clr-ins {
  transition: all 0.3s ease;
}
.\:clr-ins:hover {
  background: hsl(var(--ins));
}

.clr-in {
  background: hsl(var(--in));
}

.\:clr-in {
  transition: all 0.3s ease;
}
.\:clr-in:hover {
  background: hsl(var(--in));
}

.clr-pin {
  background: hsl(var(--pin));
}

.\:clr-pin {
  transition: all 0.3s ease;
}
.\:clr-pin:hover {
  background: hsl(var(--pin));
}

.clr-wapp {
  background: hsl(var(--wapp));
}

.\:clr-wapp {
  transition: all 0.3s ease;
}
.\:clr-wapp:hover {
  background: hsl(var(--wapp));
}

.clr-dbl {
  background: hsl(var(--dbl));
}

.\:clr-dbl {
  transition: all 0.3s ease;
}
.\:clr-dbl:hover {
  background: hsl(var(--dbl));
}

.clr-be {
  background: hsl(var(--be));
}

.\:clr-be {
  transition: all 0.3s ease;
}
.\:clr-be:hover {
  background: hsl(var(--be));
}

.clr-vim {
  background: hsl(var(--vim));
}

.\:clr-vim {
  transition: all 0.3s ease;
}
.\:clr-vim:hover {
  background: hsl(var(--vim));
}

.clr-wc {
  background: hsl(var(--wc));
}

.\:clr-wc {
  transition: all 0.3s ease;
}
.\:clr-wc:hover {
  background: hsl(var(--wc));
}

.clr-rdt {
  background: hsl(var(--rdt));
}

.\:clr-rdt {
  transition: all 0.3s ease;
}
.\:clr-rdt:hover {
  background: hsl(var(--rdt));
}

.clr-skp {
  background: hsl(var(--skp));
}

.\:clr-skp {
  transition: all 0.3s ease;
}
.\:clr-skp:hover {
  background: hsl(var(--skp));
}

.clr-snap {
  background: hsl(var(--snap));
}

.\:clr-snap {
  transition: all 0.3s ease;
}
.\:clr-snap:hover {
  background: hsl(var(--snap));
}

.clr-twitch {
  background: hsl(var(--twitch));
}

.\:clr-twitch {
  transition: all 0.3s ease;
}
.\:clr-twitch:hover {
  background: hsl(var(--twitch));
}

.clr-qra {
  background: hsl(var(--qra));
}

.\:clr-qra {
  transition: all 0.3s ease;
}
.\:clr-qra:hover {
  background: hsl(var(--qra));
}

.clr-primary-50 {
  color: hsl(var(--primary-50));
}

.\:clr-primary-50:hover {
  color: hsl(var(--primary-50));
}

.clr-primary-75 {
  color: hsl(var(--primary-75));
}

.\:clr-primary-75:hover {
  color: hsl(var(--primary-75));
}

.clr-primary-100 {
  color: hsl(var(--primary-100));
}

.\:clr-primary-100:hover {
  color: hsl(var(--primary-100));
}

.clr-primary-200 {
  color: hsl(var(--primary-200));
}

.\:clr-primary-200:hover {
  color: hsl(var(--primary-200));
}

.clr-primary-300 {
  color: hsl(var(--primary-300));
}

.\:clr-primary-300:hover {
  color: hsl(var(--primary-300));
}

.clr-primary-400 {
  color: hsl(var(--primary-400));
}

.\:clr-primary-400:hover {
  color: hsl(var(--primary-400));
}

.clr-primary-500 {
  color: hsl(var(--primary-500));
}

.\:clr-primary-500:hover {
  color: hsl(var(--primary-500));
}

.clr-secondary-50 {
  color: hsl(var(--secondary-50));
}

.\:clr-secondary-50:hover {
  color: hsl(var(--secondary-50));
}

.clr-secondary-75 {
  color: hsl(var(--secondary-75));
}

.\:clr-secondary-75:hover {
  color: hsl(var(--secondary-75));
}

.clr-secondary-100 {
  color: hsl(var(--secondary-100));
}

.\:clr-secondary-100:hover {
  color: hsl(var(--secondary-100));
}

.clr-secondary-200 {
  color: hsl(var(--secondary-200));
}

.\:clr-secondary-200:hover {
  color: hsl(var(--secondary-200));
}

.clr-secondary-300 {
  color: hsl(var(--secondary-300));
}

.\:clr-secondary-300:hover {
  color: hsl(var(--secondary-300));
}

.clr-secondary-400 {
  color: hsl(var(--secondary-400));
}

.\:clr-secondary-400:hover {
  color: hsl(var(--secondary-400));
}

.clr-secondary-500 {
  color: hsl(var(--secondary-500));
}

.\:clr-secondary-500:hover {
  color: hsl(var(--secondary-500));
}

.clr-tertiary-50 {
  color: hsl(var(--tertiary-50));
}

.\:clr-tertiary-50:hover {
  color: hsl(var(--tertiary-50));
}

.clr-tertiary-75 {
  color: hsl(var(--tertiary-75));
}

.\:clr-tertiary-75:hover {
  color: hsl(var(--tertiary-75));
}

.clr-tertiary-100 {
  color: hsl(var(--tertiary-100));
}

.\:clr-tertiary-100:hover {
  color: hsl(var(--tertiary-100));
}

.clr-tertiary-200 {
  color: hsl(var(--tertiary-200));
}

.\:clr-tertiary-200:hover {
  color: hsl(var(--tertiary-200));
}

.clr-tertiary-300 {
  color: hsl(var(--tertiary-300));
}

.\:clr-tertiary-300:hover {
  color: hsl(var(--tertiary-300));
}

.clr-tertiary-400 {
  color: hsl(var(--tertiary-400));
}

.\:clr-tertiary-400:hover {
  color: hsl(var(--tertiary-400));
}

.clr-tertiary-500 {
  color: hsl(var(--tertiary-500));
}

.\:clr-tertiary-500:hover {
  color: hsl(var(--tertiary-500));
}

.clr-neutral-0 {
  color: hsl(var(--neutral-0));
}

.\:clr-neutral-0:hover {
  color: hsl(var(--neutral-0));
}

.clr-neutral-10 {
  color: hsl(var(--neutral-10));
}

.\:clr-neutral-10:hover {
  color: hsl(var(--neutral-10));
}

.clr-neutral-20 {
  color: hsl(var(--neutral-20));
}

.\:clr-neutral-20:hover {
  color: hsl(var(--neutral-20));
}

.clr-neutral-30 {
  color: hsl(var(--neutral-30));
}

.\:clr-neutral-30:hover {
  color: hsl(var(--neutral-30));
}

.clr-neutral-40 {
  color: hsl(var(--neutral-40));
}

.\:clr-neutral-40:hover {
  color: hsl(var(--neutral-40));
}

.clr-neutral-50 {
  color: hsl(var(--neutral-50));
}

.\:clr-neutral-50:hover {
  color: hsl(var(--neutral-50));
}

.clr-neutral-60 {
  color: hsl(var(--neutral-60));
}

.\:clr-neutral-60:hover {
  color: hsl(var(--neutral-60));
}

.clr-neutral-70 {
  color: hsl(var(--neutral-70));
}

.\:clr-neutral-70:hover {
  color: hsl(var(--neutral-70));
}

.clr-neutral-80 {
  color: hsl(var(--neutral-80));
}

.\:clr-neutral-80:hover {
  color: hsl(var(--neutral-80));
}

.clr-neutral-90 {
  color: hsl(var(--neutral-90));
}

.\:clr-neutral-90:hover {
  color: hsl(var(--neutral-90));
}

.clr-neutral-100 {
  color: hsl(var(--neutral-100));
}

.\:clr-neutral-100:hover {
  color: hsl(var(--neutral-100));
}

.clr-neutral-200 {
  color: hsl(var(--neutral-200));
}

.\:clr-neutral-200:hover {
  color: hsl(var(--neutral-200));
}

.clr-neutral-300 {
  color: hsl(var(--neutral-300));
}

.\:clr-neutral-300:hover {
  color: hsl(var(--neutral-300));
}

.clr-neutral-400 {
  color: hsl(var(--neutral-400));
}

.\:clr-neutral-400:hover {
  color: hsl(var(--neutral-400));
}

.clr-neutral-500 {
  color: hsl(var(--neutral-500));
}

.\:clr-neutral-500:hover {
  color: hsl(var(--neutral-500));
}

.clr-neutral-600 {
  color: hsl(var(--neutral-600));
}

.\:clr-neutral-600:hover {
  color: hsl(var(--neutral-600));
}

.clr-neutral-700 {
  color: hsl(var(--neutral-700));
}

.\:clr-neutral-700:hover {
  color: hsl(var(--neutral-700));
}

.clr-neutral-800 {
  color: hsl(var(--neutral-800));
}

.\:clr-neutral-800:hover {
  color: hsl(var(--neutral-800));
}

.clr-neutral-900 {
  color: hsl(var(--neutral-900));
}

.\:clr-neutral-900:hover {
  color: hsl(var(--neutral-900));
}

.clr-neutral-1000 {
  color: hsl(var(--neutral-1000));
}

.\:clr-neutral-1000:hover {
  color: hsl(var(--neutral-1000));
}

.w-0 {
  width: 0;
}

.h-0 {
  height: 0;
}

.w-1 {
  width: 0.25rem;
}

.h-1 {
  height: 0.25rem;
}

.w-2 {
  width: 0.5rem;
}

.h-2 {
  height: 0.5rem;
}

.w-3 {
  width: 0.75rem;
}

.h-3 {
  height: 0.75rem;
}

.w-4 {
  width: 1rem;
}

.h-4 {
  height: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.h-5 {
  height: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.h-6 {
  height: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.h-7 {
  height: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.h-8 {
  height: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.h-9 {
  height: 2.25rem;
}

.w-10 {
  width: 2.5rem;
}

.h-10 {
  height: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.h-11 {
  height: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.h-12 {
  height: 3rem;
}

.w-13 {
  width: 3.25rem;
}

.h-13 {
  height: 3.25rem;
}

.w-14 {
  width: 3.5rem;
}

.h-14 {
  height: 3.5rem;
}

.w-15 {
  width: 3.75rem;
}

.h-15 {
  height: 3.75rem;
}

.w-16 {
  width: 4rem;
}

.h-16 {
  height: 4rem;
}

.w-17 {
  width: 4.25rem;
}

.h-17 {
  height: 4.25rem;
}

.w-18 {
  width: 4.5rem;
}

.h-18 {
  height: 4.5rem;
}

.w-19 {
  width: 4.75rem;
}

.h-19 {
  height: 4.75rem;
}

.w-20 {
  width: 5rem;
}

.h-20 {
  height: 5rem;
}

.w-21 {
  width: 5.25rem;
}

.h-21 {
  height: 5.25rem;
}

.w-22 {
  width: 5.5rem;
}

.h-22 {
  height: 5.5rem;
}

.w-23 {
  width: 5.75rem;
}

.h-23 {
  height: 5.75rem;
}

.w-24 {
  width: 6rem;
}

.h-24 {
  height: 6rem;
}

.w-25 {
  width: 6.25rem;
}

.h-25 {
  height: 6.25rem;
}

.w-26 {
  width: 6.5rem;
}

.h-26 {
  height: 6.5rem;
}

.w-27 {
  width: 6.75rem;
}

.h-27 {
  height: 6.75rem;
}

.w-28 {
  width: 7rem;
}

.h-28 {
  height: 7rem;
}

.w-29 {
  width: 7.25rem;
}

.h-29 {
  height: 7.25rem;
}

.w-30 {
  width: 7.5rem;
}

.h-30 {
  height: 7.5rem;
}

.w-200 {
  width: 200px;
}

.w-45p {
  width: 45%;
}

.w-65p {
  width: 65%;
}

.w-90p {
  width: 90%;
}

.w-24ch {
  width: 24ch;
}

@media (min-width: 576px) {
  .w-sm-50 {
    width: 50%;
  }
}

.h-200 {
  height: 200px;
}

.h-400 {
  height: 400px;
}

.gap-0 {
  gap: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-block-start: 0 !important;
}

.pb-0 {
  padding-block-end: 0 !important;
}

.py-0 {
  padding-block: 0 !important;
}

.ps-0 {
  padding-inline-start: 0 !important;
}

.pe-0 {
  padding-inline-end: 0 !important;
}

.px-0 {
  padding-inline: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-block-start: 0 !important;
}

.mb-0 {
  margin-block-end: 0 !important;
}

.my-0 {
  margin-block: 0 !important;
}

.ms-0 {
  margin-inline-start: 0 !important;
}

.me-0 {
  margin-inline-end: 0 !important;
}

.mx-0 {
  margin-inline: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1 {
  padding-block-start: 0.25rem !important;
}

.pb-1 {
  padding-block-end: 0.25rem !important;
}

.py-1 {
  padding-block: 0.25rem !important;
}

.ps-1 {
  padding-inline-start: 0.25rem !important;
}

.pe-1 {
  padding-inline-end: 0.25rem !important;
}

.px-1 {
  padding-inline: 0.25rem !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1 {
  margin-block-start: 0.25rem !important;
}

.mb-1 {
  margin-block-end: 0.25rem !important;
}

.my-1 {
  margin-block: 0.25rem !important;
}

.ms-1 {
  margin-inline-start: 0.25rem !important;
}

.me-1 {
  margin-inline-end: 0.25rem !important;
}

.mx-1 {
  margin-inline: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2 {
  padding-block-start: 0.5rem !important;
}

.pb-2 {
  padding-block-end: 0.5rem !important;
}

.py-2 {
  padding-block: 0.5rem !important;
}

.ps-2 {
  padding-inline-start: 0.5rem !important;
}

.pe-2 {
  padding-inline-end: 0.5rem !important;
}

.px-2 {
  padding-inline: 0.5rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2 {
  margin-block-start: 0.5rem !important;
}

.mb-2 {
  margin-block-end: 0.5rem !important;
}

.my-2 {
  margin-block: 0.5rem !important;
}

.ms-2 {
  margin-inline-start: 0.5rem !important;
}

.me-2 {
  margin-inline-end: 0.5rem !important;
}

.mx-2 {
  margin-inline: 0.5rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.pt-3 {
  padding-block-start: 0.75rem !important;
}

.pb-3 {
  padding-block-end: 0.75rem !important;
}

.py-3 {
  padding-block: 0.75rem !important;
}

.ps-3 {
  padding-inline-start: 0.75rem !important;
}

.pe-3 {
  padding-inline-end: 0.75rem !important;
}

.px-3 {
  padding-inline: 0.75rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.mt-3 {
  margin-block-start: 0.75rem !important;
}

.mb-3 {
  margin-block-end: 0.75rem !important;
}

.my-3 {
  margin-block: 0.75rem !important;
}

.ms-3 {
  margin-inline-start: 0.75rem !important;
}

.me-3 {
  margin-inline-end: 0.75rem !important;
}

.mx-3 {
  margin-inline: 0.75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.pt-4 {
  padding-block-start: 1rem !important;
}

.pb-4 {
  padding-block-end: 1rem !important;
}

.py-4 {
  padding-block: 1rem !important;
}

.ps-4 {
  padding-inline-start: 1rem !important;
}

.pe-4 {
  padding-inline-end: 1rem !important;
}

.px-4 {
  padding-inline: 1rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.mt-4 {
  margin-block-start: 1rem !important;
}

.mb-4 {
  margin-block-end: 1rem !important;
}

.my-4 {
  margin-block: 1rem !important;
}

.ms-4 {
  margin-inline-start: 1rem !important;
}

.me-4 {
  margin-inline-end: 1rem !important;
}

.mx-4 {
  margin-inline: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.pt-5 {
  padding-block-start: 1.25rem !important;
}

.pb-5 {
  padding-block-end: 1.25rem !important;
}

.py-5 {
  padding-block: 1.25rem !important;
}

.ps-5 {
  padding-inline-start: 1.25rem !important;
}

.pe-5 {
  padding-inline-end: 1.25rem !important;
}

.px-5 {
  padding-inline: 1.25rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.mt-5 {
  margin-block-start: 1.25rem !important;
}

.mb-5 {
  margin-block-end: 1.25rem !important;
}

.my-5 {
  margin-block: 1.25rem !important;
}

.ms-5 {
  margin-inline-start: 1.25rem !important;
}

.me-5 {
  margin-inline-end: 1.25rem !important;
}

.mx-5 {
  margin-inline: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.pt-6 {
  padding-block-start: 1.5rem !important;
}

.pb-6 {
  padding-block-end: 1.5rem !important;
}

.py-6 {
  padding-block: 1.5rem !important;
}

.ps-6 {
  padding-inline-start: 1.5rem !important;
}

.pe-6 {
  padding-inline-end: 1.5rem !important;
}

.px-6 {
  padding-inline: 1.5rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.mt-6 {
  margin-block-start: 1.5rem !important;
}

.mb-6 {
  margin-block-end: 1.5rem !important;
}

.my-6 {
  margin-block: 1.5rem !important;
}

.ms-6 {
  margin-inline-start: 1.5rem !important;
}

.me-6 {
  margin-inline-end: 1.5rem !important;
}

.mx-6 {
  margin-inline: 1.5rem !important;
}

.gap-7 {
  gap: 1.75rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.pt-7 {
  padding-block-start: 1.75rem !important;
}

.pb-7 {
  padding-block-end: 1.75rem !important;
}

.py-7 {
  padding-block: 1.75rem !important;
}

.ps-7 {
  padding-inline-start: 1.75rem !important;
}

.pe-7 {
  padding-inline-end: 1.75rem !important;
}

.px-7 {
  padding-inline: 1.75rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.mt-7 {
  margin-block-start: 1.75rem !important;
}

.mb-7 {
  margin-block-end: 1.75rem !important;
}

.my-7 {
  margin-block: 1.75rem !important;
}

.ms-7 {
  margin-inline-start: 1.75rem !important;
}

.me-7 {
  margin-inline-end: 1.75rem !important;
}

.mx-7 {
  margin-inline: 1.75rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.pt-8 {
  padding-block-start: 2rem !important;
}

.pb-8 {
  padding-block-end: 2rem !important;
}

.py-8 {
  padding-block: 2rem !important;
}

.ps-8 {
  padding-inline-start: 2rem !important;
}

.pe-8 {
  padding-inline-end: 2rem !important;
}

.px-8 {
  padding-inline: 2rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.mt-8 {
  margin-block-start: 2rem !important;
}

.mb-8 {
  margin-block-end: 2rem !important;
}

.my-8 {
  margin-block: 2rem !important;
}

.ms-8 {
  margin-inline-start: 2rem !important;
}

.me-8 {
  margin-inline-end: 2rem !important;
}

.mx-8 {
  margin-inline: 2rem !important;
}

.gap-9 {
  gap: 2.25rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.pt-9 {
  padding-block-start: 2.25rem !important;
}

.pb-9 {
  padding-block-end: 2.25rem !important;
}

.py-9 {
  padding-block: 2.25rem !important;
}

.ps-9 {
  padding-inline-start: 2.25rem !important;
}

.pe-9 {
  padding-inline-end: 2.25rem !important;
}

.px-9 {
  padding-inline: 2.25rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.mt-9 {
  margin-block-start: 2.25rem !important;
}

.mb-9 {
  margin-block-end: 2.25rem !important;
}

.my-9 {
  margin-block: 2.25rem !important;
}

.ms-9 {
  margin-inline-start: 2.25rem !important;
}

.me-9 {
  margin-inline-end: 2.25rem !important;
}

.mx-9 {
  margin-inline: 2.25rem !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.pt-10 {
  padding-block-start: 2.5rem !important;
}

.pb-10 {
  padding-block-end: 2.5rem !important;
}

.py-10 {
  padding-block: 2.5rem !important;
}

.ps-10 {
  padding-inline-start: 2.5rem !important;
}

.pe-10 {
  padding-inline-end: 2.5rem !important;
}

.px-10 {
  padding-inline: 2.5rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.mt-10 {
  margin-block-start: 2.5rem !important;
}

.mb-10 {
  margin-block-end: 2.5rem !important;
}

.my-10 {
  margin-block: 2.5rem !important;
}

.ms-10 {
  margin-inline-start: 2.5rem !important;
}

.me-10 {
  margin-inline-end: 2.5rem !important;
}

.mx-10 {
  margin-inline: 2.5rem !important;
}

.gap-11 {
  gap: 2.75rem !important;
}

.p-11 {
  padding: 2.75rem !important;
}

.pt-11 {
  padding-block-start: 2.75rem !important;
}

.pb-11 {
  padding-block-end: 2.75rem !important;
}

.py-11 {
  padding-block: 2.75rem !important;
}

.ps-11 {
  padding-inline-start: 2.75rem !important;
}

.pe-11 {
  padding-inline-end: 2.75rem !important;
}

.px-11 {
  padding-inline: 2.75rem !important;
}

.m-11 {
  margin: 2.75rem !important;
}

.mt-11 {
  margin-block-start: 2.75rem !important;
}

.mb-11 {
  margin-block-end: 2.75rem !important;
}

.my-11 {
  margin-block: 2.75rem !important;
}

.ms-11 {
  margin-inline-start: 2.75rem !important;
}

.me-11 {
  margin-inline-end: 2.75rem !important;
}

.mx-11 {
  margin-inline: 2.75rem !important;
}

.gap-12 {
  gap: 3rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.pt-12 {
  padding-block-start: 3rem !important;
}

.pb-12 {
  padding-block-end: 3rem !important;
}

.py-12 {
  padding-block: 3rem !important;
}

.ps-12 {
  padding-inline-start: 3rem !important;
}

.pe-12 {
  padding-inline-end: 3rem !important;
}

.px-12 {
  padding-inline: 3rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.mt-12 {
  margin-block-start: 3rem !important;
}

.mb-12 {
  margin-block-end: 3rem !important;
}

.my-12 {
  margin-block: 3rem !important;
}

.ms-12 {
  margin-inline-start: 3rem !important;
}

.me-12 {
  margin-inline-end: 3rem !important;
}

.mx-12 {
  margin-inline: 3rem !important;
}

.gap-13 {
  gap: 3.25rem !important;
}

.p-13 {
  padding: 3.25rem !important;
}

.pt-13 {
  padding-block-start: 3.25rem !important;
}

.pb-13 {
  padding-block-end: 3.25rem !important;
}

.py-13 {
  padding-block: 3.25rem !important;
}

.ps-13 {
  padding-inline-start: 3.25rem !important;
}

.pe-13 {
  padding-inline-end: 3.25rem !important;
}

.px-13 {
  padding-inline: 3.25rem !important;
}

.m-13 {
  margin: 3.25rem !important;
}

.mt-13 {
  margin-block-start: 3.25rem !important;
}

.mb-13 {
  margin-block-end: 3.25rem !important;
}

.my-13 {
  margin-block: 3.25rem !important;
}

.ms-13 {
  margin-inline-start: 3.25rem !important;
}

.me-13 {
  margin-inline-end: 3.25rem !important;
}

.mx-13 {
  margin-inline: 3.25rem !important;
}

.gap-14 {
  gap: 3.5rem !important;
}

.p-14 {
  padding: 3.5rem !important;
}

.pt-14 {
  padding-block-start: 3.5rem !important;
}

.pb-14 {
  padding-block-end: 3.5rem !important;
}

.py-14 {
  padding-block: 3.5rem !important;
}

.ps-14 {
  padding-inline-start: 3.5rem !important;
}

.pe-14 {
  padding-inline-end: 3.5rem !important;
}

.px-14 {
  padding-inline: 3.5rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.mt-14 {
  margin-block-start: 3.5rem !important;
}

.mb-14 {
  margin-block-end: 3.5rem !important;
}

.my-14 {
  margin-block: 3.5rem !important;
}

.ms-14 {
  margin-inline-start: 3.5rem !important;
}

.me-14 {
  margin-inline-end: 3.5rem !important;
}

.mx-14 {
  margin-inline: 3.5rem !important;
}

.gap-15 {
  gap: 3.75rem !important;
}

.p-15 {
  padding: 3.75rem !important;
}

.pt-15 {
  padding-block-start: 3.75rem !important;
}

.pb-15 {
  padding-block-end: 3.75rem !important;
}

.py-15 {
  padding-block: 3.75rem !important;
}

.ps-15 {
  padding-inline-start: 3.75rem !important;
}

.pe-15 {
  padding-inline-end: 3.75rem !important;
}

.px-15 {
  padding-inline: 3.75rem !important;
}

.m-15 {
  margin: 3.75rem !important;
}

.mt-15 {
  margin-block-start: 3.75rem !important;
}

.mb-15 {
  margin-block-end: 3.75rem !important;
}

.my-15 {
  margin-block: 3.75rem !important;
}

.ms-15 {
  margin-inline-start: 3.75rem !important;
}

.me-15 {
  margin-inline-end: 3.75rem !important;
}

.mx-15 {
  margin-inline: 3.75rem !important;
}

.gap-16 {
  gap: 4rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.pt-16 {
  padding-block-start: 4rem !important;
}

.pb-16 {
  padding-block-end: 4rem !important;
}

.py-16 {
  padding-block: 4rem !important;
}

.ps-16 {
  padding-inline-start: 4rem !important;
}

.pe-16 {
  padding-inline-end: 4rem !important;
}

.px-16 {
  padding-inline: 4rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.mt-16 {
  margin-block-start: 4rem !important;
}

.mb-16 {
  margin-block-end: 4rem !important;
}

.my-16 {
  margin-block: 4rem !important;
}

.ms-16 {
  margin-inline-start: 4rem !important;
}

.me-16 {
  margin-inline-end: 4rem !important;
}

.mx-16 {
  margin-inline: 4rem !important;
}

.gap-17 {
  gap: 4.25rem !important;
}

.p-17 {
  padding: 4.25rem !important;
}

.pt-17 {
  padding-block-start: 4.25rem !important;
}

.pb-17 {
  padding-block-end: 4.25rem !important;
}

.py-17 {
  padding-block: 4.25rem !important;
}

.ps-17 {
  padding-inline-start: 4.25rem !important;
}

.pe-17 {
  padding-inline-end: 4.25rem !important;
}

.px-17 {
  padding-inline: 4.25rem !important;
}

.m-17 {
  margin: 4.25rem !important;
}

.mt-17 {
  margin-block-start: 4.25rem !important;
}

.mb-17 {
  margin-block-end: 4.25rem !important;
}

.my-17 {
  margin-block: 4.25rem !important;
}

.ms-17 {
  margin-inline-start: 4.25rem !important;
}

.me-17 {
  margin-inline-end: 4.25rem !important;
}

.mx-17 {
  margin-inline: 4.25rem !important;
}

.gap-18 {
  gap: 4.5rem !important;
}

.p-18 {
  padding: 4.5rem !important;
}

.pt-18 {
  padding-block-start: 4.5rem !important;
}

.pb-18 {
  padding-block-end: 4.5rem !important;
}

.py-18 {
  padding-block: 4.5rem !important;
}

.ps-18 {
  padding-inline-start: 4.5rem !important;
}

.pe-18 {
  padding-inline-end: 4.5rem !important;
}

.px-18 {
  padding-inline: 4.5rem !important;
}

.m-18 {
  margin: 4.5rem !important;
}

.mt-18 {
  margin-block-start: 4.5rem !important;
}

.mb-18 {
  margin-block-end: 4.5rem !important;
}

.my-18 {
  margin-block: 4.5rem !important;
}

.ms-18 {
  margin-inline-start: 4.5rem !important;
}

.me-18 {
  margin-inline-end: 4.5rem !important;
}

.mx-18 {
  margin-inline: 4.5rem !important;
}

.gap-19 {
  gap: 4.75rem !important;
}

.p-19 {
  padding: 4.75rem !important;
}

.pt-19 {
  padding-block-start: 4.75rem !important;
}

.pb-19 {
  padding-block-end: 4.75rem !important;
}

.py-19 {
  padding-block: 4.75rem !important;
}

.ps-19 {
  padding-inline-start: 4.75rem !important;
}

.pe-19 {
  padding-inline-end: 4.75rem !important;
}

.px-19 {
  padding-inline: 4.75rem !important;
}

.m-19 {
  margin: 4.75rem !important;
}

.mt-19 {
  margin-block-start: 4.75rem !important;
}

.mb-19 {
  margin-block-end: 4.75rem !important;
}

.my-19 {
  margin-block: 4.75rem !important;
}

.ms-19 {
  margin-inline-start: 4.75rem !important;
}

.me-19 {
  margin-inline-end: 4.75rem !important;
}

.mx-19 {
  margin-inline: 4.75rem !important;
}

.gap-20 {
  gap: 5rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.pt-20 {
  padding-block-start: 5rem !important;
}

.pb-20 {
  padding-block-end: 5rem !important;
}

.py-20 {
  padding-block: 5rem !important;
}

.ps-20 {
  padding-inline-start: 5rem !important;
}

.pe-20 {
  padding-inline-end: 5rem !important;
}

.px-20 {
  padding-inline: 5rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.mt-20 {
  margin-block-start: 5rem !important;
}

.mb-20 {
  margin-block-end: 5rem !important;
}

.my-20 {
  margin-block: 5rem !important;
}

.ms-20 {
  margin-inline-start: 5rem !important;
}

.me-20 {
  margin-inline-end: 5rem !important;
}

.mx-20 {
  margin-inline: 5rem !important;
}

.gap-21 {
  gap: 5.25rem !important;
}

.p-21 {
  padding: 5.25rem !important;
}

.pt-21 {
  padding-block-start: 5.25rem !important;
}

.pb-21 {
  padding-block-end: 5.25rem !important;
}

.py-21 {
  padding-block: 5.25rem !important;
}

.ps-21 {
  padding-inline-start: 5.25rem !important;
}

.pe-21 {
  padding-inline-end: 5.25rem !important;
}

.px-21 {
  padding-inline: 5.25rem !important;
}

.m-21 {
  margin: 5.25rem !important;
}

.mt-21 {
  margin-block-start: 5.25rem !important;
}

.mb-21 {
  margin-block-end: 5.25rem !important;
}

.my-21 {
  margin-block: 5.25rem !important;
}

.ms-21 {
  margin-inline-start: 5.25rem !important;
}

.me-21 {
  margin-inline-end: 5.25rem !important;
}

.mx-21 {
  margin-inline: 5.25rem !important;
}

.gap-22 {
  gap: 5.5rem !important;
}

.p-22 {
  padding: 5.5rem !important;
}

.pt-22 {
  padding-block-start: 5.5rem !important;
}

.pb-22 {
  padding-block-end: 5.5rem !important;
}

.py-22 {
  padding-block: 5.5rem !important;
}

.ps-22 {
  padding-inline-start: 5.5rem !important;
}

.pe-22 {
  padding-inline-end: 5.5rem !important;
}

.px-22 {
  padding-inline: 5.5rem !important;
}

.m-22 {
  margin: 5.5rem !important;
}

.mt-22 {
  margin-block-start: 5.5rem !important;
}

.mb-22 {
  margin-block-end: 5.5rem !important;
}

.my-22 {
  margin-block: 5.5rem !important;
}

.ms-22 {
  margin-inline-start: 5.5rem !important;
}

.me-22 {
  margin-inline-end: 5.5rem !important;
}

.mx-22 {
  margin-inline: 5.5rem !important;
}

.gap-23 {
  gap: 5.75rem !important;
}

.p-23 {
  padding: 5.75rem !important;
}

.pt-23 {
  padding-block-start: 5.75rem !important;
}

.pb-23 {
  padding-block-end: 5.75rem !important;
}

.py-23 {
  padding-block: 5.75rem !important;
}

.ps-23 {
  padding-inline-start: 5.75rem !important;
}

.pe-23 {
  padding-inline-end: 5.75rem !important;
}

.px-23 {
  padding-inline: 5.75rem !important;
}

.m-23 {
  margin: 5.75rem !important;
}

.mt-23 {
  margin-block-start: 5.75rem !important;
}

.mb-23 {
  margin-block-end: 5.75rem !important;
}

.my-23 {
  margin-block: 5.75rem !important;
}

.ms-23 {
  margin-inline-start: 5.75rem !important;
}

.me-23 {
  margin-inline-end: 5.75rem !important;
}

.mx-23 {
  margin-inline: 5.75rem !important;
}

.gap-24 {
  gap: 6rem !important;
}

.p-24 {
  padding: 6rem !important;
}

.pt-24 {
  padding-block-start: 6rem !important;
}

.pb-24 {
  padding-block-end: 6rem !important;
}

.py-24 {
  padding-block: 6rem !important;
}

.ps-24 {
  padding-inline-start: 6rem !important;
}

.pe-24 {
  padding-inline-end: 6rem !important;
}

.px-24 {
  padding-inline: 6rem !important;
}

.m-24 {
  margin: 6rem !important;
}

.mt-24 {
  margin-block-start: 6rem !important;
}

.mb-24 {
  margin-block-end: 6rem !important;
}

.my-24 {
  margin-block: 6rem !important;
}

.ms-24 {
  margin-inline-start: 6rem !important;
}

.me-24 {
  margin-inline-end: 6rem !important;
}

.mx-24 {
  margin-inline: 6rem !important;
}

.gap-25 {
  gap: 6.25rem !important;
}

.p-25 {
  padding: 6.25rem !important;
}

.pt-25 {
  padding-block-start: 6.25rem !important;
}

.pb-25 {
  padding-block-end: 6.25rem !important;
}

.py-25 {
  padding-block: 6.25rem !important;
}

.ps-25 {
  padding-inline-start: 6.25rem !important;
}

.pe-25 {
  padding-inline-end: 6.25rem !important;
}

.px-25 {
  padding-inline: 6.25rem !important;
}

.m-25 {
  margin: 6.25rem !important;
}

.mt-25 {
  margin-block-start: 6.25rem !important;
}

.mb-25 {
  margin-block-end: 6.25rem !important;
}

.my-25 {
  margin-block: 6.25rem !important;
}

.ms-25 {
  margin-inline-start: 6.25rem !important;
}

.me-25 {
  margin-inline-end: 6.25rem !important;
}

.mx-25 {
  margin-inline: 6.25rem !important;
}

.gap-26 {
  gap: 6.5rem !important;
}

.p-26 {
  padding: 6.5rem !important;
}

.pt-26 {
  padding-block-start: 6.5rem !important;
}

.pb-26 {
  padding-block-end: 6.5rem !important;
}

.py-26 {
  padding-block: 6.5rem !important;
}

.ps-26 {
  padding-inline-start: 6.5rem !important;
}

.pe-26 {
  padding-inline-end: 6.5rem !important;
}

.px-26 {
  padding-inline: 6.5rem !important;
}

.m-26 {
  margin: 6.5rem !important;
}

.mt-26 {
  margin-block-start: 6.5rem !important;
}

.mb-26 {
  margin-block-end: 6.5rem !important;
}

.my-26 {
  margin-block: 6.5rem !important;
}

.ms-26 {
  margin-inline-start: 6.5rem !important;
}

.me-26 {
  margin-inline-end: 6.5rem !important;
}

.mx-26 {
  margin-inline: 6.5rem !important;
}

.gap-27 {
  gap: 6.75rem !important;
}

.p-27 {
  padding: 6.75rem !important;
}

.pt-27 {
  padding-block-start: 6.75rem !important;
}

.pb-27 {
  padding-block-end: 6.75rem !important;
}

.py-27 {
  padding-block: 6.75rem !important;
}

.ps-27 {
  padding-inline-start: 6.75rem !important;
}

.pe-27 {
  padding-inline-end: 6.75rem !important;
}

.px-27 {
  padding-inline: 6.75rem !important;
}

.m-27 {
  margin: 6.75rem !important;
}

.mt-27 {
  margin-block-start: 6.75rem !important;
}

.mb-27 {
  margin-block-end: 6.75rem !important;
}

.my-27 {
  margin-block: 6.75rem !important;
}

.ms-27 {
  margin-inline-start: 6.75rem !important;
}

.me-27 {
  margin-inline-end: 6.75rem !important;
}

.mx-27 {
  margin-inline: 6.75rem !important;
}

.gap-28 {
  gap: 7rem !important;
}

.p-28 {
  padding: 7rem !important;
}

.pt-28 {
  padding-block-start: 7rem !important;
}

.pb-28 {
  padding-block-end: 7rem !important;
}

.py-28 {
  padding-block: 7rem !important;
}

.ps-28 {
  padding-inline-start: 7rem !important;
}

.pe-28 {
  padding-inline-end: 7rem !important;
}

.px-28 {
  padding-inline: 7rem !important;
}

.m-28 {
  margin: 7rem !important;
}

.mt-28 {
  margin-block-start: 7rem !important;
}

.mb-28 {
  margin-block-end: 7rem !important;
}

.my-28 {
  margin-block: 7rem !important;
}

.ms-28 {
  margin-inline-start: 7rem !important;
}

.me-28 {
  margin-inline-end: 7rem !important;
}

.mx-28 {
  margin-inline: 7rem !important;
}

.gap-29 {
  gap: 7.25rem !important;
}

.p-29 {
  padding: 7.25rem !important;
}

.pt-29 {
  padding-block-start: 7.25rem !important;
}

.pb-29 {
  padding-block-end: 7.25rem !important;
}

.py-29 {
  padding-block: 7.25rem !important;
}

.ps-29 {
  padding-inline-start: 7.25rem !important;
}

.pe-29 {
  padding-inline-end: 7.25rem !important;
}

.px-29 {
  padding-inline: 7.25rem !important;
}

.m-29 {
  margin: 7.25rem !important;
}

.mt-29 {
  margin-block-start: 7.25rem !important;
}

.mb-29 {
  margin-block-end: 7.25rem !important;
}

.my-29 {
  margin-block: 7.25rem !important;
}

.ms-29 {
  margin-inline-start: 7.25rem !important;
}

.me-29 {
  margin-inline-end: 7.25rem !important;
}

.mx-29 {
  margin-inline: 7.25rem !important;
}

.gap-30 {
  gap: 7.5rem !important;
}

.p-30 {
  padding: 7.5rem !important;
}

.pt-30 {
  padding-block-start: 7.5rem !important;
}

.pb-30 {
  padding-block-end: 7.5rem !important;
}

.py-30 {
  padding-block: 7.5rem !important;
}

.ps-30 {
  padding-inline-start: 7.5rem !important;
}

.pe-30 {
  padding-inline-end: 7.5rem !important;
}

.px-30 {
  padding-inline: 7.5rem !important;
}

.m-30 {
  margin: 7.5rem !important;
}

.mt-30 {
  margin-block-start: 7.5rem !important;
}

.mb-30 {
  margin-block-end: 7.5rem !important;
}

.my-30 {
  margin-block: 7.5rem !important;
}

.ms-30 {
  margin-inline-start: 7.5rem !important;
}

.me-30 {
  margin-inline-end: 7.5rem !important;
}

.mx-30 {
  margin-inline: 7.5rem !important;
}

@media (min-width: 320px) {
  .gap-xxs-0 {
    gap: 0 !important;
  }
  .p-xxs-0 {
    padding: 0 !important;
  }
  .pt-xxs-0 {
    padding-block-start: 0 !important;
  }
  .pb-xxs-0 {
    padding-block-end: 0 !important;
  }
  .py-xxs-0 {
    padding-block: 0 !important;
  }
  .ps-xxs-0 {
    padding-inline-start: 0 !important;
  }
  .pe-xxs-0 {
    padding-inline-end: 0 !important;
  }
  .px-xxs-0 {
    padding-inline: 0 !important;
  }
  .m-xxs-0 {
    margin: 0 !important;
  }
  .mt-xxs-0 {
    margin-block-start: 0 !important;
  }
  .mb-xxs-0 {
    margin-block-end: 0 !important;
  }
  .my-xxs-0 {
    margin-block: 0 !important;
  }
  .ms-xxs-0 {
    margin-inline-start: 0 !important;
  }
  .me-xxs-0 {
    margin-inline-end: 0 !important;
  }
  .mx-xxs-0 {
    margin-inline: 0 !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-1 {
    gap: 0.25rem !important;
  }
  .p-xxs-1 {
    padding: 0.25rem !important;
  }
  .pt-xxs-1 {
    padding-block-start: 0.25rem !important;
  }
  .pb-xxs-1 {
    padding-block-end: 0.25rem !important;
  }
  .py-xxs-1 {
    padding-block: 0.25rem !important;
  }
  .ps-xxs-1 {
    padding-inline-start: 0.25rem !important;
  }
  .pe-xxs-1 {
    padding-inline-end: 0.25rem !important;
  }
  .px-xxs-1 {
    padding-inline: 0.25rem !important;
  }
  .m-xxs-1 {
    margin: 0.25rem !important;
  }
  .mt-xxs-1 {
    margin-block-start: 0.25rem !important;
  }
  .mb-xxs-1 {
    margin-block-end: 0.25rem !important;
  }
  .my-xxs-1 {
    margin-block: 0.25rem !important;
  }
  .ms-xxs-1 {
    margin-inline-start: 0.25rem !important;
  }
  .me-xxs-1 {
    margin-inline-end: 0.25rem !important;
  }
  .mx-xxs-1 {
    margin-inline: 0.25rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-2 {
    gap: 0.5rem !important;
  }
  .p-xxs-2 {
    padding: 0.5rem !important;
  }
  .pt-xxs-2 {
    padding-block-start: 0.5rem !important;
  }
  .pb-xxs-2 {
    padding-block-end: 0.5rem !important;
  }
  .py-xxs-2 {
    padding-block: 0.5rem !important;
  }
  .ps-xxs-2 {
    padding-inline-start: 0.5rem !important;
  }
  .pe-xxs-2 {
    padding-inline-end: 0.5rem !important;
  }
  .px-xxs-2 {
    padding-inline: 0.5rem !important;
  }
  .m-xxs-2 {
    margin: 0.5rem !important;
  }
  .mt-xxs-2 {
    margin-block-start: 0.5rem !important;
  }
  .mb-xxs-2 {
    margin-block-end: 0.5rem !important;
  }
  .my-xxs-2 {
    margin-block: 0.5rem !important;
  }
  .ms-xxs-2 {
    margin-inline-start: 0.5rem !important;
  }
  .me-xxs-2 {
    margin-inline-end: 0.5rem !important;
  }
  .mx-xxs-2 {
    margin-inline: 0.5rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-3 {
    gap: 0.75rem !important;
  }
  .p-xxs-3 {
    padding: 0.75rem !important;
  }
  .pt-xxs-3 {
    padding-block-start: 0.75rem !important;
  }
  .pb-xxs-3 {
    padding-block-end: 0.75rem !important;
  }
  .py-xxs-3 {
    padding-block: 0.75rem !important;
  }
  .ps-xxs-3 {
    padding-inline-start: 0.75rem !important;
  }
  .pe-xxs-3 {
    padding-inline-end: 0.75rem !important;
  }
  .px-xxs-3 {
    padding-inline: 0.75rem !important;
  }
  .m-xxs-3 {
    margin: 0.75rem !important;
  }
  .mt-xxs-3 {
    margin-block-start: 0.75rem !important;
  }
  .mb-xxs-3 {
    margin-block-end: 0.75rem !important;
  }
  .my-xxs-3 {
    margin-block: 0.75rem !important;
  }
  .ms-xxs-3 {
    margin-inline-start: 0.75rem !important;
  }
  .me-xxs-3 {
    margin-inline-end: 0.75rem !important;
  }
  .mx-xxs-3 {
    margin-inline: 0.75rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-4 {
    gap: 1rem !important;
  }
  .p-xxs-4 {
    padding: 1rem !important;
  }
  .pt-xxs-4 {
    padding-block-start: 1rem !important;
  }
  .pb-xxs-4 {
    padding-block-end: 1rem !important;
  }
  .py-xxs-4 {
    padding-block: 1rem !important;
  }
  .ps-xxs-4 {
    padding-inline-start: 1rem !important;
  }
  .pe-xxs-4 {
    padding-inline-end: 1rem !important;
  }
  .px-xxs-4 {
    padding-inline: 1rem !important;
  }
  .m-xxs-4 {
    margin: 1rem !important;
  }
  .mt-xxs-4 {
    margin-block-start: 1rem !important;
  }
  .mb-xxs-4 {
    margin-block-end: 1rem !important;
  }
  .my-xxs-4 {
    margin-block: 1rem !important;
  }
  .ms-xxs-4 {
    margin-inline-start: 1rem !important;
  }
  .me-xxs-4 {
    margin-inline-end: 1rem !important;
  }
  .mx-xxs-4 {
    margin-inline: 1rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-5 {
    gap: 1.25rem !important;
  }
  .p-xxs-5 {
    padding: 1.25rem !important;
  }
  .pt-xxs-5 {
    padding-block-start: 1.25rem !important;
  }
  .pb-xxs-5 {
    padding-block-end: 1.25rem !important;
  }
  .py-xxs-5 {
    padding-block: 1.25rem !important;
  }
  .ps-xxs-5 {
    padding-inline-start: 1.25rem !important;
  }
  .pe-xxs-5 {
    padding-inline-end: 1.25rem !important;
  }
  .px-xxs-5 {
    padding-inline: 1.25rem !important;
  }
  .m-xxs-5 {
    margin: 1.25rem !important;
  }
  .mt-xxs-5 {
    margin-block-start: 1.25rem !important;
  }
  .mb-xxs-5 {
    margin-block-end: 1.25rem !important;
  }
  .my-xxs-5 {
    margin-block: 1.25rem !important;
  }
  .ms-xxs-5 {
    margin-inline-start: 1.25rem !important;
  }
  .me-xxs-5 {
    margin-inline-end: 1.25rem !important;
  }
  .mx-xxs-5 {
    margin-inline: 1.25rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-6 {
    gap: 1.5rem !important;
  }
  .p-xxs-6 {
    padding: 1.5rem !important;
  }
  .pt-xxs-6 {
    padding-block-start: 1.5rem !important;
  }
  .pb-xxs-6 {
    padding-block-end: 1.5rem !important;
  }
  .py-xxs-6 {
    padding-block: 1.5rem !important;
  }
  .ps-xxs-6 {
    padding-inline-start: 1.5rem !important;
  }
  .pe-xxs-6 {
    padding-inline-end: 1.5rem !important;
  }
  .px-xxs-6 {
    padding-inline: 1.5rem !important;
  }
  .m-xxs-6 {
    margin: 1.5rem !important;
  }
  .mt-xxs-6 {
    margin-block-start: 1.5rem !important;
  }
  .mb-xxs-6 {
    margin-block-end: 1.5rem !important;
  }
  .my-xxs-6 {
    margin-block: 1.5rem !important;
  }
  .ms-xxs-6 {
    margin-inline-start: 1.5rem !important;
  }
  .me-xxs-6 {
    margin-inline-end: 1.5rem !important;
  }
  .mx-xxs-6 {
    margin-inline: 1.5rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-7 {
    gap: 1.75rem !important;
  }
  .p-xxs-7 {
    padding: 1.75rem !important;
  }
  .pt-xxs-7 {
    padding-block-start: 1.75rem !important;
  }
  .pb-xxs-7 {
    padding-block-end: 1.75rem !important;
  }
  .py-xxs-7 {
    padding-block: 1.75rem !important;
  }
  .ps-xxs-7 {
    padding-inline-start: 1.75rem !important;
  }
  .pe-xxs-7 {
    padding-inline-end: 1.75rem !important;
  }
  .px-xxs-7 {
    padding-inline: 1.75rem !important;
  }
  .m-xxs-7 {
    margin: 1.75rem !important;
  }
  .mt-xxs-7 {
    margin-block-start: 1.75rem !important;
  }
  .mb-xxs-7 {
    margin-block-end: 1.75rem !important;
  }
  .my-xxs-7 {
    margin-block: 1.75rem !important;
  }
  .ms-xxs-7 {
    margin-inline-start: 1.75rem !important;
  }
  .me-xxs-7 {
    margin-inline-end: 1.75rem !important;
  }
  .mx-xxs-7 {
    margin-inline: 1.75rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-8 {
    gap: 2rem !important;
  }
  .p-xxs-8 {
    padding: 2rem !important;
  }
  .pt-xxs-8 {
    padding-block-start: 2rem !important;
  }
  .pb-xxs-8 {
    padding-block-end: 2rem !important;
  }
  .py-xxs-8 {
    padding-block: 2rem !important;
  }
  .ps-xxs-8 {
    padding-inline-start: 2rem !important;
  }
  .pe-xxs-8 {
    padding-inline-end: 2rem !important;
  }
  .px-xxs-8 {
    padding-inline: 2rem !important;
  }
  .m-xxs-8 {
    margin: 2rem !important;
  }
  .mt-xxs-8 {
    margin-block-start: 2rem !important;
  }
  .mb-xxs-8 {
    margin-block-end: 2rem !important;
  }
  .my-xxs-8 {
    margin-block: 2rem !important;
  }
  .ms-xxs-8 {
    margin-inline-start: 2rem !important;
  }
  .me-xxs-8 {
    margin-inline-end: 2rem !important;
  }
  .mx-xxs-8 {
    margin-inline: 2rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-9 {
    gap: 2.25rem !important;
  }
  .p-xxs-9 {
    padding: 2.25rem !important;
  }
  .pt-xxs-9 {
    padding-block-start: 2.25rem !important;
  }
  .pb-xxs-9 {
    padding-block-end: 2.25rem !important;
  }
  .py-xxs-9 {
    padding-block: 2.25rem !important;
  }
  .ps-xxs-9 {
    padding-inline-start: 2.25rem !important;
  }
  .pe-xxs-9 {
    padding-inline-end: 2.25rem !important;
  }
  .px-xxs-9 {
    padding-inline: 2.25rem !important;
  }
  .m-xxs-9 {
    margin: 2.25rem !important;
  }
  .mt-xxs-9 {
    margin-block-start: 2.25rem !important;
  }
  .mb-xxs-9 {
    margin-block-end: 2.25rem !important;
  }
  .my-xxs-9 {
    margin-block: 2.25rem !important;
  }
  .ms-xxs-9 {
    margin-inline-start: 2.25rem !important;
  }
  .me-xxs-9 {
    margin-inline-end: 2.25rem !important;
  }
  .mx-xxs-9 {
    margin-inline: 2.25rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-10 {
    gap: 2.5rem !important;
  }
  .p-xxs-10 {
    padding: 2.5rem !important;
  }
  .pt-xxs-10 {
    padding-block-start: 2.5rem !important;
  }
  .pb-xxs-10 {
    padding-block-end: 2.5rem !important;
  }
  .py-xxs-10 {
    padding-block: 2.5rem !important;
  }
  .ps-xxs-10 {
    padding-inline-start: 2.5rem !important;
  }
  .pe-xxs-10 {
    padding-inline-end: 2.5rem !important;
  }
  .px-xxs-10 {
    padding-inline: 2.5rem !important;
  }
  .m-xxs-10 {
    margin: 2.5rem !important;
  }
  .mt-xxs-10 {
    margin-block-start: 2.5rem !important;
  }
  .mb-xxs-10 {
    margin-block-end: 2.5rem !important;
  }
  .my-xxs-10 {
    margin-block: 2.5rem !important;
  }
  .ms-xxs-10 {
    margin-inline-start: 2.5rem !important;
  }
  .me-xxs-10 {
    margin-inline-end: 2.5rem !important;
  }
  .mx-xxs-10 {
    margin-inline: 2.5rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-11 {
    gap: 2.75rem !important;
  }
  .p-xxs-11 {
    padding: 2.75rem !important;
  }
  .pt-xxs-11 {
    padding-block-start: 2.75rem !important;
  }
  .pb-xxs-11 {
    padding-block-end: 2.75rem !important;
  }
  .py-xxs-11 {
    padding-block: 2.75rem !important;
  }
  .ps-xxs-11 {
    padding-inline-start: 2.75rem !important;
  }
  .pe-xxs-11 {
    padding-inline-end: 2.75rem !important;
  }
  .px-xxs-11 {
    padding-inline: 2.75rem !important;
  }
  .m-xxs-11 {
    margin: 2.75rem !important;
  }
  .mt-xxs-11 {
    margin-block-start: 2.75rem !important;
  }
  .mb-xxs-11 {
    margin-block-end: 2.75rem !important;
  }
  .my-xxs-11 {
    margin-block: 2.75rem !important;
  }
  .ms-xxs-11 {
    margin-inline-start: 2.75rem !important;
  }
  .me-xxs-11 {
    margin-inline-end: 2.75rem !important;
  }
  .mx-xxs-11 {
    margin-inline: 2.75rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-12 {
    gap: 3rem !important;
  }
  .p-xxs-12 {
    padding: 3rem !important;
  }
  .pt-xxs-12 {
    padding-block-start: 3rem !important;
  }
  .pb-xxs-12 {
    padding-block-end: 3rem !important;
  }
  .py-xxs-12 {
    padding-block: 3rem !important;
  }
  .ps-xxs-12 {
    padding-inline-start: 3rem !important;
  }
  .pe-xxs-12 {
    padding-inline-end: 3rem !important;
  }
  .px-xxs-12 {
    padding-inline: 3rem !important;
  }
  .m-xxs-12 {
    margin: 3rem !important;
  }
  .mt-xxs-12 {
    margin-block-start: 3rem !important;
  }
  .mb-xxs-12 {
    margin-block-end: 3rem !important;
  }
  .my-xxs-12 {
    margin-block: 3rem !important;
  }
  .ms-xxs-12 {
    margin-inline-start: 3rem !important;
  }
  .me-xxs-12 {
    margin-inline-end: 3rem !important;
  }
  .mx-xxs-12 {
    margin-inline: 3rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-13 {
    gap: 3.25rem !important;
  }
  .p-xxs-13 {
    padding: 3.25rem !important;
  }
  .pt-xxs-13 {
    padding-block-start: 3.25rem !important;
  }
  .pb-xxs-13 {
    padding-block-end: 3.25rem !important;
  }
  .py-xxs-13 {
    padding-block: 3.25rem !important;
  }
  .ps-xxs-13 {
    padding-inline-start: 3.25rem !important;
  }
  .pe-xxs-13 {
    padding-inline-end: 3.25rem !important;
  }
  .px-xxs-13 {
    padding-inline: 3.25rem !important;
  }
  .m-xxs-13 {
    margin: 3.25rem !important;
  }
  .mt-xxs-13 {
    margin-block-start: 3.25rem !important;
  }
  .mb-xxs-13 {
    margin-block-end: 3.25rem !important;
  }
  .my-xxs-13 {
    margin-block: 3.25rem !important;
  }
  .ms-xxs-13 {
    margin-inline-start: 3.25rem !important;
  }
  .me-xxs-13 {
    margin-inline-end: 3.25rem !important;
  }
  .mx-xxs-13 {
    margin-inline: 3.25rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-14 {
    gap: 3.5rem !important;
  }
  .p-xxs-14 {
    padding: 3.5rem !important;
  }
  .pt-xxs-14 {
    padding-block-start: 3.5rem !important;
  }
  .pb-xxs-14 {
    padding-block-end: 3.5rem !important;
  }
  .py-xxs-14 {
    padding-block: 3.5rem !important;
  }
  .ps-xxs-14 {
    padding-inline-start: 3.5rem !important;
  }
  .pe-xxs-14 {
    padding-inline-end: 3.5rem !important;
  }
  .px-xxs-14 {
    padding-inline: 3.5rem !important;
  }
  .m-xxs-14 {
    margin: 3.5rem !important;
  }
  .mt-xxs-14 {
    margin-block-start: 3.5rem !important;
  }
  .mb-xxs-14 {
    margin-block-end: 3.5rem !important;
  }
  .my-xxs-14 {
    margin-block: 3.5rem !important;
  }
  .ms-xxs-14 {
    margin-inline-start: 3.5rem !important;
  }
  .me-xxs-14 {
    margin-inline-end: 3.5rem !important;
  }
  .mx-xxs-14 {
    margin-inline: 3.5rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-15 {
    gap: 3.75rem !important;
  }
  .p-xxs-15 {
    padding: 3.75rem !important;
  }
  .pt-xxs-15 {
    padding-block-start: 3.75rem !important;
  }
  .pb-xxs-15 {
    padding-block-end: 3.75rem !important;
  }
  .py-xxs-15 {
    padding-block: 3.75rem !important;
  }
  .ps-xxs-15 {
    padding-inline-start: 3.75rem !important;
  }
  .pe-xxs-15 {
    padding-inline-end: 3.75rem !important;
  }
  .px-xxs-15 {
    padding-inline: 3.75rem !important;
  }
  .m-xxs-15 {
    margin: 3.75rem !important;
  }
  .mt-xxs-15 {
    margin-block-start: 3.75rem !important;
  }
  .mb-xxs-15 {
    margin-block-end: 3.75rem !important;
  }
  .my-xxs-15 {
    margin-block: 3.75rem !important;
  }
  .ms-xxs-15 {
    margin-inline-start: 3.75rem !important;
  }
  .me-xxs-15 {
    margin-inline-end: 3.75rem !important;
  }
  .mx-xxs-15 {
    margin-inline: 3.75rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-16 {
    gap: 4rem !important;
  }
  .p-xxs-16 {
    padding: 4rem !important;
  }
  .pt-xxs-16 {
    padding-block-start: 4rem !important;
  }
  .pb-xxs-16 {
    padding-block-end: 4rem !important;
  }
  .py-xxs-16 {
    padding-block: 4rem !important;
  }
  .ps-xxs-16 {
    padding-inline-start: 4rem !important;
  }
  .pe-xxs-16 {
    padding-inline-end: 4rem !important;
  }
  .px-xxs-16 {
    padding-inline: 4rem !important;
  }
  .m-xxs-16 {
    margin: 4rem !important;
  }
  .mt-xxs-16 {
    margin-block-start: 4rem !important;
  }
  .mb-xxs-16 {
    margin-block-end: 4rem !important;
  }
  .my-xxs-16 {
    margin-block: 4rem !important;
  }
  .ms-xxs-16 {
    margin-inline-start: 4rem !important;
  }
  .me-xxs-16 {
    margin-inline-end: 4rem !important;
  }
  .mx-xxs-16 {
    margin-inline: 4rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-17 {
    gap: 4.25rem !important;
  }
  .p-xxs-17 {
    padding: 4.25rem !important;
  }
  .pt-xxs-17 {
    padding-block-start: 4.25rem !important;
  }
  .pb-xxs-17 {
    padding-block-end: 4.25rem !important;
  }
  .py-xxs-17 {
    padding-block: 4.25rem !important;
  }
  .ps-xxs-17 {
    padding-inline-start: 4.25rem !important;
  }
  .pe-xxs-17 {
    padding-inline-end: 4.25rem !important;
  }
  .px-xxs-17 {
    padding-inline: 4.25rem !important;
  }
  .m-xxs-17 {
    margin: 4.25rem !important;
  }
  .mt-xxs-17 {
    margin-block-start: 4.25rem !important;
  }
  .mb-xxs-17 {
    margin-block-end: 4.25rem !important;
  }
  .my-xxs-17 {
    margin-block: 4.25rem !important;
  }
  .ms-xxs-17 {
    margin-inline-start: 4.25rem !important;
  }
  .me-xxs-17 {
    margin-inline-end: 4.25rem !important;
  }
  .mx-xxs-17 {
    margin-inline: 4.25rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-18 {
    gap: 4.5rem !important;
  }
  .p-xxs-18 {
    padding: 4.5rem !important;
  }
  .pt-xxs-18 {
    padding-block-start: 4.5rem !important;
  }
  .pb-xxs-18 {
    padding-block-end: 4.5rem !important;
  }
  .py-xxs-18 {
    padding-block: 4.5rem !important;
  }
  .ps-xxs-18 {
    padding-inline-start: 4.5rem !important;
  }
  .pe-xxs-18 {
    padding-inline-end: 4.5rem !important;
  }
  .px-xxs-18 {
    padding-inline: 4.5rem !important;
  }
  .m-xxs-18 {
    margin: 4.5rem !important;
  }
  .mt-xxs-18 {
    margin-block-start: 4.5rem !important;
  }
  .mb-xxs-18 {
    margin-block-end: 4.5rem !important;
  }
  .my-xxs-18 {
    margin-block: 4.5rem !important;
  }
  .ms-xxs-18 {
    margin-inline-start: 4.5rem !important;
  }
  .me-xxs-18 {
    margin-inline-end: 4.5rem !important;
  }
  .mx-xxs-18 {
    margin-inline: 4.5rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-19 {
    gap: 4.75rem !important;
  }
  .p-xxs-19 {
    padding: 4.75rem !important;
  }
  .pt-xxs-19 {
    padding-block-start: 4.75rem !important;
  }
  .pb-xxs-19 {
    padding-block-end: 4.75rem !important;
  }
  .py-xxs-19 {
    padding-block: 4.75rem !important;
  }
  .ps-xxs-19 {
    padding-inline-start: 4.75rem !important;
  }
  .pe-xxs-19 {
    padding-inline-end: 4.75rem !important;
  }
  .px-xxs-19 {
    padding-inline: 4.75rem !important;
  }
  .m-xxs-19 {
    margin: 4.75rem !important;
  }
  .mt-xxs-19 {
    margin-block-start: 4.75rem !important;
  }
  .mb-xxs-19 {
    margin-block-end: 4.75rem !important;
  }
  .my-xxs-19 {
    margin-block: 4.75rem !important;
  }
  .ms-xxs-19 {
    margin-inline-start: 4.75rem !important;
  }
  .me-xxs-19 {
    margin-inline-end: 4.75rem !important;
  }
  .mx-xxs-19 {
    margin-inline: 4.75rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-20 {
    gap: 5rem !important;
  }
  .p-xxs-20 {
    padding: 5rem !important;
  }
  .pt-xxs-20 {
    padding-block-start: 5rem !important;
  }
  .pb-xxs-20 {
    padding-block-end: 5rem !important;
  }
  .py-xxs-20 {
    padding-block: 5rem !important;
  }
  .ps-xxs-20 {
    padding-inline-start: 5rem !important;
  }
  .pe-xxs-20 {
    padding-inline-end: 5rem !important;
  }
  .px-xxs-20 {
    padding-inline: 5rem !important;
  }
  .m-xxs-20 {
    margin: 5rem !important;
  }
  .mt-xxs-20 {
    margin-block-start: 5rem !important;
  }
  .mb-xxs-20 {
    margin-block-end: 5rem !important;
  }
  .my-xxs-20 {
    margin-block: 5rem !important;
  }
  .ms-xxs-20 {
    margin-inline-start: 5rem !important;
  }
  .me-xxs-20 {
    margin-inline-end: 5rem !important;
  }
  .mx-xxs-20 {
    margin-inline: 5rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-21 {
    gap: 5.25rem !important;
  }
  .p-xxs-21 {
    padding: 5.25rem !important;
  }
  .pt-xxs-21 {
    padding-block-start: 5.25rem !important;
  }
  .pb-xxs-21 {
    padding-block-end: 5.25rem !important;
  }
  .py-xxs-21 {
    padding-block: 5.25rem !important;
  }
  .ps-xxs-21 {
    padding-inline-start: 5.25rem !important;
  }
  .pe-xxs-21 {
    padding-inline-end: 5.25rem !important;
  }
  .px-xxs-21 {
    padding-inline: 5.25rem !important;
  }
  .m-xxs-21 {
    margin: 5.25rem !important;
  }
  .mt-xxs-21 {
    margin-block-start: 5.25rem !important;
  }
  .mb-xxs-21 {
    margin-block-end: 5.25rem !important;
  }
  .my-xxs-21 {
    margin-block: 5.25rem !important;
  }
  .ms-xxs-21 {
    margin-inline-start: 5.25rem !important;
  }
  .me-xxs-21 {
    margin-inline-end: 5.25rem !important;
  }
  .mx-xxs-21 {
    margin-inline: 5.25rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-22 {
    gap: 5.5rem !important;
  }
  .p-xxs-22 {
    padding: 5.5rem !important;
  }
  .pt-xxs-22 {
    padding-block-start: 5.5rem !important;
  }
  .pb-xxs-22 {
    padding-block-end: 5.5rem !important;
  }
  .py-xxs-22 {
    padding-block: 5.5rem !important;
  }
  .ps-xxs-22 {
    padding-inline-start: 5.5rem !important;
  }
  .pe-xxs-22 {
    padding-inline-end: 5.5rem !important;
  }
  .px-xxs-22 {
    padding-inline: 5.5rem !important;
  }
  .m-xxs-22 {
    margin: 5.5rem !important;
  }
  .mt-xxs-22 {
    margin-block-start: 5.5rem !important;
  }
  .mb-xxs-22 {
    margin-block-end: 5.5rem !important;
  }
  .my-xxs-22 {
    margin-block: 5.5rem !important;
  }
  .ms-xxs-22 {
    margin-inline-start: 5.5rem !important;
  }
  .me-xxs-22 {
    margin-inline-end: 5.5rem !important;
  }
  .mx-xxs-22 {
    margin-inline: 5.5rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-23 {
    gap: 5.75rem !important;
  }
  .p-xxs-23 {
    padding: 5.75rem !important;
  }
  .pt-xxs-23 {
    padding-block-start: 5.75rem !important;
  }
  .pb-xxs-23 {
    padding-block-end: 5.75rem !important;
  }
  .py-xxs-23 {
    padding-block: 5.75rem !important;
  }
  .ps-xxs-23 {
    padding-inline-start: 5.75rem !important;
  }
  .pe-xxs-23 {
    padding-inline-end: 5.75rem !important;
  }
  .px-xxs-23 {
    padding-inline: 5.75rem !important;
  }
  .m-xxs-23 {
    margin: 5.75rem !important;
  }
  .mt-xxs-23 {
    margin-block-start: 5.75rem !important;
  }
  .mb-xxs-23 {
    margin-block-end: 5.75rem !important;
  }
  .my-xxs-23 {
    margin-block: 5.75rem !important;
  }
  .ms-xxs-23 {
    margin-inline-start: 5.75rem !important;
  }
  .me-xxs-23 {
    margin-inline-end: 5.75rem !important;
  }
  .mx-xxs-23 {
    margin-inline: 5.75rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-24 {
    gap: 6rem !important;
  }
  .p-xxs-24 {
    padding: 6rem !important;
  }
  .pt-xxs-24 {
    padding-block-start: 6rem !important;
  }
  .pb-xxs-24 {
    padding-block-end: 6rem !important;
  }
  .py-xxs-24 {
    padding-block: 6rem !important;
  }
  .ps-xxs-24 {
    padding-inline-start: 6rem !important;
  }
  .pe-xxs-24 {
    padding-inline-end: 6rem !important;
  }
  .px-xxs-24 {
    padding-inline: 6rem !important;
  }
  .m-xxs-24 {
    margin: 6rem !important;
  }
  .mt-xxs-24 {
    margin-block-start: 6rem !important;
  }
  .mb-xxs-24 {
    margin-block-end: 6rem !important;
  }
  .my-xxs-24 {
    margin-block: 6rem !important;
  }
  .ms-xxs-24 {
    margin-inline-start: 6rem !important;
  }
  .me-xxs-24 {
    margin-inline-end: 6rem !important;
  }
  .mx-xxs-24 {
    margin-inline: 6rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-25 {
    gap: 6.25rem !important;
  }
  .p-xxs-25 {
    padding: 6.25rem !important;
  }
  .pt-xxs-25 {
    padding-block-start: 6.25rem !important;
  }
  .pb-xxs-25 {
    padding-block-end: 6.25rem !important;
  }
  .py-xxs-25 {
    padding-block: 6.25rem !important;
  }
  .ps-xxs-25 {
    padding-inline-start: 6.25rem !important;
  }
  .pe-xxs-25 {
    padding-inline-end: 6.25rem !important;
  }
  .px-xxs-25 {
    padding-inline: 6.25rem !important;
  }
  .m-xxs-25 {
    margin: 6.25rem !important;
  }
  .mt-xxs-25 {
    margin-block-start: 6.25rem !important;
  }
  .mb-xxs-25 {
    margin-block-end: 6.25rem !important;
  }
  .my-xxs-25 {
    margin-block: 6.25rem !important;
  }
  .ms-xxs-25 {
    margin-inline-start: 6.25rem !important;
  }
  .me-xxs-25 {
    margin-inline-end: 6.25rem !important;
  }
  .mx-xxs-25 {
    margin-inline: 6.25rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-26 {
    gap: 6.5rem !important;
  }
  .p-xxs-26 {
    padding: 6.5rem !important;
  }
  .pt-xxs-26 {
    padding-block-start: 6.5rem !important;
  }
  .pb-xxs-26 {
    padding-block-end: 6.5rem !important;
  }
  .py-xxs-26 {
    padding-block: 6.5rem !important;
  }
  .ps-xxs-26 {
    padding-inline-start: 6.5rem !important;
  }
  .pe-xxs-26 {
    padding-inline-end: 6.5rem !important;
  }
  .px-xxs-26 {
    padding-inline: 6.5rem !important;
  }
  .m-xxs-26 {
    margin: 6.5rem !important;
  }
  .mt-xxs-26 {
    margin-block-start: 6.5rem !important;
  }
  .mb-xxs-26 {
    margin-block-end: 6.5rem !important;
  }
  .my-xxs-26 {
    margin-block: 6.5rem !important;
  }
  .ms-xxs-26 {
    margin-inline-start: 6.5rem !important;
  }
  .me-xxs-26 {
    margin-inline-end: 6.5rem !important;
  }
  .mx-xxs-26 {
    margin-inline: 6.5rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-27 {
    gap: 6.75rem !important;
  }
  .p-xxs-27 {
    padding: 6.75rem !important;
  }
  .pt-xxs-27 {
    padding-block-start: 6.75rem !important;
  }
  .pb-xxs-27 {
    padding-block-end: 6.75rem !important;
  }
  .py-xxs-27 {
    padding-block: 6.75rem !important;
  }
  .ps-xxs-27 {
    padding-inline-start: 6.75rem !important;
  }
  .pe-xxs-27 {
    padding-inline-end: 6.75rem !important;
  }
  .px-xxs-27 {
    padding-inline: 6.75rem !important;
  }
  .m-xxs-27 {
    margin: 6.75rem !important;
  }
  .mt-xxs-27 {
    margin-block-start: 6.75rem !important;
  }
  .mb-xxs-27 {
    margin-block-end: 6.75rem !important;
  }
  .my-xxs-27 {
    margin-block: 6.75rem !important;
  }
  .ms-xxs-27 {
    margin-inline-start: 6.75rem !important;
  }
  .me-xxs-27 {
    margin-inline-end: 6.75rem !important;
  }
  .mx-xxs-27 {
    margin-inline: 6.75rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-28 {
    gap: 7rem !important;
  }
  .p-xxs-28 {
    padding: 7rem !important;
  }
  .pt-xxs-28 {
    padding-block-start: 7rem !important;
  }
  .pb-xxs-28 {
    padding-block-end: 7rem !important;
  }
  .py-xxs-28 {
    padding-block: 7rem !important;
  }
  .ps-xxs-28 {
    padding-inline-start: 7rem !important;
  }
  .pe-xxs-28 {
    padding-inline-end: 7rem !important;
  }
  .px-xxs-28 {
    padding-inline: 7rem !important;
  }
  .m-xxs-28 {
    margin: 7rem !important;
  }
  .mt-xxs-28 {
    margin-block-start: 7rem !important;
  }
  .mb-xxs-28 {
    margin-block-end: 7rem !important;
  }
  .my-xxs-28 {
    margin-block: 7rem !important;
  }
  .ms-xxs-28 {
    margin-inline-start: 7rem !important;
  }
  .me-xxs-28 {
    margin-inline-end: 7rem !important;
  }
  .mx-xxs-28 {
    margin-inline: 7rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-29 {
    gap: 7.25rem !important;
  }
  .p-xxs-29 {
    padding: 7.25rem !important;
  }
  .pt-xxs-29 {
    padding-block-start: 7.25rem !important;
  }
  .pb-xxs-29 {
    padding-block-end: 7.25rem !important;
  }
  .py-xxs-29 {
    padding-block: 7.25rem !important;
  }
  .ps-xxs-29 {
    padding-inline-start: 7.25rem !important;
  }
  .pe-xxs-29 {
    padding-inline-end: 7.25rem !important;
  }
  .px-xxs-29 {
    padding-inline: 7.25rem !important;
  }
  .m-xxs-29 {
    margin: 7.25rem !important;
  }
  .mt-xxs-29 {
    margin-block-start: 7.25rem !important;
  }
  .mb-xxs-29 {
    margin-block-end: 7.25rem !important;
  }
  .my-xxs-29 {
    margin-block: 7.25rem !important;
  }
  .ms-xxs-29 {
    margin-inline-start: 7.25rem !important;
  }
  .me-xxs-29 {
    margin-inline-end: 7.25rem !important;
  }
  .mx-xxs-29 {
    margin-inline: 7.25rem !important;
  }
}
@media (min-width: 320px) {
  .gap-xxs-30 {
    gap: 7.5rem !important;
  }
  .p-xxs-30 {
    padding: 7.5rem !important;
  }
  .pt-xxs-30 {
    padding-block-start: 7.5rem !important;
  }
  .pb-xxs-30 {
    padding-block-end: 7.5rem !important;
  }
  .py-xxs-30 {
    padding-block: 7.5rem !important;
  }
  .ps-xxs-30 {
    padding-inline-start: 7.5rem !important;
  }
  .pe-xxs-30 {
    padding-inline-end: 7.5rem !important;
  }
  .px-xxs-30 {
    padding-inline: 7.5rem !important;
  }
  .m-xxs-30 {
    margin: 7.5rem !important;
  }
  .mt-xxs-30 {
    margin-block-start: 7.5rem !important;
  }
  .mb-xxs-30 {
    margin-block-end: 7.5rem !important;
  }
  .my-xxs-30 {
    margin-block: 7.5rem !important;
  }
  .ms-xxs-30 {
    margin-inline-start: 7.5rem !important;
  }
  .me-xxs-30 {
    margin-inline-end: 7.5rem !important;
  }
  .mx-xxs-30 {
    margin-inline: 7.5rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-0 {
    gap: 0 !important;
  }
  .p-xs-0 {
    padding: 0 !important;
  }
  .pt-xs-0 {
    padding-block-start: 0 !important;
  }
  .pb-xs-0 {
    padding-block-end: 0 !important;
  }
  .py-xs-0 {
    padding-block: 0 !important;
  }
  .ps-xs-0 {
    padding-inline-start: 0 !important;
  }
  .pe-xs-0 {
    padding-inline-end: 0 !important;
  }
  .px-xs-0 {
    padding-inline: 0 !important;
  }
  .m-xs-0 {
    margin: 0 !important;
  }
  .mt-xs-0 {
    margin-block-start: 0 !important;
  }
  .mb-xs-0 {
    margin-block-end: 0 !important;
  }
  .my-xs-0 {
    margin-block: 0 !important;
  }
  .ms-xs-0 {
    margin-inline-start: 0 !important;
  }
  .me-xs-0 {
    margin-inline-end: 0 !important;
  }
  .mx-xs-0 {
    margin-inline: 0 !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-1 {
    gap: 0.25rem !important;
  }
  .p-xs-1 {
    padding: 0.25rem !important;
  }
  .pt-xs-1 {
    padding-block-start: 0.25rem !important;
  }
  .pb-xs-1 {
    padding-block-end: 0.25rem !important;
  }
  .py-xs-1 {
    padding-block: 0.25rem !important;
  }
  .ps-xs-1 {
    padding-inline-start: 0.25rem !important;
  }
  .pe-xs-1 {
    padding-inline-end: 0.25rem !important;
  }
  .px-xs-1 {
    padding-inline: 0.25rem !important;
  }
  .m-xs-1 {
    margin: 0.25rem !important;
  }
  .mt-xs-1 {
    margin-block-start: 0.25rem !important;
  }
  .mb-xs-1 {
    margin-block-end: 0.25rem !important;
  }
  .my-xs-1 {
    margin-block: 0.25rem !important;
  }
  .ms-xs-1 {
    margin-inline-start: 0.25rem !important;
  }
  .me-xs-1 {
    margin-inline-end: 0.25rem !important;
  }
  .mx-xs-1 {
    margin-inline: 0.25rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-2 {
    gap: 0.5rem !important;
  }
  .p-xs-2 {
    padding: 0.5rem !important;
  }
  .pt-xs-2 {
    padding-block-start: 0.5rem !important;
  }
  .pb-xs-2 {
    padding-block-end: 0.5rem !important;
  }
  .py-xs-2 {
    padding-block: 0.5rem !important;
  }
  .ps-xs-2 {
    padding-inline-start: 0.5rem !important;
  }
  .pe-xs-2 {
    padding-inline-end: 0.5rem !important;
  }
  .px-xs-2 {
    padding-inline: 0.5rem !important;
  }
  .m-xs-2 {
    margin: 0.5rem !important;
  }
  .mt-xs-2 {
    margin-block-start: 0.5rem !important;
  }
  .mb-xs-2 {
    margin-block-end: 0.5rem !important;
  }
  .my-xs-2 {
    margin-block: 0.5rem !important;
  }
  .ms-xs-2 {
    margin-inline-start: 0.5rem !important;
  }
  .me-xs-2 {
    margin-inline-end: 0.5rem !important;
  }
  .mx-xs-2 {
    margin-inline: 0.5rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-3 {
    gap: 0.75rem !important;
  }
  .p-xs-3 {
    padding: 0.75rem !important;
  }
  .pt-xs-3 {
    padding-block-start: 0.75rem !important;
  }
  .pb-xs-3 {
    padding-block-end: 0.75rem !important;
  }
  .py-xs-3 {
    padding-block: 0.75rem !important;
  }
  .ps-xs-3 {
    padding-inline-start: 0.75rem !important;
  }
  .pe-xs-3 {
    padding-inline-end: 0.75rem !important;
  }
  .px-xs-3 {
    padding-inline: 0.75rem !important;
  }
  .m-xs-3 {
    margin: 0.75rem !important;
  }
  .mt-xs-3 {
    margin-block-start: 0.75rem !important;
  }
  .mb-xs-3 {
    margin-block-end: 0.75rem !important;
  }
  .my-xs-3 {
    margin-block: 0.75rem !important;
  }
  .ms-xs-3 {
    margin-inline-start: 0.75rem !important;
  }
  .me-xs-3 {
    margin-inline-end: 0.75rem !important;
  }
  .mx-xs-3 {
    margin-inline: 0.75rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-4 {
    gap: 1rem !important;
  }
  .p-xs-4 {
    padding: 1rem !important;
  }
  .pt-xs-4 {
    padding-block-start: 1rem !important;
  }
  .pb-xs-4 {
    padding-block-end: 1rem !important;
  }
  .py-xs-4 {
    padding-block: 1rem !important;
  }
  .ps-xs-4 {
    padding-inline-start: 1rem !important;
  }
  .pe-xs-4 {
    padding-inline-end: 1rem !important;
  }
  .px-xs-4 {
    padding-inline: 1rem !important;
  }
  .m-xs-4 {
    margin: 1rem !important;
  }
  .mt-xs-4 {
    margin-block-start: 1rem !important;
  }
  .mb-xs-4 {
    margin-block-end: 1rem !important;
  }
  .my-xs-4 {
    margin-block: 1rem !important;
  }
  .ms-xs-4 {
    margin-inline-start: 1rem !important;
  }
  .me-xs-4 {
    margin-inline-end: 1rem !important;
  }
  .mx-xs-4 {
    margin-inline: 1rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-5 {
    gap: 1.25rem !important;
  }
  .p-xs-5 {
    padding: 1.25rem !important;
  }
  .pt-xs-5 {
    padding-block-start: 1.25rem !important;
  }
  .pb-xs-5 {
    padding-block-end: 1.25rem !important;
  }
  .py-xs-5 {
    padding-block: 1.25rem !important;
  }
  .ps-xs-5 {
    padding-inline-start: 1.25rem !important;
  }
  .pe-xs-5 {
    padding-inline-end: 1.25rem !important;
  }
  .px-xs-5 {
    padding-inline: 1.25rem !important;
  }
  .m-xs-5 {
    margin: 1.25rem !important;
  }
  .mt-xs-5 {
    margin-block-start: 1.25rem !important;
  }
  .mb-xs-5 {
    margin-block-end: 1.25rem !important;
  }
  .my-xs-5 {
    margin-block: 1.25rem !important;
  }
  .ms-xs-5 {
    margin-inline-start: 1.25rem !important;
  }
  .me-xs-5 {
    margin-inline-end: 1.25rem !important;
  }
  .mx-xs-5 {
    margin-inline: 1.25rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-6 {
    gap: 1.5rem !important;
  }
  .p-xs-6 {
    padding: 1.5rem !important;
  }
  .pt-xs-6 {
    padding-block-start: 1.5rem !important;
  }
  .pb-xs-6 {
    padding-block-end: 1.5rem !important;
  }
  .py-xs-6 {
    padding-block: 1.5rem !important;
  }
  .ps-xs-6 {
    padding-inline-start: 1.5rem !important;
  }
  .pe-xs-6 {
    padding-inline-end: 1.5rem !important;
  }
  .px-xs-6 {
    padding-inline: 1.5rem !important;
  }
  .m-xs-6 {
    margin: 1.5rem !important;
  }
  .mt-xs-6 {
    margin-block-start: 1.5rem !important;
  }
  .mb-xs-6 {
    margin-block-end: 1.5rem !important;
  }
  .my-xs-6 {
    margin-block: 1.5rem !important;
  }
  .ms-xs-6 {
    margin-inline-start: 1.5rem !important;
  }
  .me-xs-6 {
    margin-inline-end: 1.5rem !important;
  }
  .mx-xs-6 {
    margin-inline: 1.5rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-7 {
    gap: 1.75rem !important;
  }
  .p-xs-7 {
    padding: 1.75rem !important;
  }
  .pt-xs-7 {
    padding-block-start: 1.75rem !important;
  }
  .pb-xs-7 {
    padding-block-end: 1.75rem !important;
  }
  .py-xs-7 {
    padding-block: 1.75rem !important;
  }
  .ps-xs-7 {
    padding-inline-start: 1.75rem !important;
  }
  .pe-xs-7 {
    padding-inline-end: 1.75rem !important;
  }
  .px-xs-7 {
    padding-inline: 1.75rem !important;
  }
  .m-xs-7 {
    margin: 1.75rem !important;
  }
  .mt-xs-7 {
    margin-block-start: 1.75rem !important;
  }
  .mb-xs-7 {
    margin-block-end: 1.75rem !important;
  }
  .my-xs-7 {
    margin-block: 1.75rem !important;
  }
  .ms-xs-7 {
    margin-inline-start: 1.75rem !important;
  }
  .me-xs-7 {
    margin-inline-end: 1.75rem !important;
  }
  .mx-xs-7 {
    margin-inline: 1.75rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-8 {
    gap: 2rem !important;
  }
  .p-xs-8 {
    padding: 2rem !important;
  }
  .pt-xs-8 {
    padding-block-start: 2rem !important;
  }
  .pb-xs-8 {
    padding-block-end: 2rem !important;
  }
  .py-xs-8 {
    padding-block: 2rem !important;
  }
  .ps-xs-8 {
    padding-inline-start: 2rem !important;
  }
  .pe-xs-8 {
    padding-inline-end: 2rem !important;
  }
  .px-xs-8 {
    padding-inline: 2rem !important;
  }
  .m-xs-8 {
    margin: 2rem !important;
  }
  .mt-xs-8 {
    margin-block-start: 2rem !important;
  }
  .mb-xs-8 {
    margin-block-end: 2rem !important;
  }
  .my-xs-8 {
    margin-block: 2rem !important;
  }
  .ms-xs-8 {
    margin-inline-start: 2rem !important;
  }
  .me-xs-8 {
    margin-inline-end: 2rem !important;
  }
  .mx-xs-8 {
    margin-inline: 2rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-9 {
    gap: 2.25rem !important;
  }
  .p-xs-9 {
    padding: 2.25rem !important;
  }
  .pt-xs-9 {
    padding-block-start: 2.25rem !important;
  }
  .pb-xs-9 {
    padding-block-end: 2.25rem !important;
  }
  .py-xs-9 {
    padding-block: 2.25rem !important;
  }
  .ps-xs-9 {
    padding-inline-start: 2.25rem !important;
  }
  .pe-xs-9 {
    padding-inline-end: 2.25rem !important;
  }
  .px-xs-9 {
    padding-inline: 2.25rem !important;
  }
  .m-xs-9 {
    margin: 2.25rem !important;
  }
  .mt-xs-9 {
    margin-block-start: 2.25rem !important;
  }
  .mb-xs-9 {
    margin-block-end: 2.25rem !important;
  }
  .my-xs-9 {
    margin-block: 2.25rem !important;
  }
  .ms-xs-9 {
    margin-inline-start: 2.25rem !important;
  }
  .me-xs-9 {
    margin-inline-end: 2.25rem !important;
  }
  .mx-xs-9 {
    margin-inline: 2.25rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-10 {
    gap: 2.5rem !important;
  }
  .p-xs-10 {
    padding: 2.5rem !important;
  }
  .pt-xs-10 {
    padding-block-start: 2.5rem !important;
  }
  .pb-xs-10 {
    padding-block-end: 2.5rem !important;
  }
  .py-xs-10 {
    padding-block: 2.5rem !important;
  }
  .ps-xs-10 {
    padding-inline-start: 2.5rem !important;
  }
  .pe-xs-10 {
    padding-inline-end: 2.5rem !important;
  }
  .px-xs-10 {
    padding-inline: 2.5rem !important;
  }
  .m-xs-10 {
    margin: 2.5rem !important;
  }
  .mt-xs-10 {
    margin-block-start: 2.5rem !important;
  }
  .mb-xs-10 {
    margin-block-end: 2.5rem !important;
  }
  .my-xs-10 {
    margin-block: 2.5rem !important;
  }
  .ms-xs-10 {
    margin-inline-start: 2.5rem !important;
  }
  .me-xs-10 {
    margin-inline-end: 2.5rem !important;
  }
  .mx-xs-10 {
    margin-inline: 2.5rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-11 {
    gap: 2.75rem !important;
  }
  .p-xs-11 {
    padding: 2.75rem !important;
  }
  .pt-xs-11 {
    padding-block-start: 2.75rem !important;
  }
  .pb-xs-11 {
    padding-block-end: 2.75rem !important;
  }
  .py-xs-11 {
    padding-block: 2.75rem !important;
  }
  .ps-xs-11 {
    padding-inline-start: 2.75rem !important;
  }
  .pe-xs-11 {
    padding-inline-end: 2.75rem !important;
  }
  .px-xs-11 {
    padding-inline: 2.75rem !important;
  }
  .m-xs-11 {
    margin: 2.75rem !important;
  }
  .mt-xs-11 {
    margin-block-start: 2.75rem !important;
  }
  .mb-xs-11 {
    margin-block-end: 2.75rem !important;
  }
  .my-xs-11 {
    margin-block: 2.75rem !important;
  }
  .ms-xs-11 {
    margin-inline-start: 2.75rem !important;
  }
  .me-xs-11 {
    margin-inline-end: 2.75rem !important;
  }
  .mx-xs-11 {
    margin-inline: 2.75rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-12 {
    gap: 3rem !important;
  }
  .p-xs-12 {
    padding: 3rem !important;
  }
  .pt-xs-12 {
    padding-block-start: 3rem !important;
  }
  .pb-xs-12 {
    padding-block-end: 3rem !important;
  }
  .py-xs-12 {
    padding-block: 3rem !important;
  }
  .ps-xs-12 {
    padding-inline-start: 3rem !important;
  }
  .pe-xs-12 {
    padding-inline-end: 3rem !important;
  }
  .px-xs-12 {
    padding-inline: 3rem !important;
  }
  .m-xs-12 {
    margin: 3rem !important;
  }
  .mt-xs-12 {
    margin-block-start: 3rem !important;
  }
  .mb-xs-12 {
    margin-block-end: 3rem !important;
  }
  .my-xs-12 {
    margin-block: 3rem !important;
  }
  .ms-xs-12 {
    margin-inline-start: 3rem !important;
  }
  .me-xs-12 {
    margin-inline-end: 3rem !important;
  }
  .mx-xs-12 {
    margin-inline: 3rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-13 {
    gap: 3.25rem !important;
  }
  .p-xs-13 {
    padding: 3.25rem !important;
  }
  .pt-xs-13 {
    padding-block-start: 3.25rem !important;
  }
  .pb-xs-13 {
    padding-block-end: 3.25rem !important;
  }
  .py-xs-13 {
    padding-block: 3.25rem !important;
  }
  .ps-xs-13 {
    padding-inline-start: 3.25rem !important;
  }
  .pe-xs-13 {
    padding-inline-end: 3.25rem !important;
  }
  .px-xs-13 {
    padding-inline: 3.25rem !important;
  }
  .m-xs-13 {
    margin: 3.25rem !important;
  }
  .mt-xs-13 {
    margin-block-start: 3.25rem !important;
  }
  .mb-xs-13 {
    margin-block-end: 3.25rem !important;
  }
  .my-xs-13 {
    margin-block: 3.25rem !important;
  }
  .ms-xs-13 {
    margin-inline-start: 3.25rem !important;
  }
  .me-xs-13 {
    margin-inline-end: 3.25rem !important;
  }
  .mx-xs-13 {
    margin-inline: 3.25rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-14 {
    gap: 3.5rem !important;
  }
  .p-xs-14 {
    padding: 3.5rem !important;
  }
  .pt-xs-14 {
    padding-block-start: 3.5rem !important;
  }
  .pb-xs-14 {
    padding-block-end: 3.5rem !important;
  }
  .py-xs-14 {
    padding-block: 3.5rem !important;
  }
  .ps-xs-14 {
    padding-inline-start: 3.5rem !important;
  }
  .pe-xs-14 {
    padding-inline-end: 3.5rem !important;
  }
  .px-xs-14 {
    padding-inline: 3.5rem !important;
  }
  .m-xs-14 {
    margin: 3.5rem !important;
  }
  .mt-xs-14 {
    margin-block-start: 3.5rem !important;
  }
  .mb-xs-14 {
    margin-block-end: 3.5rem !important;
  }
  .my-xs-14 {
    margin-block: 3.5rem !important;
  }
  .ms-xs-14 {
    margin-inline-start: 3.5rem !important;
  }
  .me-xs-14 {
    margin-inline-end: 3.5rem !important;
  }
  .mx-xs-14 {
    margin-inline: 3.5rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-15 {
    gap: 3.75rem !important;
  }
  .p-xs-15 {
    padding: 3.75rem !important;
  }
  .pt-xs-15 {
    padding-block-start: 3.75rem !important;
  }
  .pb-xs-15 {
    padding-block-end: 3.75rem !important;
  }
  .py-xs-15 {
    padding-block: 3.75rem !important;
  }
  .ps-xs-15 {
    padding-inline-start: 3.75rem !important;
  }
  .pe-xs-15 {
    padding-inline-end: 3.75rem !important;
  }
  .px-xs-15 {
    padding-inline: 3.75rem !important;
  }
  .m-xs-15 {
    margin: 3.75rem !important;
  }
  .mt-xs-15 {
    margin-block-start: 3.75rem !important;
  }
  .mb-xs-15 {
    margin-block-end: 3.75rem !important;
  }
  .my-xs-15 {
    margin-block: 3.75rem !important;
  }
  .ms-xs-15 {
    margin-inline-start: 3.75rem !important;
  }
  .me-xs-15 {
    margin-inline-end: 3.75rem !important;
  }
  .mx-xs-15 {
    margin-inline: 3.75rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-16 {
    gap: 4rem !important;
  }
  .p-xs-16 {
    padding: 4rem !important;
  }
  .pt-xs-16 {
    padding-block-start: 4rem !important;
  }
  .pb-xs-16 {
    padding-block-end: 4rem !important;
  }
  .py-xs-16 {
    padding-block: 4rem !important;
  }
  .ps-xs-16 {
    padding-inline-start: 4rem !important;
  }
  .pe-xs-16 {
    padding-inline-end: 4rem !important;
  }
  .px-xs-16 {
    padding-inline: 4rem !important;
  }
  .m-xs-16 {
    margin: 4rem !important;
  }
  .mt-xs-16 {
    margin-block-start: 4rem !important;
  }
  .mb-xs-16 {
    margin-block-end: 4rem !important;
  }
  .my-xs-16 {
    margin-block: 4rem !important;
  }
  .ms-xs-16 {
    margin-inline-start: 4rem !important;
  }
  .me-xs-16 {
    margin-inline-end: 4rem !important;
  }
  .mx-xs-16 {
    margin-inline: 4rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-17 {
    gap: 4.25rem !important;
  }
  .p-xs-17 {
    padding: 4.25rem !important;
  }
  .pt-xs-17 {
    padding-block-start: 4.25rem !important;
  }
  .pb-xs-17 {
    padding-block-end: 4.25rem !important;
  }
  .py-xs-17 {
    padding-block: 4.25rem !important;
  }
  .ps-xs-17 {
    padding-inline-start: 4.25rem !important;
  }
  .pe-xs-17 {
    padding-inline-end: 4.25rem !important;
  }
  .px-xs-17 {
    padding-inline: 4.25rem !important;
  }
  .m-xs-17 {
    margin: 4.25rem !important;
  }
  .mt-xs-17 {
    margin-block-start: 4.25rem !important;
  }
  .mb-xs-17 {
    margin-block-end: 4.25rem !important;
  }
  .my-xs-17 {
    margin-block: 4.25rem !important;
  }
  .ms-xs-17 {
    margin-inline-start: 4.25rem !important;
  }
  .me-xs-17 {
    margin-inline-end: 4.25rem !important;
  }
  .mx-xs-17 {
    margin-inline: 4.25rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-18 {
    gap: 4.5rem !important;
  }
  .p-xs-18 {
    padding: 4.5rem !important;
  }
  .pt-xs-18 {
    padding-block-start: 4.5rem !important;
  }
  .pb-xs-18 {
    padding-block-end: 4.5rem !important;
  }
  .py-xs-18 {
    padding-block: 4.5rem !important;
  }
  .ps-xs-18 {
    padding-inline-start: 4.5rem !important;
  }
  .pe-xs-18 {
    padding-inline-end: 4.5rem !important;
  }
  .px-xs-18 {
    padding-inline: 4.5rem !important;
  }
  .m-xs-18 {
    margin: 4.5rem !important;
  }
  .mt-xs-18 {
    margin-block-start: 4.5rem !important;
  }
  .mb-xs-18 {
    margin-block-end: 4.5rem !important;
  }
  .my-xs-18 {
    margin-block: 4.5rem !important;
  }
  .ms-xs-18 {
    margin-inline-start: 4.5rem !important;
  }
  .me-xs-18 {
    margin-inline-end: 4.5rem !important;
  }
  .mx-xs-18 {
    margin-inline: 4.5rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-19 {
    gap: 4.75rem !important;
  }
  .p-xs-19 {
    padding: 4.75rem !important;
  }
  .pt-xs-19 {
    padding-block-start: 4.75rem !important;
  }
  .pb-xs-19 {
    padding-block-end: 4.75rem !important;
  }
  .py-xs-19 {
    padding-block: 4.75rem !important;
  }
  .ps-xs-19 {
    padding-inline-start: 4.75rem !important;
  }
  .pe-xs-19 {
    padding-inline-end: 4.75rem !important;
  }
  .px-xs-19 {
    padding-inline: 4.75rem !important;
  }
  .m-xs-19 {
    margin: 4.75rem !important;
  }
  .mt-xs-19 {
    margin-block-start: 4.75rem !important;
  }
  .mb-xs-19 {
    margin-block-end: 4.75rem !important;
  }
  .my-xs-19 {
    margin-block: 4.75rem !important;
  }
  .ms-xs-19 {
    margin-inline-start: 4.75rem !important;
  }
  .me-xs-19 {
    margin-inline-end: 4.75rem !important;
  }
  .mx-xs-19 {
    margin-inline: 4.75rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-20 {
    gap: 5rem !important;
  }
  .p-xs-20 {
    padding: 5rem !important;
  }
  .pt-xs-20 {
    padding-block-start: 5rem !important;
  }
  .pb-xs-20 {
    padding-block-end: 5rem !important;
  }
  .py-xs-20 {
    padding-block: 5rem !important;
  }
  .ps-xs-20 {
    padding-inline-start: 5rem !important;
  }
  .pe-xs-20 {
    padding-inline-end: 5rem !important;
  }
  .px-xs-20 {
    padding-inline: 5rem !important;
  }
  .m-xs-20 {
    margin: 5rem !important;
  }
  .mt-xs-20 {
    margin-block-start: 5rem !important;
  }
  .mb-xs-20 {
    margin-block-end: 5rem !important;
  }
  .my-xs-20 {
    margin-block: 5rem !important;
  }
  .ms-xs-20 {
    margin-inline-start: 5rem !important;
  }
  .me-xs-20 {
    margin-inline-end: 5rem !important;
  }
  .mx-xs-20 {
    margin-inline: 5rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-21 {
    gap: 5.25rem !important;
  }
  .p-xs-21 {
    padding: 5.25rem !important;
  }
  .pt-xs-21 {
    padding-block-start: 5.25rem !important;
  }
  .pb-xs-21 {
    padding-block-end: 5.25rem !important;
  }
  .py-xs-21 {
    padding-block: 5.25rem !important;
  }
  .ps-xs-21 {
    padding-inline-start: 5.25rem !important;
  }
  .pe-xs-21 {
    padding-inline-end: 5.25rem !important;
  }
  .px-xs-21 {
    padding-inline: 5.25rem !important;
  }
  .m-xs-21 {
    margin: 5.25rem !important;
  }
  .mt-xs-21 {
    margin-block-start: 5.25rem !important;
  }
  .mb-xs-21 {
    margin-block-end: 5.25rem !important;
  }
  .my-xs-21 {
    margin-block: 5.25rem !important;
  }
  .ms-xs-21 {
    margin-inline-start: 5.25rem !important;
  }
  .me-xs-21 {
    margin-inline-end: 5.25rem !important;
  }
  .mx-xs-21 {
    margin-inline: 5.25rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-22 {
    gap: 5.5rem !important;
  }
  .p-xs-22 {
    padding: 5.5rem !important;
  }
  .pt-xs-22 {
    padding-block-start: 5.5rem !important;
  }
  .pb-xs-22 {
    padding-block-end: 5.5rem !important;
  }
  .py-xs-22 {
    padding-block: 5.5rem !important;
  }
  .ps-xs-22 {
    padding-inline-start: 5.5rem !important;
  }
  .pe-xs-22 {
    padding-inline-end: 5.5rem !important;
  }
  .px-xs-22 {
    padding-inline: 5.5rem !important;
  }
  .m-xs-22 {
    margin: 5.5rem !important;
  }
  .mt-xs-22 {
    margin-block-start: 5.5rem !important;
  }
  .mb-xs-22 {
    margin-block-end: 5.5rem !important;
  }
  .my-xs-22 {
    margin-block: 5.5rem !important;
  }
  .ms-xs-22 {
    margin-inline-start: 5.5rem !important;
  }
  .me-xs-22 {
    margin-inline-end: 5.5rem !important;
  }
  .mx-xs-22 {
    margin-inline: 5.5rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-23 {
    gap: 5.75rem !important;
  }
  .p-xs-23 {
    padding: 5.75rem !important;
  }
  .pt-xs-23 {
    padding-block-start: 5.75rem !important;
  }
  .pb-xs-23 {
    padding-block-end: 5.75rem !important;
  }
  .py-xs-23 {
    padding-block: 5.75rem !important;
  }
  .ps-xs-23 {
    padding-inline-start: 5.75rem !important;
  }
  .pe-xs-23 {
    padding-inline-end: 5.75rem !important;
  }
  .px-xs-23 {
    padding-inline: 5.75rem !important;
  }
  .m-xs-23 {
    margin: 5.75rem !important;
  }
  .mt-xs-23 {
    margin-block-start: 5.75rem !important;
  }
  .mb-xs-23 {
    margin-block-end: 5.75rem !important;
  }
  .my-xs-23 {
    margin-block: 5.75rem !important;
  }
  .ms-xs-23 {
    margin-inline-start: 5.75rem !important;
  }
  .me-xs-23 {
    margin-inline-end: 5.75rem !important;
  }
  .mx-xs-23 {
    margin-inline: 5.75rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-24 {
    gap: 6rem !important;
  }
  .p-xs-24 {
    padding: 6rem !important;
  }
  .pt-xs-24 {
    padding-block-start: 6rem !important;
  }
  .pb-xs-24 {
    padding-block-end: 6rem !important;
  }
  .py-xs-24 {
    padding-block: 6rem !important;
  }
  .ps-xs-24 {
    padding-inline-start: 6rem !important;
  }
  .pe-xs-24 {
    padding-inline-end: 6rem !important;
  }
  .px-xs-24 {
    padding-inline: 6rem !important;
  }
  .m-xs-24 {
    margin: 6rem !important;
  }
  .mt-xs-24 {
    margin-block-start: 6rem !important;
  }
  .mb-xs-24 {
    margin-block-end: 6rem !important;
  }
  .my-xs-24 {
    margin-block: 6rem !important;
  }
  .ms-xs-24 {
    margin-inline-start: 6rem !important;
  }
  .me-xs-24 {
    margin-inline-end: 6rem !important;
  }
  .mx-xs-24 {
    margin-inline: 6rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-25 {
    gap: 6.25rem !important;
  }
  .p-xs-25 {
    padding: 6.25rem !important;
  }
  .pt-xs-25 {
    padding-block-start: 6.25rem !important;
  }
  .pb-xs-25 {
    padding-block-end: 6.25rem !important;
  }
  .py-xs-25 {
    padding-block: 6.25rem !important;
  }
  .ps-xs-25 {
    padding-inline-start: 6.25rem !important;
  }
  .pe-xs-25 {
    padding-inline-end: 6.25rem !important;
  }
  .px-xs-25 {
    padding-inline: 6.25rem !important;
  }
  .m-xs-25 {
    margin: 6.25rem !important;
  }
  .mt-xs-25 {
    margin-block-start: 6.25rem !important;
  }
  .mb-xs-25 {
    margin-block-end: 6.25rem !important;
  }
  .my-xs-25 {
    margin-block: 6.25rem !important;
  }
  .ms-xs-25 {
    margin-inline-start: 6.25rem !important;
  }
  .me-xs-25 {
    margin-inline-end: 6.25rem !important;
  }
  .mx-xs-25 {
    margin-inline: 6.25rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-26 {
    gap: 6.5rem !important;
  }
  .p-xs-26 {
    padding: 6.5rem !important;
  }
  .pt-xs-26 {
    padding-block-start: 6.5rem !important;
  }
  .pb-xs-26 {
    padding-block-end: 6.5rem !important;
  }
  .py-xs-26 {
    padding-block: 6.5rem !important;
  }
  .ps-xs-26 {
    padding-inline-start: 6.5rem !important;
  }
  .pe-xs-26 {
    padding-inline-end: 6.5rem !important;
  }
  .px-xs-26 {
    padding-inline: 6.5rem !important;
  }
  .m-xs-26 {
    margin: 6.5rem !important;
  }
  .mt-xs-26 {
    margin-block-start: 6.5rem !important;
  }
  .mb-xs-26 {
    margin-block-end: 6.5rem !important;
  }
  .my-xs-26 {
    margin-block: 6.5rem !important;
  }
  .ms-xs-26 {
    margin-inline-start: 6.5rem !important;
  }
  .me-xs-26 {
    margin-inline-end: 6.5rem !important;
  }
  .mx-xs-26 {
    margin-inline: 6.5rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-27 {
    gap: 6.75rem !important;
  }
  .p-xs-27 {
    padding: 6.75rem !important;
  }
  .pt-xs-27 {
    padding-block-start: 6.75rem !important;
  }
  .pb-xs-27 {
    padding-block-end: 6.75rem !important;
  }
  .py-xs-27 {
    padding-block: 6.75rem !important;
  }
  .ps-xs-27 {
    padding-inline-start: 6.75rem !important;
  }
  .pe-xs-27 {
    padding-inline-end: 6.75rem !important;
  }
  .px-xs-27 {
    padding-inline: 6.75rem !important;
  }
  .m-xs-27 {
    margin: 6.75rem !important;
  }
  .mt-xs-27 {
    margin-block-start: 6.75rem !important;
  }
  .mb-xs-27 {
    margin-block-end: 6.75rem !important;
  }
  .my-xs-27 {
    margin-block: 6.75rem !important;
  }
  .ms-xs-27 {
    margin-inline-start: 6.75rem !important;
  }
  .me-xs-27 {
    margin-inline-end: 6.75rem !important;
  }
  .mx-xs-27 {
    margin-inline: 6.75rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-28 {
    gap: 7rem !important;
  }
  .p-xs-28 {
    padding: 7rem !important;
  }
  .pt-xs-28 {
    padding-block-start: 7rem !important;
  }
  .pb-xs-28 {
    padding-block-end: 7rem !important;
  }
  .py-xs-28 {
    padding-block: 7rem !important;
  }
  .ps-xs-28 {
    padding-inline-start: 7rem !important;
  }
  .pe-xs-28 {
    padding-inline-end: 7rem !important;
  }
  .px-xs-28 {
    padding-inline: 7rem !important;
  }
  .m-xs-28 {
    margin: 7rem !important;
  }
  .mt-xs-28 {
    margin-block-start: 7rem !important;
  }
  .mb-xs-28 {
    margin-block-end: 7rem !important;
  }
  .my-xs-28 {
    margin-block: 7rem !important;
  }
  .ms-xs-28 {
    margin-inline-start: 7rem !important;
  }
  .me-xs-28 {
    margin-inline-end: 7rem !important;
  }
  .mx-xs-28 {
    margin-inline: 7rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-29 {
    gap: 7.25rem !important;
  }
  .p-xs-29 {
    padding: 7.25rem !important;
  }
  .pt-xs-29 {
    padding-block-start: 7.25rem !important;
  }
  .pb-xs-29 {
    padding-block-end: 7.25rem !important;
  }
  .py-xs-29 {
    padding-block: 7.25rem !important;
  }
  .ps-xs-29 {
    padding-inline-start: 7.25rem !important;
  }
  .pe-xs-29 {
    padding-inline-end: 7.25rem !important;
  }
  .px-xs-29 {
    padding-inline: 7.25rem !important;
  }
  .m-xs-29 {
    margin: 7.25rem !important;
  }
  .mt-xs-29 {
    margin-block-start: 7.25rem !important;
  }
  .mb-xs-29 {
    margin-block-end: 7.25rem !important;
  }
  .my-xs-29 {
    margin-block: 7.25rem !important;
  }
  .ms-xs-29 {
    margin-inline-start: 7.25rem !important;
  }
  .me-xs-29 {
    margin-inline-end: 7.25rem !important;
  }
  .mx-xs-29 {
    margin-inline: 7.25rem !important;
  }
}
@media (min-width: 375px) {
  .gap-xs-30 {
    gap: 7.5rem !important;
  }
  .p-xs-30 {
    padding: 7.5rem !important;
  }
  .pt-xs-30 {
    padding-block-start: 7.5rem !important;
  }
  .pb-xs-30 {
    padding-block-end: 7.5rem !important;
  }
  .py-xs-30 {
    padding-block: 7.5rem !important;
  }
  .ps-xs-30 {
    padding-inline-start: 7.5rem !important;
  }
  .pe-xs-30 {
    padding-inline-end: 7.5rem !important;
  }
  .px-xs-30 {
    padding-inline: 7.5rem !important;
  }
  .m-xs-30 {
    margin: 7.5rem !important;
  }
  .mt-xs-30 {
    margin-block-start: 7.5rem !important;
  }
  .mb-xs-30 {
    margin-block-end: 7.5rem !important;
  }
  .my-xs-30 {
    margin-block: 7.5rem !important;
  }
  .ms-xs-30 {
    margin-inline-start: 7.5rem !important;
  }
  .me-xs-30 {
    margin-inline-end: 7.5rem !important;
  }
  .mx-xs-30 {
    margin-inline: 7.5rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-0 {
    gap: 0 !important;
  }
  .p-xs-plus-0 {
    padding: 0 !important;
  }
  .pt-xs-plus-0 {
    padding-block-start: 0 !important;
  }
  .pb-xs-plus-0 {
    padding-block-end: 0 !important;
  }
  .py-xs-plus-0 {
    padding-block: 0 !important;
  }
  .ps-xs-plus-0 {
    padding-inline-start: 0 !important;
  }
  .pe-xs-plus-0 {
    padding-inline-end: 0 !important;
  }
  .px-xs-plus-0 {
    padding-inline: 0 !important;
  }
  .m-xs-plus-0 {
    margin: 0 !important;
  }
  .mt-xs-plus-0 {
    margin-block-start: 0 !important;
  }
  .mb-xs-plus-0 {
    margin-block-end: 0 !important;
  }
  .my-xs-plus-0 {
    margin-block: 0 !important;
  }
  .ms-xs-plus-0 {
    margin-inline-start: 0 !important;
  }
  .me-xs-plus-0 {
    margin-inline-end: 0 !important;
  }
  .mx-xs-plus-0 {
    margin-inline: 0 !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-1 {
    gap: 0.25rem !important;
  }
  .p-xs-plus-1 {
    padding: 0.25rem !important;
  }
  .pt-xs-plus-1 {
    padding-block-start: 0.25rem !important;
  }
  .pb-xs-plus-1 {
    padding-block-end: 0.25rem !important;
  }
  .py-xs-plus-1 {
    padding-block: 0.25rem !important;
  }
  .ps-xs-plus-1 {
    padding-inline-start: 0.25rem !important;
  }
  .pe-xs-plus-1 {
    padding-inline-end: 0.25rem !important;
  }
  .px-xs-plus-1 {
    padding-inline: 0.25rem !important;
  }
  .m-xs-plus-1 {
    margin: 0.25rem !important;
  }
  .mt-xs-plus-1 {
    margin-block-start: 0.25rem !important;
  }
  .mb-xs-plus-1 {
    margin-block-end: 0.25rem !important;
  }
  .my-xs-plus-1 {
    margin-block: 0.25rem !important;
  }
  .ms-xs-plus-1 {
    margin-inline-start: 0.25rem !important;
  }
  .me-xs-plus-1 {
    margin-inline-end: 0.25rem !important;
  }
  .mx-xs-plus-1 {
    margin-inline: 0.25rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-2 {
    gap: 0.5rem !important;
  }
  .p-xs-plus-2 {
    padding: 0.5rem !important;
  }
  .pt-xs-plus-2 {
    padding-block-start: 0.5rem !important;
  }
  .pb-xs-plus-2 {
    padding-block-end: 0.5rem !important;
  }
  .py-xs-plus-2 {
    padding-block: 0.5rem !important;
  }
  .ps-xs-plus-2 {
    padding-inline-start: 0.5rem !important;
  }
  .pe-xs-plus-2 {
    padding-inline-end: 0.5rem !important;
  }
  .px-xs-plus-2 {
    padding-inline: 0.5rem !important;
  }
  .m-xs-plus-2 {
    margin: 0.5rem !important;
  }
  .mt-xs-plus-2 {
    margin-block-start: 0.5rem !important;
  }
  .mb-xs-plus-2 {
    margin-block-end: 0.5rem !important;
  }
  .my-xs-plus-2 {
    margin-block: 0.5rem !important;
  }
  .ms-xs-plus-2 {
    margin-inline-start: 0.5rem !important;
  }
  .me-xs-plus-2 {
    margin-inline-end: 0.5rem !important;
  }
  .mx-xs-plus-2 {
    margin-inline: 0.5rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-3 {
    gap: 0.75rem !important;
  }
  .p-xs-plus-3 {
    padding: 0.75rem !important;
  }
  .pt-xs-plus-3 {
    padding-block-start: 0.75rem !important;
  }
  .pb-xs-plus-3 {
    padding-block-end: 0.75rem !important;
  }
  .py-xs-plus-3 {
    padding-block: 0.75rem !important;
  }
  .ps-xs-plus-3 {
    padding-inline-start: 0.75rem !important;
  }
  .pe-xs-plus-3 {
    padding-inline-end: 0.75rem !important;
  }
  .px-xs-plus-3 {
    padding-inline: 0.75rem !important;
  }
  .m-xs-plus-3 {
    margin: 0.75rem !important;
  }
  .mt-xs-plus-3 {
    margin-block-start: 0.75rem !important;
  }
  .mb-xs-plus-3 {
    margin-block-end: 0.75rem !important;
  }
  .my-xs-plus-3 {
    margin-block: 0.75rem !important;
  }
  .ms-xs-plus-3 {
    margin-inline-start: 0.75rem !important;
  }
  .me-xs-plus-3 {
    margin-inline-end: 0.75rem !important;
  }
  .mx-xs-plus-3 {
    margin-inline: 0.75rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-4 {
    gap: 1rem !important;
  }
  .p-xs-plus-4 {
    padding: 1rem !important;
  }
  .pt-xs-plus-4 {
    padding-block-start: 1rem !important;
  }
  .pb-xs-plus-4 {
    padding-block-end: 1rem !important;
  }
  .py-xs-plus-4 {
    padding-block: 1rem !important;
  }
  .ps-xs-plus-4 {
    padding-inline-start: 1rem !important;
  }
  .pe-xs-plus-4 {
    padding-inline-end: 1rem !important;
  }
  .px-xs-plus-4 {
    padding-inline: 1rem !important;
  }
  .m-xs-plus-4 {
    margin: 1rem !important;
  }
  .mt-xs-plus-4 {
    margin-block-start: 1rem !important;
  }
  .mb-xs-plus-4 {
    margin-block-end: 1rem !important;
  }
  .my-xs-plus-4 {
    margin-block: 1rem !important;
  }
  .ms-xs-plus-4 {
    margin-inline-start: 1rem !important;
  }
  .me-xs-plus-4 {
    margin-inline-end: 1rem !important;
  }
  .mx-xs-plus-4 {
    margin-inline: 1rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-5 {
    gap: 1.25rem !important;
  }
  .p-xs-plus-5 {
    padding: 1.25rem !important;
  }
  .pt-xs-plus-5 {
    padding-block-start: 1.25rem !important;
  }
  .pb-xs-plus-5 {
    padding-block-end: 1.25rem !important;
  }
  .py-xs-plus-5 {
    padding-block: 1.25rem !important;
  }
  .ps-xs-plus-5 {
    padding-inline-start: 1.25rem !important;
  }
  .pe-xs-plus-5 {
    padding-inline-end: 1.25rem !important;
  }
  .px-xs-plus-5 {
    padding-inline: 1.25rem !important;
  }
  .m-xs-plus-5 {
    margin: 1.25rem !important;
  }
  .mt-xs-plus-5 {
    margin-block-start: 1.25rem !important;
  }
  .mb-xs-plus-5 {
    margin-block-end: 1.25rem !important;
  }
  .my-xs-plus-5 {
    margin-block: 1.25rem !important;
  }
  .ms-xs-plus-5 {
    margin-inline-start: 1.25rem !important;
  }
  .me-xs-plus-5 {
    margin-inline-end: 1.25rem !important;
  }
  .mx-xs-plus-5 {
    margin-inline: 1.25rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-6 {
    gap: 1.5rem !important;
  }
  .p-xs-plus-6 {
    padding: 1.5rem !important;
  }
  .pt-xs-plus-6 {
    padding-block-start: 1.5rem !important;
  }
  .pb-xs-plus-6 {
    padding-block-end: 1.5rem !important;
  }
  .py-xs-plus-6 {
    padding-block: 1.5rem !important;
  }
  .ps-xs-plus-6 {
    padding-inline-start: 1.5rem !important;
  }
  .pe-xs-plus-6 {
    padding-inline-end: 1.5rem !important;
  }
  .px-xs-plus-6 {
    padding-inline: 1.5rem !important;
  }
  .m-xs-plus-6 {
    margin: 1.5rem !important;
  }
  .mt-xs-plus-6 {
    margin-block-start: 1.5rem !important;
  }
  .mb-xs-plus-6 {
    margin-block-end: 1.5rem !important;
  }
  .my-xs-plus-6 {
    margin-block: 1.5rem !important;
  }
  .ms-xs-plus-6 {
    margin-inline-start: 1.5rem !important;
  }
  .me-xs-plus-6 {
    margin-inline-end: 1.5rem !important;
  }
  .mx-xs-plus-6 {
    margin-inline: 1.5rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-7 {
    gap: 1.75rem !important;
  }
  .p-xs-plus-7 {
    padding: 1.75rem !important;
  }
  .pt-xs-plus-7 {
    padding-block-start: 1.75rem !important;
  }
  .pb-xs-plus-7 {
    padding-block-end: 1.75rem !important;
  }
  .py-xs-plus-7 {
    padding-block: 1.75rem !important;
  }
  .ps-xs-plus-7 {
    padding-inline-start: 1.75rem !important;
  }
  .pe-xs-plus-7 {
    padding-inline-end: 1.75rem !important;
  }
  .px-xs-plus-7 {
    padding-inline: 1.75rem !important;
  }
  .m-xs-plus-7 {
    margin: 1.75rem !important;
  }
  .mt-xs-plus-7 {
    margin-block-start: 1.75rem !important;
  }
  .mb-xs-plus-7 {
    margin-block-end: 1.75rem !important;
  }
  .my-xs-plus-7 {
    margin-block: 1.75rem !important;
  }
  .ms-xs-plus-7 {
    margin-inline-start: 1.75rem !important;
  }
  .me-xs-plus-7 {
    margin-inline-end: 1.75rem !important;
  }
  .mx-xs-plus-7 {
    margin-inline: 1.75rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-8 {
    gap: 2rem !important;
  }
  .p-xs-plus-8 {
    padding: 2rem !important;
  }
  .pt-xs-plus-8 {
    padding-block-start: 2rem !important;
  }
  .pb-xs-plus-8 {
    padding-block-end: 2rem !important;
  }
  .py-xs-plus-8 {
    padding-block: 2rem !important;
  }
  .ps-xs-plus-8 {
    padding-inline-start: 2rem !important;
  }
  .pe-xs-plus-8 {
    padding-inline-end: 2rem !important;
  }
  .px-xs-plus-8 {
    padding-inline: 2rem !important;
  }
  .m-xs-plus-8 {
    margin: 2rem !important;
  }
  .mt-xs-plus-8 {
    margin-block-start: 2rem !important;
  }
  .mb-xs-plus-8 {
    margin-block-end: 2rem !important;
  }
  .my-xs-plus-8 {
    margin-block: 2rem !important;
  }
  .ms-xs-plus-8 {
    margin-inline-start: 2rem !important;
  }
  .me-xs-plus-8 {
    margin-inline-end: 2rem !important;
  }
  .mx-xs-plus-8 {
    margin-inline: 2rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-9 {
    gap: 2.25rem !important;
  }
  .p-xs-plus-9 {
    padding: 2.25rem !important;
  }
  .pt-xs-plus-9 {
    padding-block-start: 2.25rem !important;
  }
  .pb-xs-plus-9 {
    padding-block-end: 2.25rem !important;
  }
  .py-xs-plus-9 {
    padding-block: 2.25rem !important;
  }
  .ps-xs-plus-9 {
    padding-inline-start: 2.25rem !important;
  }
  .pe-xs-plus-9 {
    padding-inline-end: 2.25rem !important;
  }
  .px-xs-plus-9 {
    padding-inline: 2.25rem !important;
  }
  .m-xs-plus-9 {
    margin: 2.25rem !important;
  }
  .mt-xs-plus-9 {
    margin-block-start: 2.25rem !important;
  }
  .mb-xs-plus-9 {
    margin-block-end: 2.25rem !important;
  }
  .my-xs-plus-9 {
    margin-block: 2.25rem !important;
  }
  .ms-xs-plus-9 {
    margin-inline-start: 2.25rem !important;
  }
  .me-xs-plus-9 {
    margin-inline-end: 2.25rem !important;
  }
  .mx-xs-plus-9 {
    margin-inline: 2.25rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-10 {
    gap: 2.5rem !important;
  }
  .p-xs-plus-10 {
    padding: 2.5rem !important;
  }
  .pt-xs-plus-10 {
    padding-block-start: 2.5rem !important;
  }
  .pb-xs-plus-10 {
    padding-block-end: 2.5rem !important;
  }
  .py-xs-plus-10 {
    padding-block: 2.5rem !important;
  }
  .ps-xs-plus-10 {
    padding-inline-start: 2.5rem !important;
  }
  .pe-xs-plus-10 {
    padding-inline-end: 2.5rem !important;
  }
  .px-xs-plus-10 {
    padding-inline: 2.5rem !important;
  }
  .m-xs-plus-10 {
    margin: 2.5rem !important;
  }
  .mt-xs-plus-10 {
    margin-block-start: 2.5rem !important;
  }
  .mb-xs-plus-10 {
    margin-block-end: 2.5rem !important;
  }
  .my-xs-plus-10 {
    margin-block: 2.5rem !important;
  }
  .ms-xs-plus-10 {
    margin-inline-start: 2.5rem !important;
  }
  .me-xs-plus-10 {
    margin-inline-end: 2.5rem !important;
  }
  .mx-xs-plus-10 {
    margin-inline: 2.5rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-11 {
    gap: 2.75rem !important;
  }
  .p-xs-plus-11 {
    padding: 2.75rem !important;
  }
  .pt-xs-plus-11 {
    padding-block-start: 2.75rem !important;
  }
  .pb-xs-plus-11 {
    padding-block-end: 2.75rem !important;
  }
  .py-xs-plus-11 {
    padding-block: 2.75rem !important;
  }
  .ps-xs-plus-11 {
    padding-inline-start: 2.75rem !important;
  }
  .pe-xs-plus-11 {
    padding-inline-end: 2.75rem !important;
  }
  .px-xs-plus-11 {
    padding-inline: 2.75rem !important;
  }
  .m-xs-plus-11 {
    margin: 2.75rem !important;
  }
  .mt-xs-plus-11 {
    margin-block-start: 2.75rem !important;
  }
  .mb-xs-plus-11 {
    margin-block-end: 2.75rem !important;
  }
  .my-xs-plus-11 {
    margin-block: 2.75rem !important;
  }
  .ms-xs-plus-11 {
    margin-inline-start: 2.75rem !important;
  }
  .me-xs-plus-11 {
    margin-inline-end: 2.75rem !important;
  }
  .mx-xs-plus-11 {
    margin-inline: 2.75rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-12 {
    gap: 3rem !important;
  }
  .p-xs-plus-12 {
    padding: 3rem !important;
  }
  .pt-xs-plus-12 {
    padding-block-start: 3rem !important;
  }
  .pb-xs-plus-12 {
    padding-block-end: 3rem !important;
  }
  .py-xs-plus-12 {
    padding-block: 3rem !important;
  }
  .ps-xs-plus-12 {
    padding-inline-start: 3rem !important;
  }
  .pe-xs-plus-12 {
    padding-inline-end: 3rem !important;
  }
  .px-xs-plus-12 {
    padding-inline: 3rem !important;
  }
  .m-xs-plus-12 {
    margin: 3rem !important;
  }
  .mt-xs-plus-12 {
    margin-block-start: 3rem !important;
  }
  .mb-xs-plus-12 {
    margin-block-end: 3rem !important;
  }
  .my-xs-plus-12 {
    margin-block: 3rem !important;
  }
  .ms-xs-plus-12 {
    margin-inline-start: 3rem !important;
  }
  .me-xs-plus-12 {
    margin-inline-end: 3rem !important;
  }
  .mx-xs-plus-12 {
    margin-inline: 3rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-13 {
    gap: 3.25rem !important;
  }
  .p-xs-plus-13 {
    padding: 3.25rem !important;
  }
  .pt-xs-plus-13 {
    padding-block-start: 3.25rem !important;
  }
  .pb-xs-plus-13 {
    padding-block-end: 3.25rem !important;
  }
  .py-xs-plus-13 {
    padding-block: 3.25rem !important;
  }
  .ps-xs-plus-13 {
    padding-inline-start: 3.25rem !important;
  }
  .pe-xs-plus-13 {
    padding-inline-end: 3.25rem !important;
  }
  .px-xs-plus-13 {
    padding-inline: 3.25rem !important;
  }
  .m-xs-plus-13 {
    margin: 3.25rem !important;
  }
  .mt-xs-plus-13 {
    margin-block-start: 3.25rem !important;
  }
  .mb-xs-plus-13 {
    margin-block-end: 3.25rem !important;
  }
  .my-xs-plus-13 {
    margin-block: 3.25rem !important;
  }
  .ms-xs-plus-13 {
    margin-inline-start: 3.25rem !important;
  }
  .me-xs-plus-13 {
    margin-inline-end: 3.25rem !important;
  }
  .mx-xs-plus-13 {
    margin-inline: 3.25rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-14 {
    gap: 3.5rem !important;
  }
  .p-xs-plus-14 {
    padding: 3.5rem !important;
  }
  .pt-xs-plus-14 {
    padding-block-start: 3.5rem !important;
  }
  .pb-xs-plus-14 {
    padding-block-end: 3.5rem !important;
  }
  .py-xs-plus-14 {
    padding-block: 3.5rem !important;
  }
  .ps-xs-plus-14 {
    padding-inline-start: 3.5rem !important;
  }
  .pe-xs-plus-14 {
    padding-inline-end: 3.5rem !important;
  }
  .px-xs-plus-14 {
    padding-inline: 3.5rem !important;
  }
  .m-xs-plus-14 {
    margin: 3.5rem !important;
  }
  .mt-xs-plus-14 {
    margin-block-start: 3.5rem !important;
  }
  .mb-xs-plus-14 {
    margin-block-end: 3.5rem !important;
  }
  .my-xs-plus-14 {
    margin-block: 3.5rem !important;
  }
  .ms-xs-plus-14 {
    margin-inline-start: 3.5rem !important;
  }
  .me-xs-plus-14 {
    margin-inline-end: 3.5rem !important;
  }
  .mx-xs-plus-14 {
    margin-inline: 3.5rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-15 {
    gap: 3.75rem !important;
  }
  .p-xs-plus-15 {
    padding: 3.75rem !important;
  }
  .pt-xs-plus-15 {
    padding-block-start: 3.75rem !important;
  }
  .pb-xs-plus-15 {
    padding-block-end: 3.75rem !important;
  }
  .py-xs-plus-15 {
    padding-block: 3.75rem !important;
  }
  .ps-xs-plus-15 {
    padding-inline-start: 3.75rem !important;
  }
  .pe-xs-plus-15 {
    padding-inline-end: 3.75rem !important;
  }
  .px-xs-plus-15 {
    padding-inline: 3.75rem !important;
  }
  .m-xs-plus-15 {
    margin: 3.75rem !important;
  }
  .mt-xs-plus-15 {
    margin-block-start: 3.75rem !important;
  }
  .mb-xs-plus-15 {
    margin-block-end: 3.75rem !important;
  }
  .my-xs-plus-15 {
    margin-block: 3.75rem !important;
  }
  .ms-xs-plus-15 {
    margin-inline-start: 3.75rem !important;
  }
  .me-xs-plus-15 {
    margin-inline-end: 3.75rem !important;
  }
  .mx-xs-plus-15 {
    margin-inline: 3.75rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-16 {
    gap: 4rem !important;
  }
  .p-xs-plus-16 {
    padding: 4rem !important;
  }
  .pt-xs-plus-16 {
    padding-block-start: 4rem !important;
  }
  .pb-xs-plus-16 {
    padding-block-end: 4rem !important;
  }
  .py-xs-plus-16 {
    padding-block: 4rem !important;
  }
  .ps-xs-plus-16 {
    padding-inline-start: 4rem !important;
  }
  .pe-xs-plus-16 {
    padding-inline-end: 4rem !important;
  }
  .px-xs-plus-16 {
    padding-inline: 4rem !important;
  }
  .m-xs-plus-16 {
    margin: 4rem !important;
  }
  .mt-xs-plus-16 {
    margin-block-start: 4rem !important;
  }
  .mb-xs-plus-16 {
    margin-block-end: 4rem !important;
  }
  .my-xs-plus-16 {
    margin-block: 4rem !important;
  }
  .ms-xs-plus-16 {
    margin-inline-start: 4rem !important;
  }
  .me-xs-plus-16 {
    margin-inline-end: 4rem !important;
  }
  .mx-xs-plus-16 {
    margin-inline: 4rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-17 {
    gap: 4.25rem !important;
  }
  .p-xs-plus-17 {
    padding: 4.25rem !important;
  }
  .pt-xs-plus-17 {
    padding-block-start: 4.25rem !important;
  }
  .pb-xs-plus-17 {
    padding-block-end: 4.25rem !important;
  }
  .py-xs-plus-17 {
    padding-block: 4.25rem !important;
  }
  .ps-xs-plus-17 {
    padding-inline-start: 4.25rem !important;
  }
  .pe-xs-plus-17 {
    padding-inline-end: 4.25rem !important;
  }
  .px-xs-plus-17 {
    padding-inline: 4.25rem !important;
  }
  .m-xs-plus-17 {
    margin: 4.25rem !important;
  }
  .mt-xs-plus-17 {
    margin-block-start: 4.25rem !important;
  }
  .mb-xs-plus-17 {
    margin-block-end: 4.25rem !important;
  }
  .my-xs-plus-17 {
    margin-block: 4.25rem !important;
  }
  .ms-xs-plus-17 {
    margin-inline-start: 4.25rem !important;
  }
  .me-xs-plus-17 {
    margin-inline-end: 4.25rem !important;
  }
  .mx-xs-plus-17 {
    margin-inline: 4.25rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-18 {
    gap: 4.5rem !important;
  }
  .p-xs-plus-18 {
    padding: 4.5rem !important;
  }
  .pt-xs-plus-18 {
    padding-block-start: 4.5rem !important;
  }
  .pb-xs-plus-18 {
    padding-block-end: 4.5rem !important;
  }
  .py-xs-plus-18 {
    padding-block: 4.5rem !important;
  }
  .ps-xs-plus-18 {
    padding-inline-start: 4.5rem !important;
  }
  .pe-xs-plus-18 {
    padding-inline-end: 4.5rem !important;
  }
  .px-xs-plus-18 {
    padding-inline: 4.5rem !important;
  }
  .m-xs-plus-18 {
    margin: 4.5rem !important;
  }
  .mt-xs-plus-18 {
    margin-block-start: 4.5rem !important;
  }
  .mb-xs-plus-18 {
    margin-block-end: 4.5rem !important;
  }
  .my-xs-plus-18 {
    margin-block: 4.5rem !important;
  }
  .ms-xs-plus-18 {
    margin-inline-start: 4.5rem !important;
  }
  .me-xs-plus-18 {
    margin-inline-end: 4.5rem !important;
  }
  .mx-xs-plus-18 {
    margin-inline: 4.5rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-19 {
    gap: 4.75rem !important;
  }
  .p-xs-plus-19 {
    padding: 4.75rem !important;
  }
  .pt-xs-plus-19 {
    padding-block-start: 4.75rem !important;
  }
  .pb-xs-plus-19 {
    padding-block-end: 4.75rem !important;
  }
  .py-xs-plus-19 {
    padding-block: 4.75rem !important;
  }
  .ps-xs-plus-19 {
    padding-inline-start: 4.75rem !important;
  }
  .pe-xs-plus-19 {
    padding-inline-end: 4.75rem !important;
  }
  .px-xs-plus-19 {
    padding-inline: 4.75rem !important;
  }
  .m-xs-plus-19 {
    margin: 4.75rem !important;
  }
  .mt-xs-plus-19 {
    margin-block-start: 4.75rem !important;
  }
  .mb-xs-plus-19 {
    margin-block-end: 4.75rem !important;
  }
  .my-xs-plus-19 {
    margin-block: 4.75rem !important;
  }
  .ms-xs-plus-19 {
    margin-inline-start: 4.75rem !important;
  }
  .me-xs-plus-19 {
    margin-inline-end: 4.75rem !important;
  }
  .mx-xs-plus-19 {
    margin-inline: 4.75rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-20 {
    gap: 5rem !important;
  }
  .p-xs-plus-20 {
    padding: 5rem !important;
  }
  .pt-xs-plus-20 {
    padding-block-start: 5rem !important;
  }
  .pb-xs-plus-20 {
    padding-block-end: 5rem !important;
  }
  .py-xs-plus-20 {
    padding-block: 5rem !important;
  }
  .ps-xs-plus-20 {
    padding-inline-start: 5rem !important;
  }
  .pe-xs-plus-20 {
    padding-inline-end: 5rem !important;
  }
  .px-xs-plus-20 {
    padding-inline: 5rem !important;
  }
  .m-xs-plus-20 {
    margin: 5rem !important;
  }
  .mt-xs-plus-20 {
    margin-block-start: 5rem !important;
  }
  .mb-xs-plus-20 {
    margin-block-end: 5rem !important;
  }
  .my-xs-plus-20 {
    margin-block: 5rem !important;
  }
  .ms-xs-plus-20 {
    margin-inline-start: 5rem !important;
  }
  .me-xs-plus-20 {
    margin-inline-end: 5rem !important;
  }
  .mx-xs-plus-20 {
    margin-inline: 5rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-21 {
    gap: 5.25rem !important;
  }
  .p-xs-plus-21 {
    padding: 5.25rem !important;
  }
  .pt-xs-plus-21 {
    padding-block-start: 5.25rem !important;
  }
  .pb-xs-plus-21 {
    padding-block-end: 5.25rem !important;
  }
  .py-xs-plus-21 {
    padding-block: 5.25rem !important;
  }
  .ps-xs-plus-21 {
    padding-inline-start: 5.25rem !important;
  }
  .pe-xs-plus-21 {
    padding-inline-end: 5.25rem !important;
  }
  .px-xs-plus-21 {
    padding-inline: 5.25rem !important;
  }
  .m-xs-plus-21 {
    margin: 5.25rem !important;
  }
  .mt-xs-plus-21 {
    margin-block-start: 5.25rem !important;
  }
  .mb-xs-plus-21 {
    margin-block-end: 5.25rem !important;
  }
  .my-xs-plus-21 {
    margin-block: 5.25rem !important;
  }
  .ms-xs-plus-21 {
    margin-inline-start: 5.25rem !important;
  }
  .me-xs-plus-21 {
    margin-inline-end: 5.25rem !important;
  }
  .mx-xs-plus-21 {
    margin-inline: 5.25rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-22 {
    gap: 5.5rem !important;
  }
  .p-xs-plus-22 {
    padding: 5.5rem !important;
  }
  .pt-xs-plus-22 {
    padding-block-start: 5.5rem !important;
  }
  .pb-xs-plus-22 {
    padding-block-end: 5.5rem !important;
  }
  .py-xs-plus-22 {
    padding-block: 5.5rem !important;
  }
  .ps-xs-plus-22 {
    padding-inline-start: 5.5rem !important;
  }
  .pe-xs-plus-22 {
    padding-inline-end: 5.5rem !important;
  }
  .px-xs-plus-22 {
    padding-inline: 5.5rem !important;
  }
  .m-xs-plus-22 {
    margin: 5.5rem !important;
  }
  .mt-xs-plus-22 {
    margin-block-start: 5.5rem !important;
  }
  .mb-xs-plus-22 {
    margin-block-end: 5.5rem !important;
  }
  .my-xs-plus-22 {
    margin-block: 5.5rem !important;
  }
  .ms-xs-plus-22 {
    margin-inline-start: 5.5rem !important;
  }
  .me-xs-plus-22 {
    margin-inline-end: 5.5rem !important;
  }
  .mx-xs-plus-22 {
    margin-inline: 5.5rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-23 {
    gap: 5.75rem !important;
  }
  .p-xs-plus-23 {
    padding: 5.75rem !important;
  }
  .pt-xs-plus-23 {
    padding-block-start: 5.75rem !important;
  }
  .pb-xs-plus-23 {
    padding-block-end: 5.75rem !important;
  }
  .py-xs-plus-23 {
    padding-block: 5.75rem !important;
  }
  .ps-xs-plus-23 {
    padding-inline-start: 5.75rem !important;
  }
  .pe-xs-plus-23 {
    padding-inline-end: 5.75rem !important;
  }
  .px-xs-plus-23 {
    padding-inline: 5.75rem !important;
  }
  .m-xs-plus-23 {
    margin: 5.75rem !important;
  }
  .mt-xs-plus-23 {
    margin-block-start: 5.75rem !important;
  }
  .mb-xs-plus-23 {
    margin-block-end: 5.75rem !important;
  }
  .my-xs-plus-23 {
    margin-block: 5.75rem !important;
  }
  .ms-xs-plus-23 {
    margin-inline-start: 5.75rem !important;
  }
  .me-xs-plus-23 {
    margin-inline-end: 5.75rem !important;
  }
  .mx-xs-plus-23 {
    margin-inline: 5.75rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-24 {
    gap: 6rem !important;
  }
  .p-xs-plus-24 {
    padding: 6rem !important;
  }
  .pt-xs-plus-24 {
    padding-block-start: 6rem !important;
  }
  .pb-xs-plus-24 {
    padding-block-end: 6rem !important;
  }
  .py-xs-plus-24 {
    padding-block: 6rem !important;
  }
  .ps-xs-plus-24 {
    padding-inline-start: 6rem !important;
  }
  .pe-xs-plus-24 {
    padding-inline-end: 6rem !important;
  }
  .px-xs-plus-24 {
    padding-inline: 6rem !important;
  }
  .m-xs-plus-24 {
    margin: 6rem !important;
  }
  .mt-xs-plus-24 {
    margin-block-start: 6rem !important;
  }
  .mb-xs-plus-24 {
    margin-block-end: 6rem !important;
  }
  .my-xs-plus-24 {
    margin-block: 6rem !important;
  }
  .ms-xs-plus-24 {
    margin-inline-start: 6rem !important;
  }
  .me-xs-plus-24 {
    margin-inline-end: 6rem !important;
  }
  .mx-xs-plus-24 {
    margin-inline: 6rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-25 {
    gap: 6.25rem !important;
  }
  .p-xs-plus-25 {
    padding: 6.25rem !important;
  }
  .pt-xs-plus-25 {
    padding-block-start: 6.25rem !important;
  }
  .pb-xs-plus-25 {
    padding-block-end: 6.25rem !important;
  }
  .py-xs-plus-25 {
    padding-block: 6.25rem !important;
  }
  .ps-xs-plus-25 {
    padding-inline-start: 6.25rem !important;
  }
  .pe-xs-plus-25 {
    padding-inline-end: 6.25rem !important;
  }
  .px-xs-plus-25 {
    padding-inline: 6.25rem !important;
  }
  .m-xs-plus-25 {
    margin: 6.25rem !important;
  }
  .mt-xs-plus-25 {
    margin-block-start: 6.25rem !important;
  }
  .mb-xs-plus-25 {
    margin-block-end: 6.25rem !important;
  }
  .my-xs-plus-25 {
    margin-block: 6.25rem !important;
  }
  .ms-xs-plus-25 {
    margin-inline-start: 6.25rem !important;
  }
  .me-xs-plus-25 {
    margin-inline-end: 6.25rem !important;
  }
  .mx-xs-plus-25 {
    margin-inline: 6.25rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-26 {
    gap: 6.5rem !important;
  }
  .p-xs-plus-26 {
    padding: 6.5rem !important;
  }
  .pt-xs-plus-26 {
    padding-block-start: 6.5rem !important;
  }
  .pb-xs-plus-26 {
    padding-block-end: 6.5rem !important;
  }
  .py-xs-plus-26 {
    padding-block: 6.5rem !important;
  }
  .ps-xs-plus-26 {
    padding-inline-start: 6.5rem !important;
  }
  .pe-xs-plus-26 {
    padding-inline-end: 6.5rem !important;
  }
  .px-xs-plus-26 {
    padding-inline: 6.5rem !important;
  }
  .m-xs-plus-26 {
    margin: 6.5rem !important;
  }
  .mt-xs-plus-26 {
    margin-block-start: 6.5rem !important;
  }
  .mb-xs-plus-26 {
    margin-block-end: 6.5rem !important;
  }
  .my-xs-plus-26 {
    margin-block: 6.5rem !important;
  }
  .ms-xs-plus-26 {
    margin-inline-start: 6.5rem !important;
  }
  .me-xs-plus-26 {
    margin-inline-end: 6.5rem !important;
  }
  .mx-xs-plus-26 {
    margin-inline: 6.5rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-27 {
    gap: 6.75rem !important;
  }
  .p-xs-plus-27 {
    padding: 6.75rem !important;
  }
  .pt-xs-plus-27 {
    padding-block-start: 6.75rem !important;
  }
  .pb-xs-plus-27 {
    padding-block-end: 6.75rem !important;
  }
  .py-xs-plus-27 {
    padding-block: 6.75rem !important;
  }
  .ps-xs-plus-27 {
    padding-inline-start: 6.75rem !important;
  }
  .pe-xs-plus-27 {
    padding-inline-end: 6.75rem !important;
  }
  .px-xs-plus-27 {
    padding-inline: 6.75rem !important;
  }
  .m-xs-plus-27 {
    margin: 6.75rem !important;
  }
  .mt-xs-plus-27 {
    margin-block-start: 6.75rem !important;
  }
  .mb-xs-plus-27 {
    margin-block-end: 6.75rem !important;
  }
  .my-xs-plus-27 {
    margin-block: 6.75rem !important;
  }
  .ms-xs-plus-27 {
    margin-inline-start: 6.75rem !important;
  }
  .me-xs-plus-27 {
    margin-inline-end: 6.75rem !important;
  }
  .mx-xs-plus-27 {
    margin-inline: 6.75rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-28 {
    gap: 7rem !important;
  }
  .p-xs-plus-28 {
    padding: 7rem !important;
  }
  .pt-xs-plus-28 {
    padding-block-start: 7rem !important;
  }
  .pb-xs-plus-28 {
    padding-block-end: 7rem !important;
  }
  .py-xs-plus-28 {
    padding-block: 7rem !important;
  }
  .ps-xs-plus-28 {
    padding-inline-start: 7rem !important;
  }
  .pe-xs-plus-28 {
    padding-inline-end: 7rem !important;
  }
  .px-xs-plus-28 {
    padding-inline: 7rem !important;
  }
  .m-xs-plus-28 {
    margin: 7rem !important;
  }
  .mt-xs-plus-28 {
    margin-block-start: 7rem !important;
  }
  .mb-xs-plus-28 {
    margin-block-end: 7rem !important;
  }
  .my-xs-plus-28 {
    margin-block: 7rem !important;
  }
  .ms-xs-plus-28 {
    margin-inline-start: 7rem !important;
  }
  .me-xs-plus-28 {
    margin-inline-end: 7rem !important;
  }
  .mx-xs-plus-28 {
    margin-inline: 7rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-29 {
    gap: 7.25rem !important;
  }
  .p-xs-plus-29 {
    padding: 7.25rem !important;
  }
  .pt-xs-plus-29 {
    padding-block-start: 7.25rem !important;
  }
  .pb-xs-plus-29 {
    padding-block-end: 7.25rem !important;
  }
  .py-xs-plus-29 {
    padding-block: 7.25rem !important;
  }
  .ps-xs-plus-29 {
    padding-inline-start: 7.25rem !important;
  }
  .pe-xs-plus-29 {
    padding-inline-end: 7.25rem !important;
  }
  .px-xs-plus-29 {
    padding-inline: 7.25rem !important;
  }
  .m-xs-plus-29 {
    margin: 7.25rem !important;
  }
  .mt-xs-plus-29 {
    margin-block-start: 7.25rem !important;
  }
  .mb-xs-plus-29 {
    margin-block-end: 7.25rem !important;
  }
  .my-xs-plus-29 {
    margin-block: 7.25rem !important;
  }
  .ms-xs-plus-29 {
    margin-inline-start: 7.25rem !important;
  }
  .me-xs-plus-29 {
    margin-inline-end: 7.25rem !important;
  }
  .mx-xs-plus-29 {
    margin-inline: 7.25rem !important;
  }
}
@media (min-width: 410px) {
  .gap-xs-plus-30 {
    gap: 7.5rem !important;
  }
  .p-xs-plus-30 {
    padding: 7.5rem !important;
  }
  .pt-xs-plus-30 {
    padding-block-start: 7.5rem !important;
  }
  .pb-xs-plus-30 {
    padding-block-end: 7.5rem !important;
  }
  .py-xs-plus-30 {
    padding-block: 7.5rem !important;
  }
  .ps-xs-plus-30 {
    padding-inline-start: 7.5rem !important;
  }
  .pe-xs-plus-30 {
    padding-inline-end: 7.5rem !important;
  }
  .px-xs-plus-30 {
    padding-inline: 7.5rem !important;
  }
  .m-xs-plus-30 {
    margin: 7.5rem !important;
  }
  .mt-xs-plus-30 {
    margin-block-start: 7.5rem !important;
  }
  .mb-xs-plus-30 {
    margin-block-end: 7.5rem !important;
  }
  .my-xs-plus-30 {
    margin-block: 7.5rem !important;
  }
  .ms-xs-plus-30 {
    margin-inline-start: 7.5rem !important;
  }
  .me-xs-plus-30 {
    margin-inline-end: 7.5rem !important;
  }
  .mx-xs-plus-30 {
    margin-inline: 7.5rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-0 {
    gap: 0 !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0 {
    padding-block-start: 0 !important;
  }
  .pb-sm-0 {
    padding-block-end: 0 !important;
  }
  .py-sm-0 {
    padding-block: 0 !important;
  }
  .ps-sm-0 {
    padding-inline-start: 0 !important;
  }
  .pe-sm-0 {
    padding-inline-end: 0 !important;
  }
  .px-sm-0 {
    padding-inline: 0 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0 {
    margin-block-start: 0 !important;
  }
  .mb-sm-0 {
    margin-block-end: 0 !important;
  }
  .my-sm-0 {
    margin-block: 0 !important;
  }
  .ms-sm-0 {
    margin-inline-start: 0 !important;
  }
  .me-sm-0 {
    margin-inline-end: 0 !important;
  }
  .mx-sm-0 {
    margin-inline: 0 !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1 {
    padding-block-start: 0.25rem !important;
  }
  .pb-sm-1 {
    padding-block-end: 0.25rem !important;
  }
  .py-sm-1 {
    padding-block: 0.25rem !important;
  }
  .ps-sm-1 {
    padding-inline-start: 0.25rem !important;
  }
  .pe-sm-1 {
    padding-inline-end: 0.25rem !important;
  }
  .px-sm-1 {
    padding-inline: 0.25rem !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1 {
    margin-block-start: 0.25rem !important;
  }
  .mb-sm-1 {
    margin-block-end: 0.25rem !important;
  }
  .my-sm-1 {
    margin-block: 0.25rem !important;
  }
  .ms-sm-1 {
    margin-inline-start: 0.25rem !important;
  }
  .me-sm-1 {
    margin-inline-end: 0.25rem !important;
  }
  .mx-sm-1 {
    margin-inline: 0.25rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2 {
    padding-block-start: 0.5rem !important;
  }
  .pb-sm-2 {
    padding-block-end: 0.5rem !important;
  }
  .py-sm-2 {
    padding-block: 0.5rem !important;
  }
  .ps-sm-2 {
    padding-inline-start: 0.5rem !important;
  }
  .pe-sm-2 {
    padding-inline-end: 0.5rem !important;
  }
  .px-sm-2 {
    padding-inline: 0.5rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2 {
    margin-block-start: 0.5rem !important;
  }
  .mb-sm-2 {
    margin-block-end: 0.5rem !important;
  }
  .my-sm-2 {
    margin-block: 0.5rem !important;
  }
  .ms-sm-2 {
    margin-inline-start: 0.5rem !important;
  }
  .me-sm-2 {
    margin-inline-end: 0.5rem !important;
  }
  .mx-sm-2 {
    margin-inline: 0.5rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-3 {
    gap: 0.75rem !important;
  }
  .p-sm-3 {
    padding: 0.75rem !important;
  }
  .pt-sm-3 {
    padding-block-start: 0.75rem !important;
  }
  .pb-sm-3 {
    padding-block-end: 0.75rem !important;
  }
  .py-sm-3 {
    padding-block: 0.75rem !important;
  }
  .ps-sm-3 {
    padding-inline-start: 0.75rem !important;
  }
  .pe-sm-3 {
    padding-inline-end: 0.75rem !important;
  }
  .px-sm-3 {
    padding-inline: 0.75rem !important;
  }
  .m-sm-3 {
    margin: 0.75rem !important;
  }
  .mt-sm-3 {
    margin-block-start: 0.75rem !important;
  }
  .mb-sm-3 {
    margin-block-end: 0.75rem !important;
  }
  .my-sm-3 {
    margin-block: 0.75rem !important;
  }
  .ms-sm-3 {
    margin-inline-start: 0.75rem !important;
  }
  .me-sm-3 {
    margin-inline-end: 0.75rem !important;
  }
  .mx-sm-3 {
    margin-inline: 0.75rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-4 {
    gap: 1rem !important;
  }
  .p-sm-4 {
    padding: 1rem !important;
  }
  .pt-sm-4 {
    padding-block-start: 1rem !important;
  }
  .pb-sm-4 {
    padding-block-end: 1rem !important;
  }
  .py-sm-4 {
    padding-block: 1rem !important;
  }
  .ps-sm-4 {
    padding-inline-start: 1rem !important;
  }
  .pe-sm-4 {
    padding-inline-end: 1rem !important;
  }
  .px-sm-4 {
    padding-inline: 1rem !important;
  }
  .m-sm-4 {
    margin: 1rem !important;
  }
  .mt-sm-4 {
    margin-block-start: 1rem !important;
  }
  .mb-sm-4 {
    margin-block-end: 1rem !important;
  }
  .my-sm-4 {
    margin-block: 1rem !important;
  }
  .ms-sm-4 {
    margin-inline-start: 1rem !important;
  }
  .me-sm-4 {
    margin-inline-end: 1rem !important;
  }
  .mx-sm-4 {
    margin-inline: 1rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-5 {
    gap: 1.25rem !important;
  }
  .p-sm-5 {
    padding: 1.25rem !important;
  }
  .pt-sm-5 {
    padding-block-start: 1.25rem !important;
  }
  .pb-sm-5 {
    padding-block-end: 1.25rem !important;
  }
  .py-sm-5 {
    padding-block: 1.25rem !important;
  }
  .ps-sm-5 {
    padding-inline-start: 1.25rem !important;
  }
  .pe-sm-5 {
    padding-inline-end: 1.25rem !important;
  }
  .px-sm-5 {
    padding-inline: 1.25rem !important;
  }
  .m-sm-5 {
    margin: 1.25rem !important;
  }
  .mt-sm-5 {
    margin-block-start: 1.25rem !important;
  }
  .mb-sm-5 {
    margin-block-end: 1.25rem !important;
  }
  .my-sm-5 {
    margin-block: 1.25rem !important;
  }
  .ms-sm-5 {
    margin-inline-start: 1.25rem !important;
  }
  .me-sm-5 {
    margin-inline-end: 1.25rem !important;
  }
  .mx-sm-5 {
    margin-inline: 1.25rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-6 {
    gap: 1.5rem !important;
  }
  .p-sm-6 {
    padding: 1.5rem !important;
  }
  .pt-sm-6 {
    padding-block-start: 1.5rem !important;
  }
  .pb-sm-6 {
    padding-block-end: 1.5rem !important;
  }
  .py-sm-6 {
    padding-block: 1.5rem !important;
  }
  .ps-sm-6 {
    padding-inline-start: 1.5rem !important;
  }
  .pe-sm-6 {
    padding-inline-end: 1.5rem !important;
  }
  .px-sm-6 {
    padding-inline: 1.5rem !important;
  }
  .m-sm-6 {
    margin: 1.5rem !important;
  }
  .mt-sm-6 {
    margin-block-start: 1.5rem !important;
  }
  .mb-sm-6 {
    margin-block-end: 1.5rem !important;
  }
  .my-sm-6 {
    margin-block: 1.5rem !important;
  }
  .ms-sm-6 {
    margin-inline-start: 1.5rem !important;
  }
  .me-sm-6 {
    margin-inline-end: 1.5rem !important;
  }
  .mx-sm-6 {
    margin-inline: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-7 {
    gap: 1.75rem !important;
  }
  .p-sm-7 {
    padding: 1.75rem !important;
  }
  .pt-sm-7 {
    padding-block-start: 1.75rem !important;
  }
  .pb-sm-7 {
    padding-block-end: 1.75rem !important;
  }
  .py-sm-7 {
    padding-block: 1.75rem !important;
  }
  .ps-sm-7 {
    padding-inline-start: 1.75rem !important;
  }
  .pe-sm-7 {
    padding-inline-end: 1.75rem !important;
  }
  .px-sm-7 {
    padding-inline: 1.75rem !important;
  }
  .m-sm-7 {
    margin: 1.75rem !important;
  }
  .mt-sm-7 {
    margin-block-start: 1.75rem !important;
  }
  .mb-sm-7 {
    margin-block-end: 1.75rem !important;
  }
  .my-sm-7 {
    margin-block: 1.75rem !important;
  }
  .ms-sm-7 {
    margin-inline-start: 1.75rem !important;
  }
  .me-sm-7 {
    margin-inline-end: 1.75rem !important;
  }
  .mx-sm-7 {
    margin-inline: 1.75rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-8 {
    gap: 2rem !important;
  }
  .p-sm-8 {
    padding: 2rem !important;
  }
  .pt-sm-8 {
    padding-block-start: 2rem !important;
  }
  .pb-sm-8 {
    padding-block-end: 2rem !important;
  }
  .py-sm-8 {
    padding-block: 2rem !important;
  }
  .ps-sm-8 {
    padding-inline-start: 2rem !important;
  }
  .pe-sm-8 {
    padding-inline-end: 2rem !important;
  }
  .px-sm-8 {
    padding-inline: 2rem !important;
  }
  .m-sm-8 {
    margin: 2rem !important;
  }
  .mt-sm-8 {
    margin-block-start: 2rem !important;
  }
  .mb-sm-8 {
    margin-block-end: 2rem !important;
  }
  .my-sm-8 {
    margin-block: 2rem !important;
  }
  .ms-sm-8 {
    margin-inline-start: 2rem !important;
  }
  .me-sm-8 {
    margin-inline-end: 2rem !important;
  }
  .mx-sm-8 {
    margin-inline: 2rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-9 {
    gap: 2.25rem !important;
  }
  .p-sm-9 {
    padding: 2.25rem !important;
  }
  .pt-sm-9 {
    padding-block-start: 2.25rem !important;
  }
  .pb-sm-9 {
    padding-block-end: 2.25rem !important;
  }
  .py-sm-9 {
    padding-block: 2.25rem !important;
  }
  .ps-sm-9 {
    padding-inline-start: 2.25rem !important;
  }
  .pe-sm-9 {
    padding-inline-end: 2.25rem !important;
  }
  .px-sm-9 {
    padding-inline: 2.25rem !important;
  }
  .m-sm-9 {
    margin: 2.25rem !important;
  }
  .mt-sm-9 {
    margin-block-start: 2.25rem !important;
  }
  .mb-sm-9 {
    margin-block-end: 2.25rem !important;
  }
  .my-sm-9 {
    margin-block: 2.25rem !important;
  }
  .ms-sm-9 {
    margin-inline-start: 2.25rem !important;
  }
  .me-sm-9 {
    margin-inline-end: 2.25rem !important;
  }
  .mx-sm-9 {
    margin-inline: 2.25rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-10 {
    gap: 2.5rem !important;
  }
  .p-sm-10 {
    padding: 2.5rem !important;
  }
  .pt-sm-10 {
    padding-block-start: 2.5rem !important;
  }
  .pb-sm-10 {
    padding-block-end: 2.5rem !important;
  }
  .py-sm-10 {
    padding-block: 2.5rem !important;
  }
  .ps-sm-10 {
    padding-inline-start: 2.5rem !important;
  }
  .pe-sm-10 {
    padding-inline-end: 2.5rem !important;
  }
  .px-sm-10 {
    padding-inline: 2.5rem !important;
  }
  .m-sm-10 {
    margin: 2.5rem !important;
  }
  .mt-sm-10 {
    margin-block-start: 2.5rem !important;
  }
  .mb-sm-10 {
    margin-block-end: 2.5rem !important;
  }
  .my-sm-10 {
    margin-block: 2.5rem !important;
  }
  .ms-sm-10 {
    margin-inline-start: 2.5rem !important;
  }
  .me-sm-10 {
    margin-inline-end: 2.5rem !important;
  }
  .mx-sm-10 {
    margin-inline: 2.5rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-11 {
    gap: 2.75rem !important;
  }
  .p-sm-11 {
    padding: 2.75rem !important;
  }
  .pt-sm-11 {
    padding-block-start: 2.75rem !important;
  }
  .pb-sm-11 {
    padding-block-end: 2.75rem !important;
  }
  .py-sm-11 {
    padding-block: 2.75rem !important;
  }
  .ps-sm-11 {
    padding-inline-start: 2.75rem !important;
  }
  .pe-sm-11 {
    padding-inline-end: 2.75rem !important;
  }
  .px-sm-11 {
    padding-inline: 2.75rem !important;
  }
  .m-sm-11 {
    margin: 2.75rem !important;
  }
  .mt-sm-11 {
    margin-block-start: 2.75rem !important;
  }
  .mb-sm-11 {
    margin-block-end: 2.75rem !important;
  }
  .my-sm-11 {
    margin-block: 2.75rem !important;
  }
  .ms-sm-11 {
    margin-inline-start: 2.75rem !important;
  }
  .me-sm-11 {
    margin-inline-end: 2.75rem !important;
  }
  .mx-sm-11 {
    margin-inline: 2.75rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-12 {
    gap: 3rem !important;
  }
  .p-sm-12 {
    padding: 3rem !important;
  }
  .pt-sm-12 {
    padding-block-start: 3rem !important;
  }
  .pb-sm-12 {
    padding-block-end: 3rem !important;
  }
  .py-sm-12 {
    padding-block: 3rem !important;
  }
  .ps-sm-12 {
    padding-inline-start: 3rem !important;
  }
  .pe-sm-12 {
    padding-inline-end: 3rem !important;
  }
  .px-sm-12 {
    padding-inline: 3rem !important;
  }
  .m-sm-12 {
    margin: 3rem !important;
  }
  .mt-sm-12 {
    margin-block-start: 3rem !important;
  }
  .mb-sm-12 {
    margin-block-end: 3rem !important;
  }
  .my-sm-12 {
    margin-block: 3rem !important;
  }
  .ms-sm-12 {
    margin-inline-start: 3rem !important;
  }
  .me-sm-12 {
    margin-inline-end: 3rem !important;
  }
  .mx-sm-12 {
    margin-inline: 3rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-13 {
    gap: 3.25rem !important;
  }
  .p-sm-13 {
    padding: 3.25rem !important;
  }
  .pt-sm-13 {
    padding-block-start: 3.25rem !important;
  }
  .pb-sm-13 {
    padding-block-end: 3.25rem !important;
  }
  .py-sm-13 {
    padding-block: 3.25rem !important;
  }
  .ps-sm-13 {
    padding-inline-start: 3.25rem !important;
  }
  .pe-sm-13 {
    padding-inline-end: 3.25rem !important;
  }
  .px-sm-13 {
    padding-inline: 3.25rem !important;
  }
  .m-sm-13 {
    margin: 3.25rem !important;
  }
  .mt-sm-13 {
    margin-block-start: 3.25rem !important;
  }
  .mb-sm-13 {
    margin-block-end: 3.25rem !important;
  }
  .my-sm-13 {
    margin-block: 3.25rem !important;
  }
  .ms-sm-13 {
    margin-inline-start: 3.25rem !important;
  }
  .me-sm-13 {
    margin-inline-end: 3.25rem !important;
  }
  .mx-sm-13 {
    margin-inline: 3.25rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-14 {
    gap: 3.5rem !important;
  }
  .p-sm-14 {
    padding: 3.5rem !important;
  }
  .pt-sm-14 {
    padding-block-start: 3.5rem !important;
  }
  .pb-sm-14 {
    padding-block-end: 3.5rem !important;
  }
  .py-sm-14 {
    padding-block: 3.5rem !important;
  }
  .ps-sm-14 {
    padding-inline-start: 3.5rem !important;
  }
  .pe-sm-14 {
    padding-inline-end: 3.5rem !important;
  }
  .px-sm-14 {
    padding-inline: 3.5rem !important;
  }
  .m-sm-14 {
    margin: 3.5rem !important;
  }
  .mt-sm-14 {
    margin-block-start: 3.5rem !important;
  }
  .mb-sm-14 {
    margin-block-end: 3.5rem !important;
  }
  .my-sm-14 {
    margin-block: 3.5rem !important;
  }
  .ms-sm-14 {
    margin-inline-start: 3.5rem !important;
  }
  .me-sm-14 {
    margin-inline-end: 3.5rem !important;
  }
  .mx-sm-14 {
    margin-inline: 3.5rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-15 {
    gap: 3.75rem !important;
  }
  .p-sm-15 {
    padding: 3.75rem !important;
  }
  .pt-sm-15 {
    padding-block-start: 3.75rem !important;
  }
  .pb-sm-15 {
    padding-block-end: 3.75rem !important;
  }
  .py-sm-15 {
    padding-block: 3.75rem !important;
  }
  .ps-sm-15 {
    padding-inline-start: 3.75rem !important;
  }
  .pe-sm-15 {
    padding-inline-end: 3.75rem !important;
  }
  .px-sm-15 {
    padding-inline: 3.75rem !important;
  }
  .m-sm-15 {
    margin: 3.75rem !important;
  }
  .mt-sm-15 {
    margin-block-start: 3.75rem !important;
  }
  .mb-sm-15 {
    margin-block-end: 3.75rem !important;
  }
  .my-sm-15 {
    margin-block: 3.75rem !important;
  }
  .ms-sm-15 {
    margin-inline-start: 3.75rem !important;
  }
  .me-sm-15 {
    margin-inline-end: 3.75rem !important;
  }
  .mx-sm-15 {
    margin-inline: 3.75rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-16 {
    gap: 4rem !important;
  }
  .p-sm-16 {
    padding: 4rem !important;
  }
  .pt-sm-16 {
    padding-block-start: 4rem !important;
  }
  .pb-sm-16 {
    padding-block-end: 4rem !important;
  }
  .py-sm-16 {
    padding-block: 4rem !important;
  }
  .ps-sm-16 {
    padding-inline-start: 4rem !important;
  }
  .pe-sm-16 {
    padding-inline-end: 4rem !important;
  }
  .px-sm-16 {
    padding-inline: 4rem !important;
  }
  .m-sm-16 {
    margin: 4rem !important;
  }
  .mt-sm-16 {
    margin-block-start: 4rem !important;
  }
  .mb-sm-16 {
    margin-block-end: 4rem !important;
  }
  .my-sm-16 {
    margin-block: 4rem !important;
  }
  .ms-sm-16 {
    margin-inline-start: 4rem !important;
  }
  .me-sm-16 {
    margin-inline-end: 4rem !important;
  }
  .mx-sm-16 {
    margin-inline: 4rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-17 {
    gap: 4.25rem !important;
  }
  .p-sm-17 {
    padding: 4.25rem !important;
  }
  .pt-sm-17 {
    padding-block-start: 4.25rem !important;
  }
  .pb-sm-17 {
    padding-block-end: 4.25rem !important;
  }
  .py-sm-17 {
    padding-block: 4.25rem !important;
  }
  .ps-sm-17 {
    padding-inline-start: 4.25rem !important;
  }
  .pe-sm-17 {
    padding-inline-end: 4.25rem !important;
  }
  .px-sm-17 {
    padding-inline: 4.25rem !important;
  }
  .m-sm-17 {
    margin: 4.25rem !important;
  }
  .mt-sm-17 {
    margin-block-start: 4.25rem !important;
  }
  .mb-sm-17 {
    margin-block-end: 4.25rem !important;
  }
  .my-sm-17 {
    margin-block: 4.25rem !important;
  }
  .ms-sm-17 {
    margin-inline-start: 4.25rem !important;
  }
  .me-sm-17 {
    margin-inline-end: 4.25rem !important;
  }
  .mx-sm-17 {
    margin-inline: 4.25rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-18 {
    gap: 4.5rem !important;
  }
  .p-sm-18 {
    padding: 4.5rem !important;
  }
  .pt-sm-18 {
    padding-block-start: 4.5rem !important;
  }
  .pb-sm-18 {
    padding-block-end: 4.5rem !important;
  }
  .py-sm-18 {
    padding-block: 4.5rem !important;
  }
  .ps-sm-18 {
    padding-inline-start: 4.5rem !important;
  }
  .pe-sm-18 {
    padding-inline-end: 4.5rem !important;
  }
  .px-sm-18 {
    padding-inline: 4.5rem !important;
  }
  .m-sm-18 {
    margin: 4.5rem !important;
  }
  .mt-sm-18 {
    margin-block-start: 4.5rem !important;
  }
  .mb-sm-18 {
    margin-block-end: 4.5rem !important;
  }
  .my-sm-18 {
    margin-block: 4.5rem !important;
  }
  .ms-sm-18 {
    margin-inline-start: 4.5rem !important;
  }
  .me-sm-18 {
    margin-inline-end: 4.5rem !important;
  }
  .mx-sm-18 {
    margin-inline: 4.5rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-19 {
    gap: 4.75rem !important;
  }
  .p-sm-19 {
    padding: 4.75rem !important;
  }
  .pt-sm-19 {
    padding-block-start: 4.75rem !important;
  }
  .pb-sm-19 {
    padding-block-end: 4.75rem !important;
  }
  .py-sm-19 {
    padding-block: 4.75rem !important;
  }
  .ps-sm-19 {
    padding-inline-start: 4.75rem !important;
  }
  .pe-sm-19 {
    padding-inline-end: 4.75rem !important;
  }
  .px-sm-19 {
    padding-inline: 4.75rem !important;
  }
  .m-sm-19 {
    margin: 4.75rem !important;
  }
  .mt-sm-19 {
    margin-block-start: 4.75rem !important;
  }
  .mb-sm-19 {
    margin-block-end: 4.75rem !important;
  }
  .my-sm-19 {
    margin-block: 4.75rem !important;
  }
  .ms-sm-19 {
    margin-inline-start: 4.75rem !important;
  }
  .me-sm-19 {
    margin-inline-end: 4.75rem !important;
  }
  .mx-sm-19 {
    margin-inline: 4.75rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-20 {
    gap: 5rem !important;
  }
  .p-sm-20 {
    padding: 5rem !important;
  }
  .pt-sm-20 {
    padding-block-start: 5rem !important;
  }
  .pb-sm-20 {
    padding-block-end: 5rem !important;
  }
  .py-sm-20 {
    padding-block: 5rem !important;
  }
  .ps-sm-20 {
    padding-inline-start: 5rem !important;
  }
  .pe-sm-20 {
    padding-inline-end: 5rem !important;
  }
  .px-sm-20 {
    padding-inline: 5rem !important;
  }
  .m-sm-20 {
    margin: 5rem !important;
  }
  .mt-sm-20 {
    margin-block-start: 5rem !important;
  }
  .mb-sm-20 {
    margin-block-end: 5rem !important;
  }
  .my-sm-20 {
    margin-block: 5rem !important;
  }
  .ms-sm-20 {
    margin-inline-start: 5rem !important;
  }
  .me-sm-20 {
    margin-inline-end: 5rem !important;
  }
  .mx-sm-20 {
    margin-inline: 5rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-21 {
    gap: 5.25rem !important;
  }
  .p-sm-21 {
    padding: 5.25rem !important;
  }
  .pt-sm-21 {
    padding-block-start: 5.25rem !important;
  }
  .pb-sm-21 {
    padding-block-end: 5.25rem !important;
  }
  .py-sm-21 {
    padding-block: 5.25rem !important;
  }
  .ps-sm-21 {
    padding-inline-start: 5.25rem !important;
  }
  .pe-sm-21 {
    padding-inline-end: 5.25rem !important;
  }
  .px-sm-21 {
    padding-inline: 5.25rem !important;
  }
  .m-sm-21 {
    margin: 5.25rem !important;
  }
  .mt-sm-21 {
    margin-block-start: 5.25rem !important;
  }
  .mb-sm-21 {
    margin-block-end: 5.25rem !important;
  }
  .my-sm-21 {
    margin-block: 5.25rem !important;
  }
  .ms-sm-21 {
    margin-inline-start: 5.25rem !important;
  }
  .me-sm-21 {
    margin-inline-end: 5.25rem !important;
  }
  .mx-sm-21 {
    margin-inline: 5.25rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-22 {
    gap: 5.5rem !important;
  }
  .p-sm-22 {
    padding: 5.5rem !important;
  }
  .pt-sm-22 {
    padding-block-start: 5.5rem !important;
  }
  .pb-sm-22 {
    padding-block-end: 5.5rem !important;
  }
  .py-sm-22 {
    padding-block: 5.5rem !important;
  }
  .ps-sm-22 {
    padding-inline-start: 5.5rem !important;
  }
  .pe-sm-22 {
    padding-inline-end: 5.5rem !important;
  }
  .px-sm-22 {
    padding-inline: 5.5rem !important;
  }
  .m-sm-22 {
    margin: 5.5rem !important;
  }
  .mt-sm-22 {
    margin-block-start: 5.5rem !important;
  }
  .mb-sm-22 {
    margin-block-end: 5.5rem !important;
  }
  .my-sm-22 {
    margin-block: 5.5rem !important;
  }
  .ms-sm-22 {
    margin-inline-start: 5.5rem !important;
  }
  .me-sm-22 {
    margin-inline-end: 5.5rem !important;
  }
  .mx-sm-22 {
    margin-inline: 5.5rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-23 {
    gap: 5.75rem !important;
  }
  .p-sm-23 {
    padding: 5.75rem !important;
  }
  .pt-sm-23 {
    padding-block-start: 5.75rem !important;
  }
  .pb-sm-23 {
    padding-block-end: 5.75rem !important;
  }
  .py-sm-23 {
    padding-block: 5.75rem !important;
  }
  .ps-sm-23 {
    padding-inline-start: 5.75rem !important;
  }
  .pe-sm-23 {
    padding-inline-end: 5.75rem !important;
  }
  .px-sm-23 {
    padding-inline: 5.75rem !important;
  }
  .m-sm-23 {
    margin: 5.75rem !important;
  }
  .mt-sm-23 {
    margin-block-start: 5.75rem !important;
  }
  .mb-sm-23 {
    margin-block-end: 5.75rem !important;
  }
  .my-sm-23 {
    margin-block: 5.75rem !important;
  }
  .ms-sm-23 {
    margin-inline-start: 5.75rem !important;
  }
  .me-sm-23 {
    margin-inline-end: 5.75rem !important;
  }
  .mx-sm-23 {
    margin-inline: 5.75rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-24 {
    gap: 6rem !important;
  }
  .p-sm-24 {
    padding: 6rem !important;
  }
  .pt-sm-24 {
    padding-block-start: 6rem !important;
  }
  .pb-sm-24 {
    padding-block-end: 6rem !important;
  }
  .py-sm-24 {
    padding-block: 6rem !important;
  }
  .ps-sm-24 {
    padding-inline-start: 6rem !important;
  }
  .pe-sm-24 {
    padding-inline-end: 6rem !important;
  }
  .px-sm-24 {
    padding-inline: 6rem !important;
  }
  .m-sm-24 {
    margin: 6rem !important;
  }
  .mt-sm-24 {
    margin-block-start: 6rem !important;
  }
  .mb-sm-24 {
    margin-block-end: 6rem !important;
  }
  .my-sm-24 {
    margin-block: 6rem !important;
  }
  .ms-sm-24 {
    margin-inline-start: 6rem !important;
  }
  .me-sm-24 {
    margin-inline-end: 6rem !important;
  }
  .mx-sm-24 {
    margin-inline: 6rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-25 {
    gap: 6.25rem !important;
  }
  .p-sm-25 {
    padding: 6.25rem !important;
  }
  .pt-sm-25 {
    padding-block-start: 6.25rem !important;
  }
  .pb-sm-25 {
    padding-block-end: 6.25rem !important;
  }
  .py-sm-25 {
    padding-block: 6.25rem !important;
  }
  .ps-sm-25 {
    padding-inline-start: 6.25rem !important;
  }
  .pe-sm-25 {
    padding-inline-end: 6.25rem !important;
  }
  .px-sm-25 {
    padding-inline: 6.25rem !important;
  }
  .m-sm-25 {
    margin: 6.25rem !important;
  }
  .mt-sm-25 {
    margin-block-start: 6.25rem !important;
  }
  .mb-sm-25 {
    margin-block-end: 6.25rem !important;
  }
  .my-sm-25 {
    margin-block: 6.25rem !important;
  }
  .ms-sm-25 {
    margin-inline-start: 6.25rem !important;
  }
  .me-sm-25 {
    margin-inline-end: 6.25rem !important;
  }
  .mx-sm-25 {
    margin-inline: 6.25rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-26 {
    gap: 6.5rem !important;
  }
  .p-sm-26 {
    padding: 6.5rem !important;
  }
  .pt-sm-26 {
    padding-block-start: 6.5rem !important;
  }
  .pb-sm-26 {
    padding-block-end: 6.5rem !important;
  }
  .py-sm-26 {
    padding-block: 6.5rem !important;
  }
  .ps-sm-26 {
    padding-inline-start: 6.5rem !important;
  }
  .pe-sm-26 {
    padding-inline-end: 6.5rem !important;
  }
  .px-sm-26 {
    padding-inline: 6.5rem !important;
  }
  .m-sm-26 {
    margin: 6.5rem !important;
  }
  .mt-sm-26 {
    margin-block-start: 6.5rem !important;
  }
  .mb-sm-26 {
    margin-block-end: 6.5rem !important;
  }
  .my-sm-26 {
    margin-block: 6.5rem !important;
  }
  .ms-sm-26 {
    margin-inline-start: 6.5rem !important;
  }
  .me-sm-26 {
    margin-inline-end: 6.5rem !important;
  }
  .mx-sm-26 {
    margin-inline: 6.5rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-27 {
    gap: 6.75rem !important;
  }
  .p-sm-27 {
    padding: 6.75rem !important;
  }
  .pt-sm-27 {
    padding-block-start: 6.75rem !important;
  }
  .pb-sm-27 {
    padding-block-end: 6.75rem !important;
  }
  .py-sm-27 {
    padding-block: 6.75rem !important;
  }
  .ps-sm-27 {
    padding-inline-start: 6.75rem !important;
  }
  .pe-sm-27 {
    padding-inline-end: 6.75rem !important;
  }
  .px-sm-27 {
    padding-inline: 6.75rem !important;
  }
  .m-sm-27 {
    margin: 6.75rem !important;
  }
  .mt-sm-27 {
    margin-block-start: 6.75rem !important;
  }
  .mb-sm-27 {
    margin-block-end: 6.75rem !important;
  }
  .my-sm-27 {
    margin-block: 6.75rem !important;
  }
  .ms-sm-27 {
    margin-inline-start: 6.75rem !important;
  }
  .me-sm-27 {
    margin-inline-end: 6.75rem !important;
  }
  .mx-sm-27 {
    margin-inline: 6.75rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-28 {
    gap: 7rem !important;
  }
  .p-sm-28 {
    padding: 7rem !important;
  }
  .pt-sm-28 {
    padding-block-start: 7rem !important;
  }
  .pb-sm-28 {
    padding-block-end: 7rem !important;
  }
  .py-sm-28 {
    padding-block: 7rem !important;
  }
  .ps-sm-28 {
    padding-inline-start: 7rem !important;
  }
  .pe-sm-28 {
    padding-inline-end: 7rem !important;
  }
  .px-sm-28 {
    padding-inline: 7rem !important;
  }
  .m-sm-28 {
    margin: 7rem !important;
  }
  .mt-sm-28 {
    margin-block-start: 7rem !important;
  }
  .mb-sm-28 {
    margin-block-end: 7rem !important;
  }
  .my-sm-28 {
    margin-block: 7rem !important;
  }
  .ms-sm-28 {
    margin-inline-start: 7rem !important;
  }
  .me-sm-28 {
    margin-inline-end: 7rem !important;
  }
  .mx-sm-28 {
    margin-inline: 7rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-29 {
    gap: 7.25rem !important;
  }
  .p-sm-29 {
    padding: 7.25rem !important;
  }
  .pt-sm-29 {
    padding-block-start: 7.25rem !important;
  }
  .pb-sm-29 {
    padding-block-end: 7.25rem !important;
  }
  .py-sm-29 {
    padding-block: 7.25rem !important;
  }
  .ps-sm-29 {
    padding-inline-start: 7.25rem !important;
  }
  .pe-sm-29 {
    padding-inline-end: 7.25rem !important;
  }
  .px-sm-29 {
    padding-inline: 7.25rem !important;
  }
  .m-sm-29 {
    margin: 7.25rem !important;
  }
  .mt-sm-29 {
    margin-block-start: 7.25rem !important;
  }
  .mb-sm-29 {
    margin-block-end: 7.25rem !important;
  }
  .my-sm-29 {
    margin-block: 7.25rem !important;
  }
  .ms-sm-29 {
    margin-inline-start: 7.25rem !important;
  }
  .me-sm-29 {
    margin-inline-end: 7.25rem !important;
  }
  .mx-sm-29 {
    margin-inline: 7.25rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-30 {
    gap: 7.5rem !important;
  }
  .p-sm-30 {
    padding: 7.5rem !important;
  }
  .pt-sm-30 {
    padding-block-start: 7.5rem !important;
  }
  .pb-sm-30 {
    padding-block-end: 7.5rem !important;
  }
  .py-sm-30 {
    padding-block: 7.5rem !important;
  }
  .ps-sm-30 {
    padding-inline-start: 7.5rem !important;
  }
  .pe-sm-30 {
    padding-inline-end: 7.5rem !important;
  }
  .px-sm-30 {
    padding-inline: 7.5rem !important;
  }
  .m-sm-30 {
    margin: 7.5rem !important;
  }
  .mt-sm-30 {
    margin-block-start: 7.5rem !important;
  }
  .mb-sm-30 {
    margin-block-end: 7.5rem !important;
  }
  .my-sm-30 {
    margin-block: 7.5rem !important;
  }
  .ms-sm-30 {
    margin-inline-start: 7.5rem !important;
  }
  .me-sm-30 {
    margin-inline-end: 7.5rem !important;
  }
  .mx-sm-30 {
    margin-inline: 7.5rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-0 {
    gap: 0 !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0 {
    padding-block-start: 0 !important;
  }
  .pb-md-0 {
    padding-block-end: 0 !important;
  }
  .py-md-0 {
    padding-block: 0 !important;
  }
  .ps-md-0 {
    padding-inline-start: 0 !important;
  }
  .pe-md-0 {
    padding-inline-end: 0 !important;
  }
  .px-md-0 {
    padding-inline: 0 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0 {
    margin-block-start: 0 !important;
  }
  .mb-md-0 {
    margin-block-end: 0 !important;
  }
  .my-md-0 {
    margin-block: 0 !important;
  }
  .ms-md-0 {
    margin-inline-start: 0 !important;
  }
  .me-md-0 {
    margin-inline-end: 0 !important;
  }
  .mx-md-0 {
    margin-inline: 0 !important;
  }
}
@media (min-width: 768px) {
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1 {
    padding-block-start: 0.25rem !important;
  }
  .pb-md-1 {
    padding-block-end: 0.25rem !important;
  }
  .py-md-1 {
    padding-block: 0.25rem !important;
  }
  .ps-md-1 {
    padding-inline-start: 0.25rem !important;
  }
  .pe-md-1 {
    padding-inline-end: 0.25rem !important;
  }
  .px-md-1 {
    padding-inline: 0.25rem !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1 {
    margin-block-start: 0.25rem !important;
  }
  .mb-md-1 {
    margin-block-end: 0.25rem !important;
  }
  .my-md-1 {
    margin-block: 0.25rem !important;
  }
  .ms-md-1 {
    margin-inline-start: 0.25rem !important;
  }
  .me-md-1 {
    margin-inline-end: 0.25rem !important;
  }
  .mx-md-1 {
    margin-inline: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2 {
    padding-block-start: 0.5rem !important;
  }
  .pb-md-2 {
    padding-block-end: 0.5rem !important;
  }
  .py-md-2 {
    padding-block: 0.5rem !important;
  }
  .ps-md-2 {
    padding-inline-start: 0.5rem !important;
  }
  .pe-md-2 {
    padding-inline-end: 0.5rem !important;
  }
  .px-md-2 {
    padding-inline: 0.5rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2 {
    margin-block-start: 0.5rem !important;
  }
  .mb-md-2 {
    margin-block-end: 0.5rem !important;
  }
  .my-md-2 {
    margin-block: 0.5rem !important;
  }
  .ms-md-2 {
    margin-inline-start: 0.5rem !important;
  }
  .me-md-2 {
    margin-inline-end: 0.5rem !important;
  }
  .mx-md-2 {
    margin-inline: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-3 {
    gap: 0.75rem !important;
  }
  .p-md-3 {
    padding: 0.75rem !important;
  }
  .pt-md-3 {
    padding-block-start: 0.75rem !important;
  }
  .pb-md-3 {
    padding-block-end: 0.75rem !important;
  }
  .py-md-3 {
    padding-block: 0.75rem !important;
  }
  .ps-md-3 {
    padding-inline-start: 0.75rem !important;
  }
  .pe-md-3 {
    padding-inline-end: 0.75rem !important;
  }
  .px-md-3 {
    padding-inline: 0.75rem !important;
  }
  .m-md-3 {
    margin: 0.75rem !important;
  }
  .mt-md-3 {
    margin-block-start: 0.75rem !important;
  }
  .mb-md-3 {
    margin-block-end: 0.75rem !important;
  }
  .my-md-3 {
    margin-block: 0.75rem !important;
  }
  .ms-md-3 {
    margin-inline-start: 0.75rem !important;
  }
  .me-md-3 {
    margin-inline-end: 0.75rem !important;
  }
  .mx-md-3 {
    margin-inline: 0.75rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-4 {
    gap: 1rem !important;
  }
  .p-md-4 {
    padding: 1rem !important;
  }
  .pt-md-4 {
    padding-block-start: 1rem !important;
  }
  .pb-md-4 {
    padding-block-end: 1rem !important;
  }
  .py-md-4 {
    padding-block: 1rem !important;
  }
  .ps-md-4 {
    padding-inline-start: 1rem !important;
  }
  .pe-md-4 {
    padding-inline-end: 1rem !important;
  }
  .px-md-4 {
    padding-inline: 1rem !important;
  }
  .m-md-4 {
    margin: 1rem !important;
  }
  .mt-md-4 {
    margin-block-start: 1rem !important;
  }
  .mb-md-4 {
    margin-block-end: 1rem !important;
  }
  .my-md-4 {
    margin-block: 1rem !important;
  }
  .ms-md-4 {
    margin-inline-start: 1rem !important;
  }
  .me-md-4 {
    margin-inline-end: 1rem !important;
  }
  .mx-md-4 {
    margin-inline: 1rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-5 {
    gap: 1.25rem !important;
  }
  .p-md-5 {
    padding: 1.25rem !important;
  }
  .pt-md-5 {
    padding-block-start: 1.25rem !important;
  }
  .pb-md-5 {
    padding-block-end: 1.25rem !important;
  }
  .py-md-5 {
    padding-block: 1.25rem !important;
  }
  .ps-md-5 {
    padding-inline-start: 1.25rem !important;
  }
  .pe-md-5 {
    padding-inline-end: 1.25rem !important;
  }
  .px-md-5 {
    padding-inline: 1.25rem !important;
  }
  .m-md-5 {
    margin: 1.25rem !important;
  }
  .mt-md-5 {
    margin-block-start: 1.25rem !important;
  }
  .mb-md-5 {
    margin-block-end: 1.25rem !important;
  }
  .my-md-5 {
    margin-block: 1.25rem !important;
  }
  .ms-md-5 {
    margin-inline-start: 1.25rem !important;
  }
  .me-md-5 {
    margin-inline-end: 1.25rem !important;
  }
  .mx-md-5 {
    margin-inline: 1.25rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-6 {
    gap: 1.5rem !important;
  }
  .p-md-6 {
    padding: 1.5rem !important;
  }
  .pt-md-6 {
    padding-block-start: 1.5rem !important;
  }
  .pb-md-6 {
    padding-block-end: 1.5rem !important;
  }
  .py-md-6 {
    padding-block: 1.5rem !important;
  }
  .ps-md-6 {
    padding-inline-start: 1.5rem !important;
  }
  .pe-md-6 {
    padding-inline-end: 1.5rem !important;
  }
  .px-md-6 {
    padding-inline: 1.5rem !important;
  }
  .m-md-6 {
    margin: 1.5rem !important;
  }
  .mt-md-6 {
    margin-block-start: 1.5rem !important;
  }
  .mb-md-6 {
    margin-block-end: 1.5rem !important;
  }
  .my-md-6 {
    margin-block: 1.5rem !important;
  }
  .ms-md-6 {
    margin-inline-start: 1.5rem !important;
  }
  .me-md-6 {
    margin-inline-end: 1.5rem !important;
  }
  .mx-md-6 {
    margin-inline: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-7 {
    gap: 1.75rem !important;
  }
  .p-md-7 {
    padding: 1.75rem !important;
  }
  .pt-md-7 {
    padding-block-start: 1.75rem !important;
  }
  .pb-md-7 {
    padding-block-end: 1.75rem !important;
  }
  .py-md-7 {
    padding-block: 1.75rem !important;
  }
  .ps-md-7 {
    padding-inline-start: 1.75rem !important;
  }
  .pe-md-7 {
    padding-inline-end: 1.75rem !important;
  }
  .px-md-7 {
    padding-inline: 1.75rem !important;
  }
  .m-md-7 {
    margin: 1.75rem !important;
  }
  .mt-md-7 {
    margin-block-start: 1.75rem !important;
  }
  .mb-md-7 {
    margin-block-end: 1.75rem !important;
  }
  .my-md-7 {
    margin-block: 1.75rem !important;
  }
  .ms-md-7 {
    margin-inline-start: 1.75rem !important;
  }
  .me-md-7 {
    margin-inline-end: 1.75rem !important;
  }
  .mx-md-7 {
    margin-inline: 1.75rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-8 {
    gap: 2rem !important;
  }
  .p-md-8 {
    padding: 2rem !important;
  }
  .pt-md-8 {
    padding-block-start: 2rem !important;
  }
  .pb-md-8 {
    padding-block-end: 2rem !important;
  }
  .py-md-8 {
    padding-block: 2rem !important;
  }
  .ps-md-8 {
    padding-inline-start: 2rem !important;
  }
  .pe-md-8 {
    padding-inline-end: 2rem !important;
  }
  .px-md-8 {
    padding-inline: 2rem !important;
  }
  .m-md-8 {
    margin: 2rem !important;
  }
  .mt-md-8 {
    margin-block-start: 2rem !important;
  }
  .mb-md-8 {
    margin-block-end: 2rem !important;
  }
  .my-md-8 {
    margin-block: 2rem !important;
  }
  .ms-md-8 {
    margin-inline-start: 2rem !important;
  }
  .me-md-8 {
    margin-inline-end: 2rem !important;
  }
  .mx-md-8 {
    margin-inline: 2rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-9 {
    gap: 2.25rem !important;
  }
  .p-md-9 {
    padding: 2.25rem !important;
  }
  .pt-md-9 {
    padding-block-start: 2.25rem !important;
  }
  .pb-md-9 {
    padding-block-end: 2.25rem !important;
  }
  .py-md-9 {
    padding-block: 2.25rem !important;
  }
  .ps-md-9 {
    padding-inline-start: 2.25rem !important;
  }
  .pe-md-9 {
    padding-inline-end: 2.25rem !important;
  }
  .px-md-9 {
    padding-inline: 2.25rem !important;
  }
  .m-md-9 {
    margin: 2.25rem !important;
  }
  .mt-md-9 {
    margin-block-start: 2.25rem !important;
  }
  .mb-md-9 {
    margin-block-end: 2.25rem !important;
  }
  .my-md-9 {
    margin-block: 2.25rem !important;
  }
  .ms-md-9 {
    margin-inline-start: 2.25rem !important;
  }
  .me-md-9 {
    margin-inline-end: 2.25rem !important;
  }
  .mx-md-9 {
    margin-inline: 2.25rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-10 {
    gap: 2.5rem !important;
  }
  .p-md-10 {
    padding: 2.5rem !important;
  }
  .pt-md-10 {
    padding-block-start: 2.5rem !important;
  }
  .pb-md-10 {
    padding-block-end: 2.5rem !important;
  }
  .py-md-10 {
    padding-block: 2.5rem !important;
  }
  .ps-md-10 {
    padding-inline-start: 2.5rem !important;
  }
  .pe-md-10 {
    padding-inline-end: 2.5rem !important;
  }
  .px-md-10 {
    padding-inline: 2.5rem !important;
  }
  .m-md-10 {
    margin: 2.5rem !important;
  }
  .mt-md-10 {
    margin-block-start: 2.5rem !important;
  }
  .mb-md-10 {
    margin-block-end: 2.5rem !important;
  }
  .my-md-10 {
    margin-block: 2.5rem !important;
  }
  .ms-md-10 {
    margin-inline-start: 2.5rem !important;
  }
  .me-md-10 {
    margin-inline-end: 2.5rem !important;
  }
  .mx-md-10 {
    margin-inline: 2.5rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-11 {
    gap: 2.75rem !important;
  }
  .p-md-11 {
    padding: 2.75rem !important;
  }
  .pt-md-11 {
    padding-block-start: 2.75rem !important;
  }
  .pb-md-11 {
    padding-block-end: 2.75rem !important;
  }
  .py-md-11 {
    padding-block: 2.75rem !important;
  }
  .ps-md-11 {
    padding-inline-start: 2.75rem !important;
  }
  .pe-md-11 {
    padding-inline-end: 2.75rem !important;
  }
  .px-md-11 {
    padding-inline: 2.75rem !important;
  }
  .m-md-11 {
    margin: 2.75rem !important;
  }
  .mt-md-11 {
    margin-block-start: 2.75rem !important;
  }
  .mb-md-11 {
    margin-block-end: 2.75rem !important;
  }
  .my-md-11 {
    margin-block: 2.75rem !important;
  }
  .ms-md-11 {
    margin-inline-start: 2.75rem !important;
  }
  .me-md-11 {
    margin-inline-end: 2.75rem !important;
  }
  .mx-md-11 {
    margin-inline: 2.75rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-12 {
    gap: 3rem !important;
  }
  .p-md-12 {
    padding: 3rem !important;
  }
  .pt-md-12 {
    padding-block-start: 3rem !important;
  }
  .pb-md-12 {
    padding-block-end: 3rem !important;
  }
  .py-md-12 {
    padding-block: 3rem !important;
  }
  .ps-md-12 {
    padding-inline-start: 3rem !important;
  }
  .pe-md-12 {
    padding-inline-end: 3rem !important;
  }
  .px-md-12 {
    padding-inline: 3rem !important;
  }
  .m-md-12 {
    margin: 3rem !important;
  }
  .mt-md-12 {
    margin-block-start: 3rem !important;
  }
  .mb-md-12 {
    margin-block-end: 3rem !important;
  }
  .my-md-12 {
    margin-block: 3rem !important;
  }
  .ms-md-12 {
    margin-inline-start: 3rem !important;
  }
  .me-md-12 {
    margin-inline-end: 3rem !important;
  }
  .mx-md-12 {
    margin-inline: 3rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-13 {
    gap: 3.25rem !important;
  }
  .p-md-13 {
    padding: 3.25rem !important;
  }
  .pt-md-13 {
    padding-block-start: 3.25rem !important;
  }
  .pb-md-13 {
    padding-block-end: 3.25rem !important;
  }
  .py-md-13 {
    padding-block: 3.25rem !important;
  }
  .ps-md-13 {
    padding-inline-start: 3.25rem !important;
  }
  .pe-md-13 {
    padding-inline-end: 3.25rem !important;
  }
  .px-md-13 {
    padding-inline: 3.25rem !important;
  }
  .m-md-13 {
    margin: 3.25rem !important;
  }
  .mt-md-13 {
    margin-block-start: 3.25rem !important;
  }
  .mb-md-13 {
    margin-block-end: 3.25rem !important;
  }
  .my-md-13 {
    margin-block: 3.25rem !important;
  }
  .ms-md-13 {
    margin-inline-start: 3.25rem !important;
  }
  .me-md-13 {
    margin-inline-end: 3.25rem !important;
  }
  .mx-md-13 {
    margin-inline: 3.25rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-14 {
    gap: 3.5rem !important;
  }
  .p-md-14 {
    padding: 3.5rem !important;
  }
  .pt-md-14 {
    padding-block-start: 3.5rem !important;
  }
  .pb-md-14 {
    padding-block-end: 3.5rem !important;
  }
  .py-md-14 {
    padding-block: 3.5rem !important;
  }
  .ps-md-14 {
    padding-inline-start: 3.5rem !important;
  }
  .pe-md-14 {
    padding-inline-end: 3.5rem !important;
  }
  .px-md-14 {
    padding-inline: 3.5rem !important;
  }
  .m-md-14 {
    margin: 3.5rem !important;
  }
  .mt-md-14 {
    margin-block-start: 3.5rem !important;
  }
  .mb-md-14 {
    margin-block-end: 3.5rem !important;
  }
  .my-md-14 {
    margin-block: 3.5rem !important;
  }
  .ms-md-14 {
    margin-inline-start: 3.5rem !important;
  }
  .me-md-14 {
    margin-inline-end: 3.5rem !important;
  }
  .mx-md-14 {
    margin-inline: 3.5rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-15 {
    gap: 3.75rem !important;
  }
  .p-md-15 {
    padding: 3.75rem !important;
  }
  .pt-md-15 {
    padding-block-start: 3.75rem !important;
  }
  .pb-md-15 {
    padding-block-end: 3.75rem !important;
  }
  .py-md-15 {
    padding-block: 3.75rem !important;
  }
  .ps-md-15 {
    padding-inline-start: 3.75rem !important;
  }
  .pe-md-15 {
    padding-inline-end: 3.75rem !important;
  }
  .px-md-15 {
    padding-inline: 3.75rem !important;
  }
  .m-md-15 {
    margin: 3.75rem !important;
  }
  .mt-md-15 {
    margin-block-start: 3.75rem !important;
  }
  .mb-md-15 {
    margin-block-end: 3.75rem !important;
  }
  .my-md-15 {
    margin-block: 3.75rem !important;
  }
  .ms-md-15 {
    margin-inline-start: 3.75rem !important;
  }
  .me-md-15 {
    margin-inline-end: 3.75rem !important;
  }
  .mx-md-15 {
    margin-inline: 3.75rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-16 {
    gap: 4rem !important;
  }
  .p-md-16 {
    padding: 4rem !important;
  }
  .pt-md-16 {
    padding-block-start: 4rem !important;
  }
  .pb-md-16 {
    padding-block-end: 4rem !important;
  }
  .py-md-16 {
    padding-block: 4rem !important;
  }
  .ps-md-16 {
    padding-inline-start: 4rem !important;
  }
  .pe-md-16 {
    padding-inline-end: 4rem !important;
  }
  .px-md-16 {
    padding-inline: 4rem !important;
  }
  .m-md-16 {
    margin: 4rem !important;
  }
  .mt-md-16 {
    margin-block-start: 4rem !important;
  }
  .mb-md-16 {
    margin-block-end: 4rem !important;
  }
  .my-md-16 {
    margin-block: 4rem !important;
  }
  .ms-md-16 {
    margin-inline-start: 4rem !important;
  }
  .me-md-16 {
    margin-inline-end: 4rem !important;
  }
  .mx-md-16 {
    margin-inline: 4rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-17 {
    gap: 4.25rem !important;
  }
  .p-md-17 {
    padding: 4.25rem !important;
  }
  .pt-md-17 {
    padding-block-start: 4.25rem !important;
  }
  .pb-md-17 {
    padding-block-end: 4.25rem !important;
  }
  .py-md-17 {
    padding-block: 4.25rem !important;
  }
  .ps-md-17 {
    padding-inline-start: 4.25rem !important;
  }
  .pe-md-17 {
    padding-inline-end: 4.25rem !important;
  }
  .px-md-17 {
    padding-inline: 4.25rem !important;
  }
  .m-md-17 {
    margin: 4.25rem !important;
  }
  .mt-md-17 {
    margin-block-start: 4.25rem !important;
  }
  .mb-md-17 {
    margin-block-end: 4.25rem !important;
  }
  .my-md-17 {
    margin-block: 4.25rem !important;
  }
  .ms-md-17 {
    margin-inline-start: 4.25rem !important;
  }
  .me-md-17 {
    margin-inline-end: 4.25rem !important;
  }
  .mx-md-17 {
    margin-inline: 4.25rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-18 {
    gap: 4.5rem !important;
  }
  .p-md-18 {
    padding: 4.5rem !important;
  }
  .pt-md-18 {
    padding-block-start: 4.5rem !important;
  }
  .pb-md-18 {
    padding-block-end: 4.5rem !important;
  }
  .py-md-18 {
    padding-block: 4.5rem !important;
  }
  .ps-md-18 {
    padding-inline-start: 4.5rem !important;
  }
  .pe-md-18 {
    padding-inline-end: 4.5rem !important;
  }
  .px-md-18 {
    padding-inline: 4.5rem !important;
  }
  .m-md-18 {
    margin: 4.5rem !important;
  }
  .mt-md-18 {
    margin-block-start: 4.5rem !important;
  }
  .mb-md-18 {
    margin-block-end: 4.5rem !important;
  }
  .my-md-18 {
    margin-block: 4.5rem !important;
  }
  .ms-md-18 {
    margin-inline-start: 4.5rem !important;
  }
  .me-md-18 {
    margin-inline-end: 4.5rem !important;
  }
  .mx-md-18 {
    margin-inline: 4.5rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-19 {
    gap: 4.75rem !important;
  }
  .p-md-19 {
    padding: 4.75rem !important;
  }
  .pt-md-19 {
    padding-block-start: 4.75rem !important;
  }
  .pb-md-19 {
    padding-block-end: 4.75rem !important;
  }
  .py-md-19 {
    padding-block: 4.75rem !important;
  }
  .ps-md-19 {
    padding-inline-start: 4.75rem !important;
  }
  .pe-md-19 {
    padding-inline-end: 4.75rem !important;
  }
  .px-md-19 {
    padding-inline: 4.75rem !important;
  }
  .m-md-19 {
    margin: 4.75rem !important;
  }
  .mt-md-19 {
    margin-block-start: 4.75rem !important;
  }
  .mb-md-19 {
    margin-block-end: 4.75rem !important;
  }
  .my-md-19 {
    margin-block: 4.75rem !important;
  }
  .ms-md-19 {
    margin-inline-start: 4.75rem !important;
  }
  .me-md-19 {
    margin-inline-end: 4.75rem !important;
  }
  .mx-md-19 {
    margin-inline: 4.75rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-20 {
    gap: 5rem !important;
  }
  .p-md-20 {
    padding: 5rem !important;
  }
  .pt-md-20 {
    padding-block-start: 5rem !important;
  }
  .pb-md-20 {
    padding-block-end: 5rem !important;
  }
  .py-md-20 {
    padding-block: 5rem !important;
  }
  .ps-md-20 {
    padding-inline-start: 5rem !important;
  }
  .pe-md-20 {
    padding-inline-end: 5rem !important;
  }
  .px-md-20 {
    padding-inline: 5rem !important;
  }
  .m-md-20 {
    margin: 5rem !important;
  }
  .mt-md-20 {
    margin-block-start: 5rem !important;
  }
  .mb-md-20 {
    margin-block-end: 5rem !important;
  }
  .my-md-20 {
    margin-block: 5rem !important;
  }
  .ms-md-20 {
    margin-inline-start: 5rem !important;
  }
  .me-md-20 {
    margin-inline-end: 5rem !important;
  }
  .mx-md-20 {
    margin-inline: 5rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-21 {
    gap: 5.25rem !important;
  }
  .p-md-21 {
    padding: 5.25rem !important;
  }
  .pt-md-21 {
    padding-block-start: 5.25rem !important;
  }
  .pb-md-21 {
    padding-block-end: 5.25rem !important;
  }
  .py-md-21 {
    padding-block: 5.25rem !important;
  }
  .ps-md-21 {
    padding-inline-start: 5.25rem !important;
  }
  .pe-md-21 {
    padding-inline-end: 5.25rem !important;
  }
  .px-md-21 {
    padding-inline: 5.25rem !important;
  }
  .m-md-21 {
    margin: 5.25rem !important;
  }
  .mt-md-21 {
    margin-block-start: 5.25rem !important;
  }
  .mb-md-21 {
    margin-block-end: 5.25rem !important;
  }
  .my-md-21 {
    margin-block: 5.25rem !important;
  }
  .ms-md-21 {
    margin-inline-start: 5.25rem !important;
  }
  .me-md-21 {
    margin-inline-end: 5.25rem !important;
  }
  .mx-md-21 {
    margin-inline: 5.25rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-22 {
    gap: 5.5rem !important;
  }
  .p-md-22 {
    padding: 5.5rem !important;
  }
  .pt-md-22 {
    padding-block-start: 5.5rem !important;
  }
  .pb-md-22 {
    padding-block-end: 5.5rem !important;
  }
  .py-md-22 {
    padding-block: 5.5rem !important;
  }
  .ps-md-22 {
    padding-inline-start: 5.5rem !important;
  }
  .pe-md-22 {
    padding-inline-end: 5.5rem !important;
  }
  .px-md-22 {
    padding-inline: 5.5rem !important;
  }
  .m-md-22 {
    margin: 5.5rem !important;
  }
  .mt-md-22 {
    margin-block-start: 5.5rem !important;
  }
  .mb-md-22 {
    margin-block-end: 5.5rem !important;
  }
  .my-md-22 {
    margin-block: 5.5rem !important;
  }
  .ms-md-22 {
    margin-inline-start: 5.5rem !important;
  }
  .me-md-22 {
    margin-inline-end: 5.5rem !important;
  }
  .mx-md-22 {
    margin-inline: 5.5rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-23 {
    gap: 5.75rem !important;
  }
  .p-md-23 {
    padding: 5.75rem !important;
  }
  .pt-md-23 {
    padding-block-start: 5.75rem !important;
  }
  .pb-md-23 {
    padding-block-end: 5.75rem !important;
  }
  .py-md-23 {
    padding-block: 5.75rem !important;
  }
  .ps-md-23 {
    padding-inline-start: 5.75rem !important;
  }
  .pe-md-23 {
    padding-inline-end: 5.75rem !important;
  }
  .px-md-23 {
    padding-inline: 5.75rem !important;
  }
  .m-md-23 {
    margin: 5.75rem !important;
  }
  .mt-md-23 {
    margin-block-start: 5.75rem !important;
  }
  .mb-md-23 {
    margin-block-end: 5.75rem !important;
  }
  .my-md-23 {
    margin-block: 5.75rem !important;
  }
  .ms-md-23 {
    margin-inline-start: 5.75rem !important;
  }
  .me-md-23 {
    margin-inline-end: 5.75rem !important;
  }
  .mx-md-23 {
    margin-inline: 5.75rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-24 {
    gap: 6rem !important;
  }
  .p-md-24 {
    padding: 6rem !important;
  }
  .pt-md-24 {
    padding-block-start: 6rem !important;
  }
  .pb-md-24 {
    padding-block-end: 6rem !important;
  }
  .py-md-24 {
    padding-block: 6rem !important;
  }
  .ps-md-24 {
    padding-inline-start: 6rem !important;
  }
  .pe-md-24 {
    padding-inline-end: 6rem !important;
  }
  .px-md-24 {
    padding-inline: 6rem !important;
  }
  .m-md-24 {
    margin: 6rem !important;
  }
  .mt-md-24 {
    margin-block-start: 6rem !important;
  }
  .mb-md-24 {
    margin-block-end: 6rem !important;
  }
  .my-md-24 {
    margin-block: 6rem !important;
  }
  .ms-md-24 {
    margin-inline-start: 6rem !important;
  }
  .me-md-24 {
    margin-inline-end: 6rem !important;
  }
  .mx-md-24 {
    margin-inline: 6rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-25 {
    gap: 6.25rem !important;
  }
  .p-md-25 {
    padding: 6.25rem !important;
  }
  .pt-md-25 {
    padding-block-start: 6.25rem !important;
  }
  .pb-md-25 {
    padding-block-end: 6.25rem !important;
  }
  .py-md-25 {
    padding-block: 6.25rem !important;
  }
  .ps-md-25 {
    padding-inline-start: 6.25rem !important;
  }
  .pe-md-25 {
    padding-inline-end: 6.25rem !important;
  }
  .px-md-25 {
    padding-inline: 6.25rem !important;
  }
  .m-md-25 {
    margin: 6.25rem !important;
  }
  .mt-md-25 {
    margin-block-start: 6.25rem !important;
  }
  .mb-md-25 {
    margin-block-end: 6.25rem !important;
  }
  .my-md-25 {
    margin-block: 6.25rem !important;
  }
  .ms-md-25 {
    margin-inline-start: 6.25rem !important;
  }
  .me-md-25 {
    margin-inline-end: 6.25rem !important;
  }
  .mx-md-25 {
    margin-inline: 6.25rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-26 {
    gap: 6.5rem !important;
  }
  .p-md-26 {
    padding: 6.5rem !important;
  }
  .pt-md-26 {
    padding-block-start: 6.5rem !important;
  }
  .pb-md-26 {
    padding-block-end: 6.5rem !important;
  }
  .py-md-26 {
    padding-block: 6.5rem !important;
  }
  .ps-md-26 {
    padding-inline-start: 6.5rem !important;
  }
  .pe-md-26 {
    padding-inline-end: 6.5rem !important;
  }
  .px-md-26 {
    padding-inline: 6.5rem !important;
  }
  .m-md-26 {
    margin: 6.5rem !important;
  }
  .mt-md-26 {
    margin-block-start: 6.5rem !important;
  }
  .mb-md-26 {
    margin-block-end: 6.5rem !important;
  }
  .my-md-26 {
    margin-block: 6.5rem !important;
  }
  .ms-md-26 {
    margin-inline-start: 6.5rem !important;
  }
  .me-md-26 {
    margin-inline-end: 6.5rem !important;
  }
  .mx-md-26 {
    margin-inline: 6.5rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-27 {
    gap: 6.75rem !important;
  }
  .p-md-27 {
    padding: 6.75rem !important;
  }
  .pt-md-27 {
    padding-block-start: 6.75rem !important;
  }
  .pb-md-27 {
    padding-block-end: 6.75rem !important;
  }
  .py-md-27 {
    padding-block: 6.75rem !important;
  }
  .ps-md-27 {
    padding-inline-start: 6.75rem !important;
  }
  .pe-md-27 {
    padding-inline-end: 6.75rem !important;
  }
  .px-md-27 {
    padding-inline: 6.75rem !important;
  }
  .m-md-27 {
    margin: 6.75rem !important;
  }
  .mt-md-27 {
    margin-block-start: 6.75rem !important;
  }
  .mb-md-27 {
    margin-block-end: 6.75rem !important;
  }
  .my-md-27 {
    margin-block: 6.75rem !important;
  }
  .ms-md-27 {
    margin-inline-start: 6.75rem !important;
  }
  .me-md-27 {
    margin-inline-end: 6.75rem !important;
  }
  .mx-md-27 {
    margin-inline: 6.75rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-28 {
    gap: 7rem !important;
  }
  .p-md-28 {
    padding: 7rem !important;
  }
  .pt-md-28 {
    padding-block-start: 7rem !important;
  }
  .pb-md-28 {
    padding-block-end: 7rem !important;
  }
  .py-md-28 {
    padding-block: 7rem !important;
  }
  .ps-md-28 {
    padding-inline-start: 7rem !important;
  }
  .pe-md-28 {
    padding-inline-end: 7rem !important;
  }
  .px-md-28 {
    padding-inline: 7rem !important;
  }
  .m-md-28 {
    margin: 7rem !important;
  }
  .mt-md-28 {
    margin-block-start: 7rem !important;
  }
  .mb-md-28 {
    margin-block-end: 7rem !important;
  }
  .my-md-28 {
    margin-block: 7rem !important;
  }
  .ms-md-28 {
    margin-inline-start: 7rem !important;
  }
  .me-md-28 {
    margin-inline-end: 7rem !important;
  }
  .mx-md-28 {
    margin-inline: 7rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-29 {
    gap: 7.25rem !important;
  }
  .p-md-29 {
    padding: 7.25rem !important;
  }
  .pt-md-29 {
    padding-block-start: 7.25rem !important;
  }
  .pb-md-29 {
    padding-block-end: 7.25rem !important;
  }
  .py-md-29 {
    padding-block: 7.25rem !important;
  }
  .ps-md-29 {
    padding-inline-start: 7.25rem !important;
  }
  .pe-md-29 {
    padding-inline-end: 7.25rem !important;
  }
  .px-md-29 {
    padding-inline: 7.25rem !important;
  }
  .m-md-29 {
    margin: 7.25rem !important;
  }
  .mt-md-29 {
    margin-block-start: 7.25rem !important;
  }
  .mb-md-29 {
    margin-block-end: 7.25rem !important;
  }
  .my-md-29 {
    margin-block: 7.25rem !important;
  }
  .ms-md-29 {
    margin-inline-start: 7.25rem !important;
  }
  .me-md-29 {
    margin-inline-end: 7.25rem !important;
  }
  .mx-md-29 {
    margin-inline: 7.25rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-30 {
    gap: 7.5rem !important;
  }
  .p-md-30 {
    padding: 7.5rem !important;
  }
  .pt-md-30 {
    padding-block-start: 7.5rem !important;
  }
  .pb-md-30 {
    padding-block-end: 7.5rem !important;
  }
  .py-md-30 {
    padding-block: 7.5rem !important;
  }
  .ps-md-30 {
    padding-inline-start: 7.5rem !important;
  }
  .pe-md-30 {
    padding-inline-end: 7.5rem !important;
  }
  .px-md-30 {
    padding-inline: 7.5rem !important;
  }
  .m-md-30 {
    margin: 7.5rem !important;
  }
  .mt-md-30 {
    margin-block-start: 7.5rem !important;
  }
  .mb-md-30 {
    margin-block-end: 7.5rem !important;
  }
  .my-md-30 {
    margin-block: 7.5rem !important;
  }
  .ms-md-30 {
    margin-inline-start: 7.5rem !important;
  }
  .me-md-30 {
    margin-inline-end: 7.5rem !important;
  }
  .mx-md-30 {
    margin-inline: 7.5rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-0 {
    gap: 0 !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0 {
    padding-block-start: 0 !important;
  }
  .pb-lg-0 {
    padding-block-end: 0 !important;
  }
  .py-lg-0 {
    padding-block: 0 !important;
  }
  .ps-lg-0 {
    padding-inline-start: 0 !important;
  }
  .pe-lg-0 {
    padding-inline-end: 0 !important;
  }
  .px-lg-0 {
    padding-inline: 0 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0 {
    margin-block-start: 0 !important;
  }
  .mb-lg-0 {
    margin-block-end: 0 !important;
  }
  .my-lg-0 {
    margin-block: 0 !important;
  }
  .ms-lg-0 {
    margin-inline-start: 0 !important;
  }
  .me-lg-0 {
    margin-inline-end: 0 !important;
  }
  .mx-lg-0 {
    margin-inline: 0 !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1 {
    padding-block-start: 0.25rem !important;
  }
  .pb-lg-1 {
    padding-block-end: 0.25rem !important;
  }
  .py-lg-1 {
    padding-block: 0.25rem !important;
  }
  .ps-lg-1 {
    padding-inline-start: 0.25rem !important;
  }
  .pe-lg-1 {
    padding-inline-end: 0.25rem !important;
  }
  .px-lg-1 {
    padding-inline: 0.25rem !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1 {
    margin-block-start: 0.25rem !important;
  }
  .mb-lg-1 {
    margin-block-end: 0.25rem !important;
  }
  .my-lg-1 {
    margin-block: 0.25rem !important;
  }
  .ms-lg-1 {
    margin-inline-start: 0.25rem !important;
  }
  .me-lg-1 {
    margin-inline-end: 0.25rem !important;
  }
  .mx-lg-1 {
    margin-inline: 0.25rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2 {
    padding-block-start: 0.5rem !important;
  }
  .pb-lg-2 {
    padding-block-end: 0.5rem !important;
  }
  .py-lg-2 {
    padding-block: 0.5rem !important;
  }
  .ps-lg-2 {
    padding-inline-start: 0.5rem !important;
  }
  .pe-lg-2 {
    padding-inline-end: 0.5rem !important;
  }
  .px-lg-2 {
    padding-inline: 0.5rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2 {
    margin-block-start: 0.5rem !important;
  }
  .mb-lg-2 {
    margin-block-end: 0.5rem !important;
  }
  .my-lg-2 {
    margin-block: 0.5rem !important;
  }
  .ms-lg-2 {
    margin-inline-start: 0.5rem !important;
  }
  .me-lg-2 {
    margin-inline-end: 0.5rem !important;
  }
  .mx-lg-2 {
    margin-inline: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-3 {
    gap: 0.75rem !important;
  }
  .p-lg-3 {
    padding: 0.75rem !important;
  }
  .pt-lg-3 {
    padding-block-start: 0.75rem !important;
  }
  .pb-lg-3 {
    padding-block-end: 0.75rem !important;
  }
  .py-lg-3 {
    padding-block: 0.75rem !important;
  }
  .ps-lg-3 {
    padding-inline-start: 0.75rem !important;
  }
  .pe-lg-3 {
    padding-inline-end: 0.75rem !important;
  }
  .px-lg-3 {
    padding-inline: 0.75rem !important;
  }
  .m-lg-3 {
    margin: 0.75rem !important;
  }
  .mt-lg-3 {
    margin-block-start: 0.75rem !important;
  }
  .mb-lg-3 {
    margin-block-end: 0.75rem !important;
  }
  .my-lg-3 {
    margin-block: 0.75rem !important;
  }
  .ms-lg-3 {
    margin-inline-start: 0.75rem !important;
  }
  .me-lg-3 {
    margin-inline-end: 0.75rem !important;
  }
  .mx-lg-3 {
    margin-inline: 0.75rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-4 {
    gap: 1rem !important;
  }
  .p-lg-4 {
    padding: 1rem !important;
  }
  .pt-lg-4 {
    padding-block-start: 1rem !important;
  }
  .pb-lg-4 {
    padding-block-end: 1rem !important;
  }
  .py-lg-4 {
    padding-block: 1rem !important;
  }
  .ps-lg-4 {
    padding-inline-start: 1rem !important;
  }
  .pe-lg-4 {
    padding-inline-end: 1rem !important;
  }
  .px-lg-4 {
    padding-inline: 1rem !important;
  }
  .m-lg-4 {
    margin: 1rem !important;
  }
  .mt-lg-4 {
    margin-block-start: 1rem !important;
  }
  .mb-lg-4 {
    margin-block-end: 1rem !important;
  }
  .my-lg-4 {
    margin-block: 1rem !important;
  }
  .ms-lg-4 {
    margin-inline-start: 1rem !important;
  }
  .me-lg-4 {
    margin-inline-end: 1rem !important;
  }
  .mx-lg-4 {
    margin-inline: 1rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-5 {
    gap: 1.25rem !important;
  }
  .p-lg-5 {
    padding: 1.25rem !important;
  }
  .pt-lg-5 {
    padding-block-start: 1.25rem !important;
  }
  .pb-lg-5 {
    padding-block-end: 1.25rem !important;
  }
  .py-lg-5 {
    padding-block: 1.25rem !important;
  }
  .ps-lg-5 {
    padding-inline-start: 1.25rem !important;
  }
  .pe-lg-5 {
    padding-inline-end: 1.25rem !important;
  }
  .px-lg-5 {
    padding-inline: 1.25rem !important;
  }
  .m-lg-5 {
    margin: 1.25rem !important;
  }
  .mt-lg-5 {
    margin-block-start: 1.25rem !important;
  }
  .mb-lg-5 {
    margin-block-end: 1.25rem !important;
  }
  .my-lg-5 {
    margin-block: 1.25rem !important;
  }
  .ms-lg-5 {
    margin-inline-start: 1.25rem !important;
  }
  .me-lg-5 {
    margin-inline-end: 1.25rem !important;
  }
  .mx-lg-5 {
    margin-inline: 1.25rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-6 {
    gap: 1.5rem !important;
  }
  .p-lg-6 {
    padding: 1.5rem !important;
  }
  .pt-lg-6 {
    padding-block-start: 1.5rem !important;
  }
  .pb-lg-6 {
    padding-block-end: 1.5rem !important;
  }
  .py-lg-6 {
    padding-block: 1.5rem !important;
  }
  .ps-lg-6 {
    padding-inline-start: 1.5rem !important;
  }
  .pe-lg-6 {
    padding-inline-end: 1.5rem !important;
  }
  .px-lg-6 {
    padding-inline: 1.5rem !important;
  }
  .m-lg-6 {
    margin: 1.5rem !important;
  }
  .mt-lg-6 {
    margin-block-start: 1.5rem !important;
  }
  .mb-lg-6 {
    margin-block-end: 1.5rem !important;
  }
  .my-lg-6 {
    margin-block: 1.5rem !important;
  }
  .ms-lg-6 {
    margin-inline-start: 1.5rem !important;
  }
  .me-lg-6 {
    margin-inline-end: 1.5rem !important;
  }
  .mx-lg-6 {
    margin-inline: 1.5rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-7 {
    gap: 1.75rem !important;
  }
  .p-lg-7 {
    padding: 1.75rem !important;
  }
  .pt-lg-7 {
    padding-block-start: 1.75rem !important;
  }
  .pb-lg-7 {
    padding-block-end: 1.75rem !important;
  }
  .py-lg-7 {
    padding-block: 1.75rem !important;
  }
  .ps-lg-7 {
    padding-inline-start: 1.75rem !important;
  }
  .pe-lg-7 {
    padding-inline-end: 1.75rem !important;
  }
  .px-lg-7 {
    padding-inline: 1.75rem !important;
  }
  .m-lg-7 {
    margin: 1.75rem !important;
  }
  .mt-lg-7 {
    margin-block-start: 1.75rem !important;
  }
  .mb-lg-7 {
    margin-block-end: 1.75rem !important;
  }
  .my-lg-7 {
    margin-block: 1.75rem !important;
  }
  .ms-lg-7 {
    margin-inline-start: 1.75rem !important;
  }
  .me-lg-7 {
    margin-inline-end: 1.75rem !important;
  }
  .mx-lg-7 {
    margin-inline: 1.75rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-8 {
    gap: 2rem !important;
  }
  .p-lg-8 {
    padding: 2rem !important;
  }
  .pt-lg-8 {
    padding-block-start: 2rem !important;
  }
  .pb-lg-8 {
    padding-block-end: 2rem !important;
  }
  .py-lg-8 {
    padding-block: 2rem !important;
  }
  .ps-lg-8 {
    padding-inline-start: 2rem !important;
  }
  .pe-lg-8 {
    padding-inline-end: 2rem !important;
  }
  .px-lg-8 {
    padding-inline: 2rem !important;
  }
  .m-lg-8 {
    margin: 2rem !important;
  }
  .mt-lg-8 {
    margin-block-start: 2rem !important;
  }
  .mb-lg-8 {
    margin-block-end: 2rem !important;
  }
  .my-lg-8 {
    margin-block: 2rem !important;
  }
  .ms-lg-8 {
    margin-inline-start: 2rem !important;
  }
  .me-lg-8 {
    margin-inline-end: 2rem !important;
  }
  .mx-lg-8 {
    margin-inline: 2rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-9 {
    gap: 2.25rem !important;
  }
  .p-lg-9 {
    padding: 2.25rem !important;
  }
  .pt-lg-9 {
    padding-block-start: 2.25rem !important;
  }
  .pb-lg-9 {
    padding-block-end: 2.25rem !important;
  }
  .py-lg-9 {
    padding-block: 2.25rem !important;
  }
  .ps-lg-9 {
    padding-inline-start: 2.25rem !important;
  }
  .pe-lg-9 {
    padding-inline-end: 2.25rem !important;
  }
  .px-lg-9 {
    padding-inline: 2.25rem !important;
  }
  .m-lg-9 {
    margin: 2.25rem !important;
  }
  .mt-lg-9 {
    margin-block-start: 2.25rem !important;
  }
  .mb-lg-9 {
    margin-block-end: 2.25rem !important;
  }
  .my-lg-9 {
    margin-block: 2.25rem !important;
  }
  .ms-lg-9 {
    margin-inline-start: 2.25rem !important;
  }
  .me-lg-9 {
    margin-inline-end: 2.25rem !important;
  }
  .mx-lg-9 {
    margin-inline: 2.25rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-10 {
    gap: 2.5rem !important;
  }
  .p-lg-10 {
    padding: 2.5rem !important;
  }
  .pt-lg-10 {
    padding-block-start: 2.5rem !important;
  }
  .pb-lg-10 {
    padding-block-end: 2.5rem !important;
  }
  .py-lg-10 {
    padding-block: 2.5rem !important;
  }
  .ps-lg-10 {
    padding-inline-start: 2.5rem !important;
  }
  .pe-lg-10 {
    padding-inline-end: 2.5rem !important;
  }
  .px-lg-10 {
    padding-inline: 2.5rem !important;
  }
  .m-lg-10 {
    margin: 2.5rem !important;
  }
  .mt-lg-10 {
    margin-block-start: 2.5rem !important;
  }
  .mb-lg-10 {
    margin-block-end: 2.5rem !important;
  }
  .my-lg-10 {
    margin-block: 2.5rem !important;
  }
  .ms-lg-10 {
    margin-inline-start: 2.5rem !important;
  }
  .me-lg-10 {
    margin-inline-end: 2.5rem !important;
  }
  .mx-lg-10 {
    margin-inline: 2.5rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-11 {
    gap: 2.75rem !important;
  }
  .p-lg-11 {
    padding: 2.75rem !important;
  }
  .pt-lg-11 {
    padding-block-start: 2.75rem !important;
  }
  .pb-lg-11 {
    padding-block-end: 2.75rem !important;
  }
  .py-lg-11 {
    padding-block: 2.75rem !important;
  }
  .ps-lg-11 {
    padding-inline-start: 2.75rem !important;
  }
  .pe-lg-11 {
    padding-inline-end: 2.75rem !important;
  }
  .px-lg-11 {
    padding-inline: 2.75rem !important;
  }
  .m-lg-11 {
    margin: 2.75rem !important;
  }
  .mt-lg-11 {
    margin-block-start: 2.75rem !important;
  }
  .mb-lg-11 {
    margin-block-end: 2.75rem !important;
  }
  .my-lg-11 {
    margin-block: 2.75rem !important;
  }
  .ms-lg-11 {
    margin-inline-start: 2.75rem !important;
  }
  .me-lg-11 {
    margin-inline-end: 2.75rem !important;
  }
  .mx-lg-11 {
    margin-inline: 2.75rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-12 {
    gap: 3rem !important;
  }
  .p-lg-12 {
    padding: 3rem !important;
  }
  .pt-lg-12 {
    padding-block-start: 3rem !important;
  }
  .pb-lg-12 {
    padding-block-end: 3rem !important;
  }
  .py-lg-12 {
    padding-block: 3rem !important;
  }
  .ps-lg-12 {
    padding-inline-start: 3rem !important;
  }
  .pe-lg-12 {
    padding-inline-end: 3rem !important;
  }
  .px-lg-12 {
    padding-inline: 3rem !important;
  }
  .m-lg-12 {
    margin: 3rem !important;
  }
  .mt-lg-12 {
    margin-block-start: 3rem !important;
  }
  .mb-lg-12 {
    margin-block-end: 3rem !important;
  }
  .my-lg-12 {
    margin-block: 3rem !important;
  }
  .ms-lg-12 {
    margin-inline-start: 3rem !important;
  }
  .me-lg-12 {
    margin-inline-end: 3rem !important;
  }
  .mx-lg-12 {
    margin-inline: 3rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-13 {
    gap: 3.25rem !important;
  }
  .p-lg-13 {
    padding: 3.25rem !important;
  }
  .pt-lg-13 {
    padding-block-start: 3.25rem !important;
  }
  .pb-lg-13 {
    padding-block-end: 3.25rem !important;
  }
  .py-lg-13 {
    padding-block: 3.25rem !important;
  }
  .ps-lg-13 {
    padding-inline-start: 3.25rem !important;
  }
  .pe-lg-13 {
    padding-inline-end: 3.25rem !important;
  }
  .px-lg-13 {
    padding-inline: 3.25rem !important;
  }
  .m-lg-13 {
    margin: 3.25rem !important;
  }
  .mt-lg-13 {
    margin-block-start: 3.25rem !important;
  }
  .mb-lg-13 {
    margin-block-end: 3.25rem !important;
  }
  .my-lg-13 {
    margin-block: 3.25rem !important;
  }
  .ms-lg-13 {
    margin-inline-start: 3.25rem !important;
  }
  .me-lg-13 {
    margin-inline-end: 3.25rem !important;
  }
  .mx-lg-13 {
    margin-inline: 3.25rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-14 {
    gap: 3.5rem !important;
  }
  .p-lg-14 {
    padding: 3.5rem !important;
  }
  .pt-lg-14 {
    padding-block-start: 3.5rem !important;
  }
  .pb-lg-14 {
    padding-block-end: 3.5rem !important;
  }
  .py-lg-14 {
    padding-block: 3.5rem !important;
  }
  .ps-lg-14 {
    padding-inline-start: 3.5rem !important;
  }
  .pe-lg-14 {
    padding-inline-end: 3.5rem !important;
  }
  .px-lg-14 {
    padding-inline: 3.5rem !important;
  }
  .m-lg-14 {
    margin: 3.5rem !important;
  }
  .mt-lg-14 {
    margin-block-start: 3.5rem !important;
  }
  .mb-lg-14 {
    margin-block-end: 3.5rem !important;
  }
  .my-lg-14 {
    margin-block: 3.5rem !important;
  }
  .ms-lg-14 {
    margin-inline-start: 3.5rem !important;
  }
  .me-lg-14 {
    margin-inline-end: 3.5rem !important;
  }
  .mx-lg-14 {
    margin-inline: 3.5rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-15 {
    gap: 3.75rem !important;
  }
  .p-lg-15 {
    padding: 3.75rem !important;
  }
  .pt-lg-15 {
    padding-block-start: 3.75rem !important;
  }
  .pb-lg-15 {
    padding-block-end: 3.75rem !important;
  }
  .py-lg-15 {
    padding-block: 3.75rem !important;
  }
  .ps-lg-15 {
    padding-inline-start: 3.75rem !important;
  }
  .pe-lg-15 {
    padding-inline-end: 3.75rem !important;
  }
  .px-lg-15 {
    padding-inline: 3.75rem !important;
  }
  .m-lg-15 {
    margin: 3.75rem !important;
  }
  .mt-lg-15 {
    margin-block-start: 3.75rem !important;
  }
  .mb-lg-15 {
    margin-block-end: 3.75rem !important;
  }
  .my-lg-15 {
    margin-block: 3.75rem !important;
  }
  .ms-lg-15 {
    margin-inline-start: 3.75rem !important;
  }
  .me-lg-15 {
    margin-inline-end: 3.75rem !important;
  }
  .mx-lg-15 {
    margin-inline: 3.75rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-16 {
    gap: 4rem !important;
  }
  .p-lg-16 {
    padding: 4rem !important;
  }
  .pt-lg-16 {
    padding-block-start: 4rem !important;
  }
  .pb-lg-16 {
    padding-block-end: 4rem !important;
  }
  .py-lg-16 {
    padding-block: 4rem !important;
  }
  .ps-lg-16 {
    padding-inline-start: 4rem !important;
  }
  .pe-lg-16 {
    padding-inline-end: 4rem !important;
  }
  .px-lg-16 {
    padding-inline: 4rem !important;
  }
  .m-lg-16 {
    margin: 4rem !important;
  }
  .mt-lg-16 {
    margin-block-start: 4rem !important;
  }
  .mb-lg-16 {
    margin-block-end: 4rem !important;
  }
  .my-lg-16 {
    margin-block: 4rem !important;
  }
  .ms-lg-16 {
    margin-inline-start: 4rem !important;
  }
  .me-lg-16 {
    margin-inline-end: 4rem !important;
  }
  .mx-lg-16 {
    margin-inline: 4rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-17 {
    gap: 4.25rem !important;
  }
  .p-lg-17 {
    padding: 4.25rem !important;
  }
  .pt-lg-17 {
    padding-block-start: 4.25rem !important;
  }
  .pb-lg-17 {
    padding-block-end: 4.25rem !important;
  }
  .py-lg-17 {
    padding-block: 4.25rem !important;
  }
  .ps-lg-17 {
    padding-inline-start: 4.25rem !important;
  }
  .pe-lg-17 {
    padding-inline-end: 4.25rem !important;
  }
  .px-lg-17 {
    padding-inline: 4.25rem !important;
  }
  .m-lg-17 {
    margin: 4.25rem !important;
  }
  .mt-lg-17 {
    margin-block-start: 4.25rem !important;
  }
  .mb-lg-17 {
    margin-block-end: 4.25rem !important;
  }
  .my-lg-17 {
    margin-block: 4.25rem !important;
  }
  .ms-lg-17 {
    margin-inline-start: 4.25rem !important;
  }
  .me-lg-17 {
    margin-inline-end: 4.25rem !important;
  }
  .mx-lg-17 {
    margin-inline: 4.25rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-18 {
    gap: 4.5rem !important;
  }
  .p-lg-18 {
    padding: 4.5rem !important;
  }
  .pt-lg-18 {
    padding-block-start: 4.5rem !important;
  }
  .pb-lg-18 {
    padding-block-end: 4.5rem !important;
  }
  .py-lg-18 {
    padding-block: 4.5rem !important;
  }
  .ps-lg-18 {
    padding-inline-start: 4.5rem !important;
  }
  .pe-lg-18 {
    padding-inline-end: 4.5rem !important;
  }
  .px-lg-18 {
    padding-inline: 4.5rem !important;
  }
  .m-lg-18 {
    margin: 4.5rem !important;
  }
  .mt-lg-18 {
    margin-block-start: 4.5rem !important;
  }
  .mb-lg-18 {
    margin-block-end: 4.5rem !important;
  }
  .my-lg-18 {
    margin-block: 4.5rem !important;
  }
  .ms-lg-18 {
    margin-inline-start: 4.5rem !important;
  }
  .me-lg-18 {
    margin-inline-end: 4.5rem !important;
  }
  .mx-lg-18 {
    margin-inline: 4.5rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-19 {
    gap: 4.75rem !important;
  }
  .p-lg-19 {
    padding: 4.75rem !important;
  }
  .pt-lg-19 {
    padding-block-start: 4.75rem !important;
  }
  .pb-lg-19 {
    padding-block-end: 4.75rem !important;
  }
  .py-lg-19 {
    padding-block: 4.75rem !important;
  }
  .ps-lg-19 {
    padding-inline-start: 4.75rem !important;
  }
  .pe-lg-19 {
    padding-inline-end: 4.75rem !important;
  }
  .px-lg-19 {
    padding-inline: 4.75rem !important;
  }
  .m-lg-19 {
    margin: 4.75rem !important;
  }
  .mt-lg-19 {
    margin-block-start: 4.75rem !important;
  }
  .mb-lg-19 {
    margin-block-end: 4.75rem !important;
  }
  .my-lg-19 {
    margin-block: 4.75rem !important;
  }
  .ms-lg-19 {
    margin-inline-start: 4.75rem !important;
  }
  .me-lg-19 {
    margin-inline-end: 4.75rem !important;
  }
  .mx-lg-19 {
    margin-inline: 4.75rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-20 {
    gap: 5rem !important;
  }
  .p-lg-20 {
    padding: 5rem !important;
  }
  .pt-lg-20 {
    padding-block-start: 5rem !important;
  }
  .pb-lg-20 {
    padding-block-end: 5rem !important;
  }
  .py-lg-20 {
    padding-block: 5rem !important;
  }
  .ps-lg-20 {
    padding-inline-start: 5rem !important;
  }
  .pe-lg-20 {
    padding-inline-end: 5rem !important;
  }
  .px-lg-20 {
    padding-inline: 5rem !important;
  }
  .m-lg-20 {
    margin: 5rem !important;
  }
  .mt-lg-20 {
    margin-block-start: 5rem !important;
  }
  .mb-lg-20 {
    margin-block-end: 5rem !important;
  }
  .my-lg-20 {
    margin-block: 5rem !important;
  }
  .ms-lg-20 {
    margin-inline-start: 5rem !important;
  }
  .me-lg-20 {
    margin-inline-end: 5rem !important;
  }
  .mx-lg-20 {
    margin-inline: 5rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-21 {
    gap: 5.25rem !important;
  }
  .p-lg-21 {
    padding: 5.25rem !important;
  }
  .pt-lg-21 {
    padding-block-start: 5.25rem !important;
  }
  .pb-lg-21 {
    padding-block-end: 5.25rem !important;
  }
  .py-lg-21 {
    padding-block: 5.25rem !important;
  }
  .ps-lg-21 {
    padding-inline-start: 5.25rem !important;
  }
  .pe-lg-21 {
    padding-inline-end: 5.25rem !important;
  }
  .px-lg-21 {
    padding-inline: 5.25rem !important;
  }
  .m-lg-21 {
    margin: 5.25rem !important;
  }
  .mt-lg-21 {
    margin-block-start: 5.25rem !important;
  }
  .mb-lg-21 {
    margin-block-end: 5.25rem !important;
  }
  .my-lg-21 {
    margin-block: 5.25rem !important;
  }
  .ms-lg-21 {
    margin-inline-start: 5.25rem !important;
  }
  .me-lg-21 {
    margin-inline-end: 5.25rem !important;
  }
  .mx-lg-21 {
    margin-inline: 5.25rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-22 {
    gap: 5.5rem !important;
  }
  .p-lg-22 {
    padding: 5.5rem !important;
  }
  .pt-lg-22 {
    padding-block-start: 5.5rem !important;
  }
  .pb-lg-22 {
    padding-block-end: 5.5rem !important;
  }
  .py-lg-22 {
    padding-block: 5.5rem !important;
  }
  .ps-lg-22 {
    padding-inline-start: 5.5rem !important;
  }
  .pe-lg-22 {
    padding-inline-end: 5.5rem !important;
  }
  .px-lg-22 {
    padding-inline: 5.5rem !important;
  }
  .m-lg-22 {
    margin: 5.5rem !important;
  }
  .mt-lg-22 {
    margin-block-start: 5.5rem !important;
  }
  .mb-lg-22 {
    margin-block-end: 5.5rem !important;
  }
  .my-lg-22 {
    margin-block: 5.5rem !important;
  }
  .ms-lg-22 {
    margin-inline-start: 5.5rem !important;
  }
  .me-lg-22 {
    margin-inline-end: 5.5rem !important;
  }
  .mx-lg-22 {
    margin-inline: 5.5rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-23 {
    gap: 5.75rem !important;
  }
  .p-lg-23 {
    padding: 5.75rem !important;
  }
  .pt-lg-23 {
    padding-block-start: 5.75rem !important;
  }
  .pb-lg-23 {
    padding-block-end: 5.75rem !important;
  }
  .py-lg-23 {
    padding-block: 5.75rem !important;
  }
  .ps-lg-23 {
    padding-inline-start: 5.75rem !important;
  }
  .pe-lg-23 {
    padding-inline-end: 5.75rem !important;
  }
  .px-lg-23 {
    padding-inline: 5.75rem !important;
  }
  .m-lg-23 {
    margin: 5.75rem !important;
  }
  .mt-lg-23 {
    margin-block-start: 5.75rem !important;
  }
  .mb-lg-23 {
    margin-block-end: 5.75rem !important;
  }
  .my-lg-23 {
    margin-block: 5.75rem !important;
  }
  .ms-lg-23 {
    margin-inline-start: 5.75rem !important;
  }
  .me-lg-23 {
    margin-inline-end: 5.75rem !important;
  }
  .mx-lg-23 {
    margin-inline: 5.75rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-24 {
    gap: 6rem !important;
  }
  .p-lg-24 {
    padding: 6rem !important;
  }
  .pt-lg-24 {
    padding-block-start: 6rem !important;
  }
  .pb-lg-24 {
    padding-block-end: 6rem !important;
  }
  .py-lg-24 {
    padding-block: 6rem !important;
  }
  .ps-lg-24 {
    padding-inline-start: 6rem !important;
  }
  .pe-lg-24 {
    padding-inline-end: 6rem !important;
  }
  .px-lg-24 {
    padding-inline: 6rem !important;
  }
  .m-lg-24 {
    margin: 6rem !important;
  }
  .mt-lg-24 {
    margin-block-start: 6rem !important;
  }
  .mb-lg-24 {
    margin-block-end: 6rem !important;
  }
  .my-lg-24 {
    margin-block: 6rem !important;
  }
  .ms-lg-24 {
    margin-inline-start: 6rem !important;
  }
  .me-lg-24 {
    margin-inline-end: 6rem !important;
  }
  .mx-lg-24 {
    margin-inline: 6rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-25 {
    gap: 6.25rem !important;
  }
  .p-lg-25 {
    padding: 6.25rem !important;
  }
  .pt-lg-25 {
    padding-block-start: 6.25rem !important;
  }
  .pb-lg-25 {
    padding-block-end: 6.25rem !important;
  }
  .py-lg-25 {
    padding-block: 6.25rem !important;
  }
  .ps-lg-25 {
    padding-inline-start: 6.25rem !important;
  }
  .pe-lg-25 {
    padding-inline-end: 6.25rem !important;
  }
  .px-lg-25 {
    padding-inline: 6.25rem !important;
  }
  .m-lg-25 {
    margin: 6.25rem !important;
  }
  .mt-lg-25 {
    margin-block-start: 6.25rem !important;
  }
  .mb-lg-25 {
    margin-block-end: 6.25rem !important;
  }
  .my-lg-25 {
    margin-block: 6.25rem !important;
  }
  .ms-lg-25 {
    margin-inline-start: 6.25rem !important;
  }
  .me-lg-25 {
    margin-inline-end: 6.25rem !important;
  }
  .mx-lg-25 {
    margin-inline: 6.25rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-26 {
    gap: 6.5rem !important;
  }
  .p-lg-26 {
    padding: 6.5rem !important;
  }
  .pt-lg-26 {
    padding-block-start: 6.5rem !important;
  }
  .pb-lg-26 {
    padding-block-end: 6.5rem !important;
  }
  .py-lg-26 {
    padding-block: 6.5rem !important;
  }
  .ps-lg-26 {
    padding-inline-start: 6.5rem !important;
  }
  .pe-lg-26 {
    padding-inline-end: 6.5rem !important;
  }
  .px-lg-26 {
    padding-inline: 6.5rem !important;
  }
  .m-lg-26 {
    margin: 6.5rem !important;
  }
  .mt-lg-26 {
    margin-block-start: 6.5rem !important;
  }
  .mb-lg-26 {
    margin-block-end: 6.5rem !important;
  }
  .my-lg-26 {
    margin-block: 6.5rem !important;
  }
  .ms-lg-26 {
    margin-inline-start: 6.5rem !important;
  }
  .me-lg-26 {
    margin-inline-end: 6.5rem !important;
  }
  .mx-lg-26 {
    margin-inline: 6.5rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-27 {
    gap: 6.75rem !important;
  }
  .p-lg-27 {
    padding: 6.75rem !important;
  }
  .pt-lg-27 {
    padding-block-start: 6.75rem !important;
  }
  .pb-lg-27 {
    padding-block-end: 6.75rem !important;
  }
  .py-lg-27 {
    padding-block: 6.75rem !important;
  }
  .ps-lg-27 {
    padding-inline-start: 6.75rem !important;
  }
  .pe-lg-27 {
    padding-inline-end: 6.75rem !important;
  }
  .px-lg-27 {
    padding-inline: 6.75rem !important;
  }
  .m-lg-27 {
    margin: 6.75rem !important;
  }
  .mt-lg-27 {
    margin-block-start: 6.75rem !important;
  }
  .mb-lg-27 {
    margin-block-end: 6.75rem !important;
  }
  .my-lg-27 {
    margin-block: 6.75rem !important;
  }
  .ms-lg-27 {
    margin-inline-start: 6.75rem !important;
  }
  .me-lg-27 {
    margin-inline-end: 6.75rem !important;
  }
  .mx-lg-27 {
    margin-inline: 6.75rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-28 {
    gap: 7rem !important;
  }
  .p-lg-28 {
    padding: 7rem !important;
  }
  .pt-lg-28 {
    padding-block-start: 7rem !important;
  }
  .pb-lg-28 {
    padding-block-end: 7rem !important;
  }
  .py-lg-28 {
    padding-block: 7rem !important;
  }
  .ps-lg-28 {
    padding-inline-start: 7rem !important;
  }
  .pe-lg-28 {
    padding-inline-end: 7rem !important;
  }
  .px-lg-28 {
    padding-inline: 7rem !important;
  }
  .m-lg-28 {
    margin: 7rem !important;
  }
  .mt-lg-28 {
    margin-block-start: 7rem !important;
  }
  .mb-lg-28 {
    margin-block-end: 7rem !important;
  }
  .my-lg-28 {
    margin-block: 7rem !important;
  }
  .ms-lg-28 {
    margin-inline-start: 7rem !important;
  }
  .me-lg-28 {
    margin-inline-end: 7rem !important;
  }
  .mx-lg-28 {
    margin-inline: 7rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-29 {
    gap: 7.25rem !important;
  }
  .p-lg-29 {
    padding: 7.25rem !important;
  }
  .pt-lg-29 {
    padding-block-start: 7.25rem !important;
  }
  .pb-lg-29 {
    padding-block-end: 7.25rem !important;
  }
  .py-lg-29 {
    padding-block: 7.25rem !important;
  }
  .ps-lg-29 {
    padding-inline-start: 7.25rem !important;
  }
  .pe-lg-29 {
    padding-inline-end: 7.25rem !important;
  }
  .px-lg-29 {
    padding-inline: 7.25rem !important;
  }
  .m-lg-29 {
    margin: 7.25rem !important;
  }
  .mt-lg-29 {
    margin-block-start: 7.25rem !important;
  }
  .mb-lg-29 {
    margin-block-end: 7.25rem !important;
  }
  .my-lg-29 {
    margin-block: 7.25rem !important;
  }
  .ms-lg-29 {
    margin-inline-start: 7.25rem !important;
  }
  .me-lg-29 {
    margin-inline-end: 7.25rem !important;
  }
  .mx-lg-29 {
    margin-inline: 7.25rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-30 {
    gap: 7.5rem !important;
  }
  .p-lg-30 {
    padding: 7.5rem !important;
  }
  .pt-lg-30 {
    padding-block-start: 7.5rem !important;
  }
  .pb-lg-30 {
    padding-block-end: 7.5rem !important;
  }
  .py-lg-30 {
    padding-block: 7.5rem !important;
  }
  .ps-lg-30 {
    padding-inline-start: 7.5rem !important;
  }
  .pe-lg-30 {
    padding-inline-end: 7.5rem !important;
  }
  .px-lg-30 {
    padding-inline: 7.5rem !important;
  }
  .m-lg-30 {
    margin: 7.5rem !important;
  }
  .mt-lg-30 {
    margin-block-start: 7.5rem !important;
  }
  .mb-lg-30 {
    margin-block-end: 7.5rem !important;
  }
  .my-lg-30 {
    margin-block: 7.5rem !important;
  }
  .ms-lg-30 {
    margin-inline-start: 7.5rem !important;
  }
  .me-lg-30 {
    margin-inline-end: 7.5rem !important;
  }
  .mx-lg-30 {
    margin-inline: 7.5rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-0 {
    gap: 0 !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0 {
    padding-block-start: 0 !important;
  }
  .pb-xl-0 {
    padding-block-end: 0 !important;
  }
  .py-xl-0 {
    padding-block: 0 !important;
  }
  .ps-xl-0 {
    padding-inline-start: 0 !important;
  }
  .pe-xl-0 {
    padding-inline-end: 0 !important;
  }
  .px-xl-0 {
    padding-inline: 0 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0 {
    margin-block-start: 0 !important;
  }
  .mb-xl-0 {
    margin-block-end: 0 !important;
  }
  .my-xl-0 {
    margin-block: 0 !important;
  }
  .ms-xl-0 {
    margin-inline-start: 0 !important;
  }
  .me-xl-0 {
    margin-inline-end: 0 !important;
  }
  .mx-xl-0 {
    margin-inline: 0 !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1 {
    padding-block-start: 0.25rem !important;
  }
  .pb-xl-1 {
    padding-block-end: 0.25rem !important;
  }
  .py-xl-1 {
    padding-block: 0.25rem !important;
  }
  .ps-xl-1 {
    padding-inline-start: 0.25rem !important;
  }
  .pe-xl-1 {
    padding-inline-end: 0.25rem !important;
  }
  .px-xl-1 {
    padding-inline: 0.25rem !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1 {
    margin-block-start: 0.25rem !important;
  }
  .mb-xl-1 {
    margin-block-end: 0.25rem !important;
  }
  .my-xl-1 {
    margin-block: 0.25rem !important;
  }
  .ms-xl-1 {
    margin-inline-start: 0.25rem !important;
  }
  .me-xl-1 {
    margin-inline-end: 0.25rem !important;
  }
  .mx-xl-1 {
    margin-inline: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2 {
    padding-block-start: 0.5rem !important;
  }
  .pb-xl-2 {
    padding-block-end: 0.5rem !important;
  }
  .py-xl-2 {
    padding-block: 0.5rem !important;
  }
  .ps-xl-2 {
    padding-inline-start: 0.5rem !important;
  }
  .pe-xl-2 {
    padding-inline-end: 0.5rem !important;
  }
  .px-xl-2 {
    padding-inline: 0.5rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2 {
    margin-block-start: 0.5rem !important;
  }
  .mb-xl-2 {
    margin-block-end: 0.5rem !important;
  }
  .my-xl-2 {
    margin-block: 0.5rem !important;
  }
  .ms-xl-2 {
    margin-inline-start: 0.5rem !important;
  }
  .me-xl-2 {
    margin-inline-end: 0.5rem !important;
  }
  .mx-xl-2 {
    margin-inline: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-3 {
    gap: 0.75rem !important;
  }
  .p-xl-3 {
    padding: 0.75rem !important;
  }
  .pt-xl-3 {
    padding-block-start: 0.75rem !important;
  }
  .pb-xl-3 {
    padding-block-end: 0.75rem !important;
  }
  .py-xl-3 {
    padding-block: 0.75rem !important;
  }
  .ps-xl-3 {
    padding-inline-start: 0.75rem !important;
  }
  .pe-xl-3 {
    padding-inline-end: 0.75rem !important;
  }
  .px-xl-3 {
    padding-inline: 0.75rem !important;
  }
  .m-xl-3 {
    margin: 0.75rem !important;
  }
  .mt-xl-3 {
    margin-block-start: 0.75rem !important;
  }
  .mb-xl-3 {
    margin-block-end: 0.75rem !important;
  }
  .my-xl-3 {
    margin-block: 0.75rem !important;
  }
  .ms-xl-3 {
    margin-inline-start: 0.75rem !important;
  }
  .me-xl-3 {
    margin-inline-end: 0.75rem !important;
  }
  .mx-xl-3 {
    margin-inline: 0.75rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-4 {
    gap: 1rem !important;
  }
  .p-xl-4 {
    padding: 1rem !important;
  }
  .pt-xl-4 {
    padding-block-start: 1rem !important;
  }
  .pb-xl-4 {
    padding-block-end: 1rem !important;
  }
  .py-xl-4 {
    padding-block: 1rem !important;
  }
  .ps-xl-4 {
    padding-inline-start: 1rem !important;
  }
  .pe-xl-4 {
    padding-inline-end: 1rem !important;
  }
  .px-xl-4 {
    padding-inline: 1rem !important;
  }
  .m-xl-4 {
    margin: 1rem !important;
  }
  .mt-xl-4 {
    margin-block-start: 1rem !important;
  }
  .mb-xl-4 {
    margin-block-end: 1rem !important;
  }
  .my-xl-4 {
    margin-block: 1rem !important;
  }
  .ms-xl-4 {
    margin-inline-start: 1rem !important;
  }
  .me-xl-4 {
    margin-inline-end: 1rem !important;
  }
  .mx-xl-4 {
    margin-inline: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-5 {
    gap: 1.25rem !important;
  }
  .p-xl-5 {
    padding: 1.25rem !important;
  }
  .pt-xl-5 {
    padding-block-start: 1.25rem !important;
  }
  .pb-xl-5 {
    padding-block-end: 1.25rem !important;
  }
  .py-xl-5 {
    padding-block: 1.25rem !important;
  }
  .ps-xl-5 {
    padding-inline-start: 1.25rem !important;
  }
  .pe-xl-5 {
    padding-inline-end: 1.25rem !important;
  }
  .px-xl-5 {
    padding-inline: 1.25rem !important;
  }
  .m-xl-5 {
    margin: 1.25rem !important;
  }
  .mt-xl-5 {
    margin-block-start: 1.25rem !important;
  }
  .mb-xl-5 {
    margin-block-end: 1.25rem !important;
  }
  .my-xl-5 {
    margin-block: 1.25rem !important;
  }
  .ms-xl-5 {
    margin-inline-start: 1.25rem !important;
  }
  .me-xl-5 {
    margin-inline-end: 1.25rem !important;
  }
  .mx-xl-5 {
    margin-inline: 1.25rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-6 {
    gap: 1.5rem !important;
  }
  .p-xl-6 {
    padding: 1.5rem !important;
  }
  .pt-xl-6 {
    padding-block-start: 1.5rem !important;
  }
  .pb-xl-6 {
    padding-block-end: 1.5rem !important;
  }
  .py-xl-6 {
    padding-block: 1.5rem !important;
  }
  .ps-xl-6 {
    padding-inline-start: 1.5rem !important;
  }
  .pe-xl-6 {
    padding-inline-end: 1.5rem !important;
  }
  .px-xl-6 {
    padding-inline: 1.5rem !important;
  }
  .m-xl-6 {
    margin: 1.5rem !important;
  }
  .mt-xl-6 {
    margin-block-start: 1.5rem !important;
  }
  .mb-xl-6 {
    margin-block-end: 1.5rem !important;
  }
  .my-xl-6 {
    margin-block: 1.5rem !important;
  }
  .ms-xl-6 {
    margin-inline-start: 1.5rem !important;
  }
  .me-xl-6 {
    margin-inline-end: 1.5rem !important;
  }
  .mx-xl-6 {
    margin-inline: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-7 {
    gap: 1.75rem !important;
  }
  .p-xl-7 {
    padding: 1.75rem !important;
  }
  .pt-xl-7 {
    padding-block-start: 1.75rem !important;
  }
  .pb-xl-7 {
    padding-block-end: 1.75rem !important;
  }
  .py-xl-7 {
    padding-block: 1.75rem !important;
  }
  .ps-xl-7 {
    padding-inline-start: 1.75rem !important;
  }
  .pe-xl-7 {
    padding-inline-end: 1.75rem !important;
  }
  .px-xl-7 {
    padding-inline: 1.75rem !important;
  }
  .m-xl-7 {
    margin: 1.75rem !important;
  }
  .mt-xl-7 {
    margin-block-start: 1.75rem !important;
  }
  .mb-xl-7 {
    margin-block-end: 1.75rem !important;
  }
  .my-xl-7 {
    margin-block: 1.75rem !important;
  }
  .ms-xl-7 {
    margin-inline-start: 1.75rem !important;
  }
  .me-xl-7 {
    margin-inline-end: 1.75rem !important;
  }
  .mx-xl-7 {
    margin-inline: 1.75rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-8 {
    gap: 2rem !important;
  }
  .p-xl-8 {
    padding: 2rem !important;
  }
  .pt-xl-8 {
    padding-block-start: 2rem !important;
  }
  .pb-xl-8 {
    padding-block-end: 2rem !important;
  }
  .py-xl-8 {
    padding-block: 2rem !important;
  }
  .ps-xl-8 {
    padding-inline-start: 2rem !important;
  }
  .pe-xl-8 {
    padding-inline-end: 2rem !important;
  }
  .px-xl-8 {
    padding-inline: 2rem !important;
  }
  .m-xl-8 {
    margin: 2rem !important;
  }
  .mt-xl-8 {
    margin-block-start: 2rem !important;
  }
  .mb-xl-8 {
    margin-block-end: 2rem !important;
  }
  .my-xl-8 {
    margin-block: 2rem !important;
  }
  .ms-xl-8 {
    margin-inline-start: 2rem !important;
  }
  .me-xl-8 {
    margin-inline-end: 2rem !important;
  }
  .mx-xl-8 {
    margin-inline: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-9 {
    gap: 2.25rem !important;
  }
  .p-xl-9 {
    padding: 2.25rem !important;
  }
  .pt-xl-9 {
    padding-block-start: 2.25rem !important;
  }
  .pb-xl-9 {
    padding-block-end: 2.25rem !important;
  }
  .py-xl-9 {
    padding-block: 2.25rem !important;
  }
  .ps-xl-9 {
    padding-inline-start: 2.25rem !important;
  }
  .pe-xl-9 {
    padding-inline-end: 2.25rem !important;
  }
  .px-xl-9 {
    padding-inline: 2.25rem !important;
  }
  .m-xl-9 {
    margin: 2.25rem !important;
  }
  .mt-xl-9 {
    margin-block-start: 2.25rem !important;
  }
  .mb-xl-9 {
    margin-block-end: 2.25rem !important;
  }
  .my-xl-9 {
    margin-block: 2.25rem !important;
  }
  .ms-xl-9 {
    margin-inline-start: 2.25rem !important;
  }
  .me-xl-9 {
    margin-inline-end: 2.25rem !important;
  }
  .mx-xl-9 {
    margin-inline: 2.25rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-10 {
    gap: 2.5rem !important;
  }
  .p-xl-10 {
    padding: 2.5rem !important;
  }
  .pt-xl-10 {
    padding-block-start: 2.5rem !important;
  }
  .pb-xl-10 {
    padding-block-end: 2.5rem !important;
  }
  .py-xl-10 {
    padding-block: 2.5rem !important;
  }
  .ps-xl-10 {
    padding-inline-start: 2.5rem !important;
  }
  .pe-xl-10 {
    padding-inline-end: 2.5rem !important;
  }
  .px-xl-10 {
    padding-inline: 2.5rem !important;
  }
  .m-xl-10 {
    margin: 2.5rem !important;
  }
  .mt-xl-10 {
    margin-block-start: 2.5rem !important;
  }
  .mb-xl-10 {
    margin-block-end: 2.5rem !important;
  }
  .my-xl-10 {
    margin-block: 2.5rem !important;
  }
  .ms-xl-10 {
    margin-inline-start: 2.5rem !important;
  }
  .me-xl-10 {
    margin-inline-end: 2.5rem !important;
  }
  .mx-xl-10 {
    margin-inline: 2.5rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-11 {
    gap: 2.75rem !important;
  }
  .p-xl-11 {
    padding: 2.75rem !important;
  }
  .pt-xl-11 {
    padding-block-start: 2.75rem !important;
  }
  .pb-xl-11 {
    padding-block-end: 2.75rem !important;
  }
  .py-xl-11 {
    padding-block: 2.75rem !important;
  }
  .ps-xl-11 {
    padding-inline-start: 2.75rem !important;
  }
  .pe-xl-11 {
    padding-inline-end: 2.75rem !important;
  }
  .px-xl-11 {
    padding-inline: 2.75rem !important;
  }
  .m-xl-11 {
    margin: 2.75rem !important;
  }
  .mt-xl-11 {
    margin-block-start: 2.75rem !important;
  }
  .mb-xl-11 {
    margin-block-end: 2.75rem !important;
  }
  .my-xl-11 {
    margin-block: 2.75rem !important;
  }
  .ms-xl-11 {
    margin-inline-start: 2.75rem !important;
  }
  .me-xl-11 {
    margin-inline-end: 2.75rem !important;
  }
  .mx-xl-11 {
    margin-inline: 2.75rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-12 {
    gap: 3rem !important;
  }
  .p-xl-12 {
    padding: 3rem !important;
  }
  .pt-xl-12 {
    padding-block-start: 3rem !important;
  }
  .pb-xl-12 {
    padding-block-end: 3rem !important;
  }
  .py-xl-12 {
    padding-block: 3rem !important;
  }
  .ps-xl-12 {
    padding-inline-start: 3rem !important;
  }
  .pe-xl-12 {
    padding-inline-end: 3rem !important;
  }
  .px-xl-12 {
    padding-inline: 3rem !important;
  }
  .m-xl-12 {
    margin: 3rem !important;
  }
  .mt-xl-12 {
    margin-block-start: 3rem !important;
  }
  .mb-xl-12 {
    margin-block-end: 3rem !important;
  }
  .my-xl-12 {
    margin-block: 3rem !important;
  }
  .ms-xl-12 {
    margin-inline-start: 3rem !important;
  }
  .me-xl-12 {
    margin-inline-end: 3rem !important;
  }
  .mx-xl-12 {
    margin-inline: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-13 {
    gap: 3.25rem !important;
  }
  .p-xl-13 {
    padding: 3.25rem !important;
  }
  .pt-xl-13 {
    padding-block-start: 3.25rem !important;
  }
  .pb-xl-13 {
    padding-block-end: 3.25rem !important;
  }
  .py-xl-13 {
    padding-block: 3.25rem !important;
  }
  .ps-xl-13 {
    padding-inline-start: 3.25rem !important;
  }
  .pe-xl-13 {
    padding-inline-end: 3.25rem !important;
  }
  .px-xl-13 {
    padding-inline: 3.25rem !important;
  }
  .m-xl-13 {
    margin: 3.25rem !important;
  }
  .mt-xl-13 {
    margin-block-start: 3.25rem !important;
  }
  .mb-xl-13 {
    margin-block-end: 3.25rem !important;
  }
  .my-xl-13 {
    margin-block: 3.25rem !important;
  }
  .ms-xl-13 {
    margin-inline-start: 3.25rem !important;
  }
  .me-xl-13 {
    margin-inline-end: 3.25rem !important;
  }
  .mx-xl-13 {
    margin-inline: 3.25rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-14 {
    gap: 3.5rem !important;
  }
  .p-xl-14 {
    padding: 3.5rem !important;
  }
  .pt-xl-14 {
    padding-block-start: 3.5rem !important;
  }
  .pb-xl-14 {
    padding-block-end: 3.5rem !important;
  }
  .py-xl-14 {
    padding-block: 3.5rem !important;
  }
  .ps-xl-14 {
    padding-inline-start: 3.5rem !important;
  }
  .pe-xl-14 {
    padding-inline-end: 3.5rem !important;
  }
  .px-xl-14 {
    padding-inline: 3.5rem !important;
  }
  .m-xl-14 {
    margin: 3.5rem !important;
  }
  .mt-xl-14 {
    margin-block-start: 3.5rem !important;
  }
  .mb-xl-14 {
    margin-block-end: 3.5rem !important;
  }
  .my-xl-14 {
    margin-block: 3.5rem !important;
  }
  .ms-xl-14 {
    margin-inline-start: 3.5rem !important;
  }
  .me-xl-14 {
    margin-inline-end: 3.5rem !important;
  }
  .mx-xl-14 {
    margin-inline: 3.5rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-15 {
    gap: 3.75rem !important;
  }
  .p-xl-15 {
    padding: 3.75rem !important;
  }
  .pt-xl-15 {
    padding-block-start: 3.75rem !important;
  }
  .pb-xl-15 {
    padding-block-end: 3.75rem !important;
  }
  .py-xl-15 {
    padding-block: 3.75rem !important;
  }
  .ps-xl-15 {
    padding-inline-start: 3.75rem !important;
  }
  .pe-xl-15 {
    padding-inline-end: 3.75rem !important;
  }
  .px-xl-15 {
    padding-inline: 3.75rem !important;
  }
  .m-xl-15 {
    margin: 3.75rem !important;
  }
  .mt-xl-15 {
    margin-block-start: 3.75rem !important;
  }
  .mb-xl-15 {
    margin-block-end: 3.75rem !important;
  }
  .my-xl-15 {
    margin-block: 3.75rem !important;
  }
  .ms-xl-15 {
    margin-inline-start: 3.75rem !important;
  }
  .me-xl-15 {
    margin-inline-end: 3.75rem !important;
  }
  .mx-xl-15 {
    margin-inline: 3.75rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-16 {
    gap: 4rem !important;
  }
  .p-xl-16 {
    padding: 4rem !important;
  }
  .pt-xl-16 {
    padding-block-start: 4rem !important;
  }
  .pb-xl-16 {
    padding-block-end: 4rem !important;
  }
  .py-xl-16 {
    padding-block: 4rem !important;
  }
  .ps-xl-16 {
    padding-inline-start: 4rem !important;
  }
  .pe-xl-16 {
    padding-inline-end: 4rem !important;
  }
  .px-xl-16 {
    padding-inline: 4rem !important;
  }
  .m-xl-16 {
    margin: 4rem !important;
  }
  .mt-xl-16 {
    margin-block-start: 4rem !important;
  }
  .mb-xl-16 {
    margin-block-end: 4rem !important;
  }
  .my-xl-16 {
    margin-block: 4rem !important;
  }
  .ms-xl-16 {
    margin-inline-start: 4rem !important;
  }
  .me-xl-16 {
    margin-inline-end: 4rem !important;
  }
  .mx-xl-16 {
    margin-inline: 4rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-17 {
    gap: 4.25rem !important;
  }
  .p-xl-17 {
    padding: 4.25rem !important;
  }
  .pt-xl-17 {
    padding-block-start: 4.25rem !important;
  }
  .pb-xl-17 {
    padding-block-end: 4.25rem !important;
  }
  .py-xl-17 {
    padding-block: 4.25rem !important;
  }
  .ps-xl-17 {
    padding-inline-start: 4.25rem !important;
  }
  .pe-xl-17 {
    padding-inline-end: 4.25rem !important;
  }
  .px-xl-17 {
    padding-inline: 4.25rem !important;
  }
  .m-xl-17 {
    margin: 4.25rem !important;
  }
  .mt-xl-17 {
    margin-block-start: 4.25rem !important;
  }
  .mb-xl-17 {
    margin-block-end: 4.25rem !important;
  }
  .my-xl-17 {
    margin-block: 4.25rem !important;
  }
  .ms-xl-17 {
    margin-inline-start: 4.25rem !important;
  }
  .me-xl-17 {
    margin-inline-end: 4.25rem !important;
  }
  .mx-xl-17 {
    margin-inline: 4.25rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-18 {
    gap: 4.5rem !important;
  }
  .p-xl-18 {
    padding: 4.5rem !important;
  }
  .pt-xl-18 {
    padding-block-start: 4.5rem !important;
  }
  .pb-xl-18 {
    padding-block-end: 4.5rem !important;
  }
  .py-xl-18 {
    padding-block: 4.5rem !important;
  }
  .ps-xl-18 {
    padding-inline-start: 4.5rem !important;
  }
  .pe-xl-18 {
    padding-inline-end: 4.5rem !important;
  }
  .px-xl-18 {
    padding-inline: 4.5rem !important;
  }
  .m-xl-18 {
    margin: 4.5rem !important;
  }
  .mt-xl-18 {
    margin-block-start: 4.5rem !important;
  }
  .mb-xl-18 {
    margin-block-end: 4.5rem !important;
  }
  .my-xl-18 {
    margin-block: 4.5rem !important;
  }
  .ms-xl-18 {
    margin-inline-start: 4.5rem !important;
  }
  .me-xl-18 {
    margin-inline-end: 4.5rem !important;
  }
  .mx-xl-18 {
    margin-inline: 4.5rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-19 {
    gap: 4.75rem !important;
  }
  .p-xl-19 {
    padding: 4.75rem !important;
  }
  .pt-xl-19 {
    padding-block-start: 4.75rem !important;
  }
  .pb-xl-19 {
    padding-block-end: 4.75rem !important;
  }
  .py-xl-19 {
    padding-block: 4.75rem !important;
  }
  .ps-xl-19 {
    padding-inline-start: 4.75rem !important;
  }
  .pe-xl-19 {
    padding-inline-end: 4.75rem !important;
  }
  .px-xl-19 {
    padding-inline: 4.75rem !important;
  }
  .m-xl-19 {
    margin: 4.75rem !important;
  }
  .mt-xl-19 {
    margin-block-start: 4.75rem !important;
  }
  .mb-xl-19 {
    margin-block-end: 4.75rem !important;
  }
  .my-xl-19 {
    margin-block: 4.75rem !important;
  }
  .ms-xl-19 {
    margin-inline-start: 4.75rem !important;
  }
  .me-xl-19 {
    margin-inline-end: 4.75rem !important;
  }
  .mx-xl-19 {
    margin-inline: 4.75rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-20 {
    gap: 5rem !important;
  }
  .p-xl-20 {
    padding: 5rem !important;
  }
  .pt-xl-20 {
    padding-block-start: 5rem !important;
  }
  .pb-xl-20 {
    padding-block-end: 5rem !important;
  }
  .py-xl-20 {
    padding-block: 5rem !important;
  }
  .ps-xl-20 {
    padding-inline-start: 5rem !important;
  }
  .pe-xl-20 {
    padding-inline-end: 5rem !important;
  }
  .px-xl-20 {
    padding-inline: 5rem !important;
  }
  .m-xl-20 {
    margin: 5rem !important;
  }
  .mt-xl-20 {
    margin-block-start: 5rem !important;
  }
  .mb-xl-20 {
    margin-block-end: 5rem !important;
  }
  .my-xl-20 {
    margin-block: 5rem !important;
  }
  .ms-xl-20 {
    margin-inline-start: 5rem !important;
  }
  .me-xl-20 {
    margin-inline-end: 5rem !important;
  }
  .mx-xl-20 {
    margin-inline: 5rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-21 {
    gap: 5.25rem !important;
  }
  .p-xl-21 {
    padding: 5.25rem !important;
  }
  .pt-xl-21 {
    padding-block-start: 5.25rem !important;
  }
  .pb-xl-21 {
    padding-block-end: 5.25rem !important;
  }
  .py-xl-21 {
    padding-block: 5.25rem !important;
  }
  .ps-xl-21 {
    padding-inline-start: 5.25rem !important;
  }
  .pe-xl-21 {
    padding-inline-end: 5.25rem !important;
  }
  .px-xl-21 {
    padding-inline: 5.25rem !important;
  }
  .m-xl-21 {
    margin: 5.25rem !important;
  }
  .mt-xl-21 {
    margin-block-start: 5.25rem !important;
  }
  .mb-xl-21 {
    margin-block-end: 5.25rem !important;
  }
  .my-xl-21 {
    margin-block: 5.25rem !important;
  }
  .ms-xl-21 {
    margin-inline-start: 5.25rem !important;
  }
  .me-xl-21 {
    margin-inline-end: 5.25rem !important;
  }
  .mx-xl-21 {
    margin-inline: 5.25rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-22 {
    gap: 5.5rem !important;
  }
  .p-xl-22 {
    padding: 5.5rem !important;
  }
  .pt-xl-22 {
    padding-block-start: 5.5rem !important;
  }
  .pb-xl-22 {
    padding-block-end: 5.5rem !important;
  }
  .py-xl-22 {
    padding-block: 5.5rem !important;
  }
  .ps-xl-22 {
    padding-inline-start: 5.5rem !important;
  }
  .pe-xl-22 {
    padding-inline-end: 5.5rem !important;
  }
  .px-xl-22 {
    padding-inline: 5.5rem !important;
  }
  .m-xl-22 {
    margin: 5.5rem !important;
  }
  .mt-xl-22 {
    margin-block-start: 5.5rem !important;
  }
  .mb-xl-22 {
    margin-block-end: 5.5rem !important;
  }
  .my-xl-22 {
    margin-block: 5.5rem !important;
  }
  .ms-xl-22 {
    margin-inline-start: 5.5rem !important;
  }
  .me-xl-22 {
    margin-inline-end: 5.5rem !important;
  }
  .mx-xl-22 {
    margin-inline: 5.5rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-23 {
    gap: 5.75rem !important;
  }
  .p-xl-23 {
    padding: 5.75rem !important;
  }
  .pt-xl-23 {
    padding-block-start: 5.75rem !important;
  }
  .pb-xl-23 {
    padding-block-end: 5.75rem !important;
  }
  .py-xl-23 {
    padding-block: 5.75rem !important;
  }
  .ps-xl-23 {
    padding-inline-start: 5.75rem !important;
  }
  .pe-xl-23 {
    padding-inline-end: 5.75rem !important;
  }
  .px-xl-23 {
    padding-inline: 5.75rem !important;
  }
  .m-xl-23 {
    margin: 5.75rem !important;
  }
  .mt-xl-23 {
    margin-block-start: 5.75rem !important;
  }
  .mb-xl-23 {
    margin-block-end: 5.75rem !important;
  }
  .my-xl-23 {
    margin-block: 5.75rem !important;
  }
  .ms-xl-23 {
    margin-inline-start: 5.75rem !important;
  }
  .me-xl-23 {
    margin-inline-end: 5.75rem !important;
  }
  .mx-xl-23 {
    margin-inline: 5.75rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-24 {
    gap: 6rem !important;
  }
  .p-xl-24 {
    padding: 6rem !important;
  }
  .pt-xl-24 {
    padding-block-start: 6rem !important;
  }
  .pb-xl-24 {
    padding-block-end: 6rem !important;
  }
  .py-xl-24 {
    padding-block: 6rem !important;
  }
  .ps-xl-24 {
    padding-inline-start: 6rem !important;
  }
  .pe-xl-24 {
    padding-inline-end: 6rem !important;
  }
  .px-xl-24 {
    padding-inline: 6rem !important;
  }
  .m-xl-24 {
    margin: 6rem !important;
  }
  .mt-xl-24 {
    margin-block-start: 6rem !important;
  }
  .mb-xl-24 {
    margin-block-end: 6rem !important;
  }
  .my-xl-24 {
    margin-block: 6rem !important;
  }
  .ms-xl-24 {
    margin-inline-start: 6rem !important;
  }
  .me-xl-24 {
    margin-inline-end: 6rem !important;
  }
  .mx-xl-24 {
    margin-inline: 6rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-25 {
    gap: 6.25rem !important;
  }
  .p-xl-25 {
    padding: 6.25rem !important;
  }
  .pt-xl-25 {
    padding-block-start: 6.25rem !important;
  }
  .pb-xl-25 {
    padding-block-end: 6.25rem !important;
  }
  .py-xl-25 {
    padding-block: 6.25rem !important;
  }
  .ps-xl-25 {
    padding-inline-start: 6.25rem !important;
  }
  .pe-xl-25 {
    padding-inline-end: 6.25rem !important;
  }
  .px-xl-25 {
    padding-inline: 6.25rem !important;
  }
  .m-xl-25 {
    margin: 6.25rem !important;
  }
  .mt-xl-25 {
    margin-block-start: 6.25rem !important;
  }
  .mb-xl-25 {
    margin-block-end: 6.25rem !important;
  }
  .my-xl-25 {
    margin-block: 6.25rem !important;
  }
  .ms-xl-25 {
    margin-inline-start: 6.25rem !important;
  }
  .me-xl-25 {
    margin-inline-end: 6.25rem !important;
  }
  .mx-xl-25 {
    margin-inline: 6.25rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-26 {
    gap: 6.5rem !important;
  }
  .p-xl-26 {
    padding: 6.5rem !important;
  }
  .pt-xl-26 {
    padding-block-start: 6.5rem !important;
  }
  .pb-xl-26 {
    padding-block-end: 6.5rem !important;
  }
  .py-xl-26 {
    padding-block: 6.5rem !important;
  }
  .ps-xl-26 {
    padding-inline-start: 6.5rem !important;
  }
  .pe-xl-26 {
    padding-inline-end: 6.5rem !important;
  }
  .px-xl-26 {
    padding-inline: 6.5rem !important;
  }
  .m-xl-26 {
    margin: 6.5rem !important;
  }
  .mt-xl-26 {
    margin-block-start: 6.5rem !important;
  }
  .mb-xl-26 {
    margin-block-end: 6.5rem !important;
  }
  .my-xl-26 {
    margin-block: 6.5rem !important;
  }
  .ms-xl-26 {
    margin-inline-start: 6.5rem !important;
  }
  .me-xl-26 {
    margin-inline-end: 6.5rem !important;
  }
  .mx-xl-26 {
    margin-inline: 6.5rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-27 {
    gap: 6.75rem !important;
  }
  .p-xl-27 {
    padding: 6.75rem !important;
  }
  .pt-xl-27 {
    padding-block-start: 6.75rem !important;
  }
  .pb-xl-27 {
    padding-block-end: 6.75rem !important;
  }
  .py-xl-27 {
    padding-block: 6.75rem !important;
  }
  .ps-xl-27 {
    padding-inline-start: 6.75rem !important;
  }
  .pe-xl-27 {
    padding-inline-end: 6.75rem !important;
  }
  .px-xl-27 {
    padding-inline: 6.75rem !important;
  }
  .m-xl-27 {
    margin: 6.75rem !important;
  }
  .mt-xl-27 {
    margin-block-start: 6.75rem !important;
  }
  .mb-xl-27 {
    margin-block-end: 6.75rem !important;
  }
  .my-xl-27 {
    margin-block: 6.75rem !important;
  }
  .ms-xl-27 {
    margin-inline-start: 6.75rem !important;
  }
  .me-xl-27 {
    margin-inline-end: 6.75rem !important;
  }
  .mx-xl-27 {
    margin-inline: 6.75rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-28 {
    gap: 7rem !important;
  }
  .p-xl-28 {
    padding: 7rem !important;
  }
  .pt-xl-28 {
    padding-block-start: 7rem !important;
  }
  .pb-xl-28 {
    padding-block-end: 7rem !important;
  }
  .py-xl-28 {
    padding-block: 7rem !important;
  }
  .ps-xl-28 {
    padding-inline-start: 7rem !important;
  }
  .pe-xl-28 {
    padding-inline-end: 7rem !important;
  }
  .px-xl-28 {
    padding-inline: 7rem !important;
  }
  .m-xl-28 {
    margin: 7rem !important;
  }
  .mt-xl-28 {
    margin-block-start: 7rem !important;
  }
  .mb-xl-28 {
    margin-block-end: 7rem !important;
  }
  .my-xl-28 {
    margin-block: 7rem !important;
  }
  .ms-xl-28 {
    margin-inline-start: 7rem !important;
  }
  .me-xl-28 {
    margin-inline-end: 7rem !important;
  }
  .mx-xl-28 {
    margin-inline: 7rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-29 {
    gap: 7.25rem !important;
  }
  .p-xl-29 {
    padding: 7.25rem !important;
  }
  .pt-xl-29 {
    padding-block-start: 7.25rem !important;
  }
  .pb-xl-29 {
    padding-block-end: 7.25rem !important;
  }
  .py-xl-29 {
    padding-block: 7.25rem !important;
  }
  .ps-xl-29 {
    padding-inline-start: 7.25rem !important;
  }
  .pe-xl-29 {
    padding-inline-end: 7.25rem !important;
  }
  .px-xl-29 {
    padding-inline: 7.25rem !important;
  }
  .m-xl-29 {
    margin: 7.25rem !important;
  }
  .mt-xl-29 {
    margin-block-start: 7.25rem !important;
  }
  .mb-xl-29 {
    margin-block-end: 7.25rem !important;
  }
  .my-xl-29 {
    margin-block: 7.25rem !important;
  }
  .ms-xl-29 {
    margin-inline-start: 7.25rem !important;
  }
  .me-xl-29 {
    margin-inline-end: 7.25rem !important;
  }
  .mx-xl-29 {
    margin-inline: 7.25rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-30 {
    gap: 7.5rem !important;
  }
  .p-xl-30 {
    padding: 7.5rem !important;
  }
  .pt-xl-30 {
    padding-block-start: 7.5rem !important;
  }
  .pb-xl-30 {
    padding-block-end: 7.5rem !important;
  }
  .py-xl-30 {
    padding-block: 7.5rem !important;
  }
  .ps-xl-30 {
    padding-inline-start: 7.5rem !important;
  }
  .pe-xl-30 {
    padding-inline-end: 7.5rem !important;
  }
  .px-xl-30 {
    padding-inline: 7.5rem !important;
  }
  .m-xl-30 {
    margin: 7.5rem !important;
  }
  .mt-xl-30 {
    margin-block-start: 7.5rem !important;
  }
  .mb-xl-30 {
    margin-block-end: 7.5rem !important;
  }
  .my-xl-30 {
    margin-block: 7.5rem !important;
  }
  .ms-xl-30 {
    margin-inline-start: 7.5rem !important;
  }
  .me-xl-30 {
    margin-inline-end: 7.5rem !important;
  }
  .mx-xl-30 {
    margin-inline: 7.5rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0 {
    padding-block-start: 0 !important;
  }
  .pb-xxl-0 {
    padding-block-end: 0 !important;
  }
  .py-xxl-0 {
    padding-block: 0 !important;
  }
  .ps-xxl-0 {
    padding-inline-start: 0 !important;
  }
  .pe-xxl-0 {
    padding-inline-end: 0 !important;
  }
  .px-xxl-0 {
    padding-inline: 0 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0 {
    margin-block-start: 0 !important;
  }
  .mb-xxl-0 {
    margin-block-end: 0 !important;
  }
  .my-xxl-0 {
    margin-block: 0 !important;
  }
  .ms-xxl-0 {
    margin-inline-start: 0 !important;
  }
  .me-xxl-0 {
    margin-inline-end: 0 !important;
  }
  .mx-xxl-0 {
    margin-inline: 0 !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1 {
    padding-block-start: 0.25rem !important;
  }
  .pb-xxl-1 {
    padding-block-end: 0.25rem !important;
  }
  .py-xxl-1 {
    padding-block: 0.25rem !important;
  }
  .ps-xxl-1 {
    padding-inline-start: 0.25rem !important;
  }
  .pe-xxl-1 {
    padding-inline-end: 0.25rem !important;
  }
  .px-xxl-1 {
    padding-inline: 0.25rem !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1 {
    margin-block-start: 0.25rem !important;
  }
  .mb-xxl-1 {
    margin-block-end: 0.25rem !important;
  }
  .my-xxl-1 {
    margin-block: 0.25rem !important;
  }
  .ms-xxl-1 {
    margin-inline-start: 0.25rem !important;
  }
  .me-xxl-1 {
    margin-inline-end: 0.25rem !important;
  }
  .mx-xxl-1 {
    margin-inline: 0.25rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2 {
    padding-block-start: 0.5rem !important;
  }
  .pb-xxl-2 {
    padding-block-end: 0.5rem !important;
  }
  .py-xxl-2 {
    padding-block: 0.5rem !important;
  }
  .ps-xxl-2 {
    padding-inline-start: 0.5rem !important;
  }
  .pe-xxl-2 {
    padding-inline-end: 0.5rem !important;
  }
  .px-xxl-2 {
    padding-inline: 0.5rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2 {
    margin-block-start: 0.5rem !important;
  }
  .mb-xxl-2 {
    margin-block-end: 0.5rem !important;
  }
  .my-xxl-2 {
    margin-block: 0.5rem !important;
  }
  .ms-xxl-2 {
    margin-inline-start: 0.5rem !important;
  }
  .me-xxl-2 {
    margin-inline-end: 0.5rem !important;
  }
  .mx-xxl-2 {
    margin-inline: 0.5rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-3 {
    gap: 0.75rem !important;
  }
  .p-xxl-3 {
    padding: 0.75rem !important;
  }
  .pt-xxl-3 {
    padding-block-start: 0.75rem !important;
  }
  .pb-xxl-3 {
    padding-block-end: 0.75rem !important;
  }
  .py-xxl-3 {
    padding-block: 0.75rem !important;
  }
  .ps-xxl-3 {
    padding-inline-start: 0.75rem !important;
  }
  .pe-xxl-3 {
    padding-inline-end: 0.75rem !important;
  }
  .px-xxl-3 {
    padding-inline: 0.75rem !important;
  }
  .m-xxl-3 {
    margin: 0.75rem !important;
  }
  .mt-xxl-3 {
    margin-block-start: 0.75rem !important;
  }
  .mb-xxl-3 {
    margin-block-end: 0.75rem !important;
  }
  .my-xxl-3 {
    margin-block: 0.75rem !important;
  }
  .ms-xxl-3 {
    margin-inline-start: 0.75rem !important;
  }
  .me-xxl-3 {
    margin-inline-end: 0.75rem !important;
  }
  .mx-xxl-3 {
    margin-inline: 0.75rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-4 {
    gap: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1rem !important;
  }
  .pt-xxl-4 {
    padding-block-start: 1rem !important;
  }
  .pb-xxl-4 {
    padding-block-end: 1rem !important;
  }
  .py-xxl-4 {
    padding-block: 1rem !important;
  }
  .ps-xxl-4 {
    padding-inline-start: 1rem !important;
  }
  .pe-xxl-4 {
    padding-inline-end: 1rem !important;
  }
  .px-xxl-4 {
    padding-inline: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1rem !important;
  }
  .mt-xxl-4 {
    margin-block-start: 1rem !important;
  }
  .mb-xxl-4 {
    margin-block-end: 1rem !important;
  }
  .my-xxl-4 {
    margin-block: 1rem !important;
  }
  .ms-xxl-4 {
    margin-inline-start: 1rem !important;
  }
  .me-xxl-4 {
    margin-inline-end: 1rem !important;
  }
  .mx-xxl-4 {
    margin-inline: 1rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-5 {
    gap: 1.25rem !important;
  }
  .p-xxl-5 {
    padding: 1.25rem !important;
  }
  .pt-xxl-5 {
    padding-block-start: 1.25rem !important;
  }
  .pb-xxl-5 {
    padding-block-end: 1.25rem !important;
  }
  .py-xxl-5 {
    padding-block: 1.25rem !important;
  }
  .ps-xxl-5 {
    padding-inline-start: 1.25rem !important;
  }
  .pe-xxl-5 {
    padding-inline-end: 1.25rem !important;
  }
  .px-xxl-5 {
    padding-inline: 1.25rem !important;
  }
  .m-xxl-5 {
    margin: 1.25rem !important;
  }
  .mt-xxl-5 {
    margin-block-start: 1.25rem !important;
  }
  .mb-xxl-5 {
    margin-block-end: 1.25rem !important;
  }
  .my-xxl-5 {
    margin-block: 1.25rem !important;
  }
  .ms-xxl-5 {
    margin-inline-start: 1.25rem !important;
  }
  .me-xxl-5 {
    margin-inline-end: 1.25rem !important;
  }
  .mx-xxl-5 {
    margin-inline: 1.25rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-6 {
    gap: 1.5rem !important;
  }
  .p-xxl-6 {
    padding: 1.5rem !important;
  }
  .pt-xxl-6 {
    padding-block-start: 1.5rem !important;
  }
  .pb-xxl-6 {
    padding-block-end: 1.5rem !important;
  }
  .py-xxl-6 {
    padding-block: 1.5rem !important;
  }
  .ps-xxl-6 {
    padding-inline-start: 1.5rem !important;
  }
  .pe-xxl-6 {
    padding-inline-end: 1.5rem !important;
  }
  .px-xxl-6 {
    padding-inline: 1.5rem !important;
  }
  .m-xxl-6 {
    margin: 1.5rem !important;
  }
  .mt-xxl-6 {
    margin-block-start: 1.5rem !important;
  }
  .mb-xxl-6 {
    margin-block-end: 1.5rem !important;
  }
  .my-xxl-6 {
    margin-block: 1.5rem !important;
  }
  .ms-xxl-6 {
    margin-inline-start: 1.5rem !important;
  }
  .me-xxl-6 {
    margin-inline-end: 1.5rem !important;
  }
  .mx-xxl-6 {
    margin-inline: 1.5rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-7 {
    gap: 1.75rem !important;
  }
  .p-xxl-7 {
    padding: 1.75rem !important;
  }
  .pt-xxl-7 {
    padding-block-start: 1.75rem !important;
  }
  .pb-xxl-7 {
    padding-block-end: 1.75rem !important;
  }
  .py-xxl-7 {
    padding-block: 1.75rem !important;
  }
  .ps-xxl-7 {
    padding-inline-start: 1.75rem !important;
  }
  .pe-xxl-7 {
    padding-inline-end: 1.75rem !important;
  }
  .px-xxl-7 {
    padding-inline: 1.75rem !important;
  }
  .m-xxl-7 {
    margin: 1.75rem !important;
  }
  .mt-xxl-7 {
    margin-block-start: 1.75rem !important;
  }
  .mb-xxl-7 {
    margin-block-end: 1.75rem !important;
  }
  .my-xxl-7 {
    margin-block: 1.75rem !important;
  }
  .ms-xxl-7 {
    margin-inline-start: 1.75rem !important;
  }
  .me-xxl-7 {
    margin-inline-end: 1.75rem !important;
  }
  .mx-xxl-7 {
    margin-inline: 1.75rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-8 {
    gap: 2rem !important;
  }
  .p-xxl-8 {
    padding: 2rem !important;
  }
  .pt-xxl-8 {
    padding-block-start: 2rem !important;
  }
  .pb-xxl-8 {
    padding-block-end: 2rem !important;
  }
  .py-xxl-8 {
    padding-block: 2rem !important;
  }
  .ps-xxl-8 {
    padding-inline-start: 2rem !important;
  }
  .pe-xxl-8 {
    padding-inline-end: 2rem !important;
  }
  .px-xxl-8 {
    padding-inline: 2rem !important;
  }
  .m-xxl-8 {
    margin: 2rem !important;
  }
  .mt-xxl-8 {
    margin-block-start: 2rem !important;
  }
  .mb-xxl-8 {
    margin-block-end: 2rem !important;
  }
  .my-xxl-8 {
    margin-block: 2rem !important;
  }
  .ms-xxl-8 {
    margin-inline-start: 2rem !important;
  }
  .me-xxl-8 {
    margin-inline-end: 2rem !important;
  }
  .mx-xxl-8 {
    margin-inline: 2rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-9 {
    gap: 2.25rem !important;
  }
  .p-xxl-9 {
    padding: 2.25rem !important;
  }
  .pt-xxl-9 {
    padding-block-start: 2.25rem !important;
  }
  .pb-xxl-9 {
    padding-block-end: 2.25rem !important;
  }
  .py-xxl-9 {
    padding-block: 2.25rem !important;
  }
  .ps-xxl-9 {
    padding-inline-start: 2.25rem !important;
  }
  .pe-xxl-9 {
    padding-inline-end: 2.25rem !important;
  }
  .px-xxl-9 {
    padding-inline: 2.25rem !important;
  }
  .m-xxl-9 {
    margin: 2.25rem !important;
  }
  .mt-xxl-9 {
    margin-block-start: 2.25rem !important;
  }
  .mb-xxl-9 {
    margin-block-end: 2.25rem !important;
  }
  .my-xxl-9 {
    margin-block: 2.25rem !important;
  }
  .ms-xxl-9 {
    margin-inline-start: 2.25rem !important;
  }
  .me-xxl-9 {
    margin-inline-end: 2.25rem !important;
  }
  .mx-xxl-9 {
    margin-inline: 2.25rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-10 {
    gap: 2.5rem !important;
  }
  .p-xxl-10 {
    padding: 2.5rem !important;
  }
  .pt-xxl-10 {
    padding-block-start: 2.5rem !important;
  }
  .pb-xxl-10 {
    padding-block-end: 2.5rem !important;
  }
  .py-xxl-10 {
    padding-block: 2.5rem !important;
  }
  .ps-xxl-10 {
    padding-inline-start: 2.5rem !important;
  }
  .pe-xxl-10 {
    padding-inline-end: 2.5rem !important;
  }
  .px-xxl-10 {
    padding-inline: 2.5rem !important;
  }
  .m-xxl-10 {
    margin: 2.5rem !important;
  }
  .mt-xxl-10 {
    margin-block-start: 2.5rem !important;
  }
  .mb-xxl-10 {
    margin-block-end: 2.5rem !important;
  }
  .my-xxl-10 {
    margin-block: 2.5rem !important;
  }
  .ms-xxl-10 {
    margin-inline-start: 2.5rem !important;
  }
  .me-xxl-10 {
    margin-inline-end: 2.5rem !important;
  }
  .mx-xxl-10 {
    margin-inline: 2.5rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-11 {
    gap: 2.75rem !important;
  }
  .p-xxl-11 {
    padding: 2.75rem !important;
  }
  .pt-xxl-11 {
    padding-block-start: 2.75rem !important;
  }
  .pb-xxl-11 {
    padding-block-end: 2.75rem !important;
  }
  .py-xxl-11 {
    padding-block: 2.75rem !important;
  }
  .ps-xxl-11 {
    padding-inline-start: 2.75rem !important;
  }
  .pe-xxl-11 {
    padding-inline-end: 2.75rem !important;
  }
  .px-xxl-11 {
    padding-inline: 2.75rem !important;
  }
  .m-xxl-11 {
    margin: 2.75rem !important;
  }
  .mt-xxl-11 {
    margin-block-start: 2.75rem !important;
  }
  .mb-xxl-11 {
    margin-block-end: 2.75rem !important;
  }
  .my-xxl-11 {
    margin-block: 2.75rem !important;
  }
  .ms-xxl-11 {
    margin-inline-start: 2.75rem !important;
  }
  .me-xxl-11 {
    margin-inline-end: 2.75rem !important;
  }
  .mx-xxl-11 {
    margin-inline: 2.75rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-12 {
    gap: 3rem !important;
  }
  .p-xxl-12 {
    padding: 3rem !important;
  }
  .pt-xxl-12 {
    padding-block-start: 3rem !important;
  }
  .pb-xxl-12 {
    padding-block-end: 3rem !important;
  }
  .py-xxl-12 {
    padding-block: 3rem !important;
  }
  .ps-xxl-12 {
    padding-inline-start: 3rem !important;
  }
  .pe-xxl-12 {
    padding-inline-end: 3rem !important;
  }
  .px-xxl-12 {
    padding-inline: 3rem !important;
  }
  .m-xxl-12 {
    margin: 3rem !important;
  }
  .mt-xxl-12 {
    margin-block-start: 3rem !important;
  }
  .mb-xxl-12 {
    margin-block-end: 3rem !important;
  }
  .my-xxl-12 {
    margin-block: 3rem !important;
  }
  .ms-xxl-12 {
    margin-inline-start: 3rem !important;
  }
  .me-xxl-12 {
    margin-inline-end: 3rem !important;
  }
  .mx-xxl-12 {
    margin-inline: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-13 {
    gap: 3.25rem !important;
  }
  .p-xxl-13 {
    padding: 3.25rem !important;
  }
  .pt-xxl-13 {
    padding-block-start: 3.25rem !important;
  }
  .pb-xxl-13 {
    padding-block-end: 3.25rem !important;
  }
  .py-xxl-13 {
    padding-block: 3.25rem !important;
  }
  .ps-xxl-13 {
    padding-inline-start: 3.25rem !important;
  }
  .pe-xxl-13 {
    padding-inline-end: 3.25rem !important;
  }
  .px-xxl-13 {
    padding-inline: 3.25rem !important;
  }
  .m-xxl-13 {
    margin: 3.25rem !important;
  }
  .mt-xxl-13 {
    margin-block-start: 3.25rem !important;
  }
  .mb-xxl-13 {
    margin-block-end: 3.25rem !important;
  }
  .my-xxl-13 {
    margin-block: 3.25rem !important;
  }
  .ms-xxl-13 {
    margin-inline-start: 3.25rem !important;
  }
  .me-xxl-13 {
    margin-inline-end: 3.25rem !important;
  }
  .mx-xxl-13 {
    margin-inline: 3.25rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-14 {
    gap: 3.5rem !important;
  }
  .p-xxl-14 {
    padding: 3.5rem !important;
  }
  .pt-xxl-14 {
    padding-block-start: 3.5rem !important;
  }
  .pb-xxl-14 {
    padding-block-end: 3.5rem !important;
  }
  .py-xxl-14 {
    padding-block: 3.5rem !important;
  }
  .ps-xxl-14 {
    padding-inline-start: 3.5rem !important;
  }
  .pe-xxl-14 {
    padding-inline-end: 3.5rem !important;
  }
  .px-xxl-14 {
    padding-inline: 3.5rem !important;
  }
  .m-xxl-14 {
    margin: 3.5rem !important;
  }
  .mt-xxl-14 {
    margin-block-start: 3.5rem !important;
  }
  .mb-xxl-14 {
    margin-block-end: 3.5rem !important;
  }
  .my-xxl-14 {
    margin-block: 3.5rem !important;
  }
  .ms-xxl-14 {
    margin-inline-start: 3.5rem !important;
  }
  .me-xxl-14 {
    margin-inline-end: 3.5rem !important;
  }
  .mx-xxl-14 {
    margin-inline: 3.5rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-15 {
    gap: 3.75rem !important;
  }
  .p-xxl-15 {
    padding: 3.75rem !important;
  }
  .pt-xxl-15 {
    padding-block-start: 3.75rem !important;
  }
  .pb-xxl-15 {
    padding-block-end: 3.75rem !important;
  }
  .py-xxl-15 {
    padding-block: 3.75rem !important;
  }
  .ps-xxl-15 {
    padding-inline-start: 3.75rem !important;
  }
  .pe-xxl-15 {
    padding-inline-end: 3.75rem !important;
  }
  .px-xxl-15 {
    padding-inline: 3.75rem !important;
  }
  .m-xxl-15 {
    margin: 3.75rem !important;
  }
  .mt-xxl-15 {
    margin-block-start: 3.75rem !important;
  }
  .mb-xxl-15 {
    margin-block-end: 3.75rem !important;
  }
  .my-xxl-15 {
    margin-block: 3.75rem !important;
  }
  .ms-xxl-15 {
    margin-inline-start: 3.75rem !important;
  }
  .me-xxl-15 {
    margin-inline-end: 3.75rem !important;
  }
  .mx-xxl-15 {
    margin-inline: 3.75rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-16 {
    gap: 4rem !important;
  }
  .p-xxl-16 {
    padding: 4rem !important;
  }
  .pt-xxl-16 {
    padding-block-start: 4rem !important;
  }
  .pb-xxl-16 {
    padding-block-end: 4rem !important;
  }
  .py-xxl-16 {
    padding-block: 4rem !important;
  }
  .ps-xxl-16 {
    padding-inline-start: 4rem !important;
  }
  .pe-xxl-16 {
    padding-inline-end: 4rem !important;
  }
  .px-xxl-16 {
    padding-inline: 4rem !important;
  }
  .m-xxl-16 {
    margin: 4rem !important;
  }
  .mt-xxl-16 {
    margin-block-start: 4rem !important;
  }
  .mb-xxl-16 {
    margin-block-end: 4rem !important;
  }
  .my-xxl-16 {
    margin-block: 4rem !important;
  }
  .ms-xxl-16 {
    margin-inline-start: 4rem !important;
  }
  .me-xxl-16 {
    margin-inline-end: 4rem !important;
  }
  .mx-xxl-16 {
    margin-inline: 4rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-17 {
    gap: 4.25rem !important;
  }
  .p-xxl-17 {
    padding: 4.25rem !important;
  }
  .pt-xxl-17 {
    padding-block-start: 4.25rem !important;
  }
  .pb-xxl-17 {
    padding-block-end: 4.25rem !important;
  }
  .py-xxl-17 {
    padding-block: 4.25rem !important;
  }
  .ps-xxl-17 {
    padding-inline-start: 4.25rem !important;
  }
  .pe-xxl-17 {
    padding-inline-end: 4.25rem !important;
  }
  .px-xxl-17 {
    padding-inline: 4.25rem !important;
  }
  .m-xxl-17 {
    margin: 4.25rem !important;
  }
  .mt-xxl-17 {
    margin-block-start: 4.25rem !important;
  }
  .mb-xxl-17 {
    margin-block-end: 4.25rem !important;
  }
  .my-xxl-17 {
    margin-block: 4.25rem !important;
  }
  .ms-xxl-17 {
    margin-inline-start: 4.25rem !important;
  }
  .me-xxl-17 {
    margin-inline-end: 4.25rem !important;
  }
  .mx-xxl-17 {
    margin-inline: 4.25rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-18 {
    gap: 4.5rem !important;
  }
  .p-xxl-18 {
    padding: 4.5rem !important;
  }
  .pt-xxl-18 {
    padding-block-start: 4.5rem !important;
  }
  .pb-xxl-18 {
    padding-block-end: 4.5rem !important;
  }
  .py-xxl-18 {
    padding-block: 4.5rem !important;
  }
  .ps-xxl-18 {
    padding-inline-start: 4.5rem !important;
  }
  .pe-xxl-18 {
    padding-inline-end: 4.5rem !important;
  }
  .px-xxl-18 {
    padding-inline: 4.5rem !important;
  }
  .m-xxl-18 {
    margin: 4.5rem !important;
  }
  .mt-xxl-18 {
    margin-block-start: 4.5rem !important;
  }
  .mb-xxl-18 {
    margin-block-end: 4.5rem !important;
  }
  .my-xxl-18 {
    margin-block: 4.5rem !important;
  }
  .ms-xxl-18 {
    margin-inline-start: 4.5rem !important;
  }
  .me-xxl-18 {
    margin-inline-end: 4.5rem !important;
  }
  .mx-xxl-18 {
    margin-inline: 4.5rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-19 {
    gap: 4.75rem !important;
  }
  .p-xxl-19 {
    padding: 4.75rem !important;
  }
  .pt-xxl-19 {
    padding-block-start: 4.75rem !important;
  }
  .pb-xxl-19 {
    padding-block-end: 4.75rem !important;
  }
  .py-xxl-19 {
    padding-block: 4.75rem !important;
  }
  .ps-xxl-19 {
    padding-inline-start: 4.75rem !important;
  }
  .pe-xxl-19 {
    padding-inline-end: 4.75rem !important;
  }
  .px-xxl-19 {
    padding-inline: 4.75rem !important;
  }
  .m-xxl-19 {
    margin: 4.75rem !important;
  }
  .mt-xxl-19 {
    margin-block-start: 4.75rem !important;
  }
  .mb-xxl-19 {
    margin-block-end: 4.75rem !important;
  }
  .my-xxl-19 {
    margin-block: 4.75rem !important;
  }
  .ms-xxl-19 {
    margin-inline-start: 4.75rem !important;
  }
  .me-xxl-19 {
    margin-inline-end: 4.75rem !important;
  }
  .mx-xxl-19 {
    margin-inline: 4.75rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-20 {
    gap: 5rem !important;
  }
  .p-xxl-20 {
    padding: 5rem !important;
  }
  .pt-xxl-20 {
    padding-block-start: 5rem !important;
  }
  .pb-xxl-20 {
    padding-block-end: 5rem !important;
  }
  .py-xxl-20 {
    padding-block: 5rem !important;
  }
  .ps-xxl-20 {
    padding-inline-start: 5rem !important;
  }
  .pe-xxl-20 {
    padding-inline-end: 5rem !important;
  }
  .px-xxl-20 {
    padding-inline: 5rem !important;
  }
  .m-xxl-20 {
    margin: 5rem !important;
  }
  .mt-xxl-20 {
    margin-block-start: 5rem !important;
  }
  .mb-xxl-20 {
    margin-block-end: 5rem !important;
  }
  .my-xxl-20 {
    margin-block: 5rem !important;
  }
  .ms-xxl-20 {
    margin-inline-start: 5rem !important;
  }
  .me-xxl-20 {
    margin-inline-end: 5rem !important;
  }
  .mx-xxl-20 {
    margin-inline: 5rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-21 {
    gap: 5.25rem !important;
  }
  .p-xxl-21 {
    padding: 5.25rem !important;
  }
  .pt-xxl-21 {
    padding-block-start: 5.25rem !important;
  }
  .pb-xxl-21 {
    padding-block-end: 5.25rem !important;
  }
  .py-xxl-21 {
    padding-block: 5.25rem !important;
  }
  .ps-xxl-21 {
    padding-inline-start: 5.25rem !important;
  }
  .pe-xxl-21 {
    padding-inline-end: 5.25rem !important;
  }
  .px-xxl-21 {
    padding-inline: 5.25rem !important;
  }
  .m-xxl-21 {
    margin: 5.25rem !important;
  }
  .mt-xxl-21 {
    margin-block-start: 5.25rem !important;
  }
  .mb-xxl-21 {
    margin-block-end: 5.25rem !important;
  }
  .my-xxl-21 {
    margin-block: 5.25rem !important;
  }
  .ms-xxl-21 {
    margin-inline-start: 5.25rem !important;
  }
  .me-xxl-21 {
    margin-inline-end: 5.25rem !important;
  }
  .mx-xxl-21 {
    margin-inline: 5.25rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-22 {
    gap: 5.5rem !important;
  }
  .p-xxl-22 {
    padding: 5.5rem !important;
  }
  .pt-xxl-22 {
    padding-block-start: 5.5rem !important;
  }
  .pb-xxl-22 {
    padding-block-end: 5.5rem !important;
  }
  .py-xxl-22 {
    padding-block: 5.5rem !important;
  }
  .ps-xxl-22 {
    padding-inline-start: 5.5rem !important;
  }
  .pe-xxl-22 {
    padding-inline-end: 5.5rem !important;
  }
  .px-xxl-22 {
    padding-inline: 5.5rem !important;
  }
  .m-xxl-22 {
    margin: 5.5rem !important;
  }
  .mt-xxl-22 {
    margin-block-start: 5.5rem !important;
  }
  .mb-xxl-22 {
    margin-block-end: 5.5rem !important;
  }
  .my-xxl-22 {
    margin-block: 5.5rem !important;
  }
  .ms-xxl-22 {
    margin-inline-start: 5.5rem !important;
  }
  .me-xxl-22 {
    margin-inline-end: 5.5rem !important;
  }
  .mx-xxl-22 {
    margin-inline: 5.5rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-23 {
    gap: 5.75rem !important;
  }
  .p-xxl-23 {
    padding: 5.75rem !important;
  }
  .pt-xxl-23 {
    padding-block-start: 5.75rem !important;
  }
  .pb-xxl-23 {
    padding-block-end: 5.75rem !important;
  }
  .py-xxl-23 {
    padding-block: 5.75rem !important;
  }
  .ps-xxl-23 {
    padding-inline-start: 5.75rem !important;
  }
  .pe-xxl-23 {
    padding-inline-end: 5.75rem !important;
  }
  .px-xxl-23 {
    padding-inline: 5.75rem !important;
  }
  .m-xxl-23 {
    margin: 5.75rem !important;
  }
  .mt-xxl-23 {
    margin-block-start: 5.75rem !important;
  }
  .mb-xxl-23 {
    margin-block-end: 5.75rem !important;
  }
  .my-xxl-23 {
    margin-block: 5.75rem !important;
  }
  .ms-xxl-23 {
    margin-inline-start: 5.75rem !important;
  }
  .me-xxl-23 {
    margin-inline-end: 5.75rem !important;
  }
  .mx-xxl-23 {
    margin-inline: 5.75rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-24 {
    gap: 6rem !important;
  }
  .p-xxl-24 {
    padding: 6rem !important;
  }
  .pt-xxl-24 {
    padding-block-start: 6rem !important;
  }
  .pb-xxl-24 {
    padding-block-end: 6rem !important;
  }
  .py-xxl-24 {
    padding-block: 6rem !important;
  }
  .ps-xxl-24 {
    padding-inline-start: 6rem !important;
  }
  .pe-xxl-24 {
    padding-inline-end: 6rem !important;
  }
  .px-xxl-24 {
    padding-inline: 6rem !important;
  }
  .m-xxl-24 {
    margin: 6rem !important;
  }
  .mt-xxl-24 {
    margin-block-start: 6rem !important;
  }
  .mb-xxl-24 {
    margin-block-end: 6rem !important;
  }
  .my-xxl-24 {
    margin-block: 6rem !important;
  }
  .ms-xxl-24 {
    margin-inline-start: 6rem !important;
  }
  .me-xxl-24 {
    margin-inline-end: 6rem !important;
  }
  .mx-xxl-24 {
    margin-inline: 6rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-25 {
    gap: 6.25rem !important;
  }
  .p-xxl-25 {
    padding: 6.25rem !important;
  }
  .pt-xxl-25 {
    padding-block-start: 6.25rem !important;
  }
  .pb-xxl-25 {
    padding-block-end: 6.25rem !important;
  }
  .py-xxl-25 {
    padding-block: 6.25rem !important;
  }
  .ps-xxl-25 {
    padding-inline-start: 6.25rem !important;
  }
  .pe-xxl-25 {
    padding-inline-end: 6.25rem !important;
  }
  .px-xxl-25 {
    padding-inline: 6.25rem !important;
  }
  .m-xxl-25 {
    margin: 6.25rem !important;
  }
  .mt-xxl-25 {
    margin-block-start: 6.25rem !important;
  }
  .mb-xxl-25 {
    margin-block-end: 6.25rem !important;
  }
  .my-xxl-25 {
    margin-block: 6.25rem !important;
  }
  .ms-xxl-25 {
    margin-inline-start: 6.25rem !important;
  }
  .me-xxl-25 {
    margin-inline-end: 6.25rem !important;
  }
  .mx-xxl-25 {
    margin-inline: 6.25rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-26 {
    gap: 6.5rem !important;
  }
  .p-xxl-26 {
    padding: 6.5rem !important;
  }
  .pt-xxl-26 {
    padding-block-start: 6.5rem !important;
  }
  .pb-xxl-26 {
    padding-block-end: 6.5rem !important;
  }
  .py-xxl-26 {
    padding-block: 6.5rem !important;
  }
  .ps-xxl-26 {
    padding-inline-start: 6.5rem !important;
  }
  .pe-xxl-26 {
    padding-inline-end: 6.5rem !important;
  }
  .px-xxl-26 {
    padding-inline: 6.5rem !important;
  }
  .m-xxl-26 {
    margin: 6.5rem !important;
  }
  .mt-xxl-26 {
    margin-block-start: 6.5rem !important;
  }
  .mb-xxl-26 {
    margin-block-end: 6.5rem !important;
  }
  .my-xxl-26 {
    margin-block: 6.5rem !important;
  }
  .ms-xxl-26 {
    margin-inline-start: 6.5rem !important;
  }
  .me-xxl-26 {
    margin-inline-end: 6.5rem !important;
  }
  .mx-xxl-26 {
    margin-inline: 6.5rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-27 {
    gap: 6.75rem !important;
  }
  .p-xxl-27 {
    padding: 6.75rem !important;
  }
  .pt-xxl-27 {
    padding-block-start: 6.75rem !important;
  }
  .pb-xxl-27 {
    padding-block-end: 6.75rem !important;
  }
  .py-xxl-27 {
    padding-block: 6.75rem !important;
  }
  .ps-xxl-27 {
    padding-inline-start: 6.75rem !important;
  }
  .pe-xxl-27 {
    padding-inline-end: 6.75rem !important;
  }
  .px-xxl-27 {
    padding-inline: 6.75rem !important;
  }
  .m-xxl-27 {
    margin: 6.75rem !important;
  }
  .mt-xxl-27 {
    margin-block-start: 6.75rem !important;
  }
  .mb-xxl-27 {
    margin-block-end: 6.75rem !important;
  }
  .my-xxl-27 {
    margin-block: 6.75rem !important;
  }
  .ms-xxl-27 {
    margin-inline-start: 6.75rem !important;
  }
  .me-xxl-27 {
    margin-inline-end: 6.75rem !important;
  }
  .mx-xxl-27 {
    margin-inline: 6.75rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-28 {
    gap: 7rem !important;
  }
  .p-xxl-28 {
    padding: 7rem !important;
  }
  .pt-xxl-28 {
    padding-block-start: 7rem !important;
  }
  .pb-xxl-28 {
    padding-block-end: 7rem !important;
  }
  .py-xxl-28 {
    padding-block: 7rem !important;
  }
  .ps-xxl-28 {
    padding-inline-start: 7rem !important;
  }
  .pe-xxl-28 {
    padding-inline-end: 7rem !important;
  }
  .px-xxl-28 {
    padding-inline: 7rem !important;
  }
  .m-xxl-28 {
    margin: 7rem !important;
  }
  .mt-xxl-28 {
    margin-block-start: 7rem !important;
  }
  .mb-xxl-28 {
    margin-block-end: 7rem !important;
  }
  .my-xxl-28 {
    margin-block: 7rem !important;
  }
  .ms-xxl-28 {
    margin-inline-start: 7rem !important;
  }
  .me-xxl-28 {
    margin-inline-end: 7rem !important;
  }
  .mx-xxl-28 {
    margin-inline: 7rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-29 {
    gap: 7.25rem !important;
  }
  .p-xxl-29 {
    padding: 7.25rem !important;
  }
  .pt-xxl-29 {
    padding-block-start: 7.25rem !important;
  }
  .pb-xxl-29 {
    padding-block-end: 7.25rem !important;
  }
  .py-xxl-29 {
    padding-block: 7.25rem !important;
  }
  .ps-xxl-29 {
    padding-inline-start: 7.25rem !important;
  }
  .pe-xxl-29 {
    padding-inline-end: 7.25rem !important;
  }
  .px-xxl-29 {
    padding-inline: 7.25rem !important;
  }
  .m-xxl-29 {
    margin: 7.25rem !important;
  }
  .mt-xxl-29 {
    margin-block-start: 7.25rem !important;
  }
  .mb-xxl-29 {
    margin-block-end: 7.25rem !important;
  }
  .my-xxl-29 {
    margin-block: 7.25rem !important;
  }
  .ms-xxl-29 {
    margin-inline-start: 7.25rem !important;
  }
  .me-xxl-29 {
    margin-inline-end: 7.25rem !important;
  }
  .mx-xxl-29 {
    margin-inline: 7.25rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-30 {
    gap: 7.5rem !important;
  }
  .p-xxl-30 {
    padding: 7.5rem !important;
  }
  .pt-xxl-30 {
    padding-block-start: 7.5rem !important;
  }
  .pb-xxl-30 {
    padding-block-end: 7.5rem !important;
  }
  .py-xxl-30 {
    padding-block: 7.5rem !important;
  }
  .ps-xxl-30 {
    padding-inline-start: 7.5rem !important;
  }
  .pe-xxl-30 {
    padding-inline-end: 7.5rem !important;
  }
  .px-xxl-30 {
    padding-inline: 7.5rem !important;
  }
  .m-xxl-30 {
    margin: 7.5rem !important;
  }
  .mt-xxl-30 {
    margin-block-start: 7.5rem !important;
  }
  .mb-xxl-30 {
    margin-block-end: 7.5rem !important;
  }
  .my-xxl-30 {
    margin-block: 7.5rem !important;
  }
  .ms-xxl-30 {
    margin-inline-start: 7.5rem !important;
  }
  .me-xxl-30 {
    margin-inline-end: 7.5rem !important;
  }
  .mx-xxl-30 {
    margin-inline: 7.5rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-0 {
    gap: 0 !important;
  }
  .p-exl-0 {
    padding: 0 !important;
  }
  .pt-exl-0 {
    padding-block-start: 0 !important;
  }
  .pb-exl-0 {
    padding-block-end: 0 !important;
  }
  .py-exl-0 {
    padding-block: 0 !important;
  }
  .ps-exl-0 {
    padding-inline-start: 0 !important;
  }
  .pe-exl-0 {
    padding-inline-end: 0 !important;
  }
  .px-exl-0 {
    padding-inline: 0 !important;
  }
  .m-exl-0 {
    margin: 0 !important;
  }
  .mt-exl-0 {
    margin-block-start: 0 !important;
  }
  .mb-exl-0 {
    margin-block-end: 0 !important;
  }
  .my-exl-0 {
    margin-block: 0 !important;
  }
  .ms-exl-0 {
    margin-inline-start: 0 !important;
  }
  .me-exl-0 {
    margin-inline-end: 0 !important;
  }
  .mx-exl-0 {
    margin-inline: 0 !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-1 {
    gap: 0.25rem !important;
  }
  .p-exl-1 {
    padding: 0.25rem !important;
  }
  .pt-exl-1 {
    padding-block-start: 0.25rem !important;
  }
  .pb-exl-1 {
    padding-block-end: 0.25rem !important;
  }
  .py-exl-1 {
    padding-block: 0.25rem !important;
  }
  .ps-exl-1 {
    padding-inline-start: 0.25rem !important;
  }
  .pe-exl-1 {
    padding-inline-end: 0.25rem !important;
  }
  .px-exl-1 {
    padding-inline: 0.25rem !important;
  }
  .m-exl-1 {
    margin: 0.25rem !important;
  }
  .mt-exl-1 {
    margin-block-start: 0.25rem !important;
  }
  .mb-exl-1 {
    margin-block-end: 0.25rem !important;
  }
  .my-exl-1 {
    margin-block: 0.25rem !important;
  }
  .ms-exl-1 {
    margin-inline-start: 0.25rem !important;
  }
  .me-exl-1 {
    margin-inline-end: 0.25rem !important;
  }
  .mx-exl-1 {
    margin-inline: 0.25rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-2 {
    gap: 0.5rem !important;
  }
  .p-exl-2 {
    padding: 0.5rem !important;
  }
  .pt-exl-2 {
    padding-block-start: 0.5rem !important;
  }
  .pb-exl-2 {
    padding-block-end: 0.5rem !important;
  }
  .py-exl-2 {
    padding-block: 0.5rem !important;
  }
  .ps-exl-2 {
    padding-inline-start: 0.5rem !important;
  }
  .pe-exl-2 {
    padding-inline-end: 0.5rem !important;
  }
  .px-exl-2 {
    padding-inline: 0.5rem !important;
  }
  .m-exl-2 {
    margin: 0.5rem !important;
  }
  .mt-exl-2 {
    margin-block-start: 0.5rem !important;
  }
  .mb-exl-2 {
    margin-block-end: 0.5rem !important;
  }
  .my-exl-2 {
    margin-block: 0.5rem !important;
  }
  .ms-exl-2 {
    margin-inline-start: 0.5rem !important;
  }
  .me-exl-2 {
    margin-inline-end: 0.5rem !important;
  }
  .mx-exl-2 {
    margin-inline: 0.5rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-3 {
    gap: 0.75rem !important;
  }
  .p-exl-3 {
    padding: 0.75rem !important;
  }
  .pt-exl-3 {
    padding-block-start: 0.75rem !important;
  }
  .pb-exl-3 {
    padding-block-end: 0.75rem !important;
  }
  .py-exl-3 {
    padding-block: 0.75rem !important;
  }
  .ps-exl-3 {
    padding-inline-start: 0.75rem !important;
  }
  .pe-exl-3 {
    padding-inline-end: 0.75rem !important;
  }
  .px-exl-3 {
    padding-inline: 0.75rem !important;
  }
  .m-exl-3 {
    margin: 0.75rem !important;
  }
  .mt-exl-3 {
    margin-block-start: 0.75rem !important;
  }
  .mb-exl-3 {
    margin-block-end: 0.75rem !important;
  }
  .my-exl-3 {
    margin-block: 0.75rem !important;
  }
  .ms-exl-3 {
    margin-inline-start: 0.75rem !important;
  }
  .me-exl-3 {
    margin-inline-end: 0.75rem !important;
  }
  .mx-exl-3 {
    margin-inline: 0.75rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-4 {
    gap: 1rem !important;
  }
  .p-exl-4 {
    padding: 1rem !important;
  }
  .pt-exl-4 {
    padding-block-start: 1rem !important;
  }
  .pb-exl-4 {
    padding-block-end: 1rem !important;
  }
  .py-exl-4 {
    padding-block: 1rem !important;
  }
  .ps-exl-4 {
    padding-inline-start: 1rem !important;
  }
  .pe-exl-4 {
    padding-inline-end: 1rem !important;
  }
  .px-exl-4 {
    padding-inline: 1rem !important;
  }
  .m-exl-4 {
    margin: 1rem !important;
  }
  .mt-exl-4 {
    margin-block-start: 1rem !important;
  }
  .mb-exl-4 {
    margin-block-end: 1rem !important;
  }
  .my-exl-4 {
    margin-block: 1rem !important;
  }
  .ms-exl-4 {
    margin-inline-start: 1rem !important;
  }
  .me-exl-4 {
    margin-inline-end: 1rem !important;
  }
  .mx-exl-4 {
    margin-inline: 1rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-5 {
    gap: 1.25rem !important;
  }
  .p-exl-5 {
    padding: 1.25rem !important;
  }
  .pt-exl-5 {
    padding-block-start: 1.25rem !important;
  }
  .pb-exl-5 {
    padding-block-end: 1.25rem !important;
  }
  .py-exl-5 {
    padding-block: 1.25rem !important;
  }
  .ps-exl-5 {
    padding-inline-start: 1.25rem !important;
  }
  .pe-exl-5 {
    padding-inline-end: 1.25rem !important;
  }
  .px-exl-5 {
    padding-inline: 1.25rem !important;
  }
  .m-exl-5 {
    margin: 1.25rem !important;
  }
  .mt-exl-5 {
    margin-block-start: 1.25rem !important;
  }
  .mb-exl-5 {
    margin-block-end: 1.25rem !important;
  }
  .my-exl-5 {
    margin-block: 1.25rem !important;
  }
  .ms-exl-5 {
    margin-inline-start: 1.25rem !important;
  }
  .me-exl-5 {
    margin-inline-end: 1.25rem !important;
  }
  .mx-exl-5 {
    margin-inline: 1.25rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-6 {
    gap: 1.5rem !important;
  }
  .p-exl-6 {
    padding: 1.5rem !important;
  }
  .pt-exl-6 {
    padding-block-start: 1.5rem !important;
  }
  .pb-exl-6 {
    padding-block-end: 1.5rem !important;
  }
  .py-exl-6 {
    padding-block: 1.5rem !important;
  }
  .ps-exl-6 {
    padding-inline-start: 1.5rem !important;
  }
  .pe-exl-6 {
    padding-inline-end: 1.5rem !important;
  }
  .px-exl-6 {
    padding-inline: 1.5rem !important;
  }
  .m-exl-6 {
    margin: 1.5rem !important;
  }
  .mt-exl-6 {
    margin-block-start: 1.5rem !important;
  }
  .mb-exl-6 {
    margin-block-end: 1.5rem !important;
  }
  .my-exl-6 {
    margin-block: 1.5rem !important;
  }
  .ms-exl-6 {
    margin-inline-start: 1.5rem !important;
  }
  .me-exl-6 {
    margin-inline-end: 1.5rem !important;
  }
  .mx-exl-6 {
    margin-inline: 1.5rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-7 {
    gap: 1.75rem !important;
  }
  .p-exl-7 {
    padding: 1.75rem !important;
  }
  .pt-exl-7 {
    padding-block-start: 1.75rem !important;
  }
  .pb-exl-7 {
    padding-block-end: 1.75rem !important;
  }
  .py-exl-7 {
    padding-block: 1.75rem !important;
  }
  .ps-exl-7 {
    padding-inline-start: 1.75rem !important;
  }
  .pe-exl-7 {
    padding-inline-end: 1.75rem !important;
  }
  .px-exl-7 {
    padding-inline: 1.75rem !important;
  }
  .m-exl-7 {
    margin: 1.75rem !important;
  }
  .mt-exl-7 {
    margin-block-start: 1.75rem !important;
  }
  .mb-exl-7 {
    margin-block-end: 1.75rem !important;
  }
  .my-exl-7 {
    margin-block: 1.75rem !important;
  }
  .ms-exl-7 {
    margin-inline-start: 1.75rem !important;
  }
  .me-exl-7 {
    margin-inline-end: 1.75rem !important;
  }
  .mx-exl-7 {
    margin-inline: 1.75rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-8 {
    gap: 2rem !important;
  }
  .p-exl-8 {
    padding: 2rem !important;
  }
  .pt-exl-8 {
    padding-block-start: 2rem !important;
  }
  .pb-exl-8 {
    padding-block-end: 2rem !important;
  }
  .py-exl-8 {
    padding-block: 2rem !important;
  }
  .ps-exl-8 {
    padding-inline-start: 2rem !important;
  }
  .pe-exl-8 {
    padding-inline-end: 2rem !important;
  }
  .px-exl-8 {
    padding-inline: 2rem !important;
  }
  .m-exl-8 {
    margin: 2rem !important;
  }
  .mt-exl-8 {
    margin-block-start: 2rem !important;
  }
  .mb-exl-8 {
    margin-block-end: 2rem !important;
  }
  .my-exl-8 {
    margin-block: 2rem !important;
  }
  .ms-exl-8 {
    margin-inline-start: 2rem !important;
  }
  .me-exl-8 {
    margin-inline-end: 2rem !important;
  }
  .mx-exl-8 {
    margin-inline: 2rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-9 {
    gap: 2.25rem !important;
  }
  .p-exl-9 {
    padding: 2.25rem !important;
  }
  .pt-exl-9 {
    padding-block-start: 2.25rem !important;
  }
  .pb-exl-9 {
    padding-block-end: 2.25rem !important;
  }
  .py-exl-9 {
    padding-block: 2.25rem !important;
  }
  .ps-exl-9 {
    padding-inline-start: 2.25rem !important;
  }
  .pe-exl-9 {
    padding-inline-end: 2.25rem !important;
  }
  .px-exl-9 {
    padding-inline: 2.25rem !important;
  }
  .m-exl-9 {
    margin: 2.25rem !important;
  }
  .mt-exl-9 {
    margin-block-start: 2.25rem !important;
  }
  .mb-exl-9 {
    margin-block-end: 2.25rem !important;
  }
  .my-exl-9 {
    margin-block: 2.25rem !important;
  }
  .ms-exl-9 {
    margin-inline-start: 2.25rem !important;
  }
  .me-exl-9 {
    margin-inline-end: 2.25rem !important;
  }
  .mx-exl-9 {
    margin-inline: 2.25rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-10 {
    gap: 2.5rem !important;
  }
  .p-exl-10 {
    padding: 2.5rem !important;
  }
  .pt-exl-10 {
    padding-block-start: 2.5rem !important;
  }
  .pb-exl-10 {
    padding-block-end: 2.5rem !important;
  }
  .py-exl-10 {
    padding-block: 2.5rem !important;
  }
  .ps-exl-10 {
    padding-inline-start: 2.5rem !important;
  }
  .pe-exl-10 {
    padding-inline-end: 2.5rem !important;
  }
  .px-exl-10 {
    padding-inline: 2.5rem !important;
  }
  .m-exl-10 {
    margin: 2.5rem !important;
  }
  .mt-exl-10 {
    margin-block-start: 2.5rem !important;
  }
  .mb-exl-10 {
    margin-block-end: 2.5rem !important;
  }
  .my-exl-10 {
    margin-block: 2.5rem !important;
  }
  .ms-exl-10 {
    margin-inline-start: 2.5rem !important;
  }
  .me-exl-10 {
    margin-inline-end: 2.5rem !important;
  }
  .mx-exl-10 {
    margin-inline: 2.5rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-11 {
    gap: 2.75rem !important;
  }
  .p-exl-11 {
    padding: 2.75rem !important;
  }
  .pt-exl-11 {
    padding-block-start: 2.75rem !important;
  }
  .pb-exl-11 {
    padding-block-end: 2.75rem !important;
  }
  .py-exl-11 {
    padding-block: 2.75rem !important;
  }
  .ps-exl-11 {
    padding-inline-start: 2.75rem !important;
  }
  .pe-exl-11 {
    padding-inline-end: 2.75rem !important;
  }
  .px-exl-11 {
    padding-inline: 2.75rem !important;
  }
  .m-exl-11 {
    margin: 2.75rem !important;
  }
  .mt-exl-11 {
    margin-block-start: 2.75rem !important;
  }
  .mb-exl-11 {
    margin-block-end: 2.75rem !important;
  }
  .my-exl-11 {
    margin-block: 2.75rem !important;
  }
  .ms-exl-11 {
    margin-inline-start: 2.75rem !important;
  }
  .me-exl-11 {
    margin-inline-end: 2.75rem !important;
  }
  .mx-exl-11 {
    margin-inline: 2.75rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-12 {
    gap: 3rem !important;
  }
  .p-exl-12 {
    padding: 3rem !important;
  }
  .pt-exl-12 {
    padding-block-start: 3rem !important;
  }
  .pb-exl-12 {
    padding-block-end: 3rem !important;
  }
  .py-exl-12 {
    padding-block: 3rem !important;
  }
  .ps-exl-12 {
    padding-inline-start: 3rem !important;
  }
  .pe-exl-12 {
    padding-inline-end: 3rem !important;
  }
  .px-exl-12 {
    padding-inline: 3rem !important;
  }
  .m-exl-12 {
    margin: 3rem !important;
  }
  .mt-exl-12 {
    margin-block-start: 3rem !important;
  }
  .mb-exl-12 {
    margin-block-end: 3rem !important;
  }
  .my-exl-12 {
    margin-block: 3rem !important;
  }
  .ms-exl-12 {
    margin-inline-start: 3rem !important;
  }
  .me-exl-12 {
    margin-inline-end: 3rem !important;
  }
  .mx-exl-12 {
    margin-inline: 3rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-13 {
    gap: 3.25rem !important;
  }
  .p-exl-13 {
    padding: 3.25rem !important;
  }
  .pt-exl-13 {
    padding-block-start: 3.25rem !important;
  }
  .pb-exl-13 {
    padding-block-end: 3.25rem !important;
  }
  .py-exl-13 {
    padding-block: 3.25rem !important;
  }
  .ps-exl-13 {
    padding-inline-start: 3.25rem !important;
  }
  .pe-exl-13 {
    padding-inline-end: 3.25rem !important;
  }
  .px-exl-13 {
    padding-inline: 3.25rem !important;
  }
  .m-exl-13 {
    margin: 3.25rem !important;
  }
  .mt-exl-13 {
    margin-block-start: 3.25rem !important;
  }
  .mb-exl-13 {
    margin-block-end: 3.25rem !important;
  }
  .my-exl-13 {
    margin-block: 3.25rem !important;
  }
  .ms-exl-13 {
    margin-inline-start: 3.25rem !important;
  }
  .me-exl-13 {
    margin-inline-end: 3.25rem !important;
  }
  .mx-exl-13 {
    margin-inline: 3.25rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-14 {
    gap: 3.5rem !important;
  }
  .p-exl-14 {
    padding: 3.5rem !important;
  }
  .pt-exl-14 {
    padding-block-start: 3.5rem !important;
  }
  .pb-exl-14 {
    padding-block-end: 3.5rem !important;
  }
  .py-exl-14 {
    padding-block: 3.5rem !important;
  }
  .ps-exl-14 {
    padding-inline-start: 3.5rem !important;
  }
  .pe-exl-14 {
    padding-inline-end: 3.5rem !important;
  }
  .px-exl-14 {
    padding-inline: 3.5rem !important;
  }
  .m-exl-14 {
    margin: 3.5rem !important;
  }
  .mt-exl-14 {
    margin-block-start: 3.5rem !important;
  }
  .mb-exl-14 {
    margin-block-end: 3.5rem !important;
  }
  .my-exl-14 {
    margin-block: 3.5rem !important;
  }
  .ms-exl-14 {
    margin-inline-start: 3.5rem !important;
  }
  .me-exl-14 {
    margin-inline-end: 3.5rem !important;
  }
  .mx-exl-14 {
    margin-inline: 3.5rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-15 {
    gap: 3.75rem !important;
  }
  .p-exl-15 {
    padding: 3.75rem !important;
  }
  .pt-exl-15 {
    padding-block-start: 3.75rem !important;
  }
  .pb-exl-15 {
    padding-block-end: 3.75rem !important;
  }
  .py-exl-15 {
    padding-block: 3.75rem !important;
  }
  .ps-exl-15 {
    padding-inline-start: 3.75rem !important;
  }
  .pe-exl-15 {
    padding-inline-end: 3.75rem !important;
  }
  .px-exl-15 {
    padding-inline: 3.75rem !important;
  }
  .m-exl-15 {
    margin: 3.75rem !important;
  }
  .mt-exl-15 {
    margin-block-start: 3.75rem !important;
  }
  .mb-exl-15 {
    margin-block-end: 3.75rem !important;
  }
  .my-exl-15 {
    margin-block: 3.75rem !important;
  }
  .ms-exl-15 {
    margin-inline-start: 3.75rem !important;
  }
  .me-exl-15 {
    margin-inline-end: 3.75rem !important;
  }
  .mx-exl-15 {
    margin-inline: 3.75rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-16 {
    gap: 4rem !important;
  }
  .p-exl-16 {
    padding: 4rem !important;
  }
  .pt-exl-16 {
    padding-block-start: 4rem !important;
  }
  .pb-exl-16 {
    padding-block-end: 4rem !important;
  }
  .py-exl-16 {
    padding-block: 4rem !important;
  }
  .ps-exl-16 {
    padding-inline-start: 4rem !important;
  }
  .pe-exl-16 {
    padding-inline-end: 4rem !important;
  }
  .px-exl-16 {
    padding-inline: 4rem !important;
  }
  .m-exl-16 {
    margin: 4rem !important;
  }
  .mt-exl-16 {
    margin-block-start: 4rem !important;
  }
  .mb-exl-16 {
    margin-block-end: 4rem !important;
  }
  .my-exl-16 {
    margin-block: 4rem !important;
  }
  .ms-exl-16 {
    margin-inline-start: 4rem !important;
  }
  .me-exl-16 {
    margin-inline-end: 4rem !important;
  }
  .mx-exl-16 {
    margin-inline: 4rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-17 {
    gap: 4.25rem !important;
  }
  .p-exl-17 {
    padding: 4.25rem !important;
  }
  .pt-exl-17 {
    padding-block-start: 4.25rem !important;
  }
  .pb-exl-17 {
    padding-block-end: 4.25rem !important;
  }
  .py-exl-17 {
    padding-block: 4.25rem !important;
  }
  .ps-exl-17 {
    padding-inline-start: 4.25rem !important;
  }
  .pe-exl-17 {
    padding-inline-end: 4.25rem !important;
  }
  .px-exl-17 {
    padding-inline: 4.25rem !important;
  }
  .m-exl-17 {
    margin: 4.25rem !important;
  }
  .mt-exl-17 {
    margin-block-start: 4.25rem !important;
  }
  .mb-exl-17 {
    margin-block-end: 4.25rem !important;
  }
  .my-exl-17 {
    margin-block: 4.25rem !important;
  }
  .ms-exl-17 {
    margin-inline-start: 4.25rem !important;
  }
  .me-exl-17 {
    margin-inline-end: 4.25rem !important;
  }
  .mx-exl-17 {
    margin-inline: 4.25rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-18 {
    gap: 4.5rem !important;
  }
  .p-exl-18 {
    padding: 4.5rem !important;
  }
  .pt-exl-18 {
    padding-block-start: 4.5rem !important;
  }
  .pb-exl-18 {
    padding-block-end: 4.5rem !important;
  }
  .py-exl-18 {
    padding-block: 4.5rem !important;
  }
  .ps-exl-18 {
    padding-inline-start: 4.5rem !important;
  }
  .pe-exl-18 {
    padding-inline-end: 4.5rem !important;
  }
  .px-exl-18 {
    padding-inline: 4.5rem !important;
  }
  .m-exl-18 {
    margin: 4.5rem !important;
  }
  .mt-exl-18 {
    margin-block-start: 4.5rem !important;
  }
  .mb-exl-18 {
    margin-block-end: 4.5rem !important;
  }
  .my-exl-18 {
    margin-block: 4.5rem !important;
  }
  .ms-exl-18 {
    margin-inline-start: 4.5rem !important;
  }
  .me-exl-18 {
    margin-inline-end: 4.5rem !important;
  }
  .mx-exl-18 {
    margin-inline: 4.5rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-19 {
    gap: 4.75rem !important;
  }
  .p-exl-19 {
    padding: 4.75rem !important;
  }
  .pt-exl-19 {
    padding-block-start: 4.75rem !important;
  }
  .pb-exl-19 {
    padding-block-end: 4.75rem !important;
  }
  .py-exl-19 {
    padding-block: 4.75rem !important;
  }
  .ps-exl-19 {
    padding-inline-start: 4.75rem !important;
  }
  .pe-exl-19 {
    padding-inline-end: 4.75rem !important;
  }
  .px-exl-19 {
    padding-inline: 4.75rem !important;
  }
  .m-exl-19 {
    margin: 4.75rem !important;
  }
  .mt-exl-19 {
    margin-block-start: 4.75rem !important;
  }
  .mb-exl-19 {
    margin-block-end: 4.75rem !important;
  }
  .my-exl-19 {
    margin-block: 4.75rem !important;
  }
  .ms-exl-19 {
    margin-inline-start: 4.75rem !important;
  }
  .me-exl-19 {
    margin-inline-end: 4.75rem !important;
  }
  .mx-exl-19 {
    margin-inline: 4.75rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-20 {
    gap: 5rem !important;
  }
  .p-exl-20 {
    padding: 5rem !important;
  }
  .pt-exl-20 {
    padding-block-start: 5rem !important;
  }
  .pb-exl-20 {
    padding-block-end: 5rem !important;
  }
  .py-exl-20 {
    padding-block: 5rem !important;
  }
  .ps-exl-20 {
    padding-inline-start: 5rem !important;
  }
  .pe-exl-20 {
    padding-inline-end: 5rem !important;
  }
  .px-exl-20 {
    padding-inline: 5rem !important;
  }
  .m-exl-20 {
    margin: 5rem !important;
  }
  .mt-exl-20 {
    margin-block-start: 5rem !important;
  }
  .mb-exl-20 {
    margin-block-end: 5rem !important;
  }
  .my-exl-20 {
    margin-block: 5rem !important;
  }
  .ms-exl-20 {
    margin-inline-start: 5rem !important;
  }
  .me-exl-20 {
    margin-inline-end: 5rem !important;
  }
  .mx-exl-20 {
    margin-inline: 5rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-21 {
    gap: 5.25rem !important;
  }
  .p-exl-21 {
    padding: 5.25rem !important;
  }
  .pt-exl-21 {
    padding-block-start: 5.25rem !important;
  }
  .pb-exl-21 {
    padding-block-end: 5.25rem !important;
  }
  .py-exl-21 {
    padding-block: 5.25rem !important;
  }
  .ps-exl-21 {
    padding-inline-start: 5.25rem !important;
  }
  .pe-exl-21 {
    padding-inline-end: 5.25rem !important;
  }
  .px-exl-21 {
    padding-inline: 5.25rem !important;
  }
  .m-exl-21 {
    margin: 5.25rem !important;
  }
  .mt-exl-21 {
    margin-block-start: 5.25rem !important;
  }
  .mb-exl-21 {
    margin-block-end: 5.25rem !important;
  }
  .my-exl-21 {
    margin-block: 5.25rem !important;
  }
  .ms-exl-21 {
    margin-inline-start: 5.25rem !important;
  }
  .me-exl-21 {
    margin-inline-end: 5.25rem !important;
  }
  .mx-exl-21 {
    margin-inline: 5.25rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-22 {
    gap: 5.5rem !important;
  }
  .p-exl-22 {
    padding: 5.5rem !important;
  }
  .pt-exl-22 {
    padding-block-start: 5.5rem !important;
  }
  .pb-exl-22 {
    padding-block-end: 5.5rem !important;
  }
  .py-exl-22 {
    padding-block: 5.5rem !important;
  }
  .ps-exl-22 {
    padding-inline-start: 5.5rem !important;
  }
  .pe-exl-22 {
    padding-inline-end: 5.5rem !important;
  }
  .px-exl-22 {
    padding-inline: 5.5rem !important;
  }
  .m-exl-22 {
    margin: 5.5rem !important;
  }
  .mt-exl-22 {
    margin-block-start: 5.5rem !important;
  }
  .mb-exl-22 {
    margin-block-end: 5.5rem !important;
  }
  .my-exl-22 {
    margin-block: 5.5rem !important;
  }
  .ms-exl-22 {
    margin-inline-start: 5.5rem !important;
  }
  .me-exl-22 {
    margin-inline-end: 5.5rem !important;
  }
  .mx-exl-22 {
    margin-inline: 5.5rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-23 {
    gap: 5.75rem !important;
  }
  .p-exl-23 {
    padding: 5.75rem !important;
  }
  .pt-exl-23 {
    padding-block-start: 5.75rem !important;
  }
  .pb-exl-23 {
    padding-block-end: 5.75rem !important;
  }
  .py-exl-23 {
    padding-block: 5.75rem !important;
  }
  .ps-exl-23 {
    padding-inline-start: 5.75rem !important;
  }
  .pe-exl-23 {
    padding-inline-end: 5.75rem !important;
  }
  .px-exl-23 {
    padding-inline: 5.75rem !important;
  }
  .m-exl-23 {
    margin: 5.75rem !important;
  }
  .mt-exl-23 {
    margin-block-start: 5.75rem !important;
  }
  .mb-exl-23 {
    margin-block-end: 5.75rem !important;
  }
  .my-exl-23 {
    margin-block: 5.75rem !important;
  }
  .ms-exl-23 {
    margin-inline-start: 5.75rem !important;
  }
  .me-exl-23 {
    margin-inline-end: 5.75rem !important;
  }
  .mx-exl-23 {
    margin-inline: 5.75rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-24 {
    gap: 6rem !important;
  }
  .p-exl-24 {
    padding: 6rem !important;
  }
  .pt-exl-24 {
    padding-block-start: 6rem !important;
  }
  .pb-exl-24 {
    padding-block-end: 6rem !important;
  }
  .py-exl-24 {
    padding-block: 6rem !important;
  }
  .ps-exl-24 {
    padding-inline-start: 6rem !important;
  }
  .pe-exl-24 {
    padding-inline-end: 6rem !important;
  }
  .px-exl-24 {
    padding-inline: 6rem !important;
  }
  .m-exl-24 {
    margin: 6rem !important;
  }
  .mt-exl-24 {
    margin-block-start: 6rem !important;
  }
  .mb-exl-24 {
    margin-block-end: 6rem !important;
  }
  .my-exl-24 {
    margin-block: 6rem !important;
  }
  .ms-exl-24 {
    margin-inline-start: 6rem !important;
  }
  .me-exl-24 {
    margin-inline-end: 6rem !important;
  }
  .mx-exl-24 {
    margin-inline: 6rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-25 {
    gap: 6.25rem !important;
  }
  .p-exl-25 {
    padding: 6.25rem !important;
  }
  .pt-exl-25 {
    padding-block-start: 6.25rem !important;
  }
  .pb-exl-25 {
    padding-block-end: 6.25rem !important;
  }
  .py-exl-25 {
    padding-block: 6.25rem !important;
  }
  .ps-exl-25 {
    padding-inline-start: 6.25rem !important;
  }
  .pe-exl-25 {
    padding-inline-end: 6.25rem !important;
  }
  .px-exl-25 {
    padding-inline: 6.25rem !important;
  }
  .m-exl-25 {
    margin: 6.25rem !important;
  }
  .mt-exl-25 {
    margin-block-start: 6.25rem !important;
  }
  .mb-exl-25 {
    margin-block-end: 6.25rem !important;
  }
  .my-exl-25 {
    margin-block: 6.25rem !important;
  }
  .ms-exl-25 {
    margin-inline-start: 6.25rem !important;
  }
  .me-exl-25 {
    margin-inline-end: 6.25rem !important;
  }
  .mx-exl-25 {
    margin-inline: 6.25rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-26 {
    gap: 6.5rem !important;
  }
  .p-exl-26 {
    padding: 6.5rem !important;
  }
  .pt-exl-26 {
    padding-block-start: 6.5rem !important;
  }
  .pb-exl-26 {
    padding-block-end: 6.5rem !important;
  }
  .py-exl-26 {
    padding-block: 6.5rem !important;
  }
  .ps-exl-26 {
    padding-inline-start: 6.5rem !important;
  }
  .pe-exl-26 {
    padding-inline-end: 6.5rem !important;
  }
  .px-exl-26 {
    padding-inline: 6.5rem !important;
  }
  .m-exl-26 {
    margin: 6.5rem !important;
  }
  .mt-exl-26 {
    margin-block-start: 6.5rem !important;
  }
  .mb-exl-26 {
    margin-block-end: 6.5rem !important;
  }
  .my-exl-26 {
    margin-block: 6.5rem !important;
  }
  .ms-exl-26 {
    margin-inline-start: 6.5rem !important;
  }
  .me-exl-26 {
    margin-inline-end: 6.5rem !important;
  }
  .mx-exl-26 {
    margin-inline: 6.5rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-27 {
    gap: 6.75rem !important;
  }
  .p-exl-27 {
    padding: 6.75rem !important;
  }
  .pt-exl-27 {
    padding-block-start: 6.75rem !important;
  }
  .pb-exl-27 {
    padding-block-end: 6.75rem !important;
  }
  .py-exl-27 {
    padding-block: 6.75rem !important;
  }
  .ps-exl-27 {
    padding-inline-start: 6.75rem !important;
  }
  .pe-exl-27 {
    padding-inline-end: 6.75rem !important;
  }
  .px-exl-27 {
    padding-inline: 6.75rem !important;
  }
  .m-exl-27 {
    margin: 6.75rem !important;
  }
  .mt-exl-27 {
    margin-block-start: 6.75rem !important;
  }
  .mb-exl-27 {
    margin-block-end: 6.75rem !important;
  }
  .my-exl-27 {
    margin-block: 6.75rem !important;
  }
  .ms-exl-27 {
    margin-inline-start: 6.75rem !important;
  }
  .me-exl-27 {
    margin-inline-end: 6.75rem !important;
  }
  .mx-exl-27 {
    margin-inline: 6.75rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-28 {
    gap: 7rem !important;
  }
  .p-exl-28 {
    padding: 7rem !important;
  }
  .pt-exl-28 {
    padding-block-start: 7rem !important;
  }
  .pb-exl-28 {
    padding-block-end: 7rem !important;
  }
  .py-exl-28 {
    padding-block: 7rem !important;
  }
  .ps-exl-28 {
    padding-inline-start: 7rem !important;
  }
  .pe-exl-28 {
    padding-inline-end: 7rem !important;
  }
  .px-exl-28 {
    padding-inline: 7rem !important;
  }
  .m-exl-28 {
    margin: 7rem !important;
  }
  .mt-exl-28 {
    margin-block-start: 7rem !important;
  }
  .mb-exl-28 {
    margin-block-end: 7rem !important;
  }
  .my-exl-28 {
    margin-block: 7rem !important;
  }
  .ms-exl-28 {
    margin-inline-start: 7rem !important;
  }
  .me-exl-28 {
    margin-inline-end: 7rem !important;
  }
  .mx-exl-28 {
    margin-inline: 7rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-29 {
    gap: 7.25rem !important;
  }
  .p-exl-29 {
    padding: 7.25rem !important;
  }
  .pt-exl-29 {
    padding-block-start: 7.25rem !important;
  }
  .pb-exl-29 {
    padding-block-end: 7.25rem !important;
  }
  .py-exl-29 {
    padding-block: 7.25rem !important;
  }
  .ps-exl-29 {
    padding-inline-start: 7.25rem !important;
  }
  .pe-exl-29 {
    padding-inline-end: 7.25rem !important;
  }
  .px-exl-29 {
    padding-inline: 7.25rem !important;
  }
  .m-exl-29 {
    margin: 7.25rem !important;
  }
  .mt-exl-29 {
    margin-block-start: 7.25rem !important;
  }
  .mb-exl-29 {
    margin-block-end: 7.25rem !important;
  }
  .my-exl-29 {
    margin-block: 7.25rem !important;
  }
  .ms-exl-29 {
    margin-inline-start: 7.25rem !important;
  }
  .me-exl-29 {
    margin-inline-end: 7.25rem !important;
  }
  .mx-exl-29 {
    margin-inline: 7.25rem !important;
  }
}
@media (min-width: 1600px) {
  .gap-exl-30 {
    gap: 7.5rem !important;
  }
  .p-exl-30 {
    padding: 7.5rem !important;
  }
  .pt-exl-30 {
    padding-block-start: 7.5rem !important;
  }
  .pb-exl-30 {
    padding-block-end: 7.5rem !important;
  }
  .py-exl-30 {
    padding-block: 7.5rem !important;
  }
  .ps-exl-30 {
    padding-inline-start: 7.5rem !important;
  }
  .pe-exl-30 {
    padding-inline-end: 7.5rem !important;
  }
  .px-exl-30 {
    padding-inline: 7.5rem !important;
  }
  .m-exl-30 {
    margin: 7.5rem !important;
  }
  .mt-exl-30 {
    margin-block-start: 7.5rem !important;
  }
  .mb-exl-30 {
    margin-block-end: 7.5rem !important;
  }
  .my-exl-30 {
    margin-block: 7.5rem !important;
  }
  .ms-exl-30 {
    margin-inline-start: 7.5rem !important;
  }
  .me-exl-30 {
    margin-inline-end: 7.5rem !important;
  }
  .mx-exl-30 {
    margin-inline: 7.5rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-0 {
    gap: 0 !important;
  }
  .p-exxl-0 {
    padding: 0 !important;
  }
  .pt-exxl-0 {
    padding-block-start: 0 !important;
  }
  .pb-exxl-0 {
    padding-block-end: 0 !important;
  }
  .py-exxl-0 {
    padding-block: 0 !important;
  }
  .ps-exxl-0 {
    padding-inline-start: 0 !important;
  }
  .pe-exxl-0 {
    padding-inline-end: 0 !important;
  }
  .px-exxl-0 {
    padding-inline: 0 !important;
  }
  .m-exxl-0 {
    margin: 0 !important;
  }
  .mt-exxl-0 {
    margin-block-start: 0 !important;
  }
  .mb-exxl-0 {
    margin-block-end: 0 !important;
  }
  .my-exxl-0 {
    margin-block: 0 !important;
  }
  .ms-exxl-0 {
    margin-inline-start: 0 !important;
  }
  .me-exxl-0 {
    margin-inline-end: 0 !important;
  }
  .mx-exxl-0 {
    margin-inline: 0 !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-1 {
    gap: 0.25rem !important;
  }
  .p-exxl-1 {
    padding: 0.25rem !important;
  }
  .pt-exxl-1 {
    padding-block-start: 0.25rem !important;
  }
  .pb-exxl-1 {
    padding-block-end: 0.25rem !important;
  }
  .py-exxl-1 {
    padding-block: 0.25rem !important;
  }
  .ps-exxl-1 {
    padding-inline-start: 0.25rem !important;
  }
  .pe-exxl-1 {
    padding-inline-end: 0.25rem !important;
  }
  .px-exxl-1 {
    padding-inline: 0.25rem !important;
  }
  .m-exxl-1 {
    margin: 0.25rem !important;
  }
  .mt-exxl-1 {
    margin-block-start: 0.25rem !important;
  }
  .mb-exxl-1 {
    margin-block-end: 0.25rem !important;
  }
  .my-exxl-1 {
    margin-block: 0.25rem !important;
  }
  .ms-exxl-1 {
    margin-inline-start: 0.25rem !important;
  }
  .me-exxl-1 {
    margin-inline-end: 0.25rem !important;
  }
  .mx-exxl-1 {
    margin-inline: 0.25rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-2 {
    gap: 0.5rem !important;
  }
  .p-exxl-2 {
    padding: 0.5rem !important;
  }
  .pt-exxl-2 {
    padding-block-start: 0.5rem !important;
  }
  .pb-exxl-2 {
    padding-block-end: 0.5rem !important;
  }
  .py-exxl-2 {
    padding-block: 0.5rem !important;
  }
  .ps-exxl-2 {
    padding-inline-start: 0.5rem !important;
  }
  .pe-exxl-2 {
    padding-inline-end: 0.5rem !important;
  }
  .px-exxl-2 {
    padding-inline: 0.5rem !important;
  }
  .m-exxl-2 {
    margin: 0.5rem !important;
  }
  .mt-exxl-2 {
    margin-block-start: 0.5rem !important;
  }
  .mb-exxl-2 {
    margin-block-end: 0.5rem !important;
  }
  .my-exxl-2 {
    margin-block: 0.5rem !important;
  }
  .ms-exxl-2 {
    margin-inline-start: 0.5rem !important;
  }
  .me-exxl-2 {
    margin-inline-end: 0.5rem !important;
  }
  .mx-exxl-2 {
    margin-inline: 0.5rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-3 {
    gap: 0.75rem !important;
  }
  .p-exxl-3 {
    padding: 0.75rem !important;
  }
  .pt-exxl-3 {
    padding-block-start: 0.75rem !important;
  }
  .pb-exxl-3 {
    padding-block-end: 0.75rem !important;
  }
  .py-exxl-3 {
    padding-block: 0.75rem !important;
  }
  .ps-exxl-3 {
    padding-inline-start: 0.75rem !important;
  }
  .pe-exxl-3 {
    padding-inline-end: 0.75rem !important;
  }
  .px-exxl-3 {
    padding-inline: 0.75rem !important;
  }
  .m-exxl-3 {
    margin: 0.75rem !important;
  }
  .mt-exxl-3 {
    margin-block-start: 0.75rem !important;
  }
  .mb-exxl-3 {
    margin-block-end: 0.75rem !important;
  }
  .my-exxl-3 {
    margin-block: 0.75rem !important;
  }
  .ms-exxl-3 {
    margin-inline-start: 0.75rem !important;
  }
  .me-exxl-3 {
    margin-inline-end: 0.75rem !important;
  }
  .mx-exxl-3 {
    margin-inline: 0.75rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-4 {
    gap: 1rem !important;
  }
  .p-exxl-4 {
    padding: 1rem !important;
  }
  .pt-exxl-4 {
    padding-block-start: 1rem !important;
  }
  .pb-exxl-4 {
    padding-block-end: 1rem !important;
  }
  .py-exxl-4 {
    padding-block: 1rem !important;
  }
  .ps-exxl-4 {
    padding-inline-start: 1rem !important;
  }
  .pe-exxl-4 {
    padding-inline-end: 1rem !important;
  }
  .px-exxl-4 {
    padding-inline: 1rem !important;
  }
  .m-exxl-4 {
    margin: 1rem !important;
  }
  .mt-exxl-4 {
    margin-block-start: 1rem !important;
  }
  .mb-exxl-4 {
    margin-block-end: 1rem !important;
  }
  .my-exxl-4 {
    margin-block: 1rem !important;
  }
  .ms-exxl-4 {
    margin-inline-start: 1rem !important;
  }
  .me-exxl-4 {
    margin-inline-end: 1rem !important;
  }
  .mx-exxl-4 {
    margin-inline: 1rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-5 {
    gap: 1.25rem !important;
  }
  .p-exxl-5 {
    padding: 1.25rem !important;
  }
  .pt-exxl-5 {
    padding-block-start: 1.25rem !important;
  }
  .pb-exxl-5 {
    padding-block-end: 1.25rem !important;
  }
  .py-exxl-5 {
    padding-block: 1.25rem !important;
  }
  .ps-exxl-5 {
    padding-inline-start: 1.25rem !important;
  }
  .pe-exxl-5 {
    padding-inline-end: 1.25rem !important;
  }
  .px-exxl-5 {
    padding-inline: 1.25rem !important;
  }
  .m-exxl-5 {
    margin: 1.25rem !important;
  }
  .mt-exxl-5 {
    margin-block-start: 1.25rem !important;
  }
  .mb-exxl-5 {
    margin-block-end: 1.25rem !important;
  }
  .my-exxl-5 {
    margin-block: 1.25rem !important;
  }
  .ms-exxl-5 {
    margin-inline-start: 1.25rem !important;
  }
  .me-exxl-5 {
    margin-inline-end: 1.25rem !important;
  }
  .mx-exxl-5 {
    margin-inline: 1.25rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-6 {
    gap: 1.5rem !important;
  }
  .p-exxl-6 {
    padding: 1.5rem !important;
  }
  .pt-exxl-6 {
    padding-block-start: 1.5rem !important;
  }
  .pb-exxl-6 {
    padding-block-end: 1.5rem !important;
  }
  .py-exxl-6 {
    padding-block: 1.5rem !important;
  }
  .ps-exxl-6 {
    padding-inline-start: 1.5rem !important;
  }
  .pe-exxl-6 {
    padding-inline-end: 1.5rem !important;
  }
  .px-exxl-6 {
    padding-inline: 1.5rem !important;
  }
  .m-exxl-6 {
    margin: 1.5rem !important;
  }
  .mt-exxl-6 {
    margin-block-start: 1.5rem !important;
  }
  .mb-exxl-6 {
    margin-block-end: 1.5rem !important;
  }
  .my-exxl-6 {
    margin-block: 1.5rem !important;
  }
  .ms-exxl-6 {
    margin-inline-start: 1.5rem !important;
  }
  .me-exxl-6 {
    margin-inline-end: 1.5rem !important;
  }
  .mx-exxl-6 {
    margin-inline: 1.5rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-7 {
    gap: 1.75rem !important;
  }
  .p-exxl-7 {
    padding: 1.75rem !important;
  }
  .pt-exxl-7 {
    padding-block-start: 1.75rem !important;
  }
  .pb-exxl-7 {
    padding-block-end: 1.75rem !important;
  }
  .py-exxl-7 {
    padding-block: 1.75rem !important;
  }
  .ps-exxl-7 {
    padding-inline-start: 1.75rem !important;
  }
  .pe-exxl-7 {
    padding-inline-end: 1.75rem !important;
  }
  .px-exxl-7 {
    padding-inline: 1.75rem !important;
  }
  .m-exxl-7 {
    margin: 1.75rem !important;
  }
  .mt-exxl-7 {
    margin-block-start: 1.75rem !important;
  }
  .mb-exxl-7 {
    margin-block-end: 1.75rem !important;
  }
  .my-exxl-7 {
    margin-block: 1.75rem !important;
  }
  .ms-exxl-7 {
    margin-inline-start: 1.75rem !important;
  }
  .me-exxl-7 {
    margin-inline-end: 1.75rem !important;
  }
  .mx-exxl-7 {
    margin-inline: 1.75rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-8 {
    gap: 2rem !important;
  }
  .p-exxl-8 {
    padding: 2rem !important;
  }
  .pt-exxl-8 {
    padding-block-start: 2rem !important;
  }
  .pb-exxl-8 {
    padding-block-end: 2rem !important;
  }
  .py-exxl-8 {
    padding-block: 2rem !important;
  }
  .ps-exxl-8 {
    padding-inline-start: 2rem !important;
  }
  .pe-exxl-8 {
    padding-inline-end: 2rem !important;
  }
  .px-exxl-8 {
    padding-inline: 2rem !important;
  }
  .m-exxl-8 {
    margin: 2rem !important;
  }
  .mt-exxl-8 {
    margin-block-start: 2rem !important;
  }
  .mb-exxl-8 {
    margin-block-end: 2rem !important;
  }
  .my-exxl-8 {
    margin-block: 2rem !important;
  }
  .ms-exxl-8 {
    margin-inline-start: 2rem !important;
  }
  .me-exxl-8 {
    margin-inline-end: 2rem !important;
  }
  .mx-exxl-8 {
    margin-inline: 2rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-9 {
    gap: 2.25rem !important;
  }
  .p-exxl-9 {
    padding: 2.25rem !important;
  }
  .pt-exxl-9 {
    padding-block-start: 2.25rem !important;
  }
  .pb-exxl-9 {
    padding-block-end: 2.25rem !important;
  }
  .py-exxl-9 {
    padding-block: 2.25rem !important;
  }
  .ps-exxl-9 {
    padding-inline-start: 2.25rem !important;
  }
  .pe-exxl-9 {
    padding-inline-end: 2.25rem !important;
  }
  .px-exxl-9 {
    padding-inline: 2.25rem !important;
  }
  .m-exxl-9 {
    margin: 2.25rem !important;
  }
  .mt-exxl-9 {
    margin-block-start: 2.25rem !important;
  }
  .mb-exxl-9 {
    margin-block-end: 2.25rem !important;
  }
  .my-exxl-9 {
    margin-block: 2.25rem !important;
  }
  .ms-exxl-9 {
    margin-inline-start: 2.25rem !important;
  }
  .me-exxl-9 {
    margin-inline-end: 2.25rem !important;
  }
  .mx-exxl-9 {
    margin-inline: 2.25rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-10 {
    gap: 2.5rem !important;
  }
  .p-exxl-10 {
    padding: 2.5rem !important;
  }
  .pt-exxl-10 {
    padding-block-start: 2.5rem !important;
  }
  .pb-exxl-10 {
    padding-block-end: 2.5rem !important;
  }
  .py-exxl-10 {
    padding-block: 2.5rem !important;
  }
  .ps-exxl-10 {
    padding-inline-start: 2.5rem !important;
  }
  .pe-exxl-10 {
    padding-inline-end: 2.5rem !important;
  }
  .px-exxl-10 {
    padding-inline: 2.5rem !important;
  }
  .m-exxl-10 {
    margin: 2.5rem !important;
  }
  .mt-exxl-10 {
    margin-block-start: 2.5rem !important;
  }
  .mb-exxl-10 {
    margin-block-end: 2.5rem !important;
  }
  .my-exxl-10 {
    margin-block: 2.5rem !important;
  }
  .ms-exxl-10 {
    margin-inline-start: 2.5rem !important;
  }
  .me-exxl-10 {
    margin-inline-end: 2.5rem !important;
  }
  .mx-exxl-10 {
    margin-inline: 2.5rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-11 {
    gap: 2.75rem !important;
  }
  .p-exxl-11 {
    padding: 2.75rem !important;
  }
  .pt-exxl-11 {
    padding-block-start: 2.75rem !important;
  }
  .pb-exxl-11 {
    padding-block-end: 2.75rem !important;
  }
  .py-exxl-11 {
    padding-block: 2.75rem !important;
  }
  .ps-exxl-11 {
    padding-inline-start: 2.75rem !important;
  }
  .pe-exxl-11 {
    padding-inline-end: 2.75rem !important;
  }
  .px-exxl-11 {
    padding-inline: 2.75rem !important;
  }
  .m-exxl-11 {
    margin: 2.75rem !important;
  }
  .mt-exxl-11 {
    margin-block-start: 2.75rem !important;
  }
  .mb-exxl-11 {
    margin-block-end: 2.75rem !important;
  }
  .my-exxl-11 {
    margin-block: 2.75rem !important;
  }
  .ms-exxl-11 {
    margin-inline-start: 2.75rem !important;
  }
  .me-exxl-11 {
    margin-inline-end: 2.75rem !important;
  }
  .mx-exxl-11 {
    margin-inline: 2.75rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-12 {
    gap: 3rem !important;
  }
  .p-exxl-12 {
    padding: 3rem !important;
  }
  .pt-exxl-12 {
    padding-block-start: 3rem !important;
  }
  .pb-exxl-12 {
    padding-block-end: 3rem !important;
  }
  .py-exxl-12 {
    padding-block: 3rem !important;
  }
  .ps-exxl-12 {
    padding-inline-start: 3rem !important;
  }
  .pe-exxl-12 {
    padding-inline-end: 3rem !important;
  }
  .px-exxl-12 {
    padding-inline: 3rem !important;
  }
  .m-exxl-12 {
    margin: 3rem !important;
  }
  .mt-exxl-12 {
    margin-block-start: 3rem !important;
  }
  .mb-exxl-12 {
    margin-block-end: 3rem !important;
  }
  .my-exxl-12 {
    margin-block: 3rem !important;
  }
  .ms-exxl-12 {
    margin-inline-start: 3rem !important;
  }
  .me-exxl-12 {
    margin-inline-end: 3rem !important;
  }
  .mx-exxl-12 {
    margin-inline: 3rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-13 {
    gap: 3.25rem !important;
  }
  .p-exxl-13 {
    padding: 3.25rem !important;
  }
  .pt-exxl-13 {
    padding-block-start: 3.25rem !important;
  }
  .pb-exxl-13 {
    padding-block-end: 3.25rem !important;
  }
  .py-exxl-13 {
    padding-block: 3.25rem !important;
  }
  .ps-exxl-13 {
    padding-inline-start: 3.25rem !important;
  }
  .pe-exxl-13 {
    padding-inline-end: 3.25rem !important;
  }
  .px-exxl-13 {
    padding-inline: 3.25rem !important;
  }
  .m-exxl-13 {
    margin: 3.25rem !important;
  }
  .mt-exxl-13 {
    margin-block-start: 3.25rem !important;
  }
  .mb-exxl-13 {
    margin-block-end: 3.25rem !important;
  }
  .my-exxl-13 {
    margin-block: 3.25rem !important;
  }
  .ms-exxl-13 {
    margin-inline-start: 3.25rem !important;
  }
  .me-exxl-13 {
    margin-inline-end: 3.25rem !important;
  }
  .mx-exxl-13 {
    margin-inline: 3.25rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-14 {
    gap: 3.5rem !important;
  }
  .p-exxl-14 {
    padding: 3.5rem !important;
  }
  .pt-exxl-14 {
    padding-block-start: 3.5rem !important;
  }
  .pb-exxl-14 {
    padding-block-end: 3.5rem !important;
  }
  .py-exxl-14 {
    padding-block: 3.5rem !important;
  }
  .ps-exxl-14 {
    padding-inline-start: 3.5rem !important;
  }
  .pe-exxl-14 {
    padding-inline-end: 3.5rem !important;
  }
  .px-exxl-14 {
    padding-inline: 3.5rem !important;
  }
  .m-exxl-14 {
    margin: 3.5rem !important;
  }
  .mt-exxl-14 {
    margin-block-start: 3.5rem !important;
  }
  .mb-exxl-14 {
    margin-block-end: 3.5rem !important;
  }
  .my-exxl-14 {
    margin-block: 3.5rem !important;
  }
  .ms-exxl-14 {
    margin-inline-start: 3.5rem !important;
  }
  .me-exxl-14 {
    margin-inline-end: 3.5rem !important;
  }
  .mx-exxl-14 {
    margin-inline: 3.5rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-15 {
    gap: 3.75rem !important;
  }
  .p-exxl-15 {
    padding: 3.75rem !important;
  }
  .pt-exxl-15 {
    padding-block-start: 3.75rem !important;
  }
  .pb-exxl-15 {
    padding-block-end: 3.75rem !important;
  }
  .py-exxl-15 {
    padding-block: 3.75rem !important;
  }
  .ps-exxl-15 {
    padding-inline-start: 3.75rem !important;
  }
  .pe-exxl-15 {
    padding-inline-end: 3.75rem !important;
  }
  .px-exxl-15 {
    padding-inline: 3.75rem !important;
  }
  .m-exxl-15 {
    margin: 3.75rem !important;
  }
  .mt-exxl-15 {
    margin-block-start: 3.75rem !important;
  }
  .mb-exxl-15 {
    margin-block-end: 3.75rem !important;
  }
  .my-exxl-15 {
    margin-block: 3.75rem !important;
  }
  .ms-exxl-15 {
    margin-inline-start: 3.75rem !important;
  }
  .me-exxl-15 {
    margin-inline-end: 3.75rem !important;
  }
  .mx-exxl-15 {
    margin-inline: 3.75rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-16 {
    gap: 4rem !important;
  }
  .p-exxl-16 {
    padding: 4rem !important;
  }
  .pt-exxl-16 {
    padding-block-start: 4rem !important;
  }
  .pb-exxl-16 {
    padding-block-end: 4rem !important;
  }
  .py-exxl-16 {
    padding-block: 4rem !important;
  }
  .ps-exxl-16 {
    padding-inline-start: 4rem !important;
  }
  .pe-exxl-16 {
    padding-inline-end: 4rem !important;
  }
  .px-exxl-16 {
    padding-inline: 4rem !important;
  }
  .m-exxl-16 {
    margin: 4rem !important;
  }
  .mt-exxl-16 {
    margin-block-start: 4rem !important;
  }
  .mb-exxl-16 {
    margin-block-end: 4rem !important;
  }
  .my-exxl-16 {
    margin-block: 4rem !important;
  }
  .ms-exxl-16 {
    margin-inline-start: 4rem !important;
  }
  .me-exxl-16 {
    margin-inline-end: 4rem !important;
  }
  .mx-exxl-16 {
    margin-inline: 4rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-17 {
    gap: 4.25rem !important;
  }
  .p-exxl-17 {
    padding: 4.25rem !important;
  }
  .pt-exxl-17 {
    padding-block-start: 4.25rem !important;
  }
  .pb-exxl-17 {
    padding-block-end: 4.25rem !important;
  }
  .py-exxl-17 {
    padding-block: 4.25rem !important;
  }
  .ps-exxl-17 {
    padding-inline-start: 4.25rem !important;
  }
  .pe-exxl-17 {
    padding-inline-end: 4.25rem !important;
  }
  .px-exxl-17 {
    padding-inline: 4.25rem !important;
  }
  .m-exxl-17 {
    margin: 4.25rem !important;
  }
  .mt-exxl-17 {
    margin-block-start: 4.25rem !important;
  }
  .mb-exxl-17 {
    margin-block-end: 4.25rem !important;
  }
  .my-exxl-17 {
    margin-block: 4.25rem !important;
  }
  .ms-exxl-17 {
    margin-inline-start: 4.25rem !important;
  }
  .me-exxl-17 {
    margin-inline-end: 4.25rem !important;
  }
  .mx-exxl-17 {
    margin-inline: 4.25rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-18 {
    gap: 4.5rem !important;
  }
  .p-exxl-18 {
    padding: 4.5rem !important;
  }
  .pt-exxl-18 {
    padding-block-start: 4.5rem !important;
  }
  .pb-exxl-18 {
    padding-block-end: 4.5rem !important;
  }
  .py-exxl-18 {
    padding-block: 4.5rem !important;
  }
  .ps-exxl-18 {
    padding-inline-start: 4.5rem !important;
  }
  .pe-exxl-18 {
    padding-inline-end: 4.5rem !important;
  }
  .px-exxl-18 {
    padding-inline: 4.5rem !important;
  }
  .m-exxl-18 {
    margin: 4.5rem !important;
  }
  .mt-exxl-18 {
    margin-block-start: 4.5rem !important;
  }
  .mb-exxl-18 {
    margin-block-end: 4.5rem !important;
  }
  .my-exxl-18 {
    margin-block: 4.5rem !important;
  }
  .ms-exxl-18 {
    margin-inline-start: 4.5rem !important;
  }
  .me-exxl-18 {
    margin-inline-end: 4.5rem !important;
  }
  .mx-exxl-18 {
    margin-inline: 4.5rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-19 {
    gap: 4.75rem !important;
  }
  .p-exxl-19 {
    padding: 4.75rem !important;
  }
  .pt-exxl-19 {
    padding-block-start: 4.75rem !important;
  }
  .pb-exxl-19 {
    padding-block-end: 4.75rem !important;
  }
  .py-exxl-19 {
    padding-block: 4.75rem !important;
  }
  .ps-exxl-19 {
    padding-inline-start: 4.75rem !important;
  }
  .pe-exxl-19 {
    padding-inline-end: 4.75rem !important;
  }
  .px-exxl-19 {
    padding-inline: 4.75rem !important;
  }
  .m-exxl-19 {
    margin: 4.75rem !important;
  }
  .mt-exxl-19 {
    margin-block-start: 4.75rem !important;
  }
  .mb-exxl-19 {
    margin-block-end: 4.75rem !important;
  }
  .my-exxl-19 {
    margin-block: 4.75rem !important;
  }
  .ms-exxl-19 {
    margin-inline-start: 4.75rem !important;
  }
  .me-exxl-19 {
    margin-inline-end: 4.75rem !important;
  }
  .mx-exxl-19 {
    margin-inline: 4.75rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-20 {
    gap: 5rem !important;
  }
  .p-exxl-20 {
    padding: 5rem !important;
  }
  .pt-exxl-20 {
    padding-block-start: 5rem !important;
  }
  .pb-exxl-20 {
    padding-block-end: 5rem !important;
  }
  .py-exxl-20 {
    padding-block: 5rem !important;
  }
  .ps-exxl-20 {
    padding-inline-start: 5rem !important;
  }
  .pe-exxl-20 {
    padding-inline-end: 5rem !important;
  }
  .px-exxl-20 {
    padding-inline: 5rem !important;
  }
  .m-exxl-20 {
    margin: 5rem !important;
  }
  .mt-exxl-20 {
    margin-block-start: 5rem !important;
  }
  .mb-exxl-20 {
    margin-block-end: 5rem !important;
  }
  .my-exxl-20 {
    margin-block: 5rem !important;
  }
  .ms-exxl-20 {
    margin-inline-start: 5rem !important;
  }
  .me-exxl-20 {
    margin-inline-end: 5rem !important;
  }
  .mx-exxl-20 {
    margin-inline: 5rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-21 {
    gap: 5.25rem !important;
  }
  .p-exxl-21 {
    padding: 5.25rem !important;
  }
  .pt-exxl-21 {
    padding-block-start: 5.25rem !important;
  }
  .pb-exxl-21 {
    padding-block-end: 5.25rem !important;
  }
  .py-exxl-21 {
    padding-block: 5.25rem !important;
  }
  .ps-exxl-21 {
    padding-inline-start: 5.25rem !important;
  }
  .pe-exxl-21 {
    padding-inline-end: 5.25rem !important;
  }
  .px-exxl-21 {
    padding-inline: 5.25rem !important;
  }
  .m-exxl-21 {
    margin: 5.25rem !important;
  }
  .mt-exxl-21 {
    margin-block-start: 5.25rem !important;
  }
  .mb-exxl-21 {
    margin-block-end: 5.25rem !important;
  }
  .my-exxl-21 {
    margin-block: 5.25rem !important;
  }
  .ms-exxl-21 {
    margin-inline-start: 5.25rem !important;
  }
  .me-exxl-21 {
    margin-inline-end: 5.25rem !important;
  }
  .mx-exxl-21 {
    margin-inline: 5.25rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-22 {
    gap: 5.5rem !important;
  }
  .p-exxl-22 {
    padding: 5.5rem !important;
  }
  .pt-exxl-22 {
    padding-block-start: 5.5rem !important;
  }
  .pb-exxl-22 {
    padding-block-end: 5.5rem !important;
  }
  .py-exxl-22 {
    padding-block: 5.5rem !important;
  }
  .ps-exxl-22 {
    padding-inline-start: 5.5rem !important;
  }
  .pe-exxl-22 {
    padding-inline-end: 5.5rem !important;
  }
  .px-exxl-22 {
    padding-inline: 5.5rem !important;
  }
  .m-exxl-22 {
    margin: 5.5rem !important;
  }
  .mt-exxl-22 {
    margin-block-start: 5.5rem !important;
  }
  .mb-exxl-22 {
    margin-block-end: 5.5rem !important;
  }
  .my-exxl-22 {
    margin-block: 5.5rem !important;
  }
  .ms-exxl-22 {
    margin-inline-start: 5.5rem !important;
  }
  .me-exxl-22 {
    margin-inline-end: 5.5rem !important;
  }
  .mx-exxl-22 {
    margin-inline: 5.5rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-23 {
    gap: 5.75rem !important;
  }
  .p-exxl-23 {
    padding: 5.75rem !important;
  }
  .pt-exxl-23 {
    padding-block-start: 5.75rem !important;
  }
  .pb-exxl-23 {
    padding-block-end: 5.75rem !important;
  }
  .py-exxl-23 {
    padding-block: 5.75rem !important;
  }
  .ps-exxl-23 {
    padding-inline-start: 5.75rem !important;
  }
  .pe-exxl-23 {
    padding-inline-end: 5.75rem !important;
  }
  .px-exxl-23 {
    padding-inline: 5.75rem !important;
  }
  .m-exxl-23 {
    margin: 5.75rem !important;
  }
  .mt-exxl-23 {
    margin-block-start: 5.75rem !important;
  }
  .mb-exxl-23 {
    margin-block-end: 5.75rem !important;
  }
  .my-exxl-23 {
    margin-block: 5.75rem !important;
  }
  .ms-exxl-23 {
    margin-inline-start: 5.75rem !important;
  }
  .me-exxl-23 {
    margin-inline-end: 5.75rem !important;
  }
  .mx-exxl-23 {
    margin-inline: 5.75rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-24 {
    gap: 6rem !important;
  }
  .p-exxl-24 {
    padding: 6rem !important;
  }
  .pt-exxl-24 {
    padding-block-start: 6rem !important;
  }
  .pb-exxl-24 {
    padding-block-end: 6rem !important;
  }
  .py-exxl-24 {
    padding-block: 6rem !important;
  }
  .ps-exxl-24 {
    padding-inline-start: 6rem !important;
  }
  .pe-exxl-24 {
    padding-inline-end: 6rem !important;
  }
  .px-exxl-24 {
    padding-inline: 6rem !important;
  }
  .m-exxl-24 {
    margin: 6rem !important;
  }
  .mt-exxl-24 {
    margin-block-start: 6rem !important;
  }
  .mb-exxl-24 {
    margin-block-end: 6rem !important;
  }
  .my-exxl-24 {
    margin-block: 6rem !important;
  }
  .ms-exxl-24 {
    margin-inline-start: 6rem !important;
  }
  .me-exxl-24 {
    margin-inline-end: 6rem !important;
  }
  .mx-exxl-24 {
    margin-inline: 6rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-25 {
    gap: 6.25rem !important;
  }
  .p-exxl-25 {
    padding: 6.25rem !important;
  }
  .pt-exxl-25 {
    padding-block-start: 6.25rem !important;
  }
  .pb-exxl-25 {
    padding-block-end: 6.25rem !important;
  }
  .py-exxl-25 {
    padding-block: 6.25rem !important;
  }
  .ps-exxl-25 {
    padding-inline-start: 6.25rem !important;
  }
  .pe-exxl-25 {
    padding-inline-end: 6.25rem !important;
  }
  .px-exxl-25 {
    padding-inline: 6.25rem !important;
  }
  .m-exxl-25 {
    margin: 6.25rem !important;
  }
  .mt-exxl-25 {
    margin-block-start: 6.25rem !important;
  }
  .mb-exxl-25 {
    margin-block-end: 6.25rem !important;
  }
  .my-exxl-25 {
    margin-block: 6.25rem !important;
  }
  .ms-exxl-25 {
    margin-inline-start: 6.25rem !important;
  }
  .me-exxl-25 {
    margin-inline-end: 6.25rem !important;
  }
  .mx-exxl-25 {
    margin-inline: 6.25rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-26 {
    gap: 6.5rem !important;
  }
  .p-exxl-26 {
    padding: 6.5rem !important;
  }
  .pt-exxl-26 {
    padding-block-start: 6.5rem !important;
  }
  .pb-exxl-26 {
    padding-block-end: 6.5rem !important;
  }
  .py-exxl-26 {
    padding-block: 6.5rem !important;
  }
  .ps-exxl-26 {
    padding-inline-start: 6.5rem !important;
  }
  .pe-exxl-26 {
    padding-inline-end: 6.5rem !important;
  }
  .px-exxl-26 {
    padding-inline: 6.5rem !important;
  }
  .m-exxl-26 {
    margin: 6.5rem !important;
  }
  .mt-exxl-26 {
    margin-block-start: 6.5rem !important;
  }
  .mb-exxl-26 {
    margin-block-end: 6.5rem !important;
  }
  .my-exxl-26 {
    margin-block: 6.5rem !important;
  }
  .ms-exxl-26 {
    margin-inline-start: 6.5rem !important;
  }
  .me-exxl-26 {
    margin-inline-end: 6.5rem !important;
  }
  .mx-exxl-26 {
    margin-inline: 6.5rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-27 {
    gap: 6.75rem !important;
  }
  .p-exxl-27 {
    padding: 6.75rem !important;
  }
  .pt-exxl-27 {
    padding-block-start: 6.75rem !important;
  }
  .pb-exxl-27 {
    padding-block-end: 6.75rem !important;
  }
  .py-exxl-27 {
    padding-block: 6.75rem !important;
  }
  .ps-exxl-27 {
    padding-inline-start: 6.75rem !important;
  }
  .pe-exxl-27 {
    padding-inline-end: 6.75rem !important;
  }
  .px-exxl-27 {
    padding-inline: 6.75rem !important;
  }
  .m-exxl-27 {
    margin: 6.75rem !important;
  }
  .mt-exxl-27 {
    margin-block-start: 6.75rem !important;
  }
  .mb-exxl-27 {
    margin-block-end: 6.75rem !important;
  }
  .my-exxl-27 {
    margin-block: 6.75rem !important;
  }
  .ms-exxl-27 {
    margin-inline-start: 6.75rem !important;
  }
  .me-exxl-27 {
    margin-inline-end: 6.75rem !important;
  }
  .mx-exxl-27 {
    margin-inline: 6.75rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-28 {
    gap: 7rem !important;
  }
  .p-exxl-28 {
    padding: 7rem !important;
  }
  .pt-exxl-28 {
    padding-block-start: 7rem !important;
  }
  .pb-exxl-28 {
    padding-block-end: 7rem !important;
  }
  .py-exxl-28 {
    padding-block: 7rem !important;
  }
  .ps-exxl-28 {
    padding-inline-start: 7rem !important;
  }
  .pe-exxl-28 {
    padding-inline-end: 7rem !important;
  }
  .px-exxl-28 {
    padding-inline: 7rem !important;
  }
  .m-exxl-28 {
    margin: 7rem !important;
  }
  .mt-exxl-28 {
    margin-block-start: 7rem !important;
  }
  .mb-exxl-28 {
    margin-block-end: 7rem !important;
  }
  .my-exxl-28 {
    margin-block: 7rem !important;
  }
  .ms-exxl-28 {
    margin-inline-start: 7rem !important;
  }
  .me-exxl-28 {
    margin-inline-end: 7rem !important;
  }
  .mx-exxl-28 {
    margin-inline: 7rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-29 {
    gap: 7.25rem !important;
  }
  .p-exxl-29 {
    padding: 7.25rem !important;
  }
  .pt-exxl-29 {
    padding-block-start: 7.25rem !important;
  }
  .pb-exxl-29 {
    padding-block-end: 7.25rem !important;
  }
  .py-exxl-29 {
    padding-block: 7.25rem !important;
  }
  .ps-exxl-29 {
    padding-inline-start: 7.25rem !important;
  }
  .pe-exxl-29 {
    padding-inline-end: 7.25rem !important;
  }
  .px-exxl-29 {
    padding-inline: 7.25rem !important;
  }
  .m-exxl-29 {
    margin: 7.25rem !important;
  }
  .mt-exxl-29 {
    margin-block-start: 7.25rem !important;
  }
  .mb-exxl-29 {
    margin-block-end: 7.25rem !important;
  }
  .my-exxl-29 {
    margin-block: 7.25rem !important;
  }
  .ms-exxl-29 {
    margin-inline-start: 7.25rem !important;
  }
  .me-exxl-29 {
    margin-inline-end: 7.25rem !important;
  }
  .mx-exxl-29 {
    margin-inline: 7.25rem !important;
  }
}
@media (min-width: 1920px) {
  .gap-exxl-30 {
    gap: 7.5rem !important;
  }
  .p-exxl-30 {
    padding: 7.5rem !important;
  }
  .pt-exxl-30 {
    padding-block-start: 7.5rem !important;
  }
  .pb-exxl-30 {
    padding-block-end: 7.5rem !important;
  }
  .py-exxl-30 {
    padding-block: 7.5rem !important;
  }
  .ps-exxl-30 {
    padding-inline-start: 7.5rem !important;
  }
  .pe-exxl-30 {
    padding-inline-end: 7.5rem !important;
  }
  .px-exxl-30 {
    padding-inline: 7.5rem !important;
  }
  .m-exxl-30 {
    margin: 7.5rem !important;
  }
  .mt-exxl-30 {
    margin-block-start: 7.5rem !important;
  }
  .mb-exxl-30 {
    margin-block-end: 7.5rem !important;
  }
  .my-exxl-30 {
    margin-block: 7.5rem !important;
  }
  .ms-exxl-30 {
    margin-inline-start: 7.5rem !important;
  }
  .me-exxl-30 {
    margin-inline-end: 7.5rem !important;
  }
  .mx-exxl-30 {
    margin-inline: 7.5rem !important;
  }
}
/*---------------------------------------
    Template Name:  Placewise - Online Booking HTML Template
    Description:    Online Booking HTML Template
    Author Name:    Pixelaxis
    Author URI:     https://themeforest.net/user/pixelaxis
    Theme URI:      https://pix-placewise.vercel.app/
    Version:        1.0
-----------------------------------------
    Table of contents
-----------------------------------------
    Components Style
    Sections Style
    Utilities Style
---------------------------------------*/