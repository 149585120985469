@use "../global" as *;

.cta-section-ii {
    @include screen(lg) {
        position: relative;
        isolation: isolate;
    }

    &::after {
        @include screen(lg) {
            content: '';
            position: absolute;
            inset-block: 0;
            inset-inline-start: 55%;
            inset-inline-end: 0;
            background-image: url(../img/cta-bg-2.png);
            background-size: contain;
            background-position: center bottom;
            background-repeat: no-repeat;
        }
        @include screen(xl) {
            inset-inline-start: 50%;
        }
    }
}