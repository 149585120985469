@use "../global" as *;

.switch {
    &[type="checkbox"] {
        position: relative;
        width: rem(60);
        height: rem(32);
        -webkit-appearance: none;
        background: hsl(var(--primary-50));
        outline: none;
        border-radius: 20px;
        transition: 0.7s;

        &::before {
            content: '';
            position: absolute;
            width: rem(28);
            height: rem(28);
            border-radius: 1.5rem;
            top: 2px;
            left: 2px;
            background: hsl(var(--neutral-0));
            box-shadow: 0 0 .25rem hsl(var(--black)/.1);
            transition: .5s;
        }
    }

    &:checked {
        &[type="checkbox"] {
            background: hsl(var(--primary-300));

            &::before {
                left: rem(30);
            }
        }
    }
}