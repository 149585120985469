@use "../global" as *;

.discover-location-section {
    position: relative;
    isolation: isolate;

    &::before {
        @include screen(exxl) {
            content: url(../img/location-el.png);
            position: absolute;
            inset-block-start: #{rem(65)};
            inset-inline-end: #{rem(120)};
        }
    }

    &::after {
        content: '';
        position: absolute;
        inset-block-start: 0;
        inset-block-end: 50%;
        inset-inline: 0;
        background-color: hsl(var(--primary-300)/.05);
        z-index: -1;
    }
}