@use "../global" as *;

.primary-hero {
    padding-top: clamp(3.75rem, 6.5vw, 7.5rem);
    padding-bottom: clamp(3.75rem, 6.5vw, 7.5rem);
    background-color: hsl(var(--primary-300)/.03);

    @include screen(xl) {
        display: grid;
        place-content: center;
        min-height: calc(100vh - 10.75rem);
        position: relative;
        isolation: isolate;
    }

    &__el {
        display: none;

        @include screen(xl) {
            display: block;
            position: absolute;
            z-index: -1;

            &-1 {
                inset-block-start: 0;
                inset-inline-start: 0;
                opacity: .25;

                @include screen(exxl) {
                    opacity: 1;
                }
            }

            &-2 {
                inset-block-start: 0;
                inset-inline-end: 0;
                opacity: .25;

                @include screen(exxl) {
                    opacity: 1;
                }
            }

            &-3 {
                inset-block-start: #{rem(70)};
                inset-inline-start: #{rem(190)};

                @include screen(exxl) {
                    inset-block-start: #{rem(100)};
                    inset-inline-start: #{rem(340)};
                }
            }

            &-4 {
                inset-block-start: #{rem(40)};
                inset-inline-end: #{rem(315)};

                @include screen(exxl) {
                    inset-block-start: #{rem(88)};
                    inset-inline-end: #{rem(530)};
                }
            }

            &-5 {
                inset-block-start: #{rem(410)};
                inset-inline-start: #{rem(575)};
                opacity: 0;

                @include screen(exxl) {
                    opacity: 1;
                }
            }

            &-6 {
                inset-block-start: #{rem(370)};
                inset-inline-end: #{rem(515)};
                opacity: 0;

                @include screen(exxl) {
                    opacity: 1;
                }
            }

            &-7 {
                inset-block-end: 1rem;
                inset-inline-start: #{rem(320)};

                @include screen(exxl) {
                    inset-block-end: 1rem;
                    inset-inline-start: #{rem(540)};
                }
            }

            &-8 {
                inset-block-end: 1rem;
                inset-inline-end: #{rem(80)};
                transform: scale(.8);

                @include screen(exxl) {
                    inset-block-end: .5rem;
                    inset-inline-end: #{rem(130)};
                }
            }
        }
    }

    &__img-1 {
        inset-block-start: 0;
        inset-inline-end: 0;
        width: #{rem(300)};

        @include screen(exxl) {
            width: auto;
        }
    }

    &__img-2 {
        inset-block-end: 0;
        inset-inline-start: 0;
        width: #{rem(300)};

        @include screen(exxl) {
            width: auto;
        }
    }
}