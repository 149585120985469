@use "../global" as *;

.about-tab {
    flex-direction: row;
    flex-wrap: wrap;

    .list-group-item {
        padding: .5rem;
        border-radius: 0;
        border: 0;
        background-color: transparent;
        font-weight: 500;
        color: hsl(var(--neutral-600));

        @include screen(xs) {
            padding: .5rem 1rem;
        }

        @include screen(md) {
            padding: .5rem 2rem;
        }

        &:first-child {
            padding-inline-start: 0;

            &.active {
                &::after {
                    inset-inline-start: 0;
                    transform: translateX(0);
                    width: calc(100% - .5rem);

                    @include screen(xs) {
                        width: calc(100% - 1rem);
                    }

                    @include screen(md) {
                        width: calc(100% - 2rem);
                    }
                }
            }
        }

        &.active {
            position: relative;
            isolation: isolate;
            color: hsl(var(--primary-300));

            &::after {
                content: '';
                position: absolute;
                inset-inline-start: 50%;
                inset-block-end: 0;
                transform: translateX(-50%);
                width: calc(100% - 1rem);
                height: 1px;
                background-color: hsl(var(--primary-300));

                @include screen(xs) {
                    width: calc(100% - 2rem);
                }

                @include screen(md) {
                    width: calc(100% - 3rem);
                }
            }
        }

        &+.list-group-item {
            @include screen(md) {
                &::before {
                    content: '';
                    position: absolute;
                    inset-inline-start: 0;
                    inset-block: .5rem;
                    width: 1px;
                    background-color: hsl(var(--neutral-200));
                }
            }
        }
    }
}