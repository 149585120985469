@use "../global" as *;

.property-card {
    background-color: hsl(var(--neutral-0));
    border-radius: 1rem;
    transition: all .3s ease;

    &:hover {
        box-shadow: 0 .5rem 1.5rem hsl(var(--black)/.1);
    }

    &__head {
        padding: .5rem;
        position: relative;
        isolation: isolate;
    }

    &__img {
        border-radius: 1rem;

        img {
            border-radius: inherit;
        }
    }

    &__tag {
        position: absolute;
        inset-block-start: 1.75rem;
        inset-inline-start: 1.75rem;
        z-index: 1;
    }

    &__fav {
        position: absolute;
        inset-block-start: 1.75rem;
        inset-inline-end: 1.75rem;
        z-index: 1;
    }

    &__heart {
        &.solid {
            color: hsl(var(--tertiary-300));
        }
    }

    &__popular-tag {
        display: inline-block;
        position: absolute;
        inset-block-end: .5rem;
        inset-inline-start: -.5rem;
        border-radius: .25rem .25rem .25rem 0;
        transform: translateY(50%);
        z-index: 1;

        span {
            position: relative;
            isolation: isolate;
            border-radius: inherit;

            &::after {
                content: '';
                width: .5rem;
                height: .5rem;
                position: absolute;
                inset-block-start: calc(100% - 1px);
                inset-inline-start: 0;
                background-color: hsl(var(--primary-400));
                border-radius: 0 0 0 .25rem;
            }
        }
    }

    &__body {
        padding: 1.5rem .5rem;

        @include screen(xs) {
            padding: 2rem 1rem;
        }

        @include screen(lg) {
            padding: 2rem;
        }

        @include screen(xl) {
            padding: 1.5rem;
        }
    }

    &-next {
        --swiper-navigation-size: 38px;
        width: var(--swiper-navigation-size);
        background-color: hsl(var(--primary-50));
        border: 1px solid hsl(var(--primary-300));
        border-radius: 50%;
        color: hsl(var(--primary-300));
        opacity: .5;
        transition: all .3s ease;

        &::after {
            --swiper-navigation-size: 20px;
        }

        &:hover {
            background-color: hsl(var(--primary-300));
            color: hsl(var(--neutral-0));
            opacity: 1;
        }
    }

    &-prev {
        --swiper-navigation-size: 38px;
        width: var(--swiper-navigation-size);
        background-color: hsl(var(--primary-50));
        border: 1px solid hsl(var(--primary-300));
        border-radius: 50%;
        color: hsl(var(--primary-300));
        opacity: .5;
        transition: all .3s ease;

        &::after {
            --swiper-navigation-size: 20px;
        }

        &:hover {
            background-color: hsl(var(--primary-300));
            color: hsl(var(--neutral-0));
            opacity: 1;
        }
    }

    &-pagination {
        .swiper-pagination-bullet {
            background: hsl(var(--neutral-40));
            opacity: 1;

            &-active {
                background: hsl(var(--tertiary-300));
            }
        }
    }

    &--row {
        @include screen(md) {
            display: flex;
        }

        .property-card__img {
            @include screen(md) {
                height: 100%;

                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .property-card__head {
            @include screen(md) {
                flex-shrink: 0;
                width: 45%;
            }
        }

        .dotted-divider li+li {
            @include screen(xl) {
                padding-inline-start: 0.75rem;
                margin-inline-start: 0.75rem;
            }

            @include screen(xxl) {
                padding-inline-start: 1.25rem;
                margin-inline-start: 1.25rem;
            }
        }

        .property-card__body {
            @include screen(xl) {
                padding-inline: 1rem;
            }
        }

        .property-card-slider {
            @include screen(md) {
                height: 100%;
            }
        }

        .property-card__content {
            @include screen(md) {
                flex-grow: 1;
            }
        }
    }

    &--row-sm {
        .property-card__head {
            @include screen(md) {
                flex-shrink: 0;
                width: 45%;
            }

            @include screen(xl) {
                width: 30%;
            }
        }
    }
}