@use "../global" as *;

.app-section {
    padding-inline: 1rem;
    border-radius: 1rem;

    @include screen(md) {
        padding-inline-start: 3.75rem;
    }

    @include screen(lg) {
        padding-inline-start: #{rem(120)};
    }

    @include screen(xl) {
        position: relative;
        isolation: isolate;

        &::after {
            content: '';
            position: absolute;
            inset-block: 0;
            inset-inline-start: 67%;
            inset-inline-end: -120px;
            background-image: url(../img/app-img.jpg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 50%;
            transform: scale(1.2);
        }
    }
}