@use "../global" as *;

.section-space {
    padding-top: clamp(3.75rem, 6.5vw + 1rem, 7.5rem);
    padding-bottom: clamp(3.75rem, 6.5vw + 1rem, 7.5rem);

    &--sm {
        padding-top: clamp(2rem, 3.25vw + 1rem, 3.75rem);
        padding-bottom: clamp(2rem, 3.25vw + 1rem, 3.75rem);

        &-top {
            padding-top: clamp(2rem, 3.25vw + 1rem, 3.75rem);
        }

        &-bottom {
            padding-bottom: clamp(2rem, 3.25vw + 1rem, 3.75rem);
        }
    }

    &-top {
        padding-top: clamp(3.75rem, 6.5vw + 1rem, 7.5rem);
    }

    &-bottom {
        padding-bottom: clamp(3.75rem, 6.5vw + 1rem, 7.5rem);
    }
}

.section-exxl-nspace {
    @include screen(exxl) {
        margin-top: -#{rem(150)};
    }
}