@use "../global" as *;

.calculate-section {
    position: relative;
    isolation: isolate;

    @include screen(lg) {
        &::after {
            content: '';
            position: absolute;
            inset-block: clamp(3.75rem, 6.5vw + 1rem, 7.5rem);
            inset-inline-end: 0;
            inset-inline-start: 55%;
            background-image: url(../img/calculate-section-bg.png);
            background-size: contain;
            background-position: right center;
            background-repeat: no-repeat;
        }
    }

    &__content {
        @include screen(lg) {
            padding-block: clamp(3.75rem, 6.5vw + 1rem, 7.5rem);
        }
    }
}