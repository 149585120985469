@use "../global" as *;

.listing-card {
    --listing-overlay-clr: var(--neutral-900);
    position: relative;
    isolation: isolate;

    &:hover {
        --listing-overlay-clr: var(--primary-500);

        .btn-outline-secondary {
            background: hsl(var(--secondary-300));
            color: hsl(var(--neutral-900));
        }
    }

    &__img {
        position: relative;
        isolation: isolate;
        border-radius: 1rem;

        &::after {
            content: '';
            position: absolute;
            inset: 0;
            background: linear-gradient(180deg, hsl(var(--listing-overlay-clr)/0) 0%, hsl(var(--listing-overlay-clr)/.3) 25%, hsl(var(--listing-overlay-clr)/.7) 60%, hsl(var(--listing-overlay-clr)) 100%);
            border-radius: inherit;
        }

        img {
            min-height: 400px;
            object-fit: cover;
            border-radius: inherit;

            @include screen(xl) {
                min-height: unset;
            }
        }
    }

    &__content {
        padding: 1rem;
        position: absolute;
        inset: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include screen(sm) {
            padding: 1.5rem;
        }
    }
}