$screen-sizes: (
	xxs: 320px,
	xs: 375px,
	xs-plus: 410px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
	exl: 1600px,
	exxl: 1920px,
);
$flex-directions: (
	row,
	column
);
$colors: (
	primary: (50: 240 82% 96%,
		75: 239 84% 83%,
		100: 238 84% 75%,
		200: 239 84% 64%,
		300: 239 84% 57%,
		400: 239 63% 40%,
		500: 239 63% 35%),
	secondary: (50: 146 67% 95%,
		75: 145 64% 80%,
		100: 145 63% 72%,
		200: 146 64% 60%,
		300: 146 63% 52%,
		400: 146 58% 36%,
		500: 146 58% 32%),
	tertiary: (50: 40 100% 96%,
		75: 39 100% 85%,
		100: 39 100% 79%,
		200: 39 100% 70%,
		300: 39 100% 64%,
		400: 39 57% 45%,
		500: 39 57% 39%,
	),
	neutral: (0: 0 0% 100%,
		10: 180 11% 98%,
		20: 210 11% 96%,
		30: 216 14% 93%,
		40: 214 12% 89%,
		50: 219 13% 79%,
		60: 219 13% 74%,
		70: 217 13% 69%,
		80: 218 13% 64%,
		90: 218 13% 59%,
		100: 217 13% 54%,
		200: 218 14% 49%,
		300: 217 17% 44%,
		400: 218 20% 39%,
		500: 218 25% 34%,
		600: 217 31% 30%,
		700: 218 41% 24%,
		800: 218 56% 19%,
		900: 218 76% 15%,
		1000: 239 84% 10%)
);
$brand-colors: (
	fb: 214 89% 52%,
	tw: 203 89% 53%,
	yt: 0 100% 50%,
	ins: 340 75% 54%,
	in: 201 100% 35%,
	pin: 351 100% 45%,
	wapp: 142 70% 49%,
	dbl: 337 79% 61%,
	be: 219 100% 55%,
	vim: 195 83% 51%,
	wc: 138 91% 38%,
	rdt: 16 100% 50%,
	skp: 196 100% 47%,
	snap: 59 100% 50%,
	twitch: 264 100% 64%,
	qra: 13 100% 33%,
);
$spacer: 1rem;
$spacers: (
	0: 0,
	1: $spacer * 0.25,
	2: $spacer * 0.5,
	3: $spacer * 0.75,
	4: $spacer,
	5: $spacer * 1.25,
	6: $spacer * 1.5,
	7: $spacer * 1.75,
	8: $spacer * 2,
	9: $spacer * 2.25,
	10: $spacer * 2.5,
	11: $spacer * 2.75,
	12: $spacer * 3,
	13: $spacer * 3.25,
	14: $spacer * 3.5,
	15: $spacer * 3.75,
	16: $spacer * 4,
	17: $spacer * 4.25,
	18: $spacer * 4.5,
	19: $spacer * 4.75,
	20: $spacer * 5,
	21: $spacer * 5.25,
	22: $spacer * 5.5,
	23: $spacer * 5.75,
	24: $spacer * 6,
	25: $spacer * 6.25,
	26: $spacer * 6.5,
	27: $spacer * 6.75,
	28: $spacer * 7,
	29: $spacer * 7.25,
	30: $spacer * 7.5,
);

:root {
	// Font Size (19px = 1vw @screen 1920px)
	--d1: clamp(2.4rem, 2.526vw + 1rem, 5rem); //80px
	--d2: clamp(2rem, 2.105vw + 1rem, 4.5rem); //72px
	--d3: clamp(1.6rem, 1.684vw + 1rem, 4rem); //64px
	--d4: clamp(1.2rem, 1.263vw + 1rem, 3.5rem); //56px
	--h1: clamp(2.4rem, 2.526vw + 1rem, 3rem); //48px
	--h2: clamp(2rem, 2.105vw + 1rem, 2.5rem); //40px
	--h3: clamp(1.6rem, 1.684vw + 1rem, 2rem); //32px
	--h4: clamp(1.2rem, 1.263vw + 1rem, 1.5rem); //24px
	--h5: clamp(1.1rem, 1.052vw + 1rem, 1.25rem); //20px
	--h6: 1rem; //16px

	//Brand Colors
	@each $brand,
	$values in $brand-colors {
		--#{$brand}: #{$values};
	}

	// Reference Colors
	@each $color,
	$shades in $colors {

		@each $shade,
		$value in $shades {
			--#{$color}-#{$shade}: #{$value};
		}
	}

	--black: 0 0% 0%;
	--white: 0 0% 100%;
}