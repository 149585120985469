@use "../global" as *;

.benefit-section {
    position: relative;
    isolation: isolate;

    &::before {
        @include screen(exxl) {
            content: url(../img/category-section-el.png);
            position: absolute;
            inset-block-end: #{rem(60)};
            inset-inline-end: #{rem(32)};
        }
    }

    &::after {
        @include screen(lg) {
            content: '';
            position: absolute;
            inset-block: 0;
            inset-inline-start: 0;
            inset-inline-end: 55%;
            background-image: url(../img/benefit-section-bg.jpg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            z-index: -1;
        }

        @include screen(xl) {
            inset-inline-end: 50%;
        }

    }

    &__content {
        @include screen(lg) {
            padding: 1.5rem;
            background-color: hsl(var(--neutral-0));
            border-radius: 1rem;
        }

        @include screen(xxl) {
            padding: #{rem(60)} 0 #{rem(60)} #{rem(110)};
        }
    }
}