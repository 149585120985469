@use "../global" as *;

.team-card {
    &:hover {
        color: hsl(var(--neutral-0));

        h4 {
            color: hsl(var(--neutral-0));
        }

        &::after {
            background-color: hsl(var(--primary-300));
        }
    }

    &::after {
        content: '';
        position: absolute;
        inset-inline: 0;
        inset-block-start: #{rem(85)};
        inset-block-end: 0;
        border-radius: inherit;
        background-color: hsl(var(--neutral-20));
        transition: all .3s ease;
        z-index: -1;

        @include screen(xl) {
            background-color: hsl(var(--neutral-0));
        }
    }
}