.discount-section {
    position: relative;
    isolation: isolate;

    &::after {
        content: '';
        position: absolute;
        inset-inline: 0;
        inset-block-start: 50%;
        inset-block-end: 0;
        background-color: hsl(var(--primary-300)/.05);
    }
}
.discount-section-start {
    position: relative;
    isolation: isolate;

    &::after {
        content: '';
        position: absolute;
        inset-inline: 0;
        inset-block-start: 0;
        inset-block-end: 50%;
        background-color: hsl(var(--primary-300)/.05);
    }
}