@use "../global" as *;

.explore-property-section {
    @include screen(exxl) {
        position: relative;
        isolation: isolate;
    }

    &__el {
        display: none;

        @include screen(exxl) {
            display: block;
            position: absolute;
            z-index: 1;
        }

        &-1 {
            @include screen(exxl) {
                inset-block-start: #{rem(200)};
                inset-inline-start: #{rem(360)};
            }
        }

        &-2 {
            @include screen(exxl) {
                inset-block-start: #{rem(100)};
                inset-inline-end: #{rem(90)};
            }
        }

        &-3 {
            @include screen(exxl) {
                inset-block-end: #{rem(100)};
                inset-inline-end: #{rem(100)};
            }
        }
    }

    &__content {
        position: relative;
        isolation: isolate;

        &::after {
            content: '';
            position: absolute;
            inset-block-start: 50%;
            inset-block-end: 0;
            inset-inline: 0;
            background-color: hsl(var(--neutral-1000));
            z-index: -1;
        }
    }
}