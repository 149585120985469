.circle-btn {
    display: grid;
    place-content: center;
    position: relative;
    isolation: isolate;
    aspect-ratio: 1;
    overflow: hidden;

    &:hover {
        span {
            color: hsl(var(--neutral-0));
        }

        &::before {
            width: 180%;
            height: 180%;
        }

        .circle-btn__arrow {
            background-color: hsl(var(--tertiary-300));
            color: hsl(var(--neutral-0));
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: var(--y);
        left: var(--x);
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        border-radius: 50%;
        background-color: hsl(var(--secondary-400));
        transition: all .5s ease;
        z-index: -1;
    }
}