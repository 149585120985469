@use "../global" as *;

.accordion {
    --bs-accordion-border-width: 0;
    --bs-accordion-inner-border-radius: 2rem;
    --bs-accordion-btn-icon-width: 2rem;
    --bs-accordion-body-padding-y: 1.5rem;
    --bs-accordion-body-padding-x: 2.5rem;

    .accordion-item {
        border-radius: var(--bs-accordion-inner-border-radius);

        &:first-of-type {
            border-radius: var(--bs-accordion-inner-border-radius);

            .accordion-button {
                border-radius: var(--bs-accordion-inner-border-radius);
            }
        }
    }

    &-button {
        padding: 1.5rem 2.5rem;
        border-radius: var(--bs-accordion-inner-border-radius);
        font-size: 1.25rem;
        font-weight: 500;

        &::after {
            content: '\e145';
            display: grid;
            place-content: center;
            background: hsl(var(--secondary-500));
            border-radius: 50%;
            font-family: 'Material Symbols Outlined';
            font-weight: 300;
            font-style: normal;
            font-size: 24px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-grid;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            -webkit-font-feature-settings: 'liga';
            -webkit-font-smoothing: antialiased;
            color: hsl(var(--neutral-0));
        }

        &:not(.collapsed) {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;

            &::after {
                content: '\e15b';
                display: grid;
                place-content: center;
                background: hsl(var(--secondary-500));
                border-radius: 50%;
                font-family: 'Material Symbols Outlined';
                font-weight: 300;
                font-style: normal;
                font-size: 24px;
                line-height: 1;
                letter-spacing: normal;
                text-transform: none;
                display: inline-grid;
                white-space: nowrap;
                word-wrap: normal;
                direction: ltr;
                -webkit-font-feature-settings: 'liga';
                -webkit-font-smoothing: antialiased;
                color: hsl(var(--neutral-0));
                transform: unset;
            }
        }
    }

    &-body {
        position: relative;
        isolation: isolate;
        background-color: var(--accordion-bg);
        border-radius: 0 0 2rem 2rem;

        &::after {
            content: '';
            position: absolute;
            inset-block: 0;
            inset-inline: rem(40);
            height: 1px;
            background-image: linear-gradient(to right, hsl(var(--neutral-200)) 33%, transparent 0);
            background-position: bottom;
            background-size: 0.75rem 1px;
            background-repeat: repeat-x;
        }
    }

    &--separated {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    &--secondary {
        --bs-accordion-active-color: hsl(var(--neutral-700));
        --bs-accordion-active-bg: hsl(var(--secondary-50));
        --bs-accordion-btn-focus-box-shadow: 0 0 0 0 transparent;
        --bs-accordion-btn-bg: hsl(var(--secondary-50));
        --bs-accordion-btn-color: hsl(var(--neutral-700));
        --accordion-bg: hsl(var(--secondary-50));
    }
}