@use "functions" as *;
@use "media-query" as mq;

html {
  font-size: 100%;
}

body {
  position: relative;
  background: var(--white);
  font-family: var(--ff-1);
  font-size: rem(16);
  font-weight: 400;
  line-height: 1.5;
  color: hsl(var(--neutral-700));
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// Dropdown Customize
.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
}

.container-max {
  @include mq.screen(exl) {
    max-width: 1540px;
    margin-left: auto;
    margin-right: auto;
  }

  @include mq.screen(exxl) {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
  }
}
form {
  margin: 0;
}