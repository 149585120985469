@use "../global" as *;

.chat-box {
    @include screen(md) {
        display: flex;
    }

    &__nav {
        border-bottom: 1px solid hsl(var(--neutral-40));

        @include screen(md) {
            width: rem(300);
            flex-shrink: 0;
            border-right: 1px solid hsl(var(--neutral-40));
            border-bottom: 0;
        }

        @include screen(lg) {
            width: rem(280);
        }

        @include screen(xl) {
            width: rem(300);
        }
    }

    &__menu {
        max-height: rem(568);

        @include screen(md) {
            max-height: rem(635);
        }

        @include screen(xl) {
            max-height: rem(580);
        }
    }

    &__content {
        flex-grow: 1;

        &-body {
            max-height: rem(568);

            @include screen(md) {
                max-height: rem(600);
            }
        }
    }

    &__message {
        display: inline-flex;
        padding: .75rem 1.25rem;
        position: relative;
        isolation: isolate;
        border-radius: .5rem;
        background-color: hsl(var(--neutral-0));

        @include screen(md) {
            max-width: 75%;
        }

        @include screen(lg) {
            max-width: 100%;
        }

        @include screen(xl) {
            max-width: 45%;
        }

        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
        }

        &.arrow-up {
            &::after {
                inset-block-end: 100%;
                inset-inline-start: .75rem;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-bottom: 12px solid hsl(var(--neutral-0));
            }
        }

        &.arrow-bottom {
            &::after {
                inset-block-start: 100%;
                inset-inline-end: .75rem;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-top: 12px solid hsl(var(--neutral-0));
            }
        }
    }
}