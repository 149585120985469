.property-showcase {

    &::after {
        content: '';
        position: absolute;
        inset: .25rem;
        background-color: hsl(var(--primary-300)/.2);
        border-radius: inherit;
        z-index: 0;
    }
}