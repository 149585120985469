@use "../global" as *;

.list {
	--direction: column;
	display: flex;
	flex-direction: var(--direction);
	margin: 0;
	padding: 0;
	list-style: none;

	@each $direction in $flex-directions {
		&-#{$direction} {
			--direction: #{$direction};
		}

		@each $screen,
		$value in $screen-sizes {
			&-#{$screen}-#{$direction} {
				@include screen(#{$screen}) {
					--direction: #{$direction};
				}
			}
		}
	}

	&-disc {
		list-style: disc;
		padding-inline-start: 1rem;
	}

	&-divider {
		&>li+li {
			padding-inline-start: 1rem;
			margin-inline-start: 1rem;
			position: relative;
			isolation: isolate;

			&::before {
				content: '';
				position: absolute;
				inset-block-start: 50%;
				inset-inline-start: 0;
				transform: translateY(-50%);
				width: 1px;
				height: 70%;
				background-color: hsl(var(--neutral-50));
			}
		}
	}

	&-divider-half {
		&>li+li {
			padding-inline-start: 1rem;
			margin-inline-start: 1rem;
			position: relative;
			isolation: isolate;

			&::before {
				content: '';
				position: absolute;
				inset-block-start: 50%;
				inset-inline-start: 0;
				transform: translateY(-50%);
				width: 1px;
				height: 45%;
				background-color: hsl(var(--neutral-50));
			}
		}
	}

	&-divider-half-xs {
		&>li+li {
			padding-inline-start: .5rem;
			margin-inline-start: .5rem;
			position: relative;
			isolation: isolate;

			&::before {
				content: '';
				position: absolute;
				inset-block-start: 50%;
				inset-inline-start: 0;
				transform: translateY(-50%);
				width: 1px;
				height: 50%;
				background-color: hsl(var(--neutral-500));
			}
		}
	}

	&-divider-dot {
		&>li+li {
			@include screen(md) {
				padding-inline-start: 1.5rem;
				margin-inline-start: 1.5rem;
				position: relative;
				isolation: isolate;

				&::before {
					content: '';
					position: absolute;
					inset-block-start: 50%;
					inset-inline-start: 0;
					transform: translateY(-50%);
					width: .25rem;
					height: .25rem;
					border-radius: 50%;
					background-color: hsl(var(--primary-300));
				}
			}

			@include screen(lg) {
				padding-inline-start: 3.25rem;
				margin-inline-start: 3.25rem;
			}

			@include screen(xl) {
				padding-inline-start: 1.5rem;
				margin-inline-start: 1.5rem;
			}

			@include screen(xxl) {
				padding-inline-start: 2.75rem;
				margin-inline-start: 2.75rem;
			}
		}
	}

	&-divider-dot-sm {
		&>li+li {
			@include screen(md) {
				padding-inline-start: 1.5rem;
				margin-inline-start: 1.5rem;
				position: relative;
				isolation: isolate;

				&::before {
					content: '';
					position: absolute;
					inset-block-start: 50%;
					inset-inline-start: 0;
					transform: translateY(-50%);
					width: .25rem;
					height: .25rem;
					border-radius: 50%;
					background-color: hsl(var(--primary-300));
				}
			}

			@include screen(lg) {
				padding-inline-start: 3.25rem;
				margin-inline-start: 3.25rem;
			}

			@include screen(xl) {
				padding-inline-start: 1.5rem;
				margin-inline-start: 1.5rem;
			}
		}
	}

	&-divider-dot-xsm {
		&>li+li {
			@include screen(md) {
				padding-inline-start: 1.25rem;
				margin-inline-start: 1.25rem;
				position: relative;
				isolation: isolate;

				&::before {
					content: '';
					position: absolute;
					inset-block-start: 50%;
					inset-inline-start: 0;
					transform: translateY(-50%);
					width: .25rem;
					height: .25rem;
					border-radius: 50%;
					background-color: hsl(var(--primary-300));
				}
			}
		}
	}

	&-arrow {
		&>li+li {
			padding-inline-start: 1rem;
			margin-inline-start: 1rem;
			position: relative;
			isolation: isolate;

			&::before {
				content: "\e5cc";
				font-family: "Material Symbols Outlined";
				font-size: 1.5rem;
				font-weight: 300;
				line-height: 1;
				position: absolute;
				inset-block-start: 50%;
				inset-inline-start: 0;
				transform: translate(-50%, -50%);
				color: hsl(var(--neutral-0));
			}
		}
	}
}