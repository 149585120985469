@use "../global" as *;

.hotel-hero {
    padding-top: clamp(4rem, 7vw, 10rem);
    padding-bottom: clamp(4rem, 7vw, 10rem);
    position: relative;
    isolation: isolate;

    &::after {
        @include screen(xl) {
            content: '';
            position: absolute;
            inset-block-start: rem(60);
            inset-block-end: rem(60);
            inset-inline-end: rem(60);
            inset-inline-start: 50%;
            background-image: url(../img/hotel-hero-bg.png);
            background-size: contain;
            background-position: center top;
            background-repeat: no-repeat;
            z-index: -1;
        }

        @include screen(xxl) {
            inset-block-start: rem(80);
            inset-block-end: rem(80);
        }
    }

    &__el {
        display: none !important;

        @include screen(exxl) {
            display: inline-block !important;
            position: absolute;
            z-index: -1;

            &-1 {
                inset-inline-end: rem(46);
                inset-block-end: rem(34);
            }

            &-2 {
                inset-inline-start: rem(48);
                inset-block-end: rem(40);
            }

            &-3 {
                inset-inline-start: 50%;
                inset-block-start: rem(50);
                transform: translateX(-50%);
            }

            &-4 {
                inset-inline-start: rem(78);
                inset-block-start: rem(44);
            }

            &-5 {
                inset-inline-start: 40%;
                inset-block-start: rem(280);
                transform: translateX(-50%);
            }

            &-6 {
                inset-inline-end: 20%;
                inset-block-start: rem(100);
                transform: translateX(50%);
                z-index: 9999;
            }
        }
    }
}