.custom-checkbox {
    position: relative;
    border: 1px solid hsl(var(--neutral-50));
    border-radius: 2px;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    width: 1.25rem;
    height: 1.25rem;
    -webkit-appearance: none;
    opacity: .75;

    &:hover {
        opacity: 1;
    }

    &:checked {
        opacity: 1;

        &::before {
            content: '';
            position: absolute;
            right: 50%;
            top: 50%;
            width: 5px;
            height: 10px;
            border: solid hsl(var(--primary-300));
            border-width: 0 1px 1px 0;
            margin: -1px -1px 0 -1px;
            transform: rotate(45deg) translate(-50%, -50%);
            z-index: 2;
        }
    }

    &--rounded {
        border-radius: 50%;

        &:checked {
            background-color: hsl(var(--neutral-0));
        }
    }
}