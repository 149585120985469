@use "../global" as *;

.faq-section {
    position: relative;
    isolation: isolate;

    &__el {
        display: none;

        @include screen(exl) {
            display: block;
            position: absolute;
            z-index: -1;
        }

        &-1 {
            inset-block-start: rem(55);
            inset-inline-end: rem(70);
        }

        &-2 {
            inset-block-end: 0;
            inset-inline-start: 0;
        }
    }
}