@use "../global" as *;

.about-list {
    row-gap: 1rem;

    @include screen(sm) {
        column-gap: 2rem;
    }

    @include screen(md) {
        column-gap: 3rem;
    }

    @include screen(lg) {
        column-gap: 2.5rem;
    }

    @include screen(xxl) {
        column-gap: 3rem;
    }
}