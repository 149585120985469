.property-accordion {
    .custom-accordion__header {
        padding-top: 0;
        padding-bottom: 1.5rem;
        padding-inline: 0;
        position: relative;
        isolation: isolate;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--accordion-border-clr);

        &::after {
            content: '\e5cf';
            position: relative;
            font-family: 'Material Symbols Outlined';
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-grid;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            -webkit-font-feature-settings: 'liga';
            -webkit-font-smoothing: antialiased;
        }
    }

    .accordion-item {
        &:first-of-type {
            .accordion-button {
                padding: 0;
                border-radius: 0;
                background-color: transparent;
            }
        }
    }

    .accordion-button {
        font-size: clamp(1.6rem, 1.684vw + 1rem, 2rem);
        ;
        font-weight: 600;
        color: hsl(var(--neutral-700));

        &:focus {
            box-shadow: none;
        }

        &:not(.collapsed) {
            &::after {
                content: "\e5cf";
                background-color: transparent;
                color: hsl(var(--neutral-700));
                font-size: 2rem;
            }
        }

        &::after {
            content: "\e5ce";
            background-color: transparent;
            color: hsl(var(--neutral-700));
            font-size: 2rem;
        }
    }

    .accordion-body {
        padding: 0;
        margin-top: 1.5rem;
        border-top: 1px solid hsl(var(--neutral-40));

        &::after {
            display: none;
        }
    }
}