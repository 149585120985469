@use "../global" as *;

.about-img-el {
    display: none;
    @include screen(exxl) {
        display: block;
        position: absolute;
        z-index: -1;
        &--1 {
            inset-inline-end: #{rem(48)};
            inset-block-start: #{rem(48)};
        }
        &--2 {
            inset-inline-start: #{rem(48)};
            inset-block-end: #{rem(48)};
        }
    }
}