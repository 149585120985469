.avatar-upload {
    position: relative;
    max-width: 200px;

    &__edit {
        position: absolute;
        top: 1.25rem;
        right: 1.25rem;
        z-index: 1;
    }

    &__input {
        display: none;
    }

    &__label {
        display: grid;
        place-content: center;
        width: 34px;
        height: 34px;
        margin-bottom: 0;
        border-radius: 100%;
        background: hsl(var(--white));
        border: 1px solid hsl(var(--primary-100)/.75);
        cursor: pointer;
        font-weight: normal;
        transition: all .2s ease-in-out;

        &:hover {
            background: hsl(var(--primary-300));

            &::after {
                color: hsl(var(--neutral-0));
            }
        }

        &:after {
            content: "\e3c9";
            font-family: "Material Symbols Outlined";
            color: hsl(var(--primary-200));
            text-align: center;
            margin: auto;
            transition: all .2s ease-in-out;
        }
    }

    &__preview {
        width: 192px;
        height: 192px;
        position: relative;
        border-radius: 100%;
        border: 6px solid hsl(var(--primary-50)/.5);
        box-shadow: 0px 2px 4px 0px hsl(var(--black)/.1);
    }

    &__img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}