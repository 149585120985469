.team {
    &:hover {
        .team__member-img {
            filter: grayscale(100%);
        }

        .team__contact-list {
            visibility: visible;
            opacity: 1;
            pointer-events: unset;

            li {
                &:nth-child(2) {
                    inset-inline-start: 25%;
                    transform: translateX(-75%);
                }

                &:nth-child(3) {
                    inset-inline-start: auto;
                    inset-inline-end: 25%;
                    transform: translateX(75%);
                }
            }
        }
    }

    &__member {
        position: relative;
        isolation: isolate;

        &-img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            transition: all .3s ease;
        }
    }

    &__contact {
        position: absolute;
        inset-block-end: 0;
        inset-inline-start: 50%;
        transform: translate(-50%, 50%);
        z-index: 1;

        &-list {
            position: absolute;
            inset: 0;
            z-index: 2;
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            transition: all .3s ease;

            li {
                transition: all .3s ease;

                &:nth-child(1) {
                    position: absolute;
                    inset-block-end: 0;
                    inset-inline-start: 50%;
                    transform: translate(-50%, 50%);

                }

                &:nth-child(2) {
                    position: absolute;
                    inset-block-end: 0;
                    inset-inline-start: 50%;
                    transform: translate(-50%, 50%);

                }

                &:nth-child(3) {
                    position: absolute;
                    inset-block-end: 0;
                    inset-inline-start: 50%;
                    transform: translate(-50%, 50%);
                }
            }
        }
    }
}