.blog-details-slider {
    &__btn-group {
        display: flex;
        align-items: center;
        gap: .75rem;
        padding: 1.25rem 1.5rem;
        position: absolute;
        inset-inline-end: 0;
        inset-block-end: 0;
        background-color: hsl(var(--primary-300));
        border-radius: 1rem 0 1rem 0;
        z-index: 1;
    }

    &__btn {
        position: static;
        width: 40px;
        height: 40px;
        margin: 0;
        border: 1px solid hsl(var(--primary-50));
        color: hsl(var(--primary-50));
        border-radius: 50%;
        transition: all .3s ease;

        &:hover {
            background-color: hsl(var(--primary-50));
            color: hsl(var(--primary-300));
        }

        &::after {
            font-size: 20px;
        }
    }
}