.location-slider {
    &__card {
        --card-overlay: var(--neutral-900);
        position: relative;
        isolation: isolate;
        border-radius: 1rem;
        overflow: hidden;

        &:hover {
            --card-overlay: var(--primary-500);

            .location-slider__play {
                visibility: visible;
                opacity: 1;
                pointer-events: unset;
            }
        }
    }

    &__img {
        position: relative;
        isolation: isolate;

        &::after {
            content: '';
            position: absolute;
            inset: 0;
            background: linear-gradient(180deg, hsl(var(--card-overlay)/.0) 0%, hsl(var(--card-overlay)/.25) 46.59%, hsl(var(--card-overlay)/.9) 76.17%, hsl(var(--card-overlay)) 100%);
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 2rem 1.5rem;
        position: absolute;
        inset: 0;
    }

    &__play {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        transform: translate(-50%, -50%);
        transition: all .3s ease;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
    }

    &-container {
        position: relative;
    }

    &__pagination {
        bottom: -40px !important;
        .swiper-pagination-bullet {
            width: .75rem;
            height: .75rem;
            opacity: 1;
            background-color: hsl(var(--neutral-40));
            &-active {
                width: 1.75rem;
                height: .75rem;
                border-radius: 1rem;
                background-color: hsl(var(--primary-300));
            }
        }
    }
}