@use "../global/variables" as *;

@each $brand,
$value in $brand-colors {
	.clr-#{$brand} {
		background: hsl(var(--#{$brand}));
	}

	.\:clr-#{$brand} {
		transition: all 0.3s ease;

		&:hover {
			background: hsl(var(--#{$brand}));
		}
	}
}

@each $color,
$shades in $colors {

	@each $shade,
	$value in $shades {
		.clr-#{$color}-#{$shade} {
			color: hsl(var(--#{$color}-#{$shade}));
		}

		.\:clr-#{$color}-#{$shade}:hover {
			color: hsl(var(--#{$color}-#{$shade}));
		}
	}
}