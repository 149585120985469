@use "../global" as *;

.choice-slider {
    margin-block: -1rem;

    &__btn {
        --swiper-navigation-size: 20px;
        display: grid;
        place-content: center;
        width: 40px;
        height: 40px;
        position: unset;
        margin: 0;
        border: 1px solid hsl(var(--primary-300));
        border-radius: 50%;
        color: hsl(var(--primary-300));
        transition: all .3s ease;

        &:hover {
            background-color: hsl(var(--primary-300));
            color: hsl(var(--neutral-0));
        }
    }
}