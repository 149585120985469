@use "../global" as *;

.itinerary-card {
    &__number {
        position: relative;

        @include screen(xs) {
            &::after {
                content: '';
                position: absolute;
                inset: -.75rem;
                border: 1px dashed hsl(var(--primary-300));
                border-radius: 50%;
            }
        }
    }
}