@use "../global" as *;

.custom-switch {
    display: grid;

    &__input {
        position: relative;
        width: rem(64);
        height: rem(32);
        -webkit-appearance: none;
        background: hsl(var(--primary-50));
        outline: none;
        border: 1px solid hsl(var(--neutral-50)/.1);
        border-radius: 24px;
        transition: 0.7s;

        &:checked[type="checkbox"] {
            background: hsl(var(--primary-300));

            &::before {
                left: 2rem;
                background: hsl(var(--neutral-0));
            }
        }

        &::before {
            content: '';
            position: absolute;
            width: rem(24);
            height: rem(24);
            border-radius: 24px;
            top: 50%;
            left: rem(4);
            background: hsl(var(--primary-300));
            transform: translateY(-50%);
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
            transition: .5s;
        }
    }
}