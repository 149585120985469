@use "../global" as *;

.flight-hero {
    padding-top: clamp(4rem, 7vw, 10rem);
    padding-bottom: clamp(4rem, 7vw, 10rem);
    position: relative;
    isolation: isolate;

    &::after {
        @include screen(xl) {
            content: '';
            position: absolute;
            inset-block: 0;
            inset-inline-end: rem(60);
            inset-inline-start: 50%;
            background-image: url(../img/flight-hero-bg.png);
            background-size: contain;
            background-position: center top;
            background-repeat: no-repeat;
            z-index: -1;
        }
    }

    &__el {
        display: none !important;

        @include screen(exxl) {
            display: inline-block !important;
            position: absolute;
            z-index: -1;

            &-1 {
                inset-block-start: rem(60);
                inset-inline-start: rem(60);
            }

            &-2 {
                inset-inline-start: 50%;
                inset-block-start: 50%;
                transform: translate(-50%, -50%);
            }

            &-3 {
                inset-inline-end: 27%;
                inset-block-start: rem(100);
                transform: translateX(50%);
                z-index: 9999;
            }
        }
    }
}