.category-card {
    &:hover {

        span,
        h5,
        h4,
        h3,
        h2,
        a {
            color: hsl(var(--neutral-0));
        }

        .category-card__footer {
            background-color: hsl(var(--primary-500));
        }
    }

    &__footer {
        background-color: hsl(var(--primary-300)/.03);
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
    }
}