.range-slider {
    position: relative;
    width: 100%;
    height: 10px;
    border-radius: 15px;
    background: hsl(var(--neutral-40));

    &__progress {
        position: absolute;
        left: 25%;
        right: 0%;
        height: 100%;
        border-radius: 15px;
        background: hsl(var(--primary-300));
    }

    &__input {
        position: absolute;
        width: 100%;
        height: 10px;
        -webkit-appearance: none;
        pointer-events: none;
        background: none;
        outline: none;

        &::-webkit-slider-thumb {
            pointer-events: auto;
            -webkit-appearance: none;
            width: .5rem;
            height: 1.25rem;
            background: hsl(var(--neutral-0));
            border: 1px solid hsl(var(--neutral-50));
            border-radius: 1rem;
            opacity: 1;
            cursor: e-resize;
        }
    }

    &__value {
        display: flex;
        align-items: center;
        font-size: 14px;

        &.max-value {
            padding-inline-start: .5rem;
        }

        input {
            border: 0;
            background: transparent;
            width: 1.75rem;
        }
    }
}