@use "../global" as *;

.why-choose-overlay {
    inset-inline-start: 50%;
    inset-block-start: 50%;
    transform: translate(-50%, -50%);

    @include screen(exxl) {
        inset-inline-start: 0;
        inset-block-start: 2rem;
        transform: translateX(-50%);
    }
}