@use "../global/variables" as *;

@each $brand,
$value in $brand-colors {
	.bg-#{$brand} {
		background: hsl(var(--#{$brand}));
	}

	.\:bg-#{$brand} {
		transition: all 0.3s ease;

		&:hover {
			background: hsl(var(--#{$brand}));
		}
	}
}

@each $color,
$shades in $colors {

	@each $shade,
	$value in $shades {
		.bg-#{$color}-#{$shade} {
			background-color: hsl(var(--#{$color}-#{$shade}));
		}

		.\:bg-#{$color}-#{$shade}:hover {
			background-color: hsl(var(--#{$color}-#{$shade}));
		}
	}
}

.bg-primary-3p {
	background-color: hsl(var(--primary-300)/.03);
}

.bg-primary-5p {
	background-color: hsl(var(--primary-300)/.05);
}

.bg-primary-10p {
	background-color: hsl(var(--primary-300)/.1);
}

.bg-primary-15p {
	background-color: hsl(var(--primary-300)/.15);
}

.bg-primary-20p {
	background-color: hsl(var(--primary-300)/.2);
}

.bg-primary-30p {
	background-color: hsl(var(--primary-300)/.3);
}

.bg-primary-70p {
	background-color: hsl(var(--primary-300)/.7);
}

.bg-secondary-3p {
	background-color: hsl(var(--secondary-300)/.03);
}

.bg-secondary-5p {
	background-color: hsl(var(--secondary-300)/.05);
}

.bg-secondary-10p {
	background-color: hsl(var(--secondary-300)/.1);
}

.bg-secondary-15p {
	background-color: hsl(var(--secondary-300)/.15);
}

.bg-secondary-20p {
	background-color: hsl(var(--secondary-300)/.2);
}

.bg-secondary-30p {
	background-color: hsl(var(--secondary-300)/.3);
}

.bg-secondary-70p {
	background-color: hsl(var(--secondary-300)/.7);
}

.bg-tertiary-3p {
	background-color: hsl(var(--tertiary-300)/.03);
}

.bg-tertiary-5p {
	background-color: hsl(var(--tertiary-300)/.05);
}

.bg-tertiary-10p {
	background-color: hsl(var(--tertiary-300)/.1);
}

.bg-tertiary-15p {
	background-color: hsl(var(--tertiary-300)/.15);
}

.bg-tertiary-20p {
	background-color: hsl(var(--tertiary-300)/.2);
}

.bg-tertiary-30p {
	background-color: hsl(var(--tertiary-300)/.3);
}

.bg-tertiary-70p {
	background-color: hsl(var(--tertiary-300)/.7);
}