@use '../global' as *;

.menu {
    position: relative;
    isolation: isolate;

    &-nav {
        max-height: calc(100vh - 80px);
        overflow-y: auto;
        position: absolute;
        inset-inline: 0;
        inset-block-start: calc(100% - .5rem);
        background-color: hsl(var(--neutral-0));
        box-shadow: 0 .5rem 1rem hsl(var(--black)/.1);
        transition: all .3s ease;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        scrollbar-color: hsl(var(--neutral-30)/.5) hsl(var(--neutral-30)/.5);
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: .25rem;
            height: .25rem;
        }

        &::-webkit-scrollbar-track {
            background: hsl(var(--neutral-30)/.5);
        }

        &::-webkit-scrollbar-thumb {
            background: hsl(var(--neutral-30)/.5);
        }

        @include screen(lg) {
            max-height: none;
            overflow-y: unset;
            background-color: transparent;
            position: relative;
            z-index: 1;
            visibility: visible;
            opacity: 1;
            pointer-events: unset;
            box-shadow: none;
        }

        .has-sub {
            justify-content: space-between;
            gap: .25rem;

            &::after {
                content: '\e5cf';
                font-family: 'Material Symbols Outlined';
                font-size: 1.5rem;
                font-weight: 300;
                line-height: 1;
            }

            &.clear-content {
                &::after {
                    display: none;
                }
            }
        }
    }

    &-open {
        .menu-nav {
            z-index: 1;
            visibility: visible;
            opacity: 1;
            pointer-events: unset;
            inset-block-start: 100%;
        }
    }

    &-list {
        @include screen(lg) {
            position: relative;
            isolation: isolate;
        }

        &+.menu-list {
            .menu-link {
                border-top: 1px solid hsl(var(--neutral-50)/.5);

                @include screen(lg) {
                    border-top: none;
                }
            }
        }

        &:hover {
            .menu-link {
                color: hsl(var(--primary-300));
            }
        }

        &:hover {
            @include screen(lg) {
                .menu-link {
                    color: hsl(var(--primary-300));
                }

                .menu-sub {
                    inset-block-start: 100%;
                    pointer-events: unset;
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        &.current-page {
            >.menu-link {
                color: hsl(var(--primary-300));
            }
        }
    }

    &-link {
        display: flex;
        align-items: center;
        padding: .75rem 1rem;
        position: relative;
        isolation: isolate;
        color: hsl(var(--neutral-500));
        font-weight: 500;

        @include screen(lg) {
            padding: 1.5rem .75rem;
        }

        &.active {
            color: hsl(var(--primary-300));
        }
    }

    &-sub {
        transition: height 0.3s ease-in-out;
        overflow: hidden;

        @include screen(lg) {
            min-width: 200px;
            max-height: 245px;
            position: absolute;
            inset-block-start: calc(100% + .5rem);
            inset-inline-start: 0;
            background-color: hsl(var(--white));
            box-shadow: 0 .5rem 1rem hsl(var(--black)/.1);
            height: auto !important;
            pointer-events: none;
            overflow-x: unset;
            overflow-y: auto;
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s ease-in-out;
            scrollbar-color: hsl(var(--primary-75)/0.5) hsl(var(--neutral-50));
            scrollbar-width: thin;

            &::-webkit-scrollbar {
                width: 0.25rem;
                height: 0.25rem;
            }

            &::-webkit-scrollbar-track {
                background: hsl(var(--neutral-30) / 0.5);
            }

            &::-webkit-scrollbar-thumb {
                background: hsl(var(--primary-75)/0.5);
            }
        }

        &:not(.active) {
            display: none;

            @include screen(lg) {
                display: block;
            }
        }

        &-link {
            display: flex;
            align-items: center;
            padding: .75rem 1rem .75rem 2rem;
            position: relative;
            isolation: isolate;
            border-top: 1px solid hsl(var(--neutral-50)/.5);
            color: hsl(var(--neutral-500));
            transition: all 0.3s ease-in-out;

            @include screen(lg) {
                padding: .75rem 1rem;

                &:hover {
                    color: hsl(var(--primary-300));
                }
            }
        }
    }

    &-nav--secondary {
        background-color: hsl(var(--neutral-900));

        @include screen(lg) {
            background-color: transparent;
        }

        .menu-list.current-page>.menu-link {
            color: hsl(var(--secondary-300));
        }

        .menu-link {
            color: hsl(var(--neutral-30));

            @include screen(lg) {
                color: hsl(var(--neutral-0));

                &.active {
                    color: hsl(var(--secondary-300));
                }

                &:hover {
                    color: hsl(var(--secondary-300));
                }
            }
        }

        .menu-sub-link {
            color: hsl(var(--neutral-30));

            @include screen(lg) {
                color: hsl(var(--neutral-900));

                &:hover {
                    color: hsl(var(--secondary-300));
                }
            }
        }
    }
}