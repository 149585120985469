@use "../global" as *;

.auth-container {
    position: relative;
    isolation: isolate;

    &::after {
        @include screen(xl) {
            content: '';
            position: absolute;
            inset-inline: 0;
            inset-block-start: 40%;
            inset-block-end: 10%;
            background-color: hsl(var(--primary-300)/.04);
            z-index: -1;
        }
    }

    &--max {
        &::after {
            @include screen(xl) {
                inset-block-start: 0;
            }
        }
    }

    &__el {
        display: none;

        @include screen(exxl) {
            display: block;
            position: absolute;
            z-index: -1;
            opacity: .7;
        }

        &-1 {
            inset-inline-start: 0;
            inset-block-start: 50%;
        }

        &-2 {
            inset-inline-end: 0;
            inset-block-start: 50%;
        }

        &-3 {
            inset-inline-start: #{rem(94)};
            inset-block-start: #{rem(86)};
        }

        &-4 {
            inset-inline-end: #{rem(85)};
            inset-block-start: #{rem(72)};
        }

        &-5 {
            inset-inline-start: #{rem(80)};
            inset-block-end: #{rem(350)};
        }

        &-6 {
            inset-inline-start: 0;
            inset-block-end: 10%;
        }
    }
}