@use "../global/index" as *;

@each $size,
$value in $spacers {
    .gap-#{$size} {
        gap: #{$value} !important;
    }

    .p-#{$size} {
        padding: #{$value} !important;
    }

    .pt-#{$size} {
        padding-block-start: #{$value} !important;
    }

    .pb-#{$size} {
        padding-block-end: #{$value} !important;
    }

    .py-#{$size} {
        padding-block: #{$value} !important;
    }

    .ps-#{$size} {
        padding-inline-start: #{$value} !important;
    }

    .pe-#{$size} {
        padding-inline-end: #{$value} !important;
    }

    .px-#{$size} {
        padding-inline: #{$value} !important;
    }

    .m-#{$size} {
        margin: #{$value} !important;
    }

    .mt-#{$size} {
        margin-block-start: #{$value} !important;
    }

    .mb-#{$size} {
        margin-block-end: #{$value} !important;
    }

    .my-#{$size} {
        margin-block: #{$value} !important;
    }

    .ms-#{$size} {
        margin-inline-start: #{$value} !important;
    }

    .me-#{$size} {
        margin-inline-end: #{$value} !important;
    }

    .mx-#{$size} {
        margin-inline: #{$value} !important;
    }
}

@each $screen,
$screenSize in $screen-sizes {

    @each $size,
    $value in $spacers {
        @include screen(#{$screen}) {
            .gap-#{$screen}-#{$size} {
                gap: #{$value} !important;
            }

            .p-#{$screen}-#{$size} {
                padding: #{$value} !important;
            }

            .pt-#{$screen}-#{$size} {
                padding-block-start: #{$value} !important;
            }

            .pb-#{$screen}-#{$size} {
                padding-block-end: #{$value} !important;
            }

            .py-#{$screen}-#{$size} {
                padding-block: #{$value} !important;
            }

            .ps-#{$screen}-#{$size} {
                padding-inline-start: #{$value} !important;
            }

            .pe-#{$screen}-#{$size} {
                padding-inline-end: #{$value} !important;
            }

            .px-#{$screen}-#{$size} {
                padding-inline: #{$value} !important;
            }

            .m-#{$screen}-#{$size} {
                margin: #{$value} !important;
            }

            .mt-#{$screen}-#{$size} {
                margin-block-start: #{$value} !important;
            }

            .mb-#{$screen}-#{$size} {
                margin-block-end: #{$value} !important;
            }

            .my-#{$screen}-#{$size} {
                margin-block: #{$value} !important;
            }

            .ms-#{$screen}-#{$size} {
                margin-inline-start: #{$value} !important;
            }

            .me-#{$screen}-#{$size} {
                margin-inline-end: #{$value} !important;
            }

            .mx-#{$screen}-#{$size} {
                margin-inline: #{$value} !important;
            }
        }
    }
}