@use "../global" as *;

.quantity {
    display: flex;
    align-items: center;

    &__value {
        width: rem(48);
        border: none;
        background-color: transparent;
        text-align: center;
        font-weight: 600;
        color: hsl(var(--neutral-500));

        &:focus {
            outline: none;
        }
    }

    &__button {
        display: inline-block;
        padding: 0;
        border: none;
        background-color: transparent;
        line-height: 1;
    }
}