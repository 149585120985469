.custom-accordion {
    --accordion-gap: 1rem;
    --accordion-border-clr: hsl(var(--neutral-40));
    --accordion-header-bg: hsl(var(--white));
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--accordion-gap);

    &__header {
        cursor: pointer;
        padding: .75rem 1.5rem;
        background-color: var(--accordion-header-bg);
    }

    &__body {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
    }

    &__content {
        padding: .75rem 1.5rem;
        background-color: var(--accordion-header-bg);
        border: 1px solid var(--accordion-border-clr);
        border-top: 0;
    }
}