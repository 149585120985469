@use "../global" as *;

.help-section {
    position: relative;
    isolation: isolate;

    &::after {
        content: '';
        position: absolute;
        inset-inline: 0;
        inset-block-start: 0;
        inset-block-end: 0;
        background-color: hsl(var(--primary-300)/.05);
        z-index: -1;

        @include screen(xl) {
            inset-block-start: 65%;
        }
    }

}