@use "../global" as *;

.user-accordion {
    --bs-accordion-inner-border-radius: 1rem;
    --bs-accordion-active-bg: hsl(var(--white));
    --bs-accordion-active-color: hsl(var(--neutral-700));

    .accordion-body::after {
        background-image: linear-gradient(to right, hsl(var(--neutral-40)), hsl(var(--neutral-40)));

    }

    .accordion-button:not(.collapsed)::after {
        content: '\e5cf';
        background-color: transparent;
        color: hsl(var(--neutral-700));
    }

    .accordion-button::after {
        content: '\e5ce';
        background-color: transparent;
        color: hsl(var(--neutral-700));
    }

    .accordion-button:focus {
        border-color: transparent;
        box-shadow: unset;
    }
}