.property-gallery-slider {
    &:hover {
        .property-gallery-slider__btn {
            opacity: 1;
            pointer-events: unset;
        }
    }

    &__btn {
        --swiper-navigation-size: 40px;
        margin: 0;
        width: var(--swiper-navigation-size);
        height: var(--swiper-navigation-size);
        border: 1px solid hsl(var(--primary-300)/.5);
        border-radius: 50%;
        opacity: 1;
        background-color: hsl(var(--neutral-0));
        color: hsl(var(--primary-300));
        transition: all .3s ease;
        opacity: 0;
        pointer-events: none;

        &:hover {
            background: hsl(var(--primary-300));

            &::after {
                color: hsl(var(--neutral-0));
            }
        }

        &::after {
            font-size: 1.25rem;
            color: hsl(var(--primary-300)/.5);
        }
    }
}