@use "../global" as *;

.tour-hero {
    padding-top: clamp(4rem, 7vw, 10rem);
    padding-bottom: clamp(4rem, 7vw, 10rem);
    position: relative;
    isolation: isolate;

    &::before {
        @include screen(xl) {
            content: '';
            position: absolute;
            inset-block-start: rem(80);
            inset-block-end: rem(50);
            inset-inline-end: rem(80);
            inset-inline-start: 50%;
            background-image: url(../img/tour-hero-el.png);
            background-size: contain;
            background-position: center top;
            background-repeat: no-repeat;
            z-index: -2;
        }
    }

    &::after {
        @include screen(xl) {
            content: '';
            position: absolute;
            inset-block-start: 0;
            inset-block-end: 0;
            inset-inline-end: rem(60);
            inset-inline-start: 50%;
            background-image: url(../img/tour-hero-bg.png);
            background-size: contain;
            background-position: center top;
            background-repeat: no-repeat;
            z-index: -1;
        }
    }

    &__el {
        display: none !important;

        @include screen(exxl) {
            display: inline-block !important;
            position: absolute;
            z-index: -1;

            &-1 {
                inset-inline-end: 44.5%;
                inset-block-start: 6.75rem;
                transform: translateX(50%);
                z-index: 9999;
            }
        }
    }
}