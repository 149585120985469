@use "../global" as *;

.property-search {
    box-shadow: 0 6px 30px hsl(var(--black)/.08);

    &__select {
        border: 1px solid hsl(var(--neutral-40));
        background-color: hsl(var(--primary-300)/.03);

        .form-select {
            min-width: 130px;
            padding-block: .75rem;
            background-color: transparent;
            border: none;
            cursor: pointer;

            &:focus {
                box-shadow: none;
            }

            option {
                padding-block: .5rem;
            }
        }

    }

    &__price {
        position: relative;
        isolation: isolate;
        background-color: hsl(var(--primary-300)/.03);
        border: 1px solid hsl(var(--neutral-40));
    }

    &__range {
        max-width: 100px;

        &-input {
            -webkit-appearance: none;
            width: 100%;
            height: .25rem;
            background: hsl(var(--neutral-50));
            border-radius: 1rem;
            background-image: linear-gradient(hsl(var(--primary-300)), hsl(var(--primary-300)));
            background-size: 45% 100%;
            background-repeat: no-repeat;

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                border: 1px solid hsl(var(--primary-300)/.75);
                height: .75rem;
                width: .5rem;
                border-radius: .25rem;
                background-color: hsl(var(--white));
                cursor: ew-resize;
            }

            &::-moz-range-thumb {
                -webkit-appearance: none;
                border: 1px solid hsl(var(--primary-300)/.75);
                height: .75rem;
                width: .5rem;
                border-radius: .25rem;
                background-color: hsl(var(--white));
                cursor: ew-resize;
            }

            &::-ms-thumb {
                -webkit-appearance: none;
                border: 1px solid hsl(var(--primary-300)/.75);
                height: .75rem;
                width: .5rem;
                border-radius: .25rem;
                background-color: hsl(var(--white));
                cursor: ew-resize;
            }

            &::-webkit-slider-runnable-track {
                -webkit-appearance: none;
                box-shadow: none;
                border: none;
                background: transparent;
            }

            &::-moz-range-track {
                -webkit-appearance: none;
                box-shadow: none;
                border: none;
                background: transparent;
            }

            &::-ms-track {
                -webkit-appearance: none;
                box-shadow: none;
                border: none;
                background: transparent;
            }
        }

        &-price {
            display: flex;
            justify-content: center;
            padding: .25rem .5rem;
            background-color: hsl(var(--neutral-0));
            border: 1px solid hsl(var(--neutral-40)/.25);
            border-radius: 1rem;
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: 1rem;
            transform: translateY(-50%);
            box-shadow: 0 .25rem .5rem hsl(var(--black)/.08);
        }
    }

    &__btn {
        padding: .75rem 1.5rem;
        background-color: hsl(var(--primary-300));
        border: none;
        color: hsl(var(--neutral-0));
        line-height: 1;

        &:hover {
            background-color: hsl(var(--primary-300));
            border: none;
            color: hsl(var(--neutral-0));
        }

        &:focus {
            background-color: hsl(var(--primary-300));
            border: none;
            color: hsl(var(--neutral-0));
        }

        &:active {
            background-color: hsl(var(--primary-300)) !important;
            border: none;
            color: hsl(var(--neutral-0)) !important;
        }
    }

    &__col {
        width: 100%;

        @include screen(md) {
            width: calc(50% - .5rem);
        }

        @include screen(lg) {
            width: calc(25% - .75rem);
        }

        @include screen(xl) {
            width: auto;
        }

        &:last-child {
            @include screen(md) {
                width: 100%;
            }

            @include screen(xl) {
                width: auto;
            }
        }
    }
}