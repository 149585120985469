.faq-nav {
    --bs-list-group-item-padding-x: 1.25rem;
    --bs-list-group-item-padding-y: .75rem;
    --bs-list-group-active-bg: hsl(var(--primary-300));
    --bs-list-group-active-border-color: hsl(var(--primary-300));

    &__link {
        border: none;
        background-color: transparent;
        border-radius: 2rem !important;
        font-size: 18px;
        font-weight: 500;
    }
}